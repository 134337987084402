// eslint-disable-next-line require-jsdoc
import React, { useEffect } from 'react';
import TextField from '@ids/text-field';
import { CircleInfoIcon } from '@ids-ts/icon';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
import Checkbox from '@ids/checkbox';
import Tooltip from '@ids-beta/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
// import { convertJSONValuesToUpperCase } from '../../Util/CommonUtils';

// eslint-disable-next-line require-jsdoc
export const CompanyInfowithAttributes = (props) => {
  // const [componayInfo, setCompanyInfo] = useState('');
  const {
    allCaps,
    hideExtraline,
    hideExtraline2,
    FontConfig,
    TooltipCompanyInfo,
    extralinePlaceholder,
    extraline2Placeholder,
  } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { company } = customizationReducer;
  const dispatch = useDispatch();

  const handleTextAttribute = (key, param, value) => {
    dispatch({
      type: ReduxEvent.COMPANY_INFO_TEXT_CHANGED,
      data: {
        company: {
          ...company,
          [key]: {
            ...company[key],
            [param]: value,
          },
        },
      },
    });
  };

  const handleTextChanged = (key, value) => {
    dispatch({
      type: ReduxEvent.COMPANY_INFO_TEXT_CHANGED,
      data: {
        company: {
          ...company,
          [key]: allCaps ? value.toUpperCase() : value,
        },
      },
    });
  };

  useEffect(() => {
    // for (const property in FontConfig) {
    //   handleTextAttribute(property,'FontSize', FontConfig[property].DefaultValue);
    // }
    Object.keys(FontConfig).map((key) =>
      handleTextAttribute(key, 'FontSize', FontConfig[key].DefaultValue),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 className="margin-bottom-10 component-header">
        Enter your company information{' '}
        <Tooltip
          message={
            TooltipCompanyInfo || (
              <div>
                <span>
                  Company Name <br />
                  Address <br />
                  City, State and Zip
                  <br />
                  Phone Number
                  {!hideExtraline && (
                    <>
                      {' '}
                      <br />
                      Extra Line
                    </>
                  )}
                  {!hideExtraline2 && (
                    <>
                      {' '}
                      <br />
                      Extra Line
                    </>
                  )}
                </span>
                {allCaps && <div style={{ marginTop: '10px' }}>*Text is in all caps</div>}
              </div>
            )
          }
        >
          <span className="circle-info-span">
            <CircleInfoIcon size="medium" />
          </span>
        </Tooltip>
      </h3>
      <p className="optinal-text margin-bottom-20">Optional: Select font size, bold lines </p>
      <div className="company-information-text">
        <p className="company-info-header">
          <span className="custom-ids-text-field">Enter company information</span>
          <span className="width-font-size" style={{ marginRight: '0px;' }}>
            Size
          </span>
          <span>Bold</span>
        </p>
        <div>
          <TextField
            theme="quickbooks"
            type="text"
            placeholder="Company Name"
            className="custom-ids-text-field"
            value={company.CompanyName}
            onChange={(e) => handleTextChanged('CompanyName', e.target.value)}
          />
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-font-size dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            onChange={(e) =>
              handleTextAttribute('CompanyNameAttribute', 'FontSize', e.target.value)
            } // {handleChangeFontSize}
            value={company.CompanyNameAttribute.FontSize}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | company_name"
            data-ui-object-details="Pick company name"
          >
            {FontConfig.CompanyNameAttribute.FontSize.map((a) => {
              return (
                <MenuItem key={a} value={a} className={a}>
                  {a}
                </MenuItem>
              );
            })}
          </Dropdown>
          {FontConfig.CompanyNameAttribute.BoldFeature && (
            <div className="width-font-weight">
              <Checkbox
                theme="quickbooks"
                aria-label="select row"
                onChange={(e) =>
                  handleTextAttribute('CompanyNameAttribute', 'bold', e.target.checked)
                }
                checked={company.CompanyNameAttribute.bold}
              />
            </div>
          )}
        </div>
        <div>
          <TextField
            theme="quickbooks"
            type="text"
            placeholder="Address"
            className="custom-ids-text-field"
            value={company.AddressLine1}
            onChange={(e) => handleTextChanged('AddressLine1', e.target.value)}
          />
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-font-size dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            onChange={(e) =>
              handleTextAttribute('AddressLine1Attribute', 'FontSize', e.target.value)
            } // {handleChangeFontSize}
            value={company.AddressLine1Attribute.FontSize}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | company_addres_line1"
            data-ui-object-details="Pick address line 1"
          >
            {FontConfig.AddressLine1Attribute.FontSize.map((a) => {
              return (
                <MenuItem key={a} value={a} className={a}>
                  {a}
                </MenuItem>
              );
            })}
          </Dropdown>
          {FontConfig.AddressLine1Attribute.BoldFeature && (
            <div className="width-font-weight">
              <Checkbox
                theme="quickbooks"
                aria-label="select row"
                onChange={(e) =>
                  handleTextAttribute('AddressLine1Attribute', 'bold', e.target.checked)
                } // {handleChangeFontSize}
                checked={company.AddressLine1Attribute.bold}
              />
            </div>
          )}
        </div>
        <div>
          <TextField
            theme="quickbooks"
            type="text"
            placeholder="City, State and Zip"
            className="custom-ids-text-field"
            value={company.AddressLine2}
            onChange={(e) => handleTextChanged('AddressLine2', e.target.value)}
          />
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-font-size dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            onChange={(e) =>
              handleTextAttribute('AddressLine2Attribute', 'FontSize', e.target.value)
            } // {handleChangeFontSize}
            value={company.AddressLine2Attribute.FontSize}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | company_addres_line2"
            data-ui-object-details="Pick address line 2"
          >
            {FontConfig.AddressLine2Attribute.FontSize.map((a) => {
              return (
                <MenuItem key={a} value={a} className={a}>
                  {a}
                </MenuItem>
              );
            })}
          </Dropdown>
          {FontConfig.AddressLine2Attribute.BoldFeature && (
            <div className="width-font-weight">
              <Checkbox
                theme="quickbooks"
                aria-label="select row"
                onChange={(e) =>
                  handleTextAttribute('AddressLine2Attribute', 'bold', e.target.checked)
                } // {handleChangeFontSize}
                checked={company.AddressLine2Attribute.bold}
              />
            </div>
          )}
        </div>
        <div>
          <TextField
            theme="quickbooks"
            type="text"
            placeholder="Phone"
            className="custom-ids-text-field"
            value={company.Phone}
            onChange={(e) => handleTextChanged('Phone', e.target.value)}
          />
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-font-size dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            onChange={(e) => handleTextAttribute('PhoneAttribute', 'FontSize', e.target.value)} // {handleChangeFontSize}
            value={company.PhoneAttribute.FontSize}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | company_phone"
            data-ui-object-details="Pick phone"
          >
            {FontConfig.PhoneAttribute.FontSize.map((a) => {
              return (
                <MenuItem key={a} value={a} className={a}>
                  {a}
                </MenuItem>
              );
            })}
          </Dropdown>
          {FontConfig.PhoneAttribute.BoldFeature && (
            <div className="width-font-weight">
              <Checkbox
                theme="quickbooks"
                aria-label="select row"
                onChange={(e) => handleTextAttribute('PhoneAttribute', 'bold', e.target.checked)} // {handleChangeFontSize}
                checked={company.PhoneAttribute.bold}
              />
            </div>
          )}
        </div>

        {!hideExtraline && (
          <div>
            <TextField
              theme="quickbooks"
              type="text"
              placeholder={extralinePlaceholder || 'Extra Line (Optional)'}
              className="custom-ids-text-field"
              value={company.ExtraLine}
              onChange={(e) => handleTextChanged('ExtraLine', e.target.value)}
            />

            <Dropdown
              data-wa-link="quantity"
              theme="quickbooks"
              buttonType="secondaryGhost"
              className="width-font-size dropdown-background-color-white"
              width="inherit"
              aria-label="quantity"
              onChange={(e) =>
                handleTextAttribute('ExtraLineAttribute', 'FontSize', e.target.value)
              } // {handleChangeFontSize}
              value={company.ExtraLineAttribute.FontSize}
              data-ui-object="dropdown"
              data-refer-ui-access-point="customization_page | company_extra_line1"
              data-ui-object-details="Pick extra line 1"
            >
              {FontConfig.ExtraLineAttribute.FontSize.map((a) => {
                return (
                  <MenuItem key={a} value={a} className={a}>
                    {a}
                  </MenuItem>
                );
              })}
            </Dropdown>
            {FontConfig.ExtraLineAttribute.BoldFeature && (
              <div className="width-font-weight">
                <Checkbox
                  theme="quickbooks"
                  aria-label="select row"
                  onChange={(e) =>
                    handleTextAttribute('ExtraLineAttribute', 'bold', e.target.checked)
                  } // {handleChangeFontSize}
                  checked={company.ExtraLineAttribute.bold}
                />
              </div>
            )}
          </div>
        )}
        {!hideExtraline2 && (
          <div>
            <TextField
              theme="quickbooks"
              type="text"
              placeholder={extraline2Placeholder || 'Extra Line (Optional)'}
              className="custom-ids-text-field"
              value={company.ExtraLine2}
              onChange={(e) => handleTextChanged('ExtraLine2', e.target.value)}
            />

            <Dropdown
              data-wa-link="quantity"
              theme="quickbooks"
              buttonType="secondaryGhost"
              className="width-font-size dropdown-background-color-white"
              width="inherit"
              aria-label="quantity"
              onChange={(e) =>
                handleTextAttribute('ExtraLine2Attribute', 'FontSize', e.target.value)
              } // {handleChangeFontSize}
              value={company.ExtraLine2Attribute.FontSize}
              data-ui-object="dropdown"
              data-refer-ui-access-point="customization_page | company_extra_line2"
              data-ui-object-details="Pick extra line 2"
            >
              {FontConfig.ExtraLine2Attribute.FontSize.map((a) => {
                return (
                  <MenuItem key={a} value={a} className={a}>
                    {a}
                  </MenuItem>
                );
              })}
            </Dropdown>
            {FontConfig.ExtraLine2Attribute.BoldFeature && (
              <div className="width-font-weight">
                <Checkbox
                  theme="quickbooks"
                  aria-label="select row"
                  onChange={(e) =>
                    handleTextAttribute('ExtraLine2Attribute', 'bold', e.target.checked)
                  } // {handleChangeFontSize}
                  checked={company.ExtraLine2Attribute.bold}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
