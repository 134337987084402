// eslint-disable-next-line require-jsdoc
import React from 'react';
import Button from '@ids/button';

// eslint-disable-next-line require-jsdoc
export const AddLogoOption = (props) => {
  const { ID, Title, SubTitle, ButtonText } = props;

  return (
    <div className="margin-top-55">
      <h4 className="logo-option-title margin-bottom-10">{Title}</h4>
      <p className="logo-option-sub-title margin-bottom-20">{SubTitle}</p>
      <Button
        buttonType="primary"
        theme="quickbooks"
        // disabled={loading}
        onClick={() => props.onButtonClick(ID)}
        // data-wa-link="cancelccinfo"
      >
        {ButtonText}
      </Button>
    </div>
  );
};
