import { FeatureConfiguration } from '../../../assets/pagedata/constant';
import { loggingOnServerex } from '../../services/commonDataService';

// var newrelic = newrelic || {};
let freshpageLoad = true;
const bandwidth = 0; // bandwidth
// var lastBandwidthNotedTime = new Date().getTime();  //milliseconds.
// var self = this;
let resourceTimings = [];
const configuration = window.configuration || FeatureConfiguration;

const getActiveModules = (f) => {
  const j = [];
  /* code to check splunk issue >> */
  const d = !!f && !!configuration[f] ? configuration[f].length : 0;
  if (d === 0) console.log(`Unable to load module ${f}`);
  /* << code to check splunk issue */
  for (let g = 0; g < d; g++) {
    const h = configuration[f][g];
    if (h.status === 'on') {
      j.push(h.name);
    }
  }
  return j;
};

const findFeature = (d) => {
  // eslint-disable-next-line no-use-before-define
  const activeFeatures = getActiveModules('features');
  return activeFeatures.includes(d);
};

// get duration from last click of link if possible
const getTheMarkedLinkClicked = () => {
  let marker = null;
  const markers = window.performance.getEntriesByType('mark');
  for (let i = 0; i < markers.length; i++) {
    if (markers[i].name.indexOf('link_') !== -1) {
      marker = markers[i];
    }
  }
  return marker;
};

// get duration from last click of link if possible
const getTheRouteChanged = () => {
  let marker = null;
  const markers = window.performance.getEntriesByType('mark');
  for (let i = 0; i < markers.length; i++) {
    if (markers[i].name.indexOf('route_') !== -1) {
      if (markers[i].name !== 'route_,') {
        marker = markers[i];
      }
    }
  }
  return marker;
};

// get the short name of the url without the querystring
const getShortName = (url) => {
  const indexOfQuery = url.indexOf('?');
  if (indexOfQuery !== -1) {
    url = url.slice(0, indexOfQuery);
  }
  const shortname = `${url.slice(0, 20)}...${url.slice(-20)}`;
  return shortname;
};

// add resources to json object
const getResourcesJSON = (resources, isIFrame) => {
  const markedLink = getTheMarkedLinkClicked();
  const markedRoute = getTheRouteChanged();
  let markedStartTime = 0; // start time
  // var iframe = document.querySelector('#iframecres');
  if (!freshpageLoad) {
    // if a marked link is clicked
    if (markedLink) {
      markedStartTime = markedLink.startTime;
    } else if (markedRoute) {
      // else if a route change happened for not a marked link
      markedStartTime = markedRoute.startTime;
    }
  }
  if (resources === null) return;
  for (let i = 0; i < resources.length; i++) {
    // if markedStartTime is 0 then its a fresh page load
    let startTime = parseInt(resources[i].startTime - markedStartTime);
    let duration = parseInt(resources[i].duration);
    let shortName = getShortName(resources[i].name);
    if (isIFrame) {
      startTime = parseInt(resources[i].startTime); // should happen close to route change in parent window. done for lack of a better estimate.
      duration = parseInt(resources[i].duration);
      shortName = getShortName(resources[i].name);
    }
    const resource = { n: shortName, st: startTime, d: duration };
    resourceTimings.push(resource);
  }
};

// get all the resource timings from page or if application iframe exists
const getResourceTimings = () => {
  resourceTimings = [];
  let resources = window.performance.getEntriesByType('resource');
  getResourcesJSON(resources, false);

  const iframe = document.querySelector('#iframecres');
  if (
    iframe &&
    iframe.nodeName === 'IFRAME' &&
    iframe.contentWindow &&
    iframe.contentWindow.performance
  ) {
    resources = iframe.contentWindow.performance.getEntriesByType('resource');
    getResourcesJSON(resources, true);
  }

  if (JSON) {
    return JSON.stringify(resourceTimings);
  }

  return 'no resources';
};

// the marker string
export const getMarkers = (m, startTime) => {
  let message = ' ';
  if (findFeature('usertimings')) {
    message = ' WITH MARKERS: ';
    const markers = [];
    for (let i = 0; i < m.length; i++) {
      const markerDuration = parseInt(m[i].startTime - startTime);
      const marker = { n: m[i].name, st: markerDuration };
      markers.push(marker);
    }
    message += JSON.stringify(markers);
  }
  return message;
};

export const wtt = (n, t, b, m) => {
  let message = '';
  t = Math.round(t);
  let t1 = -1;
  let t2 = -1;
  const markedLink = getTheMarkedLinkClicked();
  const markedRoute = getTheRouteChanged();
  if (markedLink) {
    t1 = t - markedLink.startTime;
  }
  if (markedRoute) {
    t2 = t - markedRoute.startTime;
  }
  let rTimings = '';
  if (findFeature('resourcetimings')) {
    rTimings = getResourceTimings();
  }

  if ((t1 >= 0 && t1 < 3600000) || (t2 >= 0 && t2 < 3600000) || (t >= 0 && t < 3600000)) {
    if (markedLink) {
      message = `TIME FOR LINK:${markedLink.name} TO LOAD:${t1} MILLISECONDS with bandwidth:${bandwidth} mbps`;
      message += getMarkers(m, markedLink.startTime);
      message = `${message} AND THE BROWSER USER AGENT IS: ${navigator.userAgent}, resourceTimings:${rTimings}`;
      // call imweblogger
      loggingOnServerex('info', message);
    } else if (freshpageLoad) {
      message = `FRESH PAGE LOAD:${window.location.pathname}, TIME TAKEN:${t} MILLISECONDS with bandwidth:${bandwidth} mbps`;
      message += getMarkers(m, 0);
      message = `${message} AND THE BROWSER USER AGENT IS: ${navigator.userAgent}, resourceTimings:${rTimings}`;
      // call imweblogger
      loggingOnServerex('info', message);
      freshpageLoad = false;
    } else if (markedRoute) {
      message = `TIME FOR ROUTE:${markedRoute.name} TO LOAD:${t2} MILLISECONDS with bandwidth:${bandwidth} mbps`;
      message += getMarkers(m, markedRoute.startTime);
      message = `${message} AND THE BROWSER USER AGENT IS: ${navigator.userAgent}, resourceTimings:${rTimings}`;
      // call imweblogger
      loggingOnServerex('info', message);
    }
  }
  // schedule next bandwidth call.
  // if (window.IMConfig && window.IMConfig.ff('bandwidth') && window.requestIdleCallback) {
  //     requestIdleCallback(calculateBandwidth);
  // }
};

export const utReportRUM = (b) => {
  const m = window.performance.getEntriesByType('mark');
  const lm = {};
  let g;
  for (let i = 0; i < m.length; i++) {
    g = 'usertiming';
    if (lm[g] === undefined || m[i].startTime > lm[g]) lm[g] = m[i].startTime;
    // eslint-disable-next-line  no-useless-escape
    const p = m[i].name.match(/([^\.]+)\.([^\.]*)/);
    if (p && p.length > 2 && (lm[p[1]] === undefined || m[i].startTime > lm[p[1]]))
      lm[p[1]] = m[i].startTime;
  }

  // eslint-disable-next-line  no-restricted-syntax
  for (g in lm) {
    wtt(g, lm[g], b, m);
  }
};

export const sendData = () => {
  try {
    if (!window.performance) {
      return;
    }

    utReportRUM(false);
    // clear all previous marks & measures when a link is clicked.
    window.performance.clearMarks();
    window.performance.clearResourceTimings();
    const iframe = document.querySelector('#iframecres');
    if (
      iframe &&
      iframe.nodeName === 'IFRAME' &&
      iframe.contentWindow &&
      iframe.contentWindow.performance
    ) {
      iframe.contentWindow.performance.clearResourceTimings();
    }
  } catch (e) {
    console.log(e);
  }
};

export const lproutechange = () => {
  try {
    if (window.lpTag) {
      let url;
      if (window.history.pushState) url = window.top.location.origin;
      else url = `${window.top.location.protocol}//${window.top.location.hostname}`;

      if (window.lpTag.newPage) {
        window.lpTag.newPage(url, {
          section: ['FSG'],
          sdes: [],
          taglets: {},
        });
      }
    }
  } catch (e) {
    console.log(`LivePerson rote change Exception:${e}`);
  }
};

// const calculateBandwidth = (deadline) => {
//     var currentTime = new Date().getTime();
//     var timeLapsed = currentTime - lastBandwidthNotedTime;
//     if (deadline.timeRemaining() > 0) {
//         if ((timeLapsed > 1000000) || (bandwidth == 0)) {
//             //calculate bandwidth using xhr request.
//             var start = new Date().getTime();;
//             var XHRObj = $.ajax({
//                 type: 'GET',
//                 cache: false,
//                 url: "https://dev.sd.intuit.com/fsg_repository/abimages/new/a.gif",
//                 success: function (data) {
//                     var size = XHRObj.getResponseHeader('Content-Length');
//                     var timeTaken = new Date().getTime() - start;
//                     bandwidth = (size * 8) / (1000 * timeTaken); //mbps
//                     lastBandwidthNotedTime = new Date().getTime();
//                 }
//             });
//         }
//     }
// };
