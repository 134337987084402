// eslint-disable-next-line require-jsdoc
import { combineReducers } from 'redux';
import productListReducer from './ProductListReducer.jsx';
import mainReducer from './MainReducer.jsx';
import myAccountReducer from './MyAccountReducer';
import orderStatusReducer from './OrderStatusReducer';
import retrieveOrderReducer from './RetrieveOrderReducer';
import customizationReducer from './CustomizationReducer';
import checkoutReducer from './CheckoutReducer';

const combinedReducer = combineReducers({
  mainReducer,
  productListReducer,
  myAccountReducer,
  orderStatusReducer,
  retrieveOrderReducer,
  customizationReducer,
  checkoutReducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
