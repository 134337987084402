import {
  createHTMLNode,
  getCookie,
  removeCookie,
  maskString,
  setDocumentCookie,
  getSessionStorage,
  getAccountRecoveryUrl,
  setCookie,
} from './CommonUtils';

import { signInWidgetName, ReduxEvent } from '../../../assets/pagedata/constant';
// import { getEnvironmentConfig } from '../../services/config/restClientEnvConfig';
import { shopperDataService } from '../../services/shopperDataService';
import store from '../../redux/reducers/store';
import { getPageData } from '../../services/commonDataService';
import { getEnvironmentConfig } from '../../services/config/restClientEnvConfig';

const prefix = '/';
const loadDefaultWidgetConfig = (location) => {
  const signinhelp = getAccountRecoveryUrl();
  // var root = "";
  // var rootLink = "";
  let prefixLink = '';
  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }
  // var createaccountlnk = prefixLink + prefix + 'iussignup';
  // var migrateaccountlnk = prefixLink + prefix + 'iusupgrade';
  // var signinhelplnk = prefixLink + prefix + 'signinhelp';
  // var isBM = getCookie("bm");
  // if ("1" === isBM) {
  //     createaccountlnk = prefix + 'ipdiussignup';
  //     migrateaccountlnk = prefix + 'ipdiusupgrade';
  //     signinhelplnk = prefix + 'ipdiussigninhelp';
  // }
  const widgetConfig = {
    widgetName: signInWidgetName.GlobalNavSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iussignup`,
    LoginSuccessRedirectLink: `${prefix}orderhistory`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}orderhistory`,
    onSignInSuccess: () => {
      // Setting the cookie 'is_p' on sign in success
      setDocumentCookie('is_p', 1);
      // $('#reorderSignedOut').hide();
      // $('#reorderSignedIn').show();
    },
  };
  // TODO: Need to check if we need it.
  // if ("1" == isBM) {
  //     var root = "";
  //     var rootLink = "";
  //     if (location.pathname.indexOf("/checkstaxipd") === 0) root = "/checkstaxipd";
  //     else if (location.pathname.indexOf("/ipd") === 0) root = "/ipd";
  //     if (!window.history.pushState)
  //         rootLink = root + "#";
  //     else
  //         rootLink = root + prefix;
  // }
  return widgetConfig;
};

export const loadCheckoutSignInWidget = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.CartSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iussignup`,
    LoginSuccessRedirectLink: `${prefix}shipping`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}shipping`,
    onSignInSuccess() {
      // $('#reorderSignedOut').hide();
      // $('#reorderSignedIn').show();
    },
  };

  setDocumentCookie('redirecturl', `${prefix}shipping`);

  return widgetConfig;
};

export const loadCartSignInWidget = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.CartSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iussignup`,
    LoginSuccessRedirectLink: `${prefix}cart`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}shipping`,
    onSignInSuccess() {
      // $('#reorderSignedOut').hide();
      // $('#reorderSignedIn').show();
    },
  };
  return widgetConfig;
};

export const loadReorderChecksInWidget = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.ReorderChecksSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    LoginSuccessRedirectLink: `${prefix}orderhistory`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}orderhistory`,
    onSignInSuccess() {
      // $('#reorderSignedOut').hide();
      // $('#reorderSignedIn').show();
    },
  };

  setDocumentCookie('redirecturl', `${prefix}orderhistory`);

  return widgetConfig;
};

export const loadWelcomeSignin = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.WelcomeBackSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    LoginSuccessRedirectLink: `${prefix}orderhistory`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}orderhistory`,
    onSignInSuccess() {
      // $('#reorderSignedOut').hide();
      // $('#reorderSignedIn').show();
    },
  };

  setDocumentCookie('redirecturl', `${prefix}orderhistory`);

  return widgetConfig;
};

const loadInterruptiveXSellSignInWidget = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.GlobalNavSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iussignup`,
    LoginSuccessRedirectLink: `${prefix}orderhistory`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}orderhistory`,
    onSignInSuccess() {},
  };

  setDocumentCookie('redirecturl', `${prefix}orderhistory`);

  return widgetConfig;
};

export const loadConfirmationPageSignInWidget = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.GlobalNavSignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iussignup`,
    LoginSuccessRedirectLink: `${prefix}orderhistory`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}orderhistory`,
    receiptLnk: 'true',
    srcPage: 'confirmation',
    onSignInSuccess() {
      setDocumentCookie('is_p', 1);
    },
  };

  setDocumentCookie('redirecturl', `${prefix}orderhistory_fromipdconf`);
  return widgetConfig;
};

export const loadUploadLogoSignInWidget = (location, signInSuccessCallback) => {
  let prefixLink = '';
  const signinHelp = getAccountRecoveryUrl();

  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }

  const widgetConfig = {
    widgetName: signInWidgetName.LogoUploadSignIn,
    BottomLink1: signinHelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iusupgrade`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}shipping`,
    onSignInSuccess: () => {
      if (signInSuccessCallback) {
        signInSuccessCallback();
      }
    },
  };

  setDocumentCookie('redirecturl', `${prefix}shipping`);

  return widgetConfig;
};

export const loadOrderDetailWidgetConfig = (location) => {
  let prefixLink = '';
  const signinhelp = getAccountRecoveryUrl();
  prefixLink = location.origin;
  if (prefixLink === undefined) {
    prefixLink = `${location.protocol}//${location.hostname}`;
  }
  const widgetConfig = {
    widgetName: signInWidgetName.SignIn,
    BottomLink1: signinhelp,
    BottomLink1Text: "Can't access your account?",
    BottomLink2: `${prefix}retrievepreviousorder`,
    BottomLink2Text: 'Retrieve a previous order',
    CreateAccountLink: `${prefix}iussignup`,
    LoginSuccessRedirectLink: `${prefix}orderhistory`,
    MigrateAccountLink: `${prefix}iusupgrade`,
    nextPageLink: `${prefix}orderhistory`,
    onSignInSuccess: () => {
      // Setting the cookie 'is_p' on sign in success
      setDocumentCookie('is_p', 1);
      // $('#reorderSignedOut').hide();
      // $('#reorderSignedIn').show();
    },
  };
  return widgetConfig;
};

export const getSignInWidgetConfig = (location, widget_Config) => {
  let config;
  const config_match = widget_Config || location.pathname;
  switch (config_match) {
    case '/routepathgoeshere':
      break;
    case '/interruptivexsell':
      config = loadInterruptiveXSellSignInWidget(location);
      break;
    default:
      config = loadDefaultWidgetConfig(location);
      break;
  }
  return config;
};

const insertAfter = (newNode, existingNode) => {
  existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
};

export const setUpBottomLink1 = (url, widgetConfig) => {
  const a = createHTMLNode(
    'a',
    { onclick: `window.top.location='${url}'` },
    { text: widgetConfig.BottomLink1Text },
  );
  const p = createHTMLNode('p');
  p.className += 'bottom-link-1';
  p.setAttribute('data-wa-link', '');
  p.append(a);

  const iusSigninListNode = document.querySelector('.ius-sign-in-list');
  insertAfter(p, iusSigninListNode);
};
export const setUpBottomLink2 = (widgetConfig) => {
  const a = createHTMLNode(
    'a',
    { onclick: `window.top.location='${widgetConfig.BottomLink2}'` },
    { text: widgetConfig.BottomLink2Text },
  );
  const iusSignUpSpan = createHTMLNode('span', null);
  const p = createHTMLNode('p');
  p.className += 'bottom-link-2';
  p.appendChild(a);
  p.appendChild(iusSignUpSpan);
  p.setAttribute('data-wa-link', '');

  const bottomLink1Node = document.querySelector('.bottom-link-1');
  insertAfter(p, bottomLink1Node);
};
/**
 * Sign in success helper method.
 * @param {any} widgetConfig Widget config object
 * @param {any} widgetRef React ref for Widget
 * @param {*} res Sign in success response
 * @param {*} props Component props
 */
export const signInSuccess = (widgetConfig, widgetRef, res, props, dispatch) => {
  let pageData;
  let userJson;
  // Commenting the below logic as it was meant for IPD flow
  // const stayBackRoutes = ['/shipping'];
  try {
    if (window.intuit.tracking !== undefined) {
      window.intuit.tracking.ecs.webAnalytics.identify({
        AuthId: res.userId,
      });
    }
  } catch (error) {
    props.logToIMWebLogger(
      'ERROR',
      `Flow=Eventbus Opperation=identify Component=SigninUtil.jsx Status=error Message=${error}`,
    );
  }
  if (widgetConfig.iusimUser === 'true') {
    userJson = {
      UserId: document.getElementById('ius-userid').value,
      Email: {
        address: '',
      },
      Id: 'xxxx',
      GuestShopperId: '',
      AuthId: res.userId,
      SessionId: '',
      OrderId: '',
    };
  } else {
    userJson = {
      UserId: document.getElementById('ius-userid').value,
      Email: {
        address: '',
      },
      Id: '',
      GuestShopperId: '',
      AuthId: res.userId,
      SessionId: '',
      OrderId: '',
    };
  }
  //  Jabba test evar track, the call is made on load
  // var isBM = getCookie('bm');
  const linkData = {
    linkName: 'login',
    eventList: 'login',
  };
  if (typeof window.wa.trackLinkPlus !== 'undefined') {
    window.wa.trackLinkPlus(this, linkData);
  }
  // this should be refactered
  const objURL = {};
  let imprint = '';
  // eslint-disable-next-line no-unused-vars
  let reorderFlow = '0';
  if (window.location.hash.indexOf('?') >= 0) {
    const str = window.location.hash.substring(
      window.location.hash.indexOf('?'),
      window.location.hash.length,
    );
    str.toLowerCase().replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => {
      if ($1 === 'reorder' && $2 === '1') {
        reorderFlow = '1';
      }
      objURL[$1] = $3;
    });
  }
  if (
    objURL &&
    (objURL.new_imprint_id || objURL.imprintid || objURL.imprint_id || objURL.imprint_instance_id)
  ) {
    imprint =
      objURL.new_imprint_id || objURL.imprintid || objURL.imprint_id || objURL.imprint_instance_id;
    if (reorderFlow === '1') {
      if (typeof getCookie('imprintid') !== 'undefined') {
        userJson.imprintId = getCookie('imprintid');
        removeCookie('imprintid');
      }
    } else if (imprint !== -1) userJson.imprintId = imprint;
  } else {
    const redirecturl = getCookie('redirecturl');
    if (
      !!redirecturl &&
      redirecturl.indexOf('_fromipdconf') > -1 &&
      widgetConfig.srcPage === 'confirmation'
    ) {
      if (typeof getCookie('imprintID_copy') !== 'undefined') {
        userJson.imprintId = getCookie('imprintID_copy');
        removeCookie('imprintID_copy');
      }
    } else if (typeof getCookie('imprintid') !== 'undefined') {
      userJson.imprintId = getCookie('imprintid');
      removeCookie('imprintid');
    }
  }
  /*
   * Sign-in or Sign up @ receipt page: set widgetConfig.receiptLnk to true.
   */
  if (widgetConfig.receiptLnk === 'true' && !!getCookie('_lastOrder')) {
    userJson.OrderId = getCookie('_lastOrder').orderData.OrderNumber;
  }
  /*
   * Sign-in @ value pack logo upload: pass all imprints associated with the bundle and update shopper for all.
   */
  const bundle_Imprints = getSessionStorage('Bundle_Imprints')
    ? getSessionStorage('Bundle_Imprints').value
      ? JSON.parse(getSessionStorage('Bundle_Imprints').value)
      : ''
    : '';
  if (bundle_Imprints) {
    userJson.bundle_imprintIds = bundle_Imprints;
  }
  shopperDataService
    .updateShopper({ data: userJson })
    .then(() => {
      props.closeModal();
      props.showLoader && props.showLoader(true);
      getPageData().then((pageRes) => {
        let pageInfo;
        if (typeof pageRes === 'string') {
          pageInfo = JSON.parse(pageRes);
        } else {
          pageInfo = pageRes;
        }
        dispatch({
          type: ReduxEvent.PAGE_DATA,
          data: {
            pageData: {
              cartCount: pageInfo.CartCount,
              login: pageInfo.login,
              promotionMessage: pageInfo.PromotionMessage,
            },
          },
        });
      });
      if (typeof widgetConfig.onSignInSuccess === 'function') {
        widgetConfig.onSignInSuccess();
      }
      props.logToIMWebLogger(
        'Info',
        `IusLoginWidget: redirecting to login success url:${widgetConfig.LoginSuccessRedirectLink}`,
      );
      if (
        widgetConfig.LoginSuccessRedirectLink !== null &&
        widgetConfig.LoginSuccessRedirectLink !== undefined &&
        widgetConfig.LoginSuccessRedirectLink !== ''
      ) {
        // If the URL contains .aspx, that means the routes are not present for that. Backbone.history.navigate won't work in this case
        if (
          window.top.Backbone &&
          window.top.Backbone.history &&
          (window.top.location.pathname.indexOf('.aspx') === -1 ||
            window.location.pathname.indexOf('ipd') >= 0)
        ) {
          const isBM = getCookie('bm');
          let prefixLink = window.location.origin;
          if (prefixLink === undefined) {
            prefixLink = `${window.location.protocol}//${window.location.hostname}`;
          }
          let rootLink = '';
          if (
            isBM &&
            isBM === '1' &&
            (window.location.pathname.indexOf('/checkstaxipd') === 0 ||
              window.location.pathname.indexOf('/ipd') === 0)
          ) {
            window.wa.ttTestId = 'im_ipd_banamid_3302_Test_Flow_returning';
            window.top.Backbone.history.navigate(widgetConfig.LoginSuccessRedirectLink, {
              trigger: true,
              replace: true,
            });
          } else if (
            window.location.pathname.indexOf('/checkstaxipd') === 0 ||
            window.location.pathname.indexOf('/ipd') === 0
          ) {
            if (!window.history.pushState) rootLink = `${prefixLink}#`;
            else rootLink = `${prefixLink}/`;
            window.wa.ttTestId = 'im_ipd_banamid_3302_Coltrol_GBB_page_returning';
            if (typeof window.wa.trackPage !== 'undefined') {
              pageData = {
                pagePath: 'orderhistory',
              };
              window.wa.trackPage(pageData);
            }
            widgetConfig.LoginSuccessRedirectLink = `${rootLink}orderhistory`;
            window.open(widgetConfig.LoginSuccessRedirectLink, '_self');
          } else {
            window.open(widgetConfig.LoginSuccessRedirectLink, '_self');
          }
        }
        // When user directly goes to brand4 using URL
        else {
          // Commenting the below logic as it was meant for IPD flow
          /* const stayBackAfterSigIn = stayBackRoutes.find((route) => {
            return window.location.pathname.indexOf(route) === 0;
          });
          if (stayBackAfterSigIn) {
            dispatch({
              type: ReduxEvent.SIGN_IN_SUCCESS,
              data: {
                signinSuccess: true,
              },
            });
          } else {
            window.open(widgetConfig.LoginSuccessRedirectLink, '_self');
          } */
          window.open(widgetConfig.LoginSuccessRedirectLink, '_self');
        }
      }
    })
    .catch((err) => {
      props.logToIMWebLogger('Error', `IusLoginWidget: failed at dataservice.updateShopper:${err}`);
    });
};
/**
 * Helper method for sign in widget on load
 * @param {any} widgetRef React ref for IUS Widget
 * @param {any} widgetConfig Loading widget configuratiom
 * @param {func} setShowSubText2 Toggle Show Sub Text
 * @param {func} setShowNewUserHeader Toggle Show New User Header
 * @param {func} setShowPreOrderHeader Toggel show pre order header
 * @param {func} setShowSubText1 Toggle show subtext1
 */
export const onWidgetLoad = (
  widgetRef,
  widgetConfig,
  setShowSubText2,
  setShowNewUserHeader,
  setShowPreOrderHeader,
  setShowSubText1,
) => {
  const signInButton = widgetRef.current.getElementsByClassName('ius-btn').item(0);
  signInButton.style.background = '#2ca01c';
  signInButton.setAttribute('value', 'SIGN IN');
  signInButton.setAttribute('data-clicked-text', 'SIGNING IN ...');
  signInButton.setAttribute('data-wa-link', '');
  signInButton.setAttribute('data-wa-event', 'loginAttempt');
  signInButton.setAttribute(
    'style',
    'background-color: #2ca01c !important;' +
      'font-family: "AvenirNextDemi", "HelveticaNeue","Helvetica Neue","Helvetica","Arial",sans-serif !important;' +
      'background-image: -webkit-gradient(linear,50% 0,50% 100%,color-stop(0,#2ca01c),color-stop(100%,#2ca01c)) !important;' +
      'background-image: -webkit-linear-gradient(top,#2ca01c 0,#2ca01c 100%) !important;' +
      'background-image: -moz-linear-gradient(top,#2ca01c 0,#2ca01c 100%) !important;' +
      'background-image: -o-linear-gradient(top,#2ca01c 0,#2ca01c 100%) !important;' +
      'background: linear-gradient(to bottom,#2ca01c 0,#2ca01c 100%) !important;' +
      'filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2ca01c", endColorstr="#2ca01c", GradientType=0) !important;',
  );
  let pageData;
  const url = widgetConfig.BottomLink1;
  // Customization of IUS Dialog according to IM.
  setUpBottomLink1(url, widgetConfig, widgetRef);
  setUpBottomLink2(widgetConfig, widgetRef);
  // const { a, iusSignUpSpan } = setUpBottomLink2(widgetConfig, widgetRef);
  setShowSubText2(false);
  if (widgetConfig.widgetName === signInWidgetName.GlobalNavSignIn) {
    pageData = {
      pagePath: 'sign-in.pop',
    };
  }
  if (widgetConfig.widgetName === signInWidgetName.SignIn) {
    pageData = {
      pagePath: 'sign-in.pop',
    };
    // a.style.display = 'none';
    // iusSignUpSpan.style.display = 'none';
  }
  if (widgetConfig.widgetName === signInWidgetName.ReorderChecksSignIn) {
    pageData = {
      pagePath: 'sign-in.pop',
    };
    // a.style.display = 'none';
    // iusSignUpSpan.style.display = 'none';
    widgetRef.current.getElementsByTagName('span')[0].textContent = 'Sign in to Reorder';
  }
  if (widgetConfig.widgetName === signInWidgetName.CartSignIn) {
    pageData = {
      pagePath: 'sign-in.pop',
    };
  }
  if (widgetConfig.widgetName === signInWidgetName.RetrieveSignIn) {
    pageData = {
      pagePath: 'fsg/login/shopper_lookup.aspx',
    };
    widgetRef.current.getElementsByTagName('span')[0].textContent =
      'Sign In to Retrieve Your Order';
  }
  if (widgetConfig.widgetName === signInWidgetName.LogoUploadSignIn) {
    pageData = {
      pagePath: 'fsg/login/shopper_lookup.aspx',
    };
    // a.style.display = 'none';
    // iusSignUpSpan.style.display = 'none';
    widgetRef.current.getElementsByTagName('span')[0].textContent = 'Sign In to Upload a Logo';
    if (getCookie('bm') && getCookie('bm') === '1') {
      widgetRef.current.getElementsByTagName('span')[0].textContent =
        'Sign in or create an account to save your logo, or continue as a guest.';
      widgetRef.current.getElementsByTagName('span')[0].style.fontSize = '22px';
      setShowNewUserHeader(false);
      setShowPreOrderHeader(false);
      setShowSubText1(false);
    }
  }
  if (widgetConfig.widgetName === signInWidgetName.WelcomeBackSignIn) {
    pageData = {
      pagePath: 'fsg/login/shopper_lookup.aspx',
    };
    // a.style.display = 'none';
    // iusSignUpSpan.style.display = 'none';
    widgetRef.current.getElementsByTagName('span')[0].textContent = 'Welcome Back';
    widgetRef.current.getElementsByTagName('span')[2].textContent = 'Sign in to continue';
  }
  if (widgetConfig.widgetName === signInWidgetName.ReorderSignIn) {
    pageData = {
      pagePath: 'fsg/login/shopper_lookup.aspx',
    };
    // a.style.display = 'none';
    // iusSignUpSpan.style.display = 'none';
  }
  if (typeof window.wa !== 'undefined' && typeof window.wa.trackPage !== 'undefined') {
    window.wa.trackPage(pageData);
  }
  // For IE
  // widgetRef.current.getElementsByTagName('input')[5].style.opacity = '0.5';
};
/**
 * Helper method for singin failure
 * @param {any} ref React ref for the UI widget
 * @param {any} res response from sign failure
 * @param {any} props parent component props
 * @param {any} widgetConfig Loading widget configuratiom
 * @param {func} setShowFooterErrorNote Toggle show footer error note
 */
export const onSignInFail = (ref, res, props, widgetConfig, setShowFooterErrorNote) => {
  let userJson;
  const iusSignInMsgEl = ref.current.getElementsByTagName('div')[6];
  iusSignInMsgEl.style.display = 'none';
  // TODO: Take care of this when chat is introduced
  // - live persion monitor
  // lpTag.section = ["FSG"];
  // lpTag.sdes = lpTag.sdes || [];
  // // This code is to trigger the proactive engagement when the visitor encounters a login error
  // lpTag.sdes.push(
  //     {
  //         "type": "error",  //required
  //         "error": {
  //             "code": "loginError"
  //         }
  //     }
  // );
  // - end of live person track
  const userid = document.getElementById('ius-userid').value;
  const password = document.getElementById('ius-password').value;
  if (res.responseCode === 'USER_NOT_FOUND') {
    props.logToIMWebLogger(
      'Info',
      'IusLoginWidget: inside onSignInFail. USER_NOT_FOUND response got from IUS',
    );
    userJson = {
      UserId: userid,
      Password: password,
      ShopperId: '',
      IsAuthenticated: false,
    };
    props.logToIMWebLogger(
      'Info',
      'IusLoginWidget: USER_NOT_FOUND in IUS, Calling IM Shopper Authentication',
    );
    shopperDataService.authenticate({ data: userJson }).then((response) => {
      props.logToIMWebLogger('Info', 'IusLoginWidget: Shopper.authenticate service called');
      if (response || response === 'true') {
        props.logToIMWebLogger(
          'Info',
          'IusLoginWidget: USER_NOT_FOUND in IUS, Calling IM Shopper Authentication, Result Success',
        );
        setDocumentCookie('iusemail', userid);
        props.closeSignInModal();
        props.logToIMWebLogger(
          'Info',
          'IusLoginWidget: IM only user found, redirecting to migration page',
        );
        const isBM = getCookie('bm'); // doing this to handle the slit scenario to move to im routes
        if (window.Backbone && window.Backbone.history && isBM && isBM === '1')
          window.Backbone.history.navigate(widgetConfig.MigrateAccountLink, { trigger: true });
        else window.top.location.href = widgetConfig.MigrateAccountLink;
        // Backbone.history.navigate("#iusupgrade", true);
      } else {
        props.logToIMWebLogger(
          'Info',
          'IusLoginWidget: USER_NOT_FOUND in IUS, Calling IM Shopper Authentication, Result Failed',
        );
        iusSignInMsgEl.style.display = 'block';
        // widgetview.$('#ius-account-recovery-link').attr('data-wa-link', '');
        const pageData = {
          eventList: 'error',
          error: iusSignInMsgEl.textContent.trim(),
        };
        if (typeof window.wa !== 'undefined' && typeof window.wa.trackPage !== 'undefined') {
          window.wa.trackPage(pageData);
        }
      }
    });
  } else {
    props.logToIMWebLogger(
      'Info',
      `IusLoginWidget: inside onSignInFail ius callback.  response code from IUS: ${res.responseCode}`,
    );
    setShowFooterErrorNote(false);
    // widgetview.$('#ius-footer-error-note2').css({ "display": "none" });
    props.logToIMWebLogger(
      'Info',
      `IusLoginWidget: Calling ius service to find if user exists, api : /v1/users/me/user_id?username=${maskString(
        userid,
        1,
        1,
      )}`,
    );
    shopperDataService
      .getIUSShopper(userid)
      .then((iusShopperResponse) => {
        if (iusShopperResponse || iusShopperResponse === 'true') {
          // Exists in IUS
          // Check if he is IM Only
          userJson = {
            UserId: userid,
            Password: password,
            ShopperId: '',
            IsAuthenticated: false,
          };
          props.logToIMWebLogger(
            'Info',
            'IusLoginWidget: User exists in IUS, Calling IM Shopper Authentication',
          );
          shopperDataService.authenticate({ data: userJson }).then(() => {
            props.logToIMWebLogger(
              'Info',
              'IusLoginWidget: User migrated offline, sign in fail message display',
            );
            iusSignInMsgEl.style.display = 'block';
            // widgetview.$('#ius-account-recovery-link').attr('data-wa-link', '');
            const pageData = {
              eventList: 'error',
              error: iusSignInMsgEl.textContent.trim(),
            };
            if (typeof window.wa !== 'undefined' && typeof window.wa.trackPage !== 'undefined') {
              window.wa.trackPage(pageData);
            }
          });
        } else {
          props.logToIMWebLogger(
            'Info',
            `IusLoginWidget: Did not find user in IUS, api /v1/users/me/user_id?username= ${maskString(
              userid,
              1,
              1,
              '*',
            )}`,
          );
          userJson = {
            UserId: userid,
            Password: password,
            ShopperId: '',
            IsAuthenticated: false,
          };
          props.logToIMWebLogger(
            'Info',
            'IusLoginWidget: Did not find user in IUS, Calling IM Shopper Authentication',
          );
          shopperDataService.authenticate({ data: userJson }).then((response) => {
            if (response || response === 'true') {
              props.logToIMWebLogger(
                'Info',
                'IusLoginWidget: Did not find user in IUS, Calling IM Shopper Authentication, Result Success',
              );
              props.logToIMWebLogger(
                'Info',
                `IusLoginWidget: Did not find user in IUS, Calling IM Shopper Authentication, Result Success, Setting iusemail cookie ${maskString(
                  userid,
                  1,
                  1,
                  '*',
                )}`,
              );
              setDocumentCookie('iusemail', userid);
              props.closeModal();
              props.logToIMWebLogger(
                'Info',
                'IusLoginWidget: Did not find user in IUS, Calling IM Shopper Authentication, Result Success, Redirecting to migration page for IM user',
              );
              window.top.location.href = widgetConfig.MigrateAccountLink;
            } else {
              props.logToIMWebLogger(
                'Info',
                'IusLoginWidget: Did not find user in IUS, Calling IM Shopper Authentication, Result Failed',
              );
              iusSignInMsgEl.style.display = 'block';
              // widgetview.$('#ius-account-recovery-link').attr('data-wa-link', '');
              const pageData = {
                eventList: 'error',
                error: iusSignInMsgEl.textContent.trim(),
              };
              if (typeof window.wa !== 'undefined' && typeof window.wa.trackPage !== 'undefined') {
                window.wa.trackPage(pageData);
              }
            }
          });
        }
      })
      .catch((err) => {
        props.logToIMWebLogger(
          'error',
          `IusLoginWidget: ius service error, api : /v1/users/me/user_id?username=${maskString(
            userid,
            1,
            1,
          )}. Error: ${err}`,
        );
      });
  }
};
export function getHostedURI(redirecturl) {
  const { ius, hostname } = getEnvironmentConfig();
  const state = store.getState();
  const securityGreen =
    state.mainReducer.clientConfigs.intuitmarketFlagssecurityGreen &&
    state.mainReducer.clientConfigs.intuitmarketFlagssecurityGreen.toLowerCase() === 'true';
  const redirect = encodeURIComponent(`${hostname}${redirecturl}`);
  setCookie('redirectPageURI', `/${redirecturl}`);
  let hostedUrl = `${ius}/app/sign-in?app_group=QBDT&asset_alias=Intuit.qbc.ps.intuitmarket&redirect_uri=${redirect}?ref_=-1`;
  // if securityGreen is true and hostname is qdcstage.intuitmarket then add security_category=green to the hostedUrl to reproduce getuser issue in stage
  if (securityGreen && hostname.indexOf('qdcstage.intuitmarket') > -1) {
    hostedUrl = `${ius}/app/sign-in?security_category=green&app_group=QBDT&asset_alias=Intuit.qbc.ps.intuitmarket&redirect_uri=${redirect}?ref_=-1`;
  }
  return hostedUrl;
}
export function getHostedSignUpURI(redirecturl) {
  const { ius, hostname } = getEnvironmentConfig();
  const redirect = encodeURIComponent(`${hostname}${redirecturl}`);
  setCookie('redirectPageURI', `/${redirecturl}`);
  return `${ius}/app/sign-in?app_group=QBDT&asset_alias=Intuit.qbc.ps.intuitmarket&start_screen_hint=SignUp&redirect_uri=${redirect}?ref_=-1`;
}
export function getHostedRouteSignUp(redirecturl) {
  const { ius, hostname } = getEnvironmentConfig();
  const redirect = encodeURIComponent(`${hostname}${redirecturl}`);
  return `${ius}/app/sign-in?app_group=QBDT&asset_alias=Intuit.qbc.ps.intuitmarket&start_screen_hint=SignUp&redirect_uri=${redirect}?ref_=-1`;
}
