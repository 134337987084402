// eslint-disable-next-line require-jsdoc
import React, { useState } from 'react';
import Button from '@ids/button';
// import { EditIcon, DeleteIcon } from '@ids-ts/icon';
import { useSelector } from 'react-redux';
import { Modal, ModalContent, ModalHeader } from '@ids/modal-dialog';
import InlineValidationMessage from '@ids/inline-validation-message';
import { LogoDrawer } from './Logo';
import '../../../../stylesheets/checkout/cart.scss';

// eslint-disable-next-line require-jsdoc
export const CommonAddLogoComponent = (props) => {
  const { LogoRequired, Title, Buttons, logo } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { validationError } = customizationReducer;

  const closeModal = (params) => {
    setShowDrawer(false);
    if (params) {
      props.saveLogo(params);
    }
  };

  const removeLogo = () => {
    props.removeLogo();
    setShowConfirm(false);
  };

  const getAddEditButtonText = () => {
    if (logo) {
      return Buttons && Buttons.Edit && Buttons.Edit.Text ? Buttons.Edit.Text : 'Edit logo';
    }

    return Buttons && Buttons.Add && Buttons.Add.Text ? Buttons.Add.Text : 'Add logo';
  };

  const getRemoveButtonText = () => {
    return Buttons && Buttons.Delete && Buttons.Delete.Text ? Buttons.Delete.Text : 'Remove logo';
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">{Title}</h3>
      <Button
        buttonType="secondary"
        theme="quickbooks"
        className="add-edit-button"
        // icon={logo ? <EditIcon /> : null}
        // disabled={loading}
        onClick={() => setShowDrawer(true)}
        // data-wa-link="cancelccinfo"
      >
        {getAddEditButtonText()}
      </Button>
      {logo && (
        <Button
          buttonType="secondary"
          theme="quickbooks"
          style={{ marginLeft: '10px' }}
          // className="logo-remove-button"
          // icon={<DeleteIcon />}
          // disabled={loading}
          onClick={() => setShowConfirm(true)}
          // data-wa-link="cancelccinfo"
        >
          {getRemoveButtonText()}
        </Button>
      )}

      {validationError && LogoRequired && !logo && (
        <InlineValidationMessage type="error" message="Please add a logo." />
      )}

      <LogoDrawer
        {...props}
        open={showDrawer}
        modalOpen={() => setShowDrawer(true)}
        modalDismiss={closeModal}
      />

      <Modal
        dismissible
        onClose={() => setShowConfirm(false)}
        open={showConfirm}
        backdropZIndex={20000}
        size="medium"
      >
        <ModalHeader />
        <ModalContent overflow="true">
          <p className="cart-item-remove text-align-left">
            Are you sure you want to remove this logo?
          </p>

          <div className="remove-confirmation-btn text-align-left">
            <Button buttonType="secondary" theme="quickbooks" onClick={() => setShowConfirm(false)}>
              No
            </Button>
            <Button buttonType="primary" theme="quickbooks" onClick={removeLogo}>
              Yes
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};
