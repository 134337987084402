import React from 'react';
import stmpEndros from '../../images/IM/Stamps/stamps_endorse_thumb.gif';
import stmpEndoThum from '../../images/IM/POS/stamps_endorse_thumb.gif';
import stmpRetThum from '../../images/IM/POS/stamps_return_thumb.gif';
import stmpRetMonoThum from '../../images/IM/POS/stamps_return_mono_thumb.gif';
import faxHeaderCoverStamp from '../../images/IM/POS/FaxHeaderCoverStamp_ProductPage.png';
import secStmpThum from '../../images/IM/POS/security_stamps_thumb.jpg';
import stpCopyThum from '../../images/IM/POS/stamps_copy_thumb.png';
import stpPDThum from '../../images/IM/POS/stamps_pastdue_thumb.png';
import stpDraftThum from '../../images/IM/POS/stamps_draft_thumb.png';
import stpFIleThum from '../../images/IM/POS/stamps_filecopy_thumb.png';
import stpVoidThum from '../../images/IM/POS/stamps_void_thumb.png';
import stpPaidThum from '../../images/IM/POS/stamps_paid_thumb.png';
import stpConfThum from '../../images/IM/POS/stamps_confidential_thumb.png';
import stpFaxedThum from '../../images/IM/POS/stamps_faxed_thumb.png';
import stpUrgentThum from '../../images/IM/POS/stamps_urgent_thumb.png';
import faxHeader120 from '../../images/IM/POS/FaxHeaderCoverStamp_thumb.png';
import faxHeader340 from '../../images/IM/POS/FaxHeaderCoverStamp_1.png';
import secStmpThumLrg from '../../images/IM/POS/security_stamp_lrg.jpg';
import stampDraft from '../../images/IM/POS/stamps_draft.png';
import voidStamp from '../../images/IM/POS/stamps_void.png';
import stampFaxed from '../../images/IM/POS/stamps_faxed.png';
import stampUrgent from '../../images/IM/POS/stamps_urgent.png';
import optimized_faxHeaderCoverStamp from '../../images/IM/POS/FxHdCvrStp_PrdPg.webp';
import optimized_secStmpThum from '../../images/IM/POS/ScStpT.webp';
import optimized_stpConfThum from '../../images/IM/POS/stp_cnfdtl.webp';
import optimized_stpCopyThum from '../../images/IM/POS/stp_cpy.webp';
import optimized_stpDraftThum from '../../images/IM/POS/stp_dft.webp';
import optimized_stmpEndoThum from '../../images/IM/POS/stp_end.webp';
import optimized_stpFaxedThum from '../../images/IM/POS/stp_fx.webp';
import optimized_stpFIleThum from '../../images/IM/POS/stp_flcpy.webp';
import optimized_stpPaidThum from '../../images/IM/POS/stp_pd.webp';
import optimized_stpPDThum from '../../images/IM/POS/stp_ptd.webp';
import optimized_stmpRetMonoThum from '../../images/IM/POS/stp_rtnmn.webp';
import optimized_stpUrgentThum from '../../images/IM/POS/stp_urgt.webp';
import optimized_stpVoidThum from '../../images/IM/POS/stp_vd.webp';
import optimized_stmpRetThum from '../../images/IM/POS/stp_rtn.webp';
const StampsData = {
  SELF_INKING_STAMPS: {
    Department: {
      DeptName: 'Self-inking stamps',
      DeptDesc:
        'QuickBooks self-inking stamps give you better quality impressions and save you money with easily-replaceable ink refill pads.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: [
        'STAMP-19',
        'STAMP-03',
        'STAMP-04',
        'STAMP-06',
        'SSTAMP-01',
        'STAMP-07',
        'STAMP-09',
        'STAMP-11',
        'STAMP-13',
        'STAMP-18',
        'STAMP-08',
        'STAMP-10',
        'STAMP-12',
        'STAMP-17',
      ],
    },
    ProductSections: [
      {
        SectionHeader: 'Customizable stamps',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Endorsement stamp',
            ProductDesc:
              'Forget manually endorsing checks. Quickly and easily endorse a stack of checks with this self-inking endorsement stamp.',
            ImageSrc: stmpEndoThum,
            ImageSrcOptimized: optimized_stmpEndoThum,
            ImageWidth: '122px',
            ImageHeight: '55px',
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/stampsandlabels/endorsement-stamps/customization',
            LearnMoreLink: '/stampsandlabels/endorsement-stamps',
            LearnMoreSuffixText: null,
            ActualPrice: 35.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-19',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Personalized return address stamp',
            ProductDesc:
              'Never hand-write your address again! Our return address stamp gives all of your business correspondence an impressive, professional look.',
            ImageSrc: stmpRetThum,
            ImageSrcOptimized: optimized_stmpRetThum,
            ImageWidth: '122px',
            ImageHeight: '40px',
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/stampsandlabels/return-address-stamps/customization',
            LearnMoreLink: '/stampsandlabels/return-address-stamps',
            LearnMoreSuffixText: null,
            ActualPrice: 35.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-03',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Return address stamp with monogram',
            ProductDesc:
              'Customize your return address stamp with one of five professional monogram styles. Each stamp can include up to five lines of custom text.',
            ImageSrc: stmpRetMonoThum,
            ImageSrcOptimized: optimized_stmpRetMonoThum,
            ImageWidth: '122px',
            ImageHeight: '40px',
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/stampsandlabels/monogram-stamps/customization',
            LearnMoreLink: '/stampsandlabels/monogram-stamps',
            LearnMoreSuffixText: null,
            ActualPrice: 35.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-04',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Fax header cover stamp',
            ProductDesc:
              "No more fax coversheets! With our customizable fax cover stamps, there's no need to send a separate fax cover sheet.",
            ImageSrc: faxHeaderCoverStamp,
            ImageSrcOptimized: optimized_faxHeaderCoverStamp,
            ImageWidth: '130px',
            ImageHeight: '63px',
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/stampsandlabels/fax-cover-stamps/customization',
            LearnMoreLink: '/stampsandlabels/fax-cover-stamps',
            LearnMoreSuffixText: null,
            ActualPrice: 35.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-06',
          },
        ],
      },
      {
        SectionHeader: 'Standard stamps',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Security stamp',
            ProductDesc:
              'These self-inking stamps obscure the sensitive information on your checks, forms, legal documents, packages, and mail. If you have employees handling sensitive documents, security stamps are ideal.',
            ImageSrc: secStmpThum,
            ImageSrcOptimized: optimized_secStmpThum,
            ImageWidth: '155px',
            ImageHeight: '108px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/security-stamps',
            LearnMoreLink: '/stampsandlabels/security-stamps',
            LearnMoreSuffixText: null,
            ActualPrice: 21.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'SSTAMP-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Copy stamp',
            ProductDesc:
              'Avoid mixing up document copies with their originals by using these self-inking stamps.',
            ImageSrc: stpCopyThum,
            ImageSrcOptimized: optimized_stpCopyThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/copy-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-07',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Past due stamp',
            ProductDesc:
              'With this self-inking stamp, add a sense of urgency to overdue invoices and make sure they get noticed.',
            ImageSrc: stpPDThum,
            ImageSrcOptimized: optimized_stpPDThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/past-due-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-09',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Draft stamp',
            ProductDesc: 'Clearly indicate draft documents with this self-inking stamp.',
            ImageSrc: stpDraftThum,
            ImageSrcOptimized: optimized_stpDraftThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/draft-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-11',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'File stamp',
            ProductDesc:
              'Quickly identify the copies you should retain for your records with these self-inking stamps.',
            ImageSrc: stpFIleThum,
            ImageSrcOptimized: optimized_stpFIleThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/file-copy-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-13',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Void stamp',
            ProductDesc:
              "Make sure misprinted checks won't be cashed with these self-inking stamps.",
            ImageSrc: stpVoidThum,
            ImageSrcOptimized: optimized_stpVoidThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/void-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-18',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Paid stamp',
            ProductDesc:
              'Save time and money by using this self-inking stamp to indicate when invoices have been paid.',
            ImageSrc: stpPaidThum,
            ImageSrcOptimized: optimized_stpPaidThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/paid-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-08',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Confidential stamp',
            ProductDesc: 'Alert readers to confidential documents with this self-inking stamp.',
            ImageSrc: stpConfThum,
            ImageSrcOptimized: optimized_stpConfThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/confidential-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-10',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Faxed stamp',
            ProductDesc:
              'Easily see which faxes have already been sent with this self-inking stamp.',
            ImageSrc: stpFaxedThum,
            ImageSrcOptimized: optimized_stpFaxedThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/fixed-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-12',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Urgent stamp',
            ProductDesc: 'Give important documents top priority using these self-inking stamps.',
            ImageSrc: stpUrgentThum,
            ImageSrcOptimized: optimized_stpUrgentThum,
            ImageWidth: '90px',
            ImageHeight: '28px',
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/stampsandlabels/urgent-stamp',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: 13.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STAMP-17',
          },
        ],
      },
    ],
  },
  SELFINK_CUSTOM_BANK_ENDORSEMENT_STAMPS: {
    Department: {
      DeptName: 'Self-Inking Custom Bank Endorsment Stamps',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-19'],
      Image: stmpEndros,
      ImageLg: stmpEndros,
      PFID: 'STAMP-19',
      ProductInformation: {
        Desc: (
          <span>
            Hand writing endorsements on each check takes an unnecessary amount of time. A
            self-inking check endorsement stamp makes deposits ready in seconds. Accurately record
            pertinent information including your business name, bank name and bank account number
            with a handy QuickBooks endorsement stamp.
          </span>
        ),
        DescExtras: null,
        DescPoints: {
          Header: 'QuickBooks endorsement stamps:',
          List: [
            'Are easy to personalize',
            'Save money with a standard ink refill pad',
            'Allow for up to five lines of capitalized text',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/stampsandlabels/endorsement-stamps/customization',
        LinkEnabled: true,
      },
    },
    CheckFacts: true,
  },
  PERSONALIZED_RETURN_ADDRESS_STAMP: {
    Department: {
      DeptName: 'Personalized return address stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-03'],
      Image: stmpRetThum,
      ImageLg: stmpRetThum,
      PFID: 'STAMP-03',
      ProductInformation: {
        Desc: (
          <span>
            Save time addressing correspondence with a QuickBooks customized address stamp. Mark
            your return address on blank envelopes of any size or shape- up to five lines of custom
            text available (all text is capitalized).
          </span>
        ),
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'A personalized return address stamp gives plain envelopes a more professional appearance.',
            'Custom address stamps are perfect for when you run out of pre-printed envelopes or have a variety of business addresses.',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 3 1/8" x 3/4"'],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/stampsandlabels/return-address-stamps/customization',
        LinkEnabled: true,
      },
    },
  },
  RETURN_ADDRESS_STAMP_MONOGRAM: {
    Department: {
      DeptName: 'Return address stamp with monogram',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-04'],
      Image: stmpRetMonoThum,
      ImageLg: stmpRetMonoThum,
      PFID: 'STAMP-04',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Customize your return address stamp with one of five professional monogram styles',
            'Includes up to 5 lines of custom text',
            'The monogram is in blue ink and the text is in black ink',
            'All text is capitalized',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 3 1/8" x 3/4"'],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/stampsandlabels/monogram-stamps/customization',
        LinkEnabled: true,
      },
    },
  },
  FAX_HEADER_COVER_STAMP: {
    Department: {
      DeptName: 'Fax header cover stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-06'],
      Image: faxHeader120,
      ImageLg: faxHeader340,
      PFID: 'STAMP-06',
      ProductInformation: {
        Desc: (
          <span>
            Reduce waste and avoid the hassle of fax cover sheets with this fax header stamp. Simply
            stamp your fax document, fill in the fields and fax. The fax stamp clearly designates
            the date, sender and intended recipient.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/stampsandlabels/fax-cover-stamps/customization',
        LinkEnabled: true,
      },
    },
  },
  SECURITY_STAMP: {
    Department: {
      DeptName: 'Security stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['SSTAMP-01'],
      Image: secStmpThum,
      ImageLg: secStmpThumLrg,
      PFID: 'SSTAMP-01',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'If people other than yourself access your documents with sensitive information, protect yourself by using a security stamp in your business.',
            'Obscures information on documents such as checks, forms, legal documents, mail and packages.',
            'Provides an alternative to shredding bank account information, personal data, and other sensitive information.',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Large stamp impression area for maximum coverage: 1 x 2.5“',
          'Self-inking',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  COPY_STAMP: {
    Department: {
      DeptName: 'Copy stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-07'],
      Image: stpCopyThum,
      ImageLg: stpCopyThum,
      PFID: 'STAMP-07',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Easily distinguish between original files and copies',
            'Available in red ink only',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  PAST_DUE_STAMP: {
    Department: {
      DeptName: 'Past due stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-09'],
      Image: stpPDThum,
      ImageLg: stpPDThum,
      PFID: 'STAMP-09',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Get noticed and add a sense of urgency to overdue invoices',
            'Available in red ink only',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  DRAFT_STAMP: {
    Department: {
      DeptName: 'Draft stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-11'],
      Image: stampDraft,
      ImageLg: stampDraft,
      PFID: 'STAMP-11',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Clearly indicates a document is a draft', 'Available in red ink only'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  FILE_STAMP: {
    Department: {
      DeptName: 'File stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-13'],
      Image: stpFIleThum,
      ImageLg: stpFIleThum,
      PFID: 'STAMP-13',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Identifies the copies you should retain for your records',
            'Available in red ink only',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  PAID_STAMP: {
    Department: {
      DeptName: 'Paid stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-08'],
      Image: stpPaidThum,
      ImageLg: stpPaidThum,
      PFID: 'STAMP-08',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Save time and paper while letting customers know they are paid in full. Use a pre-inked QuickBooks PAID stamp instead of printing a second invoice.',
            'QuickBooks paid stamps - available in red ink only',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  VOID_STAMP: {
    Department: {
      DeptName: 'Void stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-18'],
      Image: voidStamp,
      ImageLg: voidStamp,
      PFID: 'STAMP-18',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Ensure misprinted checks are not cashed', 'Available in blue ink only'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  CONFIDENTIAL_STAMP: {
    Department: {
      DeptName: 'Confidential stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-10'],
      Image: stpConfThum,
      ImageLg: stpConfThum,
      PFID: 'STAMP-10',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Helps to ensure confidential documents remain confidential',
            'Available in red ink only',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  FAXED_STAMP: {
    Department: {
      DeptName: 'Faxed stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-12'],
      Image: stampFaxed,
      ImageLg: stampFaxed,
      PFID: 'STAMP-12',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: ['Easily track faxes you’ve already sent', 'Available in red ink only'],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  URGENT_STAMP: {
    Department: {
      DeptName: 'Urgent stamp',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STAMP-17'],
      Image: stampUrgent,
      ImageLg: stampUrgent,
      PFID: 'STAMP-17',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Helps ensure that important documents are given top priority',
            'Available in red ink only',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
};

export default StampsData;
