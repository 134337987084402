// eslint-disable-next-line require-jsdoc
import React from 'react';
import TextField from '@ids/text-field';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import '../../../stylesheets/commonComponent.scss';

// eslint-disable-next-line require-jsdoc
export const CustomTextField = (props) => {
  const { labelText, showInfo, tooltip, tooltipDuration, textFieldProps } = props;

  return (
    <>
      <p className="margin-bottom-10 custom-text-label">
        {labelText}{' '}
        {showInfo && (
          <Tooltip
            duration={tooltipDuration || 0}
            automationId="tooltip_custom"
            dura
            message={tooltip}
          >
            <span className="circle-info-span">
              <CircleInfoIcon size="x-small" />
            </span>
          </Tooltip>
        )}
      </p>
      <TextField theme="quickbooks" {...textFieldProps} />
    </>
  );
};
