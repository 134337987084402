import Button from '@ids/button';
import { Modal, ModalContent } from '@ids/modal-dialog';
import React from 'react';
import { Link } from 'react-router-dom';
import { isIE11Browser, setCookie, setDocumentCookie } from '../Util/CommonUtils';
import { getHostedSignUpURI, getHostedURI } from '../Util/SignInUtil';
import '../../../stylesheets/signin.scss';
import '../../../stylesheets/checkAndSuppliesResourceCenter.scss';

export default function HostedSignIn(props) {
  const signinClicked = () => {
    if (props.redirectCookie && props.redirectCookie.length > 0) {
      setDocumentCookie('redirecturl', props.redirectCookie);
    }
    if (props.imprintID && props.imprintID.indexOf('id=')) {
      setCookie('imprintIdSignin', props.imprintID);
    }
    if (props.location && props.location.length > 0) {
      window.location.href = getHostedURI(props.location);
    }
  };
  const onCreateAccClicked = () => {
    if (props.imprintID && props.imprintID.indexOf('id=')) {
      setCookie('imprintIdSignin', props.imprintID);
    }
    if (props.redirectCookie && props.redirectCookie.length > 0) {
      setDocumentCookie('redirecturl', props.redirectCookie);
    } else if (props.location && props.location.length > 0) {
      setCookie('redirecturl', props.location);
    }
    window.location.href = getHostedSignUpURI(props.location);
  };
  const onContinueGuest = () => {
    props.logToIMWebLogger('Info', 'HostedSignIn: Continue as guest clicked');
    setCookie('allowGuestCheckout', '1');
    props.closeModal();
    if (props.location && props.location.length > 0) {
      props.history.push(props.location);
    }
  };
  const onContinueReorder = () => {
    props.logToIMWebLogger('Info', 'HostedSignIn: Reorder continue clicked');
    props.closeModal();
    props.history.push('/retrievepreviousorder');
  };
  return (
    <div className="sign-in-page">
      <Modal
        onClose={() => {
          props.closeModal();
        }}
        open={props.showModal}
        styleOverrideReason="none"
        className={`sign-in-modal ${isIE11Browser() ? 'sign-in-modal-ie' : ''}`}
        style={{ zIndex: 20000, width: 'auto' }}
      >
        {!isIE11Browser() && (
          <ModalContent>
            <div className="hosted-sign-in">
              {props.guest ? (
                <h1 className="siginHeader">Sign in or continue as a guest to check out</h1>
              ) : props.WelcomeBackSignIn ? (
                <h1> Sign in to Continue</h1>
              ) : (
                <h1>Sign in to reorder</h1>
              )}

              <p className="hosted-sign-in-bottom-border padding-top-17px padding-bottom-40px">
                When you sign in with your Intuit account, we’ll keep your order details on file and
                you’ll get fast access to your order history.
              </p>
            </div>
            <div className="row hosted-sign-in-action">
              <div className="col-lg-4 col-sm-4 col-xs-4 padding-top-45px display-flex">
                {props.guest ? (
                  <Link
                    className="sign-in-tartiary-link"
                    theme="quickbooks"
                    to="#"
                    onClick={onContinueGuest}
                    data-wa-link="continue_as_guest"
                    data-ui-object="link"
                    data-ui-access-point="modal"
                    data-refer-ui-access-point="hosted_sign_in | continue_as_guest"
                  >
                    Continue as a guest
                  </Link>
                ) : (
                  <Link
                    className="sign-in-tartiary-link"
                    theme="quickbooks"
                    to="#"
                    onClick={onContinueReorder}
                    data-wa-link="find_previous_order"
                    data-ui-object="link"
                    data-ui-access-point="modal"
                    data-refer-ui-access-point="hosted_sign_in | find_previous_order"
                  >
                    Find a previous order
                  </Link>
                )}
              </div>
              <div className="col-lg-8 col-sm-8 col-xs-8 padding-top-40px display-flex justify-content-end">
                <div className="margin-right-15px">
                  <Button
                    className="sign-in-passive-btn"
                    buttonType="secondary"
                    theme="quickbooks"
                    onClick={onCreateAccClicked}
                    data-wa-link="create_account"
                    data-ui-object="button"
                    data-ui-access-point="modal"
                    data-refer-ui-access-point="hosted_sign_in | create_account"
                  >
                    Create an account
                  </Button>
                </div>
                <div>
                  <Button
                    className="sign-in-green-btn"
                    buttonType="primary"
                    theme="quickbooks"
                    onClick={signinClicked}
                    data-wa-link="sign_in"
                    data-ui-object="button"
                    data-ui-access-point="modal"
                    data-refer-ui-access-point="hosted_sign_in | sign_in"
                  >
                    Sign in
                  </Button>
                </div>
              </div>
            </div>
          </ModalContent>
        )}
      </Modal>
    </div>
  );
}
