import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentFisicalYear } from '../../../js/components/Util/CommonUtils';

import w2_img from '../../images/IM/Tax Forms/Tx-QBW-2-Kits.png';
import OptimizedW2_img from '../../images/IM/Tax Forms/Tx-QBW-2-KitsOp.webp';
import tax_form_hero_image from '../../images/IM/Tax Forms/AllTax-HeroImage.jpg';
import w2_blank_img from '../../images/IM/Tax Forms/Tx-LaserBlankW-2-Kit.png';
import nec_img from '../../images/IM/Tax Forms/Tx-1099-NEC-Kits_V2.png';
import OptimizedNec_img from '../../images/IM/Tax Forms/Tx-1099-NEC-Kits_V2Op.webp';
import misc_img from '../../images/IM/Tax Forms/Tx-1099-Kits_V2.png';
import OptimizedMisc_img from '../../images/IM/Tax Forms/Tx-1099-Kits_V2Op.webp';
import w3_img from '../../images/IM/Tax Forms/Tx-W-3.png';
import OptimizedW3_Image from '../../images/IM/Tax Forms/Tx-W-3Op.webp';
import pre_printed_1096_img from '../../images/IM/Tax Forms/Tx-1096.png';
import OptimizedPre_Printed_1096_img from '../../images/IM/Tax Forms/Tx-1096Op.webp'

const AllTaxPageData = {
  ALL_TAX_FORM: {
    Department: {
      DeptName: 'QuickBooks Compatible Tax Forms',
      DeptDesc: 'Save time and hassle with federal tax forms designed to work with QuickBooks.',
      FetchPricing: true,
      RequestBody: ['TAX-01', 'PERF-01', 'TAX-72', 'TAX-02', 'TAX-03', 'TAX-04'],
    },
    ProductSections: [
      {
        Products: [
          {
            ImageSrc: w2_img,
            ImageSrcOptimized: OptimizedW2_img,
            ImageHeight: '260px',
            ImageWidth: '281px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed W-2 Kits`,
            PFID: 'TAX-01',
            Variant: '4-part',
            ProductDesc: (
              <span>
                Pre-printed federal tax forms used to report employee wages to federal, state and
                local agencies; includes a copy for your employee.
              </span>
            ),
            EachKitContains: [
              `${getCurrentFisicalYear()} W-2 Forms (two tax forms per page)`,
              'four free W-3 forms',
              'compatible double-window envelopes',
            ],
            additionalDesc: [
              'Pre-printed W-2 Kits are compatible with QuickBooks Desktop and QuickBooks Desktop Pro customers using QuickBooks Desktop Basic or Enhanced Payroll.',
              'QuickBooks Online, QuickBooks Online Payroll, and QuickBooks Desktop Assisted Payroll customers need to use Blank W-2 Kits. Printable from laser or inkjet printers.',
            ],
            LearnMoreLink: '/tax-forms/w2-forms/pre-printed-w-2-kits',
            HasPricing: false,
            HasSkuSelection: true,
            NavName: 'W-2',
            DisplayStateHelperText: false,
          },
          {
            ImageSrc: w2_blank_img,
            ProductName: 'Blank W-2 Kits',
            PFID: 'PERF-01',
            ProductDesc: (
              <span>
                Print your employees' W-2 forms directly from QuickBooks using IRS-approved blank,
                perforated paper.
              </span>
            ),
            EachKitContains: ['perforated W-2 paper', 'compatible double-window envelopes'],
            additionalDesc: [
              'Blank W-2 Kits are compatible with QuickBooks Online, QuickBooks Online Payroll, QuickBooks Desktop Basic, Enhanced or Assisted Payroll, and QuickBooks for Mac. Printable from laser or inkjet printers.',
            ],
            LearnMoreLink: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
            HasPricing: false,
            NavName: 'Blank W-2',
            ButtonLink: '/tax-forms/w2-forms/w-2-blank-envelope-kit',
            ButtonText: 'Get started',
          },
          {
            ImageSrc: nec_img,
            ImageSrcOptimized: OptimizedNec_img,
            ImageHeight: '260px',
            ImageWidth: '280px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed 1099-NEC Kits`,
            PFID: 'TAX-72',
            Variant: '4-part',
            ProductDesc: (
              <span>
                Use federal 1099-NEC tax forms to report payments of $600 or more to non-employees
                (contractors).
              </span>
            ),
            ProductSubDesc: (
              <p>
                These new “continuous use” forms no longer include the tax year. QuickBooks will
                print the year on the forms for you.
              </p>
            ),
            EachKitContains: [
              `${getCurrentFisicalYear()} 1099-NEC tax forms (three tax forms per page)`,
              'Four free 1096 tax forms',
              'Compatible double-window envelopes',
            ],
            additionalDesc: [
              'Pre-printed 1099-NEC kits are compatible with QuickBooks Online, QuickBooks Online Payroll, QuickBooks Desktop 2020 or later, and QuickBooks for Mac 2020 or later. Printable from laser and inkjet printers.',
            ],
            LearnMoreLink: '/tax-forms/1099-forms',
            HasPricing: false,
            HasSkuSelection: true,
            NavName: '1099-NEC',
            DisplayStateHelperText: false,
          },
          {
            ImageSrc: misc_img,
            ImageSrcOptimized: OptimizedMisc_img,
            ImageHeight: '260px',
            ImageWidth: '280px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed 1099-MISC Kits`,
            PFID: 'TAX-02',
            Variant: '4-part',
            ProductDesc: (
              <span>
                Use federal 1099-MISC tax forms to report payments of $600 or more for rents,
                royalties, medical and health care payments, and gross proceeds paid to attorneys.
              </span>
            ),
            ProductSubDesc: (
              <p>
                These new “continuous use” forms no longer include the tax year. QuickBooks will
                print the year on the forms for you.
              </p>
            ),
            EachKitContains: [
              `${getCurrentFisicalYear()} 1099-MISC forms (two forms per page)`,
              'Four free 1096 tax forms',
              'Compatible double-window envelopes',
            ],
            additionalDesc: [
              'Pre-printed 1099-MISC kits are compatible with QuickBooks Online, QuickBooks Online Payroll, QuickBooks Desktop 2019 or later, and QuickBooks for Mac 2019 or later. Printable from laser and inkjet printers.',
            ],
            LearnMoreLink: '/tax-forms/1099-forms',
            HasPricing: false,
            HasSkuSelection: true,
            NavName: '1099-MISC',
            DisplayStateHelperText: false,
          },
          {
            ImageSrc: w3_img,
            ImageSrcOptimized: OptimizedW3_Image,
            ImageHeight: '260px',
            ImageWidth: '203px',
            ProductName: `${getCurrentFisicalYear()} W-3 Forms`,
            PFID: 'TAX-03',
            ProductDesc: <span>Summarize your W-2 filings for the IRS with these W-3 forms.</span>,
            EachKitContains: null,
            additionalDesc: [
              'Pre-printed W-3 Forms are compatible with QuickBooks Desktop and QuickBooks Desktop Pro customers who are using QuickBooks Desktop Basic or Enhanced Payroll.',
            ],
            LearnMoreLink: '/tax-forms/w3-forms',
            HasPricing: false,
            NavName: 'W-3',
            DefaultSku: '134',
            ButtonText: 'Add to cart',
          },
          {
            ImageSrc: pre_printed_1096_img,
            ImageSrcOptimized: OptimizedPre_Printed_1096_img,
            ImageHeight: '260px',
            ImageWidth: '201px',
            ProductName: `${getCurrentFisicalYear()} Pre-Printed 1096 Forms`,
            PFID: 'TAX-04',
            ProductDesc: (
              <span>
                Summarize your 1099-NEC and 1099-MISC filings for the IRS with these 1096 forms.
              </span>
            ),
            EachKitContains: null,
            additionalDesc: [
              '1096 Forms are compatible with QuickBooks Desktop 2020 or later, QuickBooks Online Plus, and QuickBooks for Mac 2020 or later.',
            ],
            LearnMoreLink: '/tax-forms/1096-forms',
            HasPricing: false,
            NavName: '1096',
            DefaultSku: '178',
            ButtonText: 'Add to cart',
          },
        ],
      },
    ],
    Hero: {
      Header: 'Our kits make tax time less taxing.',
      Desc: <span>Check out our tax kits for small business.</span>,
      Image: tax_form_hero_image,
      ImageAltText: 'Tax form 1099 NEC header',
      Action: {
        Type: 'LINK',
        Text: 'Get started',
        Index: 0,
      },
      unavailableMsg: `${getCurrentFisicalYear()} tax forms and kits go on sale [DATE].`,
    },
    HasEfileModal: true,
    HasFaq: true,
    FaqDescription: {
      Header: 'Tax Forms FAQ',
      LeftQuestionList: [
        {
          Header: 'As an employer, which tax forms do I need?',
          Description:(
            'If you have full or part time employees that are paid hourly or are salaried, you’ll need W-2s and W-3s. W-2s are used to report an individual employee’s total annual wages and W-3s provide a summary of your W-2s, along with other required information. If you’ve paid freelancers or independent contractors, then you’ll need 1099-NEC and 1096 forms. We offer convenient packs that include everything you’ll need for filling either W-2s or 1099s.'
          ),
        },
        {
          Description:(
            <span>
              To learn more about tax forms from QuickBooks you can go to our
              <Link
                to={{ pathname: 'https://quickbooks.intuit.com/learn-support/en-us/quickbooks-online/help-topic/taxes/tax-forms' }}
                target="_blank"
              >
                <strong> Tax Form Guide.</strong>
              </Link>
            </span>
          ),
        },
      ],
      RightQuestionList: [
        {
          Header: 'Will these forms all work with QuickBooks?',
          Description:(
            'We list which versions of QuickBooks work with which tax forms in the product descriptions for all our forms.'
          ),
        },
        {
          Header: 'What are the IRS e-filing requirements?',
          Description:(
            <span>
              Beginning in 2024, all businesses with 10 or more filings in a calendar year must file electronically. This 10 or more limit is now determined by all return types combined.
            </span>
          ),
          LearnMore: (
            <span className="irs-learn-more"> Learn more about the e-filing requirements.</span>
          ),
        },
      ],
    },
  }
};

export default AllTaxPageData;
