import React from 'react';
import FooterLink from './FooterLink';
import '../../../stylesheets/footer.scss';

// eslint-disable-next-line require-jsdoc
const FooterPage = () => {
  return (
    <div className="footerInfo">
      <FooterLink />
    </div>
  );
};

export default FooterPage;
