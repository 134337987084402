import React from 'react';
import {
  CompanyInformation,
  CompanyInfowithAttributes,
  CustomAttributesComponent,
  AddMonogramOrLogo,
  Quantity,
  Summary,
  AddShadowMonogramOrLogo,
  ColorToggle,
  CheckStartingNumber,
  SignatureLines,
  AccountOpenDate,
  FormatQuantity,
} from '../Common';
import {
  RoutingAndBankInformation,
  ManualDepositSlipImage,
  PrintableDepositSlipImage,
} from '../DepositSlips';
import {
  EndorsementStampImage,
  CompanyInfo,
  ReturnAddressStampImage,
  FaxHeaderCoverStampImage,
  MonogramStampImage,
  FaxHeaderCompanyInfo,
} from '../Stamps';

import {
  CustomerBillingStatementImage,
  PrinterFormatQuantity,
  ProductInvoiceImage,
  RemittanceStatementImage,
  MultiPurposeFormImage,
} from '../Forms';
import {
  FormEnvelopesImage,
  BusinessReplyEnvelopesImage,
  LargeMailingEnvelopesImage,
  SkuQuantitySelection,
} from '../CustomEnvelopes';

import {
  DirectDepositFormsImage,
  SecurePlusVoucherChecksImage,
  SecurePremierVoucherChecksImage,
  BasicVoucherChecksImage,
  SecurePlusStandardChecksImage,
  SecurePremierStandardChecksImage,
  BasicStandardChecksImage,
  SecurePlusWalletChecksImage,
  BasicWalletChecksImage,
  ManualChecksImage,
  OfficeAwayChecksImage,
  PersonalChecksImage,
} from '../Checks';

import {
  DEPcolorMap,
  SPVCcolorMap,
  SPVCPremiercolorMap,
  BVCcolorMap,
  SPSCcolorMap,
  SPSCPremiercolorMap,
  BSCcolorMap,
  SPWCcolorMap,
  BWCcolorMap,
  MCheckcolorMap,
  SPPCcolorMap,
  LargeMailingcolorMap,
} from '../../../../assets/pagedata/checks-color-map';

import { validStatesInUS } from '../../../../assets/pagedata/constant';
import { validateCheckStartNumber } from '../../Util/CustomizationUtil';

import pdsImage from '../../../../assets/images/IM/Customization/DEP-02.jpg';
import dep_prnt_qnty_1 from '../../../../assets/images/IM/Customization/dep_prnt_qnty_1.gif';
import dep_prnt_qnty_2 from '../../../../assets/images/IM/Customization/dep_prnt_qnty_2.gif';
import dep_prnt_qnty_3 from '../../../../assets/images/IM/Customization/dep_prnt_qnty_3.gif';
import mdsImage from '../../../../assets/images/IM/Customization/DEP-01.gif';
import dep_man_qnty_1 from '../../../../assets/images/IM/Customization/dep_man_qnty_1.gif';
import dep_man_qnty_2 from '../../../../assets/images/IM/Customization/dep_man_qnty_2.gif';
import dep_man_qnty_3 from '../../../../assets/images/IM/Customization/dep_man_qnty_3.gif';
import STAMP_19 from '../../../../assets/images/IM/Customization/STAMP-19.gif';
import STAMP_03 from '../../../../assets/images/IM/Customization/STAMP-03.jpg';
import STAMP_04 from '../../../../assets/images/IM/Customization/STAMP-04.jpg';
import ENV_05 from '../../../../assets/images/IM/Customization/ENV-05.gif';
import FaxHeaderCoverStamp_Customisation from '../../../../assets/images/IM/Customization/FaxHeaderCoverStamp_Customisation.png';
import state_qnty_1 from '../../../../assets/images/IM/Customization/state_qnty_1.gif';
import state_qnty_2 from '../../../../assets/images/IM/Customization/state_qnty_2.gif';
import STATE_01 from '../../../../assets/images/IM/Customization/STATE-01.gif';
import STATE_02 from '../../../../assets/images/IM/Customization/STATE-02.gif';
import ENV_07 from '../../../../assets/images/IM/Customization/ENV-07.gif';
import INV_01 from '../../../../assets/images/IM/Customization/INV-01.gif';
import INV_02 from '../../../../assets/images/IM/Customization/INV-02.gif';
import INV_03 from '../../../../assets/images/IM/Customization/INV-03.gif';
import inv_qnty_1 from '../../../../assets/images/IM/Customization/inv_qnty_1.gif';
import inv_qnty_2 from '../../../../assets/images/IM/Customization/inv_qnty_2.gif';
import inv_qnty_3 from '../../../../assets/images/IM/Customization/inv_qnty_3.gif';
import inv_qnty_4 from '../../../../assets/images/IM/Customization/inv_qnty_4.gif';
import LME_01_WHITE from '../../../../assets/images/IM/Customization/env_large_white.png';
import MULTIFORM_01 from '../../../../assets/images/IM/Customization/MULTIFORM-01.gif';

import DEPOSIT_FORM_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/depositforms/cblue.gif';

import VOUCHER_SECURE_PLUS_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/voucherchecks/secureplus/VPlus_Blue_Preview.jpg';

import VOUCHER_SECURE_PREMIER_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/voucherchecks/securepremier/VoucherPremier_Blue_Preview.jpg';

import VOUCHER_BASIC_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_Blue_Preview.jpg';

import STANDARD_SECURE_PLUS_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/standardchecks/secureplus/prism_blue.jpg';

import STANDARD_SECURE_PREMIER_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/standardchecks/securepremier/prism_blue.jpg';

import STANDARD_BASIC_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/standardchecks/basic/cblue.jpg';

import WALLET_SECURE_PLUS_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/walletchecks/secureplus/SecurePlusWalletPreview_blue_1Sign.jpg';

import WALLET_BASIC_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/walletchecks/basic/cblue.jpg';

import MANUAL_CHECK_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/manualchecks/safety_blue.jpg';

import OFFICE_AWAY_CHECK_LARGE from '../../../../assets/images/IM/Customization/checks/officeawaychecks/office-and-away.jpg';
// import OFFICE_AWAY_CHECK_LARGE2 from '../../../../assets/images/IM/Customization/checks/officeawaychecks/office-and-away-2line.jpg';

import PERSONAL_SECURE_PLUS_BLUE_LARGE from '../../../../assets/images/IM/Customization/checks/personalchecks/personal-secureplus-blue.jpg';

// checks upgrade images
import SECURE_PLUS_VOUCHER_CHECK_UPGRADE from '../../../../assets/images/IM/Customization/checks/upgrade/Upgrade_SecurePlusVoucher.png';

import DEFAULT_SECURE_PLUS_VOUCHER_CHECK_UPGRADE from '../../../../assets/images/IM/Customization/checks/voucherchecks/secureplus/Default.png';

import SECURE_PREMIER_VOUCHER_CHECK_UPGRADE from '../../../../assets/images/IM/Customization/checks/upgrade/Upgrade_SecurePremierVoucher.png';

import DEFAULT_SECURE_PREMIER_VOUCHER_CHECK_UPGRADE from '../../../../assets/images/IM/Customization/checks/voucherchecks/securepremier/Default.png';

import SECURE_PLUS_WALLET_CHECK_UPGRADE from '../../../../assets/images/IM/Customization/checks/upgrade/Upgrade_SecurePlusWallet.png';

import DEFAULT_SECURE_PLUS_WALLET_CHECK_UPGRADE from '../../../../assets/images/IM/Customization/checks/walletchecks/secureplus/Default.png';

import LOGO_CATALOG from '../../../../assets/document/standardlogos.pdf';

import tooltip_envelope from '../../../../assets/images/IM/Customization/tooltip_envelope.png';

import tooltip_envelope_wallet from '../../../../assets/images/IM/Customization/tooltip_envelope_wallet.png';

import tooltip_checkimage_account from '../../../../assets/images/IM/Customization/tooltip_checkimage_account.png';

import tooltip_checkimage_routing from '../../../../assets/images/IM/Customization/tooltip_checkimage_routing.png';

const addLogoOptions = {
  UPLOAD: {
    ID: 'UPLOAD',
    LogoType: '3',
    Title: 'Upload your logo',
    SubTitle: 'Add your own logo. Using a custom logo will increase printing time by 5-6 days.',
    ButtonText: 'Upload a logo',
  },
  PREDESIGN: {
    ID: 'PREDESIGN',
    Title: 'Select a pre-designed logo',
    LogoType: '1',
    SubTitle:
      'Choose one of our existing logos. Using a pre-designed logo will increase printing time by 3-5 days.',
    ButtonText: 'Browse logos',
    CatalogLink: LOGO_CATALOG,
  },
  MONOGRAM: {
    ID: 'MONOGRAM',
    LogoType: '2',
    Title: 'Add a monogram',
    SubTitle:
      'Select the letter of your choice in one of our designer fonts. Using a monogram will increase printing time by 3-5 days.',
    ButtonText: 'Select a monogram',
  },
};

const addLogoOptionsLookup = {
  1: 'PREDESIGN',
  2: 'MONOGRAM',
  3: 'UPLOAD',
};

const pdsRouteProps = {
  Title: 'printable deposit slips',
  PFID: 'DEP-02',
  ImageInformation: {
    Image: pdsImage,
    Component: PrintableDepositSlipImage,
  },
  Steps: [
    {
      Title: 'Choose quantity and number of sheets and enter your company information',
      Components: [
        {
          Component: FormatQuantity,
          Props: {
            PFID: 'DEP-02',
            DefaultQuantity: 250,
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: dep_prnt_qnty_1,
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: dep_prnt_qnty_2,
              },
              {
                Key: 'Triplicate',
                Val: '3',
                Image: dep_prnt_qnty_3,
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 25,
            hideExtraline: true,
          },
        },
        {
          divider: true,
        },
        {
          Component: Summary,
          Props: {
            Title: 'Optional: Add your deposit summary header',
            Size: 25,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add your deposit summary header</div>
                <div className="tooltip-body">
                  This text will be added to the top of the deposit summary
                </div>
              </div>
            ),
            BoldFeature: false,
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Add your account and banking information',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div>
                <span>Enter your bank's routing number. Routing numbers are usually 9 digits</span>
              </div>
            ),
            TooltipAccountInfo: (
              <div>
                <span>Enter your account number; account numbers are usually 7-10 digits.</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }

        return isValid;
      },
    },
  ],
};

const mdsRouteProps = {
  Title: 'Booked Manual Deposit Slips',
  PFID: 'DEP-01',
  ImageInformation: {
    Image: mdsImage,
    Component: ManualDepositSlipImage,
  },
  Steps: [
    {
      Title: 'Choose your format, quantity and enter your company information',
      Components: [
        {
          Component: FormatQuantity,
          Props: {
            PFID: 'DEP-01',
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: dep_man_qnty_1,
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: dep_man_qnty_2,
              },
              {
                Key: 'Triplicate',
                Val: '3',
                Image: dep_man_qnty_3,
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 25,
            hideExtraline: true,
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Add your account and banking information',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div>
                <span>Enter your bank's routing number. Routing numbers are usually 9 digits</span>
              </div>
            ),
            TooltipAccountInfo: (
              <div>
                <span>Enter your account number; account numbers are usually 7-10 digits.</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        return isValid;
      },
    },
  ],
};

const endorsementStamp = {
  Title: 'endorsement stamp',
  PFID: 'STAMP-19',
  CompanyInfoInCaps: true,
  ImageInformation: {
    Image: STAMP_19,
    Component: EndorsementStampImage,
  },
  Steps: [
    {
      Title: 'Enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'STAMP-19',
            DefaultQuantity: 1,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfo,
          Props: {
            allCaps: true,
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
  ],
};

const returnAddressStamp = {
  Title: 'return address stamp',
  PFID: 'STAMP-03',
  CompanyInfoInCaps: true,
  ImageInformation: {
    Image: STAMP_03,
    Component: ReturnAddressStampImage,
  },
  Steps: [
    {
      Title: 'Enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'STAMP-03',
            DefaultQuantity: 1,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            allCaps: true,
            hideExtraline: true,
            maxLength: 25,
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
  ],
};

const returnAddressStampWithMonogram = {
  Title: 'return address stamp with monogram',
  PFID: 'STAMP-04',
  CompanyInfoInCaps: true,
  ImageInformation: {
    Image: STAMP_04,
    Component: MonogramStampImage,
  },
  Steps: [
    {
      Title: 'Enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'STAMP-04',
            DefaultQuantity: 1,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            allCaps: true,
            hideExtraline: true,
            maxLength: 25,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [addLogoOptions.MONOGRAM],
            Title: 'Add a monogram',
            LogoRequired: true,
            Buttons: {
              Add: {
                Text: 'Add monogram',
              },
              Edit: {
                Text: 'Edit monogram',
              },
              Delete: {
                Text: 'Remove monogram',
              },
            },
            ModalConfiguration: {
              hideTitleSection: true,
            },
          },
        },
      ],
      Validate: (custReducer) => {
        if (!custReducer.selectedSku || !custReducer.logo) return false;

        return true;
      },
    },
  ],
};

const faxHeadCoverStamp = {
  Title: 'fax cover stamp',
  PFID: 'STAMP-06',
  CompanyInfoInCaps: true,
  ImageInformation: {
    Image: FaxHeaderCoverStamp_Customisation,
    Component: FaxHeaderCoverStampImage,
  },
  Steps: [
    {
      Title: 'Enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'STAMP-06',
            DefaultQuantity: 1,
          },
        },
        {
          divider: true,
        },
        {
          Component: FaxHeaderCompanyInfo,
          Props: {
            allCaps: true,
            hideExtraline: true,
            maxLength: 25,
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
  ],
};

const singlewindowformenvelope = {
  Title: 'single window form envelopes',
  PFID: 'ENV-05',
  ImageInformation: {
    Image: ENV_05,
    Component: FormEnvelopesImage,
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'ENV-05',
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            allCaps: false,
            hideExtraline: true,
            hideExtraline2: true,
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
          },
        },
      ],
    },
    {
      Title: 'Design your envelopes',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const businessreplyenvelope = {
  Title: 'business reply envelopes',
  PFID: 'ENV-07',
  ImageInformation: {
    Image: ENV_07,
    Component: BusinessReplyEnvelopesImage,
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'ENV-07',
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            allCaps: false,
            hideExtraline: true,
            hideExtraline2: true,
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['8'],
                DefaultValue: '8',
                BoldFeature: false,
              },
              AddressLine2Attribute: {
                FontSize: ['8'],
                DefaultValue: '8',
                BoldFeature: false,
              },
              PhoneAttribute: {
                FontSize: ['8'],
                DefaultValue: '8',
                BoldFeature: false,
              },
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: false,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const largemailingenvelope = {
  Title: 'large mailing envelopes',
  PFID: 'LME-01',
  ColorMapping: LargeMailingcolorMap,
  ImageInformation: {
    Image: LME_01_WHITE,
    Component: LargeMailingEnvelopesImage,
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: SkuQuantitySelection,
          Props: {
            Title: 'Choose your envelope colors',
            TooltipInfo: <span>Choose your envelope colors</span>,
            PFID: 'LME-01',
            defaultSku: '115WH',
            DefaultQuantity: 100,
            ColorToggler: LargeMailingcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            allCaps: false,
            hideExtraline: false,
            hideExtraline2: false,
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['12', '14', '16', '18', '20', '22', '24'],
                DefaultValue: '16',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['12', '14', '16', '18', '20', '22', '24'],
                DefaultValue: '16',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['12', '14', '16', '18', '20', '22', '24'],
                DefaultValue: '16',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['12', '14', '16', '18', '20', '22', '24'],
                DefaultValue: '16',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['12', '14', '16', '18', '20', '22', '24'],
                DefaultValue: '16',
                BoldFeature: true,
              },
              ExtraLine2Attribute: {
                FontSize: ['12', '14', '16', '18', '20', '22', '24'],
                DefaultValue: '16',
                BoldFeature: true,
              },
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: Summary,
          Props: {
            Title: 'Optional: Add a marketing message',
            Size: 50,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a marketing message</div>
                <div className="tooltip-body">
                  Add some business promotion with a marketing message on the bottom of the front of
                  your envelope.
                </div>
              </div>
            ),
            BoldFeature: true,
          },
        },
      ],
    },
    {
      Title: 'Design your envelopes',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const customerBillingStatement = {
  Title: 'customer billing statements',
  PFID: 'STATE-01',
  ImageInformation: {
    Image: STATE_01,
    Component: CustomerBillingStatementImage,
  },
  Steps: [
    {
      Title: 'Choose your printer type, format, and quantity and enter your company information',
      Components: [
        {
          Component: PrinterFormatQuantity,
          Props: {
            DefaultQuantity: 500,
            Printers: [
              {
                key: 'Laser_InkJet',
                val: 'Laser/InkJet',
              },
              {
                key: 'Dot_Matrix',
                val: 'Dot Matrix',
              },
            ],
            PFID: 'STATE-01',
            DynamicSheet: true,
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: state_qnty_1,
                PrinterTypes: ['Laser_InkJet'],
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: state_qnty_2,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            extralinePlaceholder: 'Fax',
            hideExtraline2: true,
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your forms (like your business
                  name, address, phone number, email address). You can make certain lines bold or
                  change the font size.
                </div>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Design your statements',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const remittanceStatement = {
  Title: 'remittance statements',
  PFID: 'STATE-02',
  ImageInformation: {
    Image: STATE_02,
    Component: RemittanceStatementImage,
  },
  Steps: [
    {
      Title: 'Choose your printer type, format, and quantity and enter your company information',
      Components: [
        {
          Component: PrinterFormatQuantity,
          Props: {
            DefaultQuantity: 500,
            Printers: [
              {
                key: 'Laser_InkJet',
                val: 'Laser/InkJet',
              },
            ],
            PFID: 'STATE-02',
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: state_qnty_1,
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            extralinePlaceholder: 'Fax',
            hideExtraline2: false,
            extraline2Placeholder: 'Company',
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your forms (like your business
                  name, address, phone number, email address). You can make certain lines bold or
                  change the font size.
                </div>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Design your statement',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const productInvoice = {
  Title: 'product invoice',
  PFID: 'INV-01',
  ImageInformation: {
    Image: INV_01,
    Component: ProductInvoiceImage,
  },
  Steps: [
    {
      Title: 'Choose your printer type, format, and quantity and enter your company information',
      Components: [
        {
          Component: PrinterFormatQuantity,
          Props: {
            DefaultQuantity: 500,
            Printers: [
              {
                key: 'Laser_InkJet',
                val: 'Laser/InkJet',
              },
              {
                key: 'Dot_Matrix',
                val: 'Dot Matrix',
              },
            ],
            PFID: 'INV-01',
            DynamicSheet: true,
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: inv_qnty_1,
                PrinterTypes: ['Laser_InkJet'],
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: inv_qnty_2,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
              {
                Key: 'Triplicate',
                Val: '3',
                Image: inv_qnty_3,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
              {
                Key: 'Quadruplicate',
                Val: '4',
                Image: inv_qnty_4,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            extralinePlaceholder: 'Fax',
            hideExtraline2: true,
            extraline2Placeholder: 'Company',
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your forms (like your business
                  name, address, phone number, email address). You can make certain lines bold or
                  change the font size.
                </div>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Design your invoices',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const serviceInvoice = {
  Title: 'service invoice',
  PFID: 'INV-02',
  ImageInformation: {
    Image: INV_02,
    Component: ProductInvoiceImage,
  },
  Steps: [
    {
      Title: 'Choose your printer type, format, and quantity and enter your company information',
      Components: [
        {
          Component: PrinterFormatQuantity,
          Props: {
            DefaultQuantity: 500,
            Printers: [
              {
                key: 'Laser_InkJet',
                val: 'Laser/InkJet',
              },
              {
                key: 'Dot_Matrix',
                val: 'Dot Matrix',
              },
            ],
            PFID: 'INV-02',
            DynamicSheet: true,
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: inv_qnty_1,
                PrinterTypes: ['Laser_InkJet'],
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: inv_qnty_2,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
              {
                Key: 'Triplicate',
                Val: '3',
                Image: inv_qnty_3,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            extralinePlaceholder: 'Fax',
            hideExtraline2: true,
            extraline2Placeholder: 'Company',
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your forms (like your business
                  name, address, phone number, email address). You can make certain lines bold or
                  change the font size.
                </div>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Design your invoices',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const professionalInvoice = {
  Title: 'professional invoice',
  PFID: 'INV-03',
  ImageInformation: {
    Image: INV_03,
    Component: ProductInvoiceImage,
  },
  Steps: [
    {
      Title: 'Choose your printer type, format, and quantity and enter your company information',
      Components: [
        {
          Component: PrinterFormatQuantity,
          Props: {
            DefaultQuantity: 500,
            Printers: [
              {
                key: 'Laser_InkJet',
                val: 'Laser/InkJet',
              },
              {
                key: 'Dot_Matrix',
                val: 'Dot Matrix',
              },
            ],
            PFID: 'INV-03',
            DynamicSheet: true,
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: inv_qnty_1,
                PrinterTypes: ['Laser_InkJet'],
              },
              {
                Key: 'Triplicate',
                Val: '3',
                Image: inv_qnty_3,
                PrinterTypes: ['Laser_InkJet', 'Dot_Matrix'],
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            extralinePlaceholder: 'Fax',
            hideExtraline2: true,
            extraline2Placeholder: 'Company',
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your forms (like your business
                  name, address, phone number, email address). You can make certain lines bold or
                  change the font size.
                </div>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Design your invoices',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const multiPurposeForm = {
  Title: 'multi-Purpose Form',
  PFID: 'MULTIFORM-01',
  ImageInformation: {
    Image: MULTIFORM_01,
    Component: MultiPurposeFormImage,
  },
  Steps: [
    {
      Title: 'Choose your printer type, format, and quantity and enter your company information',
      Components: [
        {
          Component: PrinterFormatQuantity,
          Props: {
            DefaultQuantity: 500,
            Printers: [
              {
                key: 'Laser_InkJet',
                val: 'Laser/InkJet',
              },
            ],
            PFID: 'MULTIFORM-01',
            DynamicSheet: true,
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: inv_qnty_1,
                PrinterTypes: ['Laser_InkJet'],
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: inv_qnty_2,
                PrinterTypes: ['Laser_InkJet'],
              },
              {
                Key: 'Triplicate',
                Val: '3',
                Image: inv_qnty_3,
                PrinterTypes: ['Laser_InkJet'],
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            hideExtraline: true,
            hideExtraline2: false,
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: false,
              },
              ExtraLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '8',
                BoldFeature: true,
              },
            },
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your forms (like your business
                  name, address, phone number, email address). You can make certain lines bold or
                  change the font size.
                </div>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        return !!custReducer.selectedSku;
      },
    },
    {
      Title: 'Design your forms',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const directDepositForms = {
  Title: 'direct deposit forms',
  PFID: 'DEP-ADV-01',
  ColorMapping: DEPcolorMap,
  ImageInformation: {
    Image: DEPOSIT_FORM_BLUE_LARGE,
    Component: DirectDepositFormsImage,
    ModalOverFlow: true,
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'DEP-ADV-01',
            defaultSku: '414A-00250',
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInfowithAttributes,
          Props: {
            allCaps: false,
            hideExtraline: false,
            hideExtraline2: true,
            FontConfig: {
              CompanyNameAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              AddressLine1Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              AddressLine2Attribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              PhoneAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
              ExtraLineAttribute: {
                FontSize: ['7', '8', '9', '10'],
                DefaultValue: '10',
                BoldFeature: true,
              },
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: Summary,
          Props: {
            Title: 'Add your voucher stub text',
            Size: 100,
            TooltipInfo: <span>Your Company Info</span>,
            BoldFeature: false,
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'DEP-ADV-01',
            Title: 'Enter a starting sequence number',
            MaxLength: 8,
            MinLength: 3,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header"> Enter a starting sequence number</div>
                <div className="tooltip-body">
                  Enter a starting number for your direct deposit forms between 101-999999.
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: <span>Starting check from reverse number</span>,
            Type: 'text',
            ErrorMessage: 'Enter a number in between 101-999999',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
    },
    {
      Title: 'Design your direct deposit forms ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your form color',
            ColorToggler: DEPcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Buttons: {
              Add: {
                Text: 'Add logo',
              },
              Edit: {
                Text: 'Edit logo',
              },
              Delete: {
                Text: 'Remove logo',
              },
            },
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              modalHeading: 'Add a shadow logo',
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            TooltipFontInfo: (
              <div>
                <span>Select a different font </span>
              </div>
            ),
            TooltipInkInfo: (
              <div>
                <span>Add a different font or ink color</span>
              </div>
            ),
          },
        },
      ],
      Validate: (custReducer) => {
        if (custReducer.selectedFontColor === 'DoubleColor') {
          if (!custReducer.secondaryColor) {
            return false;
          }

          if (!custReducer.companyInfoColor) {
            return false;
          }

          if (custReducer.logo && !custReducer.logoColor) {
            return false;
          }

          if (custReducer.shadowLogo && !custReducer.shadowLogoColor) {
            return false;
          }
        }

        return true;
      },
    },
  ],
};

const securePlusVoucherChecks = {
  Title: 'Secure Plus Voucher Checks',
  PFID: 'CHECK-11-ult',
  IsCheckProduct: true,
  isUpgradeAvailable: true,
  upgradeTo: 'CHECK-16-ult',
  ColorMapping: SPVCcolorMap,
  ImageInformation: {
    Image: VOUCHER_SECURE_PLUS_BLUE_LARGE,
    Component: SecurePlusVoucherChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-11',
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-11',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-11',
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            showNameInSummary: true,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: Summary,
          Props: {
            Title: 'Add your check stub text',
            Size: 100,
            PFID: 'CHECK-11',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add your check stub text</div>
                <div className="tooltip-body">
                  We automatically added your business name. If you’d prefer something else appear
                  on your check stub, you can enter that now.
                </div>
              </div>
            ),
            BoldFeature: false,
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-11',
            Title: 'Enter a starting check number',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }

        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: SPVCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
  UpgradeSteps: {
    upgradeStep: true,
    upgradeType: 'Premier',
    featuresCount: '29',
    subTitle: {
      textContent:
        'With 82% of companies hit by payments fraud, you need the powerful protection of',
      boldTxtContent: 'the most secure checks in the US.¹',
    },
    upgradeImage: SECURE_PREMIER_VOUCHER_CHECK_UPGRADE,
    MobUpgradeImage: DEFAULT_SECURE_PREMIER_VOUCHER_CHECK_UPGRADE,
    imgAltTxt: 'Secur premier voucher check',
    upgradeColorMapping: SPVCPremiercolorMap,
    features: [
      { txt: 'An exclusive security coating', boldTxt: 'to block tampering.' },
      { txt: 'A custom watermark', boldTxt: 'to protect against forgery.' },
      { txt: 'A heat-sensitive icon', boldTxt: 'to prevent photocopying.' },
      {
        txt: 'A security hologram that makes your checks',
        boldTxt: 'virtually impossible to counterfeit.',
      },
    ],
    disclaimer: '1. Based on a feature comparison of secure checks conducted January 2019.',
  },
};

const securePremierVoucherChecks = {
  Title: 'Secure Premier Voucher Checks',
  PFID: 'CHECK-16-ult',
  IsCheckProduct: true,
  ColorMapping: SPVCPremiercolorMap,
  ImageInformation: {
    Image: VOUCHER_SECURE_PREMIER_BLUE_LARGE,
    Component: SecurePremierVoucherChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-16',
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-16',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-16',
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            showNameInSummary: true,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: Summary,
          Props: {
            Title: 'Add your check stub text',
            Size: 100,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add your check stub text</div>
                <div className="tooltip-body">
                  We automatically added your business name. If you’d prefer something else appear
                  on your check stub, you can enter that now.
                </div>
              </div>
            ),
            BoldFeature: false,
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-16',
            Title: 'Enter a starting check number',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: SPVCPremiercolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
};

const basicVoucherChecks = {
  Title: 'Basic Voucher Checks',
  PFID: 'CHECK-02-ult',
  IsCheckProduct: true,
  isUpgradeAvailable: true,
  upgradeTo: 'CHECK-11-ult',
  ColorMapping: BVCcolorMap,
  ImageInformation: {
    Image: VOUCHER_BASIC_BLUE_LARGE,
    Component: BasicVoucherChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-02',
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
              ColorToggler: BVCcolorMap,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-02',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-02',
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
            ColorToggler: BVCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            showNameInSummary: true,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: Summary,
          Props: {
            Title: 'Add your check stub text',
            Size: 100,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add your check stub text</div>
                <div className="tooltip-body">
                  We automatically added your business name. If you’d prefer something else appear
                  on your check stub, you can enter that now.
                </div>
              </div>
            ),
            BoldFeature: false,
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-02',
            Title: 'Enter a starting check number',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            PFID: 'CHECK-02-ult',
            ColorToggler: BVCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
  UpgradeSteps: {
    upgradeStep: true,
    upgradeType: 'Plus',
    featuresCount: '23',
    subTitle: {
      textContent:
        'Payment fraud happens to 82% of companies. Our secure checks help make sure it doesn’t happen to yours.',
      boldTxtContent: '',
    },
    upgradeImage: SECURE_PLUS_VOUCHER_CHECK_UPGRADE,
    MobUpgradeImage: DEFAULT_SECURE_PLUS_VOUCHER_CHECK_UPGRADE,
    imgAltTxt: 'Secure plus voucher check',
    upgradeColorMapping: SPVCcolorMap,
    features: [
      { txt: 'An exclusive security coating', boldTxt: 'to block tampering.' },
      { txt: 'A custom watermark', boldTxt: 'to protect against forgery.' },
      { txt: 'A heat-sensitive icon', boldTxt: 'to prevent photocopying.' },
    ],
  },
};

const securePlusStandardChecks = {
  Title: 'Secure Plus Standard Checks',
  PFID: 'CHECK-12',
  IsCheckProduct: true,
  isUpgradeAvailable: true,
  upgradeTo: 'CHECK-17',
  ColorMapping: SPSCcolorMap,
  ImageInformation: {
    Image: STANDARD_SECURE_PLUS_BLUE_LARGE,
    Component: SecurePlusStandardChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-12',
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-12',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-12',
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-12',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: SPSCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
  UpgradeSteps: {
    upgradeStep: true,
    upgradeType: 'Premier',
    featuresCount: '29',
    subTitle: {
      textContent:
        'With 82% of companies hit by payments fraud, you need the powerful protection of',
      boldTxtContent: 'the most secure checks in the US.¹',
    },
    upgradeImage: SECURE_PREMIER_VOUCHER_CHECK_UPGRADE,
    MobUpgradeImage: DEFAULT_SECURE_PREMIER_VOUCHER_CHECK_UPGRADE,
    imgAltTxt: 'Secur premier standard check',
    upgradeColorMapping: SPSCPremiercolorMap,
    features: [
      { txt: 'An exclusive security coating', boldTxt: 'to block tampering.' },
      { txt: 'A custom watermark', boldTxt: 'to protect against forgery.' },
      { txt: 'A heat-sensitive icon', boldTxt: 'to prevent photocopying.' },
      {
        txt: 'A security hologram that makes your checks',
        boldTxt: 'virtually impossible to counterfeit.',
      },
    ],
    disclaimer: '1. Based on a feature comparison of secure checks conducted January 2019.',
  },
};

const securePremierStandardChecks = {
  Title: 'Secure Premier Standard Checks',
  PFID: 'CHECK-17',
  IsCheckProduct: true,
  ColorMapping: SPSCPremiercolorMap,
  ImageInformation: {
    Image: STANDARD_SECURE_PREMIER_BLUE_LARGE,
    Component: SecurePremierStandardChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-17',
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-17',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-17',
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-17',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: SPSCPremiercolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
};

const basicStandardChecks = {
  Title: 'Basic Standard Checks',
  PFID: 'CHECK-01',
  IsCheckProduct: true,
  isUpgradeAvailable: true,
  upgradeTo: 'CHECK-12',
  ColorMapping: BSCcolorMap,
  ImageInformation: {
    Image: STANDARD_BASIC_BLUE_LARGE,
    Component: BasicStandardChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-01',
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-01',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-01',
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-01',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: BSCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
  UpgradeSteps: {
    upgradeStep: true,
    upgradeType: 'Plus',
    featuresCount: '23',
    subTitle: {
      textContent:
        'Payment fraud happens to 82% of companies. Our secure checks help make sure it doesn’t happen to yours.',
      boldTxtContent: '',
    },
    upgradeImage: SECURE_PLUS_VOUCHER_CHECK_UPGRADE,
    MobUpgradeImage: DEFAULT_SECURE_PLUS_VOUCHER_CHECK_UPGRADE,
    imgAltTxt: 'Secure plus standard check',
    upgradeColorMapping: SPSCcolorMap,
    features: [
      { txt: 'An exclusive security coating', boldTxt: 'to block tampering.' },
      { txt: 'A custom watermark', boldTxt: 'to protect against forgery.' },
      { txt: 'A heat-sensitive icon', boldTxt: 'to prevent photocopying.' },
    ],
  },
};

const securePlusWalletChecks = {
  Title: 'Secure Plus Wallet Checks',
  PFID: 'CHECK-14',
  IsCheckProduct: true,
  ColorMapping: SPWCcolorMap,
  ImageInformation: {
    Image: WALLET_SECURE_PLUS_BLUE_LARGE,
    Component: SecurePlusWalletChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-14',
              showEnvConfig: true,
              TitleEnV: 'Add wallet check envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $49.99 for 250.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope_wallet}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-14',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits.
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 6,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(
            custReducer.checkStartingNumber.csn,
            3,
            6,
            'CHECK-14',
          );
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-14',
            showEnvConfig: true,
            TitleEnV: 'Add wallet check envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $49.99 for 250.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope_wallet}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-14',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits.
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 6,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(
          custReducer.checkStartingNumber.csn,
          3,
          6,
          'CHECK-14',
        );
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: SPWCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
};

const basicWalletChecks = {
  Title: 'Basic Wallet Checks',
  PFID: 'CHECK-09',
  IsCheckProduct: true,
  isUpgradeAvailable: true,
  upgradeTo: 'CHECK-14',
  ColorMapping: BWCcolorMap,
  ImageInformation: {
    Image: WALLET_BASIC_BLUE_LARGE,
    Component: BasicWalletChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-09',
              showEnvConfig: true,
              TitleEnV: 'Add wallet check envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $49.99 for 250.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope_wallet}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-09',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits.
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 6,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(
            custReducer.checkStartingNumber.csn,
            3,
            6,
            'CHECK-09',
          );
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-09',
            showEnvConfig: true,
            TitleEnV: 'Add wallet check envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $49.99 for 250.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope_wallet}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-09',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits.
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 6,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(
          custReducer.checkStartingNumber.csn,
          3,
          6,
          'CHECK-09',
        );
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: BWCcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
  UpgradeSteps: {
    upgradeStep: true,
    upgradeType: 'Plus',
    featuresCount: '23',
    subTitle: {
      textContent:
        'Payment fraud happens to 82% of companies. Our secure checks help make sure it doesn’t happen to yours.',
      boldTxtContent: '',
    },
    upgradeImage: SECURE_PLUS_WALLET_CHECK_UPGRADE,
    MobUpgradeImage: DEFAULT_SECURE_PLUS_WALLET_CHECK_UPGRADE,
    imgAltTxt: 'Secure plus wallet check',
    upgradeColorMapping: SPWCcolorMap,
    features: [
      { txt: 'An exclusive security coating', boldTxt: 'to block tampering.' },
      { txt: 'A custom watermark', boldTxt: 'to protect against forgery.' },
      { txt: 'A heat-sensitive icon', boldTxt: 'to prevent photocopying.' },
    ],
  },
};

const manualChecks = {
  Title: 'Manual Checks',
  PFID: 'MCHECK-01',
  IsCheckProduct: true,
  ColorMapping: MCheckcolorMap,
  ImageInformation: {
    Image: MANUAL_CHECK_BLUE_LARGE,
    Component: ManualChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: FormatQuantity,
            Props: {
              PFID: 'MCHECK-01',
              DefaultQuantity: 300,
              showEnvConfig: true,
              TitleEnV: 'Add self-seal security envelopes',
              TooltipInfoEnvRequired: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Add self-seal security envelopes</div>
                  <div className="tooltip-body">
                    Matching self-seal security envelopes are designed to align perfectly with
                    addresses and keep confidential information hidden. Security tabs on the flap
                    indicate attempts to open them. Starting at $30.99 for 50.
                  </div>
                  <div>
                    <img
                      className="tooltip-img"
                      src={tooltip_envelope}
                      alt="self-seal security envelopes"
                    />
                  </div>
                </div>
              ),
              NumberOfSheets: [
                {
                  Key: 'Original',
                  Val: '1',
                  Image: dep_prnt_qnty_1,
                },
                {
                  Key: 'Duplicate',
                  Val: '2',
                  Image: dep_prnt_qnty_2,
                },
              ],
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'MCHECK-01',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: FormatQuantity,
          Props: {
            PFID: 'MCHECK-01',
            DefaultQuantity: 300,
            showEnvConfig: true,
            TitleEnV: 'Add self-seal security envelopes',
            TooltipInfoEnvRequired: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Add self-seal security envelopes</div>
                <div className="tooltip-body">
                  Matching self-seal security envelopes are designed to align perfectly with
                  addresses and keep confidential information hidden. Security tabs on the flap
                  indicate attempts to open them. Starting at $30.99 for 50.
                </div>
                <div>
                  <img
                    className="tooltip-img"
                    src={tooltip_envelope}
                    alt="self-seal security envelopes"
                  />
                </div>
              </div>
            ),
            NumberOfSheets: [
              {
                Key: 'Original',
                Val: '1',
                Image: dep_prnt_qnty_1,
              },
              {
                Key: 'Duplicate',
                Val: '2',
                Image: dep_prnt_qnty_2,
              },
            ],
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'MCHECK-01',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: MCheckcolorMap,
          },
        },
        {
          divider: true,
        },
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
};

const officeAwayChecks = {
  Title: 'Office & Away Checks',
  PFID: 'CHECK-04',
  IsCheckProduct: true,
  ImageInformation: {
    Image: OFFICE_AWAY_CHECK_LARGE,
    Component: OfficeAwayChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-04',
              showEnvConfig: false,
              TooltipInfoEnvRequired: '',
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-04',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits. If you
                    prefer alphanumeric numbering, enter 1-2 letters followed by 3-6 digits.
                    (Alphanumeric numbering has a one-time set-up fee of $4.99.)
                  </div>
                </div>
              ),
              ReverseNumbering: true,
              TooltipInfoReverseNumbering: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Optional: Number your checks in reverse</div>
                  <div className="tooltip-body">
                    Though not common, some printers require that you load and feed your checks with
                    the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer
                    loads from the front, you may need reverse numbering.
                  </div>
                </div>
              ),
              Type: 'text',
              MaxLength: 8,
              MinLength: 3,
              ErrorMessage:
                'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your company information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-04',
            showEnvConfig: false,
            TooltipInfoEnvRequired: '',
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your company information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your business
                  name, address, phone number, email address)
                </div>
              </div>
            ),
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-04',
            Title: 'Enter a starting check number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                  numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering has a
                  one-time set-up fee of $4.99.)
                </div>
              </div>
            ),
            ReverseNumbering: true,
            TooltipInfoReverseNumbering: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Number your checks in reverse</div>
                <div className="tooltip-body">
                  Though not common, some printers require that you load and feed your checks with
                  the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed printer loads
                  from the front, you may need reverse numbering.
                </div>
              </div>
            ),
            Type: 'text',
            MaxLength: 8,
            MinLength: 3,
            ErrorMessage:
              'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: AddMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: AddShadowMonogramOrLogo,
          Props: {
            AddLogoDisplayOptions: [
              addLogoOptions.UPLOAD,
              addLogoOptions.PREDESIGN,
              addLogoOptions.MONOGRAM,
            ],
            Title: 'Optional: Add a shadow monogram or logo',
            ModalConfiguration: {
              hideTitleSection: false,
              description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
            },
          },
        },
        {
          divider: true,
        },
        {
          Component: SignatureLines,
          Props: {
            Size: 40,
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add text above the signature line</div>
                <div className="tooltip-body">
                  Provide additional instructions for your checks with text above the signature
                  line, for example,“Void after 90 days”.
                </div>
              </div>
            ),
            TooltipAnotherSignatureInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add another signature line</div>
                <div className="tooltip-body">
                  Add an additional line for a second signature on your checks.
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CustomAttributesComponent,
          Props: {
            showFontFamily: true,
            showInkColor: true,
            showSingleColor: true,
            TooltipFontInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Select a different font</div>
                <div className="tooltip-body">Choose a different font at no extra cost.</div>
              </div>
            ),
            TooltipInkInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Optional: Add a different font or ink color</div>
                <div className="tooltip-body">
                  Print your company information in a different ink color for $19.95. If you add a
                  monogram, logo, or custom logo, it will also print in that color.
                </div>
              </div>
            ),
          },
        },
      ],
    },
  ],
};

const personalChecks = {
  Title: 'Secure Plus Personal Checks',
  PFID: 'CHECK-15',
  IsCheckProduct: true,
  ColorMapping: SPPCcolorMap,
  ImageInformation: {
    Image: PERSONAL_SECURE_PLUS_BLUE_LARGE,
    Component: PersonalChecksImage,
    ModalOverFlow: true,
  },
  ReorderFlow: {
    reorderChecks: true,
    Steps: [
      {
        Components: [
          {
            Component: Quantity,
            Props: {
              PFID: 'CHECK-15',
              showEnvConfig: false,
              TooltipInfoEnvRequired: '',
              DefaultQuantity: 250,
            },
          },
          {
            divider: true,
          },
          {
            Component: CheckStartingNumber,
            Props: {
              PFID: 'CHECK-15',
              Title: 'Confirm your starting check number',
              TooltipInfo: (
                <div className="tooltip-container-customization">
                  <div className="tooltip-header">Confirm your starting check number</div>
                  <div className="tooltip-body">
                    We’ve pre-filled your starting check number based on your last order. You can
                    use this number or enter a new starting check number between 3-6 digits.
                  </div>
                </div>
              ),
              ReverseNumbering: false,
              TooltipInfoReverseNumbering: '',
              Type: 'text',
              MaxLength: 4,
              MinLength: 3,
              ErrorMessage:
                'Enter your starting check number between 101-9999. It should be between 3-4 digits.',
            },
          },
        ],
        Validate: (custReducer) => {
          const validError = validateCheckStartNumber(
            custReducer.checkStartingNumber.csn,
            3,
            4,
            'CHECK-15',
          );
          if (validError) {
            return false;
          }
          return true;
        },
        // Set this flag whereever we have check starting number component defined in the step
        GetMicr: true,
      },
    ],
  },
  Steps: [
    {
      Title: 'Choose your quantity and enter your personal information',
      Components: [
        {
          Component: Quantity,
          Props: {
            PFID: 'CHECK-15',
            showEnvConfig: false,
            TooltipInfoEnvRequired: '',
            DefaultQuantity: 250,
          },
        },
        {
          divider: true,
        },
        {
          Component: CompanyInformation,
          Props: {
            Title: 'Enter your personal information',
            maxLength: 50,
            hideExtraline: false,
            TooltipCompanyInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your personal information</div>
                <div className="tooltip-body">
                  Enter the information you’d like to include on your checks (like your name,
                  address, phone number, email address).
                </div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: AccountOpenDate,
          Props: {
            Title: 'Date account opened ',
            TooltipInfo: <span>Required for residents of IL.</span>,
          },
        },
      ],
    },
    {
      Title: 'Add your banking information and starting check number ',
      Components: [
        {
          Component: RoutingAndBankInformation,
          Props: {
            TooltipRoutingInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_routing} alt="Routing number" />
                </div>
                <div className="tooltip-header">Routing Number</div>
                <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
              </div>
            ),
            TooltipAccountInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-top-img">
                  <img src={tooltip_checkimage_account} alt="Account number" />
                </div>
                <div className="tooltip-header">Account Number</div>
                <div className="tooltip-body">Enter your bank account number.</div>
              </div>
            ),
          },
        },
        {
          divider: true,
        },
        {
          Component: CheckStartingNumber,
          Props: {
            PFID: 'CHECK-15',
            Title: 'Enter a starting check  number ',
            TooltipInfo: (
              <div className="tooltip-container-customization">
                <div className="tooltip-header">Enter your starting check number</div>
                <div className="tooltip-body">
                  Enter a starting check number between 3-6 digits.
                </div>
              </div>
            ),
            ReverseNumbering: false,
            TooltipInfoReverseNumbering: '',
            Type: 'text',
            MaxLength: 4,
            MinLength: 3,
            ErrorMessage:
              'Enter your starting check number between 101-9999. It should be between 3-4 digits.',
          },
        },
      ],
      Validate: (custReducer) => {
        let isValid = false;
        if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
        else isValid = false;

        if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
          isValid = true;
        else isValid = false;

        if (isValid && custReducer.bankInformation.name) isValid = true;
        else isValid = false;

        if (custReducer.bankInformation.state) {
          if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
            isValid = true;
          else isValid = false;
        }
        const validError = validateCheckStartNumber(
          custReducer.checkStartingNumber.csn,
          3,
          4,
          'CHECK-15',
        );
        if (!isValid || validError) {
          isValid = false;
        } else {
          isValid = true;
        }
        return isValid;
      },
      // Set this flag whereever we have check starting number component defined in the step
      GetMicr: true,
    },
    {
      Title: 'Design your check ',
      Components: [
        {
          Component: ColorToggle,
          Props: {
            Title: 'Choose your check color',
            ColorToggler: SPPCcolorMap,
          },
        },
      ],
    },
  ],
};
const previewChecksMap = [
  {
    PFID: 'CHECK-11-ult',
    additionalProps: securePlusVoucherChecks,
  },
  {
    PFID: 'CHECK-16-ult',
    additionalProps: securePremierVoucherChecks,
  },
  {
    PFID: 'CHECK-02-ult',
    additionalProps: basicVoucherChecks,
  },
  {
    PFID: 'CHECK-12',
    additionalProps: securePlusStandardChecks,
  },
  {
    PFID: 'CHECK-17',
    additionalProps: securePremierStandardChecks,
  },
  {
    PFID: 'CHECK-01',
    additionalProps: basicStandardChecks,
  },
  {
    PFID: 'CHECK-14',
    additionalProps: securePlusWalletChecks,
  },
  {
    PFID: 'CHECK-09',
    additionalProps: basicWalletChecks,
  },
  {
    PFID: 'MCHECK-01',
    additionalProps: manualChecks,
  },
  {
    PFID: 'CHECK-04',
    additionalProps: officeAwayChecks,
  },
  {
    PFID: 'CHECK-15',
    additionalProps: personalChecks,
  },
];

const voucherCheckValuePackPDS = {
  BundleSteps: [
    // first bundle product - voucher check
    {
      Title: 'Basic Voucher Checks',
      PFID: 'CHECK-02-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Basic Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: BVCcolorMap,
      ImageInformation: {
        Image: VOUCHER_BASIC_BLUE_LARGE,
        Component: BasicVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-02',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                PFID: 'CHECK-02-ult',
                ColorToggler: BVCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: AddShadowMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a shadow monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information and deposit summary header',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            return isValid;
          },
        },
      ],
    },
    // third bundle product - stamps
    {
      Title: 'Endorsement Stamp',
      PFID: 'STAMP-19',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // NextIndex and StepNavIndex is not needed for last step product.
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 3,
      PreviousAction: 'Back to deposit slips',
      BundleStepText: 'Lastly, confirm your endorsement stamp',
      BundleStepSubText:
        'We’ve used the information you provided to prepopulate your endorsement stamp; you can make changes below if necessary. If everything looks good, click <span class="bold-txt">Add to cart</span>.',
      CompanyInfoInCaps: true,
      ImageInformation: {
        Image: STAMP_19,
        Component: EndorsementStampImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: CompanyInfo,
              Props: {
                allCaps: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
  ],
};
const voucherCheckValuePackMDS = {
  BundleSteps: [
    // first bundle product - voucher check
    {
      Title: 'Basic Voucher Checks',
      PFID: 'CHECK-02-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Basic Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: BVCcolorMap,
      ImageInformation: {
        Image: VOUCHER_BASIC_BLUE_LARGE,
        Component: BasicVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-02',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                PFID: 'CHECK-02-ult',
                ColorToggler: BVCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: AddShadowMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a shadow monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [],
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            return isValid;
          },
        },
      ],
    },
    // third bundle product - stamps
    {
      Title: 'Endorsement Stamp',
      PFID: 'STAMP-19',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // NextIndex and StepNavIndex is not needed for last step product.
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 3,
      PreviousAction: 'Back to deposit slips',
      BundleStepText: 'Lastly, confirm your endorsement stamp',
      BundleStepSubText:
        'We’ve used the information you provided to prepopulate your endorsement stamp; you can make changes below if necessary. If everything looks good, click <span class="bold-txt">Add to cart</span>.',
      CompanyInfoInCaps: true,
      ImageInformation: {
        Image: STAMP_19,
        Component: EndorsementStampImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: CompanyInfo,
              Props: {
                allCaps: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
  ],
};
const basicCheckValuePackPDS = {
  BundleSteps: [
    // first bundle product - voucher check
    {
      Title: 'Basic Voucher Checks',
      PFID: 'CHECK-02-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Basic Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: BVCcolorMap,
      ImageInformation: {
        Image: VOUCHER_BASIC_BLUE_LARGE,
        Component: BasicVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-02',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                PFID: 'CHECK-02-ult',
                ColorToggler: BVCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: AddShadowMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a shadow monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Lastly, confirm your printable deposit slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information and deposit summary header',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            return isValid;
          },
        },
      ],
    },
  ],
};
const basicCheckValuePackMDS = {
  BundleSteps: [
    // first bundle product - voucher check
    {
      Title: 'Basic Voucher Checks',
      PFID: 'CHECK-02-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Basic Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: BVCcolorMap,
      ImageInformation: {
        Image: VOUCHER_BASIC_BLUE_LARGE,
        Component: BasicVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-02',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                PFID: 'CHECK-02-ult',
                ColorToggler: BVCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: AddShadowMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a shadow monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Lastly, confirm your Booked Manual Deposit Slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [],
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            return isValid;
          },
        },
      ],
    },
  ],
};
const standardValuePackPDS = {
  BundleSteps: [
    // first bundle product - standard check
    {
      Title: 'Basic Standard Checks',
      PFID: 'CHECK-01',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Basic Standard Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: BSCcolorMap,
      ImageInformation: {
        Image: STANDARD_BASIC_BLUE_LARGE,
        Component: BasicStandardChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-01',
                Title: 'Enter a starting check number ',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                ColorToggler: BSCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: AddShadowMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a shadow or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information and deposit summary header',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            return isValid;
          },
        },
      ],
    },
    // third bundle product - stamps
    {
      Title: 'Endorsement Stamp',
      PFID: 'STAMP-19',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // NextIndex and StepNavIndex is not needed for last step product.
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 3,
      PreviousAction: 'Back to deposit slips',
      BundleStepText: 'Lastly, confirm your endorsement stamp',
      BundleStepSubText:
        'We’ve used the information you provided to prepopulate your endorsement stamp; you can make changes below if necessary. If everything looks good, click <span class="bold-txt">Add to cart</span>.',
      CompanyInfoInCaps: true,
      ImageInformation: {
        Image: STAMP_19,
        Component: EndorsementStampImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: CompanyInfo,
              Props: {
                allCaps: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
  ],
};
const standardValuePackMDS = {
  BundleSteps: [
    // first bundle product - voucher check
    {
      Title: 'Basic Standard Checks',
      PFID: 'CHECK-01',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Basic Standard Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: BSCcolorMap,
      ImageInformation: {
        Image: STANDARD_BASIC_BLUE_LARGE,
        Component: BasicStandardChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-01',
                Title: 'Enter a starting check number ',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                ColorToggler: BSCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: AddShadowMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a shadow or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [],
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 4,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            return isValid;
          },
        },
      ],
    },
    // third bundle product - stamps
    {
      Title: 'Endorsement Stamp',
      PFID: 'STAMP-19',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // NextIndex and StepNavIndex is not needed for last step product.
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 3,
      PreviousAction: 'Back to deposit slips',
      BundleStepText: 'Lastly, confirm your endorsement stamp',
      BundleStepSubText:
        'We’ve used the information you provided to prepopulate your endorsement stamp; you can make changes below if necessary. If everything looks good, click <span class="bold-txt">Add to cart</span>.',
      CompanyInfoInCaps: true,
      ImageInformation: {
        Image: STAMP_19,
        Component: EndorsementStampImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: CompanyInfo,
              Props: {
                allCaps: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
  ],
};
const voucherPlusValuePackPDS = {
  BundleSteps: [
    // first bundle product - Secure Plus Voucher Checks
    {
      Title: 'Secure Plus Voucher Checks',
      PFID: 'CHECK-11-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Secure Plus Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: SPVCcolorMap,
      ImageInformation: {
        Image: VOUCHER_SECURE_PLUS_BLUE_LARGE,
        Component: SecurePlusVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                PFID: 'CHECK-11',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-11',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                ColorToggler: SPVCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Lastly, confirm your printable deposit slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information and deposit summary header',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            return isValid;
          },
        },
      ],
    },
  ],
};
const voucherPlusValuePackMDS = {
  BundleSteps: [
    // first bundle product - Secure Plus Voucher Checks
    {
      Title: 'Secure Plus Voucher Checks',
      PFID: 'CHECK-11-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Secure Plus Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: SPVCcolorMap,
      ImageInformation: {
        Image: VOUCHER_SECURE_PLUS_BLUE_LARGE,
        Component: SecurePlusVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                PFID: 'CHECK-11',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-11',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                ColorToggler: SPVCcolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Lastly, confirm your Booked Manual Deposit Slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [],
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            return isValid;
          },
        },
      ],
    },
  ],
};
const voucherPremierValuePackPDS = {
  BundleSteps: [
    // first bundle product - Secure Premier Voucher Checks
    {
      Title: 'Secure Premier Voucher Checks',
      PFID: 'CHECK-16-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Secure Premier Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: SPVCPremiercolorMap,
      ImageInformation: {
        Image: VOUCHER_SECURE_PREMIER_BLUE_LARGE,
        Component: SecurePremierVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-16',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                ColorToggler: SPVCPremiercolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Lastly, confirm your printable deposit slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Printable Deposit Slips',
      PFID: 'DEP-02',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your printable deposit slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: pdsImage,
        Component: PrintableDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information and deposit summary header',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Optional: Add your deposit summary header',
                Size: 25,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add your deposit summary header</div>
                    <div className="tooltip-body">
                      This text will be added to the top of the deposit summary
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }

            return isValid;
          },
        },
      ],
    },
  ],
};
const voucherPremierValuePackMDS = {
  BundleSteps: [
    // first bundle product - Secure Premier Voucher Checks
    {
      Title: 'Secure Premier Voucher Checks',
      PFID: 'CHECK-16-ult',
      IsCheckProduct: true,
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'save',
      // NextIndex decides the next step on proceeding further or proceeding back
      NextIndex: 2,
      // StepNavIndex is used to load proper step when we navigate through bread crumbs
      StepNavIndex: 1,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 1,
      BundleStepText: 'Let’s start with your Secure Premier Voucher Checks',
      BundleStepSubText:
        'We’ll use this information to customize the rest of your products, although you can always make changes later.',
      ColorMapping: SPVCPremiercolorMap,
      ImageInformation: {
        Image: VOUCHER_SECURE_PREMIER_BLUE_LARGE,
        Component: SecurePremierVoucherChecksImage,
        ModalOverFlow: true,
      },
      Steps: [
        {
          // For value packs Quantity is defined by its sku so this property is used to calculate and add envelopes by default
          addMatchingEnv: true,
          Title: 'Enter your company information and check stub text',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 50,
                hideExtraline: false,
                showNameInSummary: true,
                TooltipCompanyInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your company information</div>
                    <div className="tooltip-body">
                      Enter the information you’d like to include on your checks (like your business
                      name, address, phone number, email address)
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: Summary,
              Props: {
                Title: 'Add your check stub text',
                Size: 100,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Add your check stub text</div>
                    <div className="tooltip-body">
                      We automatically added your business name. If you’d prefer something else
                      appear on your check stub, you can enter that now.
                    </div>
                  </div>
                ),
                BoldFeature: false,
              },
            },
          ],
        },
        {
          Title: 'Add your banking information and starting check number ',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_routing} alt="Routing number" />
                    </div>
                    <div className="tooltip-header">Routing Number</div>
                    <div className="tooltip-body">Enter your bank's 9-digit routing number.</div>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-top-img">
                      <img src={tooltip_checkimage_account} alt="Account number" />
                    </div>
                    <div className="tooltip-header">Account Number</div>
                    <div className="tooltip-body">Enter your bank account number.</div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CheckStartingNumber,
              Props: {
                PFID: 'CHECK-16',
                Title: 'Enter a starting check number',
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Enter your starting check number</div>
                    <div className="tooltip-body">
                      Enter a starting check number between 3-6 digits. If you prefer alphanumeric
                      numbering, enter 1-2 letters followed by 3-6 digits. (Alphanumeric numbering
                      has a one-time set-up fee of $4.99.)
                    </div>
                  </div>
                ),
                ReverseNumbering: true,
                TooltipInfoReverseNumbering: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Number your checks in reverse</div>
                    <div className="tooltip-body">
                      Though not common, some printers require that you load and feed your checks
                      with the numbering in reverse (2000, 1999, 1998, etc.). If your sheet-fed
                      printer loads from the front, you may need reverse numbering.
                    </div>
                  </div>
                ),
                Type: 'text',
                MaxLength: 8,
                MinLength: 3,
                ErrorMessage:
                  'The check starting number should be between 101-999999. Please provide your preferred check starting number.',
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            if (custReducer.bankInformation.state) {
              if (isValid && validStatesInUS.includes(custReducer.bankInformation.state))
                isValid = true;
              else isValid = false;
            }
            const validError = validateCheckStartNumber(custReducer.checkStartingNumber.csn, 3, 8);
            if (!isValid || validError) {
              isValid = false;
            } else {
              isValid = true;
            }
            return isValid;
          },
          // Set this flag whereever we have check starting number component defined in the step
          GetMicr: true,
        },
        {
          Title: 'Design your check ',
          Components: [
            {
              Component: ColorToggle,
              Props: {
                Title: 'Choose your check color',
                ColorToggler: SPVCPremiercolorMap,
              },
            },
            {
              divider: true,
            },
            {
              Component: AddMonogramOrLogo,
              Props: {
                AddLogoDisplayOptions: [
                  addLogoOptions.UPLOAD,
                  addLogoOptions.PREDESIGN,
                  addLogoOptions.MONOGRAM,
                ],
                Title: 'Optional: Add a monogram or logo',
                ModalConfiguration: {
                  hideTitleSection: false,
                  description: `Customize your products with a logo: upload your company’s logo or choose from one of our pre-designed logos or monograms.`,
                },
              },
            },
            {
              divider: true,
            },
            {
              Component: SignatureLines,
              Props: {
                Size: 40,
                TooltipInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add text above the signature line
                    </div>
                    <div className="tooltip-body">
                      Provide additional instructions for your checks with text above the signature
                      line, for example,“Void after 90 days”.
                    </div>
                  </div>
                ),
                TooltipAnotherSignatureInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Add another signature line</div>
                    <div className="tooltip-body">
                      Add an additional line for a second signature on your checks.
                    </div>
                  </div>
                ),
              },
            },
            {
              divider: true,
            },
            {
              Component: CustomAttributesComponent,
              Props: {
                showFontFamily: true,
                showInkColor: true,
                showSingleColor: true,
                TooltipFontInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">Optional: Select a different font</div>
                    <div className="tooltip-body">Choose a different font at no extra cost.</div>
                  </div>
                ),
                TooltipInkInfo: (
                  <div className="tooltip-container-customization">
                    <div className="tooltip-header">
                      Optional: Add a different font or ink color
                    </div>
                    <div className="tooltip-body">
                      Print your company information in a different ink color for $19.95. If you add
                      a monogram, logo, or custom logo, it will also print in that color.
                    </div>
                  </div>
                ),
              },
            },
          ],
        },
      ],
    },
    // second bundle product - Deposit slips - intermediate step page where we save the data copied from checks
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      // Edit link is used to open customization step from intermediate save page
      EditLink: 'Edit deposit slips',
      PreviousAction: 'Back to checks',
      BundleStepText: 'Lastly, confirm your Booked Manual Deposit Slips',
      BundleStepSubText:
        'We’ve used the information you provided to pre-populate your deposit slips. Click <span class="bold-txt">Edit deposit slips</span> if you’d like to make changes.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: '',
          Components: [],
        },
      ],
    },
    // second bundle product - Deposit slips edit
    {
      Title: 'Booked Manual Deposit Slips',
      PFID: 'DEP-01',
      // Next Action is used to determine next step whether to save and proceed or add to cart
      NextAction: 'cart',
      // StepNavIndex is used to load proper step when we navigate through bread crumbs, keep it same as step which offers whole customization. in this case its 3rd step
      StepNavIndex: 3,
      // StepIndex is used to indicate step in bread crumbs
      StepIndex: 2,
      BundleStepText: 'Now let’s finish your Booked Manual Deposit Slips',
      BundleStepSubText: 'Follow the steps to finish customizing your deposit slips.',
      ImageInformation: {
        Image: mdsImage,
        Component: ManualDepositSlipImage,
      },
      Steps: [
        {
          Title: 'Enter your company information',
          Components: [
            {
              Component: CompanyInformation,
              Props: {
                maxLength: 25,
                hideExtraline: true,
              },
            },
          ],
          Validate: (custReducer) => {
            return !!custReducer.selectedSku;
          },
        },
        {
          Title: 'Add your account and banking information',
          Components: [
            {
              Component: RoutingAndBankInformation,
              Props: {
                TooltipRoutingInfo: (
                  <div>
                    <span>
                      Enter your bank's routing number. Routing numbers are usually 9 digits
                    </span>
                  </div>
                ),
                TooltipAccountInfo: (
                  <div>
                    <span>Enter your account number; account numbers are usually 7-10 digits.</span>
                  </div>
                ),
              },
            },
          ],
          Validate: (custReducer) => {
            let isValid = false;
            if (custReducer.routingNumber && custReducer.routingNumber.length === 9) isValid = true;
            else isValid = false;

            if (isValid && custReducer.accountNumber && custReducer.accountNumber.length > 2)
              isValid = true;
            else isValid = false;

            if (isValid && custReducer.bankInformation.name) isValid = true;
            else isValid = false;

            return isValid;
          },
        },
      ],
    },
  ],
};
export {
  addLogoOptions,
  addLogoOptionsLookup,
  pdsRouteProps,
  mdsRouteProps,
  endorsementStamp,
  returnAddressStamp,
  returnAddressStampWithMonogram,
  singlewindowformenvelope,
  businessreplyenvelope,
  largemailingenvelope,
  faxHeadCoverStamp,
  customerBillingStatement,
  remittanceStatement,
  productInvoice,
  serviceInvoice,
  professionalInvoice,
  multiPurposeForm,
  directDepositForms,
  securePlusVoucherChecks,
  securePremierVoucherChecks,
  basicVoucherChecks,
  securePlusStandardChecks,
  officeAwayChecks,
  personalChecks,
  securePremierStandardChecks,
  basicStandardChecks,
  securePlusWalletChecks,
  basicWalletChecks,
  manualChecks,
  previewChecksMap,
  voucherCheckValuePackPDS,
  voucherCheckValuePackMDS,
  basicCheckValuePackPDS,
  basicCheckValuePackMDS,
  standardValuePackPDS,
  standardValuePackMDS,
  voucherPlusValuePackPDS,
  voucherPlusValuePackMDS,
  voucherPremierValuePackPDS,
  voucherPremierValuePackMDS,
};
