// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/directdepositforms.scss';
import { formatLogoUrl, formatShadowLogoUrl } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const DirectDepositFormsImage = (props) => {
  const {
    image,
    company,
    logo,
    customColor,
    logoColor,
    shadowLogo,
    shadowLogoColor,
    fromModal,
    summaryText,
    checkStartingNumber,
    selectedFontColor,
    selectedFontFamily,
    companyInfoColor,
  } = props;

  return (
    <div
      className={
        !fromModal
          ? 'direct-deposit-forms-image-container'
          : 'direct-deposit-forms-modal-image-container'
      }
    >
      <img
        style={{ maxWidth: '100%' }}
        alt="..."
        src={customColor.selectedColorPickerImage || image}
        name={customColor.customColorValue || ''}
      />
      {logo && (
        <img className="logo" src={formatLogoUrl(logo, logoColor, selectedFontColor)} alt="..." />
      )}
      {shadowLogo && (
        <img
          className="shadow-logo"
          src={formatShadowLogoUrl(shadowLogo, shadowLogoColor, selectedFontColor)}
          alt="..."
        />
      )}
      {shadowLogo && (
        <img
          className="shadow-logo shadow-logo-voucher"
          src={formatShadowLogoUrl(shadowLogo, shadowLogoColor, selectedFontColor)}
          alt="..."
        />
      )}
      <div
        style={{ textAlign: logo && logo.LogoNumber !== '' ? 'left' : 'center' }}
        className={`image-company-info ${
          companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
        } ${selectedFontFamily.toLowerCase()}`}
      >
        <div
          style={{
            fontSize: `${company.CompanyNameAttribute.FontSize}px`,
            fontWeight: company.CompanyNameAttribute.bold ? 'bold' : 'normal',
          }}
          className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          {company.CompanyName}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine1Attribute.FontSize}px`,
            fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
          }}
          className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          {company.AddressLine1}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine2Attribute.FontSize}px`,
            fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
          }}
          className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          {company.AddressLine2}
        </div>
        <div
          style={{
            fontSize: `${company.PhoneAttribute.FontSize}px`,
            fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
          }}
          className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          {company.Phone}
        </div>
        <div
          style={{
            fontSize: `${company.ExtraLineAttribute.FontSize}px`,
            fontWeight: company.ExtraLineAttribute.bold ? 'bold' : 'normal',
          }}
          className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          {company.ExtraLine}
        </div>
      </div>
      <div className="image-checkStartingNumber-text">
        <div>{checkStartingNumber.csn}</div>
      </div>
      <div className="image-summary-text">
        <div>{summaryText.text}</div>
      </div>
    </div>
  );
};
