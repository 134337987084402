import React, { useState } from 'react';
import '../../../stylesheets/header.scss';
import '@ids-alpha/responsive-grid';
import '../../../stylesheets/responsiveGridStyles.scss';
import '../../../stylesheets/main.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
  convertToSnakeCase,
} from '../Util/CommonUtils.jsx';
import { getHostedURI } from '../Util/SignInUtil';

// eslint-disable-next-line require-jsdoc
const HeaderLink = () => {
  const mainReducer = useSelector((state) => state.mainReducer);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [inputValueSearch, setInputValueSearch] = useState('');

  const onSignInClicked = (e) => {
    // This is a link. The default action needs to be stopped
    e.preventDefault();
    window.location.href = getHostedURI('orderhistory');
  };

  const openDrawer = () => {
    if (isDrawerOpen === false) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const searchParameters = inputValueSearch;
    const footerSearchQuery = {
      searchText: searchParameters,
      currentUrl: window.location.href,
    };
    const savedSearchQuery = getSessionStorage('IMSearchParams');
    if (savedSearchQuery) {
      removeSessionStorage('IMSearchParams');
    }
    setSessionStorage('footerSearchQuery', JSON.stringify(footerSearchQuery));
    const trackInfo = {
      currentUrl: window.location.href,
      currentPageTitle: document.title,
      userAgt: navigator.userAgent.toLowerCase(),
      language: navigator.language,
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
    };
    setSessionStorage('trackInfo', JSON.stringify(trackInfo));

    window.open('/search', '_self');
  };
  const updateInputValue = (event) => {
    setInputValueSearch(event.target.value);
  };
  return (
    <div className="container header-container">
      <div className="im-info">
        <div className="im-data">
          <div className="row gutter">
            <div className="col-xs-12 col-sm-3 col-lg-6">
              <div
                id="hamburger"
                className={`im-icon hamburger ${isDrawerOpen ? 'hamburger-to-cross' : ''}`}
                onClick={openDrawer}
              />
              <div className="im-logo">
                <Link to="/" data-wa-link="intuit logo" aria-label="Company Logo">
                  <div
                    data-wa-link="intuit logo"
                    data-ui-object="logo"
                    data-refer-ui-access-point="intuit_logo"
                    className="header-logo"
                  />
                </Link>
              </div>
              <div role="heading" aria-level="1" className="im-name">
                <span>QuickBooks Checks & Supplies</span>
                <p style={{fontWeight: '700', fontSize:'1px', visibility: 'hidden'}}>intuitmarket</p>
              </div>
              <div className="customer-care" style={{ float: 'right', marginTop: '17px' }}>
                <p style={{ textAlign: 'right', fontSize: '14px', fontWeight: '600' }}>
                  (866) 570-3842
                </p>
                <p style={{ textAlign: 'right', fontSize: '10px', color: '#6B6C72' }}>
                  Mon - Fri, 9am - 8pm ET
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-9 col-lg-6 " style={{ margin: 'auto 0' }}>
              <ul
                id="sideNav"
                className={`resouce-center ${isDrawerOpen ? 'resouce-center-drawer' : ''}`}
              >
                <li>
                  <Link
                    className="margin-data"
                    to="/cart"
                    aria-label={`Cart Items ${mainReducer.pageData.cartCount}`}
                    data-wa-link="cart"
                    data-ui-object="link"
                    data-refer-ui-access-point="cart_items"
                  >
                    Cart{' '}
                    {`${
                      mainReducer.pageData.cartCount > 0
                        ? `(${mainReducer.pageData.cartCount})`
                        : ''
                    }`}
                  </Link>
                </li>
                <li className="border-line">
                  <Link
                    data-wa-link="order-status"
                    data-ui-object="link"
                    data-refer-ui-access-point="order_status"
                    aria-label="Go to Order Status"
                    to="/orderstatus/lookup"
                    className="margin-data"
                  >
                    Order Status
                  </Link>
                </li>
                {!mainReducer.pageData.login && (
                  <li className="border-line">
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      className="margin-data"
                      onClick={onSignInClicked}
                      to="/"
                      aria-label="Go to Sign In page"
                      data-wa-link="Sign In"
                      data-ui-object="link"
                      data-refer-ui-access-point="header_link | sign_in"
                    >
                      Sign In
                    </Link>
                  </li>
                )}

                <li className="border-line">
                  <form
                    role="search"
                    id="IMFooterSearchForm"
                    onSubmit={handleSubmit}
                    className="search-form"
                  >
                    <input
                      aria-label="Search"
                      type="text"
                      id="txtSearchHd"
                      onChange={updateInputValue}
                      value={inputValueSearch}
                      className="search-query"
                      placeholder="Search this site"
                      data-di-id="#txtSearchFt"
                      data-wa-link="Search"
                    />
                    <span
                      className="add-on"
                      role="button"
                      aria-label="search"
                      data-wa-link={`search ${inputValueSearch}`}
                      data-ui-object="form_field"
                      data-refer-ui-access-point={`search_this_site_${convertToSnakeCase(
                        inputValueSearch,
                      )}`}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                      tabIndex={0}
                      onClick={handleSubmit}
                    >
                      {' '}
                      <i className="icon" />{' '}
                    </span>
                  </form>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLink;
