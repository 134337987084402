// eslint-disable-next-line require-jsdoc
import React from 'react';
import zero from '../../../../assets/images/IM/Customization/Micr/0.gif';
import one from '../../../../assets/images/IM/Customization/Micr/1.gif';
import two from '../../../../assets/images/IM/Customization/Micr/2.gif';
import three from '../../../../assets/images/IM/Customization/Micr/3.gif';
import four from '../../../../assets/images/IM/Customization/Micr/4.gif';
import five from '../../../../assets/images/IM/Customization/Micr/5.gif';
import six from '../../../../assets/images/IM/Customization/Micr/6.gif';
import seven from '../../../../assets/images/IM/Customization/Micr/7.gif';
import eight from '../../../../assets/images/IM/Customization/Micr/8.gif';
import nine from '../../../../assets/images/IM/Customization/Micr/9.gif';
import transit from '../../../../assets/images/IM/Customization/Micr/transit.gif';
import onUs from '../../../../assets/images/IM/Customization/Micr/on-us.gif';
import dash from '../../../../assets/images/IM/Customization/Micr/dash.gif';

const micrSet = [zero, one, two, three, four, five, six, seven, eight, nine];

// eslint-disable-next-line require-jsdoc
export const Micr = (props) => {
  const { micr } = props;

  const micrArr = Array.from(micr);

  const html = micrArr.map((digit, key) => {
    if (digit === 'A') {
      return <img key={key} alt={digit} src={transit} />;
    }
    if (digit === 'C') {
      return <img key={key} alt={digit} src={onUs} />;
    }
    if (digit === '_') {
      return <img key={key} alt={digit} src={dash} />;
    }
    if (digit === 'S') {
      return <span key={key}>&nbsp;</span>;
    }
    return <img key={key} alt={digit} src={micrSet[parseInt(digit)]} />;
  });

  return <div>{html}</div>;
};
