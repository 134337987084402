import { ReduxEvent } from '../../../assets/pagedata/constant';

const initialState = {
  orderSearchQuery: {
    bankAccountNumber: '',
    emailAddress: '',
    orderNumber: '',
    phoneNumber: '',
  },
  orderList: [],
  selectedOrderDetail: [],
};

const orderStatusReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ReduxEvent.ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderSearchQuery: data.orderSearchQuery,
        orderList: data.orderList,
      };
    case ReduxEvent.ORDER_SELECTED:
      return {
        ...state,
        selectedOrderDetail: data.selectedOrderDetail,
      };

    default:
      return state;
  }
};

export default orderStatusReducer;
