import { getColorFromHex } from '../../Util/CustomizationUtil';

const companyModal = {
  CompanyName: '',
  AddressLine1: '',
  AddressLine2: '',
  Phone: '',
  ExtraLine: '',
  CompanyNameAttribute: {
    FontSize: '10',
    bold: true,
  },
  AddressLine1Attribute: {
    FontSize: '10',
    bold: false,
  },
  AddressLine2Attribute: {
    FontSize: '10',
    bold: false,
  },
  PhoneAttribute: {
    FontSize: '10',
    bold: false,
  },
  ExtraLineAttribute: {
    FontSize: '10',
    bold: false,
  },
  ExtraLine2: '',
  ExtraLine2Attribute: {
    FontSize: '10',
    bold: false,
  },
};

const ignoreDefaultValues = (value, valueAttributes) => {
  if (
    !!valueAttributes &&
    !!valueAttributes.default_value &&
    valueAttributes.default_value === value
  ) {
    value = '';
  }
  return value;
};

const getExtraLine2Text = (imprintDetails) => {
  // Extraline is needed in remittance statement case
  if (imprintDetails.DataIslandAttributes.ProductID === 'STATE-02') {
    if (imprintDetails.VoucherLine && imprintDetails.VoucherLine !== 'Your Company') {
      return imprintDetails.VoucherLine;
    }
  }
  // Extraline2 is needed in LME case or MULTIFORM
  if (imprintDetails.DataIslandAttributes.ProductID === 'LME-01') {
    if (!imprintDetails.ExtraImprintLines['Extra Line (Optional)']) {
      const keys = Object.keys(imprintDetails.ExtraImprintLines);
      return keys[0];
    }
  }
  // Extraline is needed for MULTIFORM
  if (imprintDetails.DataIslandAttributes.ProductID === 'MULTIFORM-01') {
    return Object.keys(imprintDetails.ExtraImprintLines).find(
      (key) =>
        imprintDetails.ExtraImprintLines[key].default_value === 'Extra Line (Optional)' &&
        imprintDetails.ExtraImprintLines[key].default_text ===
          imprintDetails.ExtraImprintLines[key].default_value,
    );
  }

  if (imprintDetails.CompanyImprint.ExtraLine2) {
    return imprintDetails.CompanyImprint.ExtraLine2;
  }

  return '';
};

const getExtraLine2TextAttributes = (imprintDetails) => {
  // Extraline is needed in remittance statement case
  if (imprintDetails.DataIslandAttributes.ProductID === 'LME-01') {
    const keys = Object.keys(imprintDetails.ExtraImprintLines);
    return {
      FontSize: imprintDetails.ExtraImprintLines[keys[0]]
        ? imprintDetails.ExtraImprintLines[keys[0]].font_size
        : '',
      bold: imprintDetails.ExtraImprintLines[keys[0]]
        ? imprintDetails.ExtraImprintLines[keys[0]].font_weight === 'bold'
        : '',
      font_color: imprintDetails.ExtraImprintLines[keys[0]]
        ? imprintDetails.ExtraImprintLines[keys[0]].font_color
        : '#000000',
    };
  }
  if (imprintDetails.DataIslandAttributes.ProductID === 'MULTIFORM-01') {
    const selected_key = Object.keys(imprintDetails.ExtraImprintLines).find(
      (key) =>
        imprintDetails.ExtraImprintLines[key].default_value === 'Extra Line (Optional)' &&
        imprintDetails.ExtraImprintLines[key].default_text ===
          imprintDetails.ExtraImprintLines[key].default_value,
    );
    return {
      FontSize: imprintDetails.ExtraImprintLines[selected_key]
        ? imprintDetails.ExtraImprintLines[selected_key].font_size
        : '8',
      bold: imprintDetails.ExtraImprintLines[selected_key]
        ? imprintDetails.ExtraImprintLines[selected_key].font_weight === 'bold'
        : '',
      font_color: imprintDetails.ExtraImprintLines[selected_key]
        ? imprintDetails.ExtraImprintLines[selected_key].font_color
        : '#000000',
    };
  }
  return {
    FontSize: imprintDetails.CompanyImprint.ExtraLine2Attributes
      ? imprintDetails.CompanyImprint.ExtraLine2Attributes.font_size
      : '10',
    bold:
      imprintDetails.CompanyImprint.ExtraLine2Attributes &&
      imprintDetails.CompanyImprint.ExtraLine2Attributes.font_weight === 'bold',
    font_color:
      imprintDetails.CompanyImprint.ExtraLine2Attributes &&
      getColorFromHex(imprintDetails.CompanyImprint.ExtraLine2Attributes.font_color),
  };
};
const setAddressLineForCompany = (address) => {
  if (
    !address.City &&
    address.City.trim() === '' &&
    !address.State &&
    address.State.trim() === '' &&
    !address.Zip &&
    address.Zip.trim() === ''
  ) {
    return '';
  }
  return `${address.City}, ${address.State} ${address.Zip}`;
};
const getCompanyImprintReducerCreateModal = (shopperInfo) => {
  if (shopperInfo) {
    return {
      ...companyModal,
      CompanyName: shopperInfo.PaymentDetails.FsgShopperInformation.BusinessName,
      AddressLine1: shopperInfo.PaymentDetails.FsgShopperInformation.AddressLine1,
      AddressLine2: setAddressLineForCompany(shopperInfo.PaymentDetails.FsgShopperInformation),
      Phone: shopperInfo.PaymentDetails.FsgShopperInformation.Phone,
    };
  }
  return companyModal;
};

const setCompanyInfoDefaultFontSize = (modal, fontConfig) => {
  if (fontConfig) {
    return {
      ...modal,
      CompanyNameAttribute: {
        ...modal.CompanyNameAttribute,
        FontSize: fontConfig.CompanyNameAttribute
          ? fontConfig.CompanyNameAttribute.DefaultValue
          : '10',
      },
      AddressLine1Attribute: {
        ...modal.AddressLine1Attribute,
        FontSize: fontConfig.AddressLine1Attribute
          ? fontConfig.AddressLine1Attribute.DefaultValue
          : '10',
      },
      AddressLine2Attribute: {
        ...modal.AddressLine2Attribute,
        FontSize: fontConfig.AddressLine2Attribute
          ? fontConfig.AddressLine2Attribute.DefaultValue
          : '10',
      },
      PhoneAttribute: {
        ...modal.PhoneAttribute,
        FontSize: fontConfig.PhoneAttribute ? fontConfig.PhoneAttribute.DefaultValue : '10',
      },
      ExtraLineAttribute: {
        ...modal.ExtraLineAttribute,
        FontSize: fontConfig.ExtraLineAttribute ? fontConfig.ExtraLineAttribute.DefaultValue : '10',
      },
      ExtraLine2Attribute: {
        ...modal.ExtraLine2Attribute,
        FontSize: fontConfig.ExtraLine2Attribute
          ? fontConfig.ExtraLine2Attribute.DefaultValue
          : '10',
      },
    };
  }
  return modal;
};

const getCompanyImprintReducerEditModal = (imprintDetails) => {
  return {
    CompanyName: ignoreDefaultValues(
      imprintDetails.CompanyImprint.CompanyName,
      imprintDetails.CompanyImprint.CompanyNameAttributes,
    ),
    CompanyNameAttribute: {
      FontSize: imprintDetails.CompanyImprint.CompanyNameAttributes.font_size,
      bold: imprintDetails.CompanyImprint.CompanyNameAttributes.font_weight === 'bold',
    },
    AddressLine1: ignoreDefaultValues(
      imprintDetails.CompanyImprint.AddressLine1,
      imprintDetails.CompanyImprint.AddressLine1Attributes,
    ),
    AddressLine1Attribute: {
      FontSize: imprintDetails.CompanyImprint.AddressLine1Attributes.font_size,
      bold: imprintDetails.CompanyImprint.AddressLine1Attributes.font_weight === 'bold',
    },
    AddressLine2: ignoreDefaultValues(
      imprintDetails.CompanyImprint.AddressLine2,
      imprintDetails.CompanyImprint.AddressLine2Attributes,
    ),
    AddressLine2Attribute: {
      FontSize: imprintDetails.CompanyImprint.AddressLine2Attributes.font_size,
      bold: imprintDetails.CompanyImprint.AddressLine2Attributes.font_weight === 'bold',
    },
    Phone: ignoreDefaultValues(
      imprintDetails.CompanyImprint.Phone,
      imprintDetails.CompanyImprint.PhoneAttributes,
    ),
    PhoneAttribute: {
      FontSize: imprintDetails.CompanyImprint.PhoneAttributes
        ? imprintDetails.CompanyImprint.PhoneAttributes.font_size
        : '10',
      bold:
        imprintDetails.CompanyImprint.PhoneAttributes &&
        imprintDetails.CompanyImprint.PhoneAttributes.font_weight === 'bold',
    },
    ExtraLine: ignoreDefaultValues(
      imprintDetails.CompanyImprint.ExtraLine,
      imprintDetails.CompanyImprint.ExtraLineAttributes,
    ),
    ExtraLineAttribute: {
      FontSize: imprintDetails.CompanyImprint.ExtraLineAttributes
        ? imprintDetails.CompanyImprint.ExtraLineAttributes.font_size
        : '10',
      bold:
        imprintDetails.CompanyImprint.ExtraLineAttributes &&
        imprintDetails.CompanyImprint.ExtraLineAttributes.font_weight === 'bold',
    },
    ExtraLine2: getExtraLine2Text(imprintDetails),
    ExtraLine2Attribute: getExtraLine2TextAttributes(imprintDetails),
  };
};

export const getCompanyImprintReducerModal = (mode, imprintDetails, shopperInfo, fontConfig) => {
  let returnVal = companyModal;
  switch (mode) {
    case 'create': {
      const modal = getCompanyImprintReducerCreateModal(shopperInfo);
      returnVal = setCompanyInfoDefaultFontSize(modal, fontConfig);
      break;
    }

    case 'edit': {
      returnVal = getCompanyImprintReducerEditModal(imprintDetails);
      break;
    }

    default:
      break;
  }

  return returnVal;
};
