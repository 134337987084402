import React from 'react';
import { Modal, ModalContent, ModalHeader } from '@ids/modal-dialog';
import '../../../../stylesheets/customization/customization.scss';

export const ImageModalPreview = (props) => {
  const { open, children, overflow, isCheck } = props;

  return (
    <Modal
      dismissible
      size="large"
      onClose={() => {
        props.close();
      }}
      className={overflow ? 'image-preview-modal-scroll' : 'image-preview-modal'}
      open={open}
      styleOverrideReason="none"
    >
      {overflow && isCheck && (
        <ModalHeader alignment="left">
          <div className="preview-header-modal">Here's a preview of your check</div>
          <div className="preview-sub-header-modal">
            Please double-check your information before ordering.
          </div>
        </ModalHeader>
      )}

      <ModalContent alignment="center" overflow={overflow}>
        <div className={overflow ? 'image-preview-modal-content' : ''}> {children} </div>
      </ModalContent>
    </Modal>
  );
};
