import { ReduxEvent } from '../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
const initialState = {
  sameAsShipping: false,
  signinSuccess: false,
  orderConfirmed: false,
  isBillingEdit: false,
  isCreditCardSelected: false,
  ccerror: null,
};

const checkoutReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ReduxEvent.IS_BILLING_EDIT:
      return { ...state, isBillingEdit: data.isBillingEdit };
    case ReduxEvent.BACK_TO_PAYMENT_CC:
      return { ...state, isCreditCardSelected: data.isCreditCardSelected };
    case ReduxEvent.SAME_AS_SHIPPING:
      return { ...state, sameAsShipping: data.sameAsShipping };
    case ReduxEvent.SIGN_IN_SUCCESS:
      return { ...state, signinSuccess: data.signinSuccess };
    case ReduxEvent.ORDER_CONFIRMED:
      return { ...state, orderConfirmed: data.orderConfirmed };
    case ReduxEvent.CC_ERROR_ORDER_PLACED:
      return { ...state, ccerror: data.ccerror };
    default:
      return state;
  }
};

export default checkoutReducer;
