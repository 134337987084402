// eslint-disable-next-line require-jsdoc
import React from 'react';
import { Micr } from '../Common/Micr';
import '../../../../stylesheets/customization/depositSlip.scss';

// eslint-disable-next-line require-jsdoc
export const PrintableDepositSlipImage = (props) => {
  const { image, company, summaryText, micr, bankInformation, fromModal } = props;

  return (
    <div
      className={!fromModal ? 'deposit-slip-image-container' : 'deposit-slip-modal-image-container'}
    >
      <img style={{ maxWidth: '100%', maxHeight: '705px' }} alt="..." src={image} />
      <div className="image-company-info">
        <div style={{ fontWeight: 'bold' }}>{company.CompanyName}</div>
        <div>{company.AddressLine1}</div>
        <div>{company.AddressLine2}</div>
        <div>{company.Phone}</div>
        <div>{company.ExtraLine}</div>
      </div>
      <div className="image-summary-text">{summaryText.text}</div>
      {micr && (
        <div className="image-micr">
          <Micr micr={micr} />
        </div>
      )}
      <div className="image-bank-info">
        <div>
          {bankInformation.name}
          <br />
          {bankInformation.address}
          <br />
          {`${bankInformation.city}${
            bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
          } ${bankInformation.state} ${bankInformation.zipcode}`}
        </div>
      </div>
    </div>
  );
};
