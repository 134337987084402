// eslint-disable-next-line require-jsdoc
import React from 'react';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';

// eslint-disable-next-line require-jsdoc
export const SheetsToggle = (props) => {
  const { options, value, className } = props;

  const handleSheetChange = (e) => {
    if (e.target.value) {
      props.onChange(e.target.value);
    }
  };

  const getGetSheetDropDownValue = (el) => {
    let returnString = el.Val;

    if (el.Val === '1') {
      returnString += ' sheet';
    } else {
      returnString += ' sheets';
    }

    returnString += ` / ${el.Key.toLowerCase()}`;

    return returnString;
  };

  return (
    <>
      <Dropdown
        data-wa-link="quantity"
        theme="quickbooks"
        buttonType="secondaryGhost"
        className={`width-100-dropdown ${className} dropdown-background-color-white`}
        width="inherit"
        aria-label="quantity"
        onChange={handleSheetChange}
        value={value}
        data-ui-object="dropdown"
        data-refer-ui-access-point="customization_page | select_sheets_quantity"
        data-ui-object-details="Select Sheets Quantity"
      >
        {options.map((el, key) => {
          return (
            <MenuItem key={key} value={el.Val}>
              {getGetSheetDropDownValue(el)}
            </MenuItem>
          );
        })}
      </Dropdown>
    </>
  );
};
