// eslint-disable-next-line import/prefer-default-export
export const GET_PRODUCT_DETAILS = {
  url: 'imrestfulservices/im.simpleservices/api/GetProductDetails',
};
// eslint-disable-next-line import/prefer-default-export
export const POST_PRODUCT_DETAILS = {
  url: 'imrestfulservices/im.simpleservices/api/v2/cart/addproduct',
};
// eslint-disable-next-line import/prefer-default-export
export const GET_PRODUCT_INFO = {
  url: 'ui/server/getPageData.ashx',
};

export const GET_CLIENT_CONFIGS = {
  url: 'imrestfulservices/im.simpleservices/api/clientconfigs',
};

export const GET_PAGE_INFO = {
  url: 'imrestfulservices/im.simpleservices/api/v3/page',
};
export const GET_PAGE_INFO_PARAM = {
  url: 'imrestfulservices/im.simpleservices/api/v3/page?isHostedUISignin=true',
};
export const PUT_WEBLOG_INFO = {
  url: 'imrestfulservices/im.simpleservices/api/log',
};

export const SHOPPER = {
  url: `imrestfulservices/im.simpleservices/api/{version}/shopper`,
};

export const AUTHENTICATE_SHOPPER = {
  url: `imrestfulservices/im.simpleservices/api/{version}/IMShopperAuthentication`,
};

export const GET_SHOPPER_ACCOUNT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/shopperaccount',
};

export const POST_SHIPPING_INFORMATION = {
  url: 'imrestfulservices/im.simpleservices/api/shippinginformation?p1=1',
};

export const POST_BILLING_INFORMATION = {
  url: 'imrestfulservices/im.simpleservices/api/billinginformation?p1=1',
};
export const GET_SHOPPER_INFORMATION = {
  url: 'imrestfulservices/im.simpleservices/api/v1/shopper/GetShopperInformation',
};
export const PUT_SHOPPER_INFORMATION = {
  url: 'imrestfulservices/im.simpleservices/api/v1/shopper?ipAddress=clientIp&sessionid=',
};
export const POST_SHOPPER_INFORMATION = {
  url: 'IMRestfulServices/IM.SimpleServices/api/v1/Shopper',
};
export const GET_VALIDATION = {
  url: 'imrestfulservices/im.simpleservices/api/Validation?state={state}&zip={zip}',
};
export const GET_ORDER_STATUS = {
  url: 'imrestfulservices/im.simpleservices/api/orderstatus',
};
export const GET_SAVED_LOGO = {
  url: 'IMRestfulServices/IM.SimpleServices/api/{version}/logos',
};
export const GET_SAVED_WORK = {
  url: 'IMRestfulServices/IM.SimpleServices/api/savedwork',
};
export const GET_IMAGE = {
  url: 'imrestfulservices/im.simpleservices/api/{version}/image',
};
export const POST_TOKENIZED = {
  url: 'imrestfulservices/im.simpleservices/api/tokenize',
};
export const POST_PAYMENT = {
  url: 'imrestfulservices/im.simpleservices/api/payment',
};

export const GET_ORDER_HISTORY_LIST = {
  url: 'imrestfulservices/im.simpleservices/api/v3/Order',
};

export const GET_SECURE_PLUS_FEATURES = {
  url: 'FSG/Help/SecureChecks/SecurePlusProductFeatures.html?spvc',
};

export const RETRIEVE_REORDER = {
  url: 'imrestfulservices/im.simpleservices/api/v3/retrievereorder/Search',
};

export const RETRIEVE_REORDER_SECURITY_QUESTIONS = {
  url: 'imrestfulservices/im.simpleservices/api/v3/retrievereorder/securityquestionsifany',
};

export const RETRIEVE_REORDER_SEND_EMAIL = {
  url: 'imrestfulservices/im.simpleservices/api/v3/retrievereorder/sendemailnextcsn',
};

export const RETRIEVE_REORDER_REORDER = {
  url: 'imrestfulservices/im.simpleservices/api/v3/retrievereorder/reorder',
};

export const CART_ITEM = {
  url: 'imrestfulservices/im.simpleservices/api/v2/cart',
};

export const POST_QUOTE = {
  url: 'imrestfulservices/im.simpleservices/api/Quote?state={state}&zip={zip}&taxCall={taxCall}',
};

export const GET_QUOTE = {
  url: 'imrestfulservices/im.simpleservices/api/Quote',
};

export const GET_PROMO = {
  url: 'imrestfulservices/im.simpleservices/api/promotion?pfid={pfids}',
};

export const APPLY_PROMO = {
  url: 'SimpleServices/api/promotion?source={source}&pfid={pfids}&shopperId=""',
};

export const ADD_ITEM_TO_CART = {
  url: 'imrestfulservices/im.simpleservices/api/v2/cart/additem',
};

export const GET_XSELL_ITEMS = {
  url: 'SimpleServices/api/xsell',
};

export const GET_MISCDATA = {
  url: 'imrestfulservices/im.simpleservices/api/v3/miscdata/variant',
};

export const GET_ORDER_FORM = {
  url: 'SimpleServices/api/order',
};

export const UPDATE_ORDER_FORM = {
  url: 'SimpleServices/api/order?action=cart',
};
export const CART_PUT = {
  url: 'SimpleServices/api/cart',
};
export const SHIPPING_PUT = {
  url: 'imrestfulservices/im.simpleservices/api/shipping',
};
export const ADDR_STANDARDIZATION = {
  url: 'imrestfulservices/im.simpleservices/api/addressstandardization',
};
export const BILLING_INFO_PUT = {
  url: 'imrestfulservices/im.simpleservices/api/billinginformation',
};
export const GET_SAFE_TECH = {
  url: 'imrestfulservices/im.simpleservices/api/safetech',
};
export const PUT_BILLING = {
  url: 'imrestfulservices/im.simpleservices/api/v2/Billing',
};
export const GET_SHIPPING_SPEED = {
  url: 'imrestfulservices/im.simpleservices/api/shipping/ShippingOptions',
};
export const GET_ORDER_DATA = {
  url: 'imrestfulservices/im.simpleservices/api/v3/Order/{orderNumber}',
};
export const POST_SURVEY_DATA = {
  url: 'imrestfulservices/im.simpleservices/api/v1/Feedback/PostFeedbackInfo',
};
export const GET_RPS_VALIDATION = {
  url: 'imrestfulservices/im.simpleservices/api/Validation?state=ca&zip=111&rps=1',
};
export const GET_BUNDLE_PRICING_INFO = {
  url: 'imrestfulservices/im.simpleservices/api/v1/productImprint/GetBundleSku',
};
export const POST_EFT_BILLING_INFORMATION = {
  url: 'imrestfulservices/im.simpleservices/api/EFTImprintAddressVerification',
};
export const IM_SEARCH = {
  url: 'imrestfulservices/im.simpleservices/api/IMSearch',
};
export const IM_SEARCH_CLICK = {
  url: 'imrestfulservices/im.simpleservices/api/IMSearch/Click',
};
export const COMBINED_FDS_POST = {
  url: 'IMRestfulServices/IM.SimpleServices/api/fds/CombinedFDS',
};

export const EBIZ_AUTHCODE_POST = {
  url: 'imrestfulservices/im.simpleservices/api/orderstatus/ebizorderlookup',
};
