import React from 'react';
import { Link } from 'react-router-dom';

import INTUIT_CHECK_ORDER_FORM from '../../document/intuit_check_order_form.pdf';
import LOGO_CATALOG from '../../document/standardlogos.pdf';

const HelpCommon = {
  title: 'Support',
  navigation: [
    {
      tabName: 'Help',
      link: '/help',
      rel: 'help',
      helpContentVariable: 'HELP_MAIN_PAGE',
      isHidden: true,
    },
    {
      tabName: 'Shipping & Handling FAQ ',
      link: '/shipping-handling-faq',
      rel: 'shipping-and-handeling-faq',
      helpContentVariable: 'HELP_SHIP_HANDLE_FAQ',
      isHidden: true,
    },
    {
      tabName: 'Ordering and shipping',
      link: '/help/ordering-and-shipping',
      rel: 'ordering-and-shipping',
      helpContentVariable: 'HELP_ORDERING_AND_SHIPPING',
    },
    {
      tabName: 'Customizing product',
      link: '/help/customization',
      rel: 'customization',
      helpContentVariable: 'HELP_CUSTOMIZATION',
    },
    {
      tabName: 'Managing my account',
      link: '/help/account',
      rel: 'account',
      helpContentVariable: 'HELP_ACCOUNT',
    },
    {
      tabName: 'Contact us',
      link: '/help/contact-intuit-market',
      rel: 'contact-intuit-market',
      helpContentVariable: 'HELP_CONTACT',
    },
  ],
};

const HELP_MAIN_PAGE = {
  Topics: [
    {
      topicTitle: 'Ordering, reordering, and managing your orders',
      topicContent: [
        {
          topicText: (
            <>
              QuickBooks Checks & Supplies{' '}
              <span className="font-wgt-400">
                is your best source for products guaranteed to work with QuickBooks and present a
                professional look to clients and vendors alike. Order, reorder, and manage your
                business supplies with ease.
              </span>
            </>
          ),
          subTopicLinks: [
            {
              text: 'How do I order from QuickBooks Checks & Supplies?',
              link: '/help/ordering-and-shipping#1',
            },
            {
              text: 'How do I reorder from QuickBooks Checks & Supplies?',
              link: '/help/ordering-and-shipping#2',
            },
            {
              text: 'What should I know about shipping and delivery?',
              link: '/help/ordering-and-shipping#3',
            },
            {
              text: 'How do I check my order status or change my order once it has been placed?',
              link: '/help/ordering-and-shipping#4',
            },
            {
              text: "What is Intuit's policy for returns or exchanges; do you offer a guarantee?",
              link: '/help/ordering-and-shipping#5',
            },
          ],
        },
      ],
    },
    {
      topicTitle: 'Customizing QuickBooks Checks & Supplies',
      topicContent: [
        {
          subTopicLinks: [
            {
              text: 'Can I customize the fonts on my product?',
              link: '/help/customization#1',
            },
            {
              text: "Can I add my business' logo?",
              link: '/help/customization#2',
            },
            {
              text: 'How do I upload and use my logo?',
              link: '/help/customization#3',
            },
            {
              text: "I'm having problems with my logo.",
              link: '/help/customization#5',
            },
          ],
        },
      ],
    },
    {
      topicTitle: 'Managing my account, banking information, and privacy options',
      topicContent: [
        {
          subTopicLinks: [
            {
              text: 'What if I forget my password?',
              link: '/help/account#1',
            },
            {
              text: 'How does Intuit use and protect my banking information?',
              link: '/help/account#2',
            },
            {
              text: 'What is your privacy policy?',
              link: '/help/account#3',
            },
            {
              text: 'How do I manage my marketing preferences?',
              link: '/help/account#3',
            },
            {
              text: 'How do I manage my account information and data rights?',
              link: '/help/account#3',
            },
          ],
        },
      ],
    },
    {
      topicTitle: 'How do I contact QuickBooks Checks & Supplies?',
      topicContent: [
        {
          topicText:
            'Our customer service representatives are ready with product support, help with your account, and help with new and existing orders. Give us a call at (866) 570-3842, Monday to Friday from 9 am to 8 pm ET.',
          subTopicLinks: [
            {
              text: 'How do I contact Checks & Supplies by email?',
              link: '/help/contact-intuit-market',
            },
            {
              text: 'Can I order labor law posters by phone?',
              link: '/help/contact-intuit-market',
            },
            {
              text: 'Can I request a QuickBooks Checks & Supplies catalog?',
              link: '/help/contact-intuit-market',
            },
          ],
        },
      ],
    },
    {
      topicTitle: 'Where do I go for Intuit Software Help and Technical Support?',
      topicContent: [
        {
          topicText: '',
          subTopicLinks: [
            {
              text: 'QuickBooks Software technical support',
              link: 'https://quickbooks.intuit.com/learn-support/en-us/',
            },
            {
              text: 'TurboTax Software technical support',
              link: 'https://turbotax.intuit.com/best-tax-software/common-questions/',
            },
          ],
        },
        {
          topicText:
            'You can learn more about and buy Intuit Software and Services, like QuickBooks and QuickBooks Payroll here:',
          additionalClass: 'help-content-indent',
          subTopicLinks: [
            {
              text: 'QuickBooks Software',
              link: 'https://ttlc.intuit.com/turbotax-support/en-us',
            },
            {
              text: 'QuickBooks Services',
              link: 'https://quickbooks.intuit.com/app/apps/home/',
            },
            {
              text: 'Payroll Services',
              link: 'https://quickbooks.intuit.com/payroll/',
            },
            {
              text: 'TurboTax Software',
              link: 'https://turbotax.intuit.com/',
            },
          ],
        },
      ],
      additionalText: (
        <p className="font-wgt-700">
          <a href="https://www.intuit.com/">Get more information about Intuit.</a>
        </p>
      ),
    },
  ],
};

const HELP_ORDERING_AND_SHIPPING = {
  Topics: [
    {
      topicTitle: 'Ordering, reordering, and managing your orders',
      topicContent: [
        {
          topicText:
            'QuickBooks Checks & Supplies is your best source for products guaranteed to work with QuickBooks and present a professional look to clients and vendors alike. Order, reorder, and manage your business supplies with ease.',
          subTopicLinks: [
            {
              text: 'How do I order from QuickBooks Checks & Supplies?',
              link: '',
            },
            {
              text: 'How do I reorder from QuickBooks Checks & Supplies?',
              link: '',
            },
            {
              text: 'What should I know about shipping and delivery?',
              link: '',
            },
            {
              text: 'How do I check my order status or change my order once it has been placed?',
              link: '',
            },
            {
              text: "What is Intuit's policy for returns or exchanges; do you offer a guarantee?",
              link: '',
            },
          ],
        },
      ],
    },
  ],
  Content: [
    {
      title: 'How do I order from QuickBooks Checks & Supplies?',
      contentText: [
        {
          title: 'Order online:',
          text: 'You can order online, anytime at intuitmarket.com.',
        },
        {
          title: 'Call us:',
          text: 'You can also place an order by calling us at (866) 570-3842, Monday through Friday, 9am-8pm ET.',
        },
        {
          title: 'Fax us:',
          text: (
            <p>
              To fax us your order,{' '}
              <a
                href={INTUIT_CHECK_ORDER_FORM}
                className="font-wgt-700"
                target="_blank"
                rel="noopener noreferrer"
              >
                download an order form
              </a>
              , fill out your order with your credit card or bank debit information as your form of
              payment, and fax it to (888) 352-3956.
            </p>
          ),
        },
        {
          title: 'Mail us:',
          text: (
            <div>
              You can mail your downloaded and completed order form with your credit card or bank
              debit information, check, or money order to:
              <div className="help-content-indent help-content-margin">
                Intuit Inc.
                <br />
                P.O. Box 27207
                <br />
                Salt Lake City, UT 84127-0207
              </div>
            </div>
          ),
        },
        {
          title: 'How can I place an order for labor law posters?',
          text: 'Labor law posters can only be ordered by phone. Call us at (800) 556-0879, Monday through Friday, 9am-8pm ET.',
        },
        {
          title: "I'm a non-profit organization. Can I order without paying tax?",
          text: 'To qualify for a sales tax exemption you must either have a valid tax exempt certificate from your state or have a valid reseller license and purchase the items only for resale. Additionally, payment for the purchase must come from an account owned by the sales tax-exempt entity. We are required to confirm sales tax exemption for every purchase.',
        },
      ],
    },
    {
      title: 'How do I reorder from QuickBooks Checks & Supplies?',
      contentText: [
        {
          title: 'Reordering is quick and easy.',
          text: (
            <div>
              <ol className="help-content-indent">
                <li>
                  Click the{' '}
                  <Link to="/orderhistory" className="font-wgt-700">
                    Time to reorder
                  </Link>{' '}
                  link on the home page or hit{' '}
                  <Link to="/signin" className="font-wgt-700">
                    Sign in
                  </Link>{' '}
                  at the top right of the page.
                </li>
                <li>
                  Once you sign in, you’ll be taken to your Order History page; find the product
                  you’d like to reorder and hit <span className="font-wgt-600">Reorder</span>. You
                  can filter your order history by date and order number.
                </li>
              </ol>
              <div>
                For customizable products like checks, we’ll use the information from your last
                order but you can always make changes.
              </div>
            </div>
          ),
        },
        {
          title: 'How do I reorder my value pack?',
          text: "Value pack orders cannot be reordered online. To place a value pack reorder, call us at (866) 570-3842. You can also place a new Value Pack order; if you're signed in, we'll use your saved information when you customize your products.",
        },
        {
          title: 'How do I set a check reorder reminder in QuickBooks Desktop?',
          text: (
            <div>
              <p>
                If you set a reminder for a particular account in QuickBooks Desktop, each time you
                print a check that matches or exceeds the reminder check number, you'll see a window
                that asks if you'd like to order more checks, reset your reminder check number, or
                be reminded later.
              </p>
              <p>
                Use the reminder option in QuickBooks Desktop to give yourself enough time to
                receive your checks before your supplies run out. For example, if your current
                supply of checks ends at check #500 and you use 20 checks a week, enter 450 in the
                reminder field so that you'll be reminded to order more checks while you still have
                50 checks left.
              </p>
              <ol className="help-content-indent">
                <li>From the QuickBooks Desktop Lists menu, choose Chart of accounts.</li>
                <li>Next, select the account you want to use to write checks.</li>
                <li>
                  From the account menu, choose Edit or New. You can either set up a new reminder or
                  edit or update an existing check reorder reminder in QuickBooks Desktop.
                </li>
                <li>
                  At the bottom of the General Info tab, click the button for ordering checks. This
                  will take you to the Checks & Supplies check ordering page.
                </li>
              </ol>
            </div>
          ),
        },
      ],
    },
    {
      title: 'What should I know about shipping and delivery?',
      contentText: [
        {
          title: 'What shipping methods do you offer?',
          text: (
            <div className="help-content-indent">
              <div className="font-wgt-600">Shipping to the 48 contiguous states:</div>
              <div>
                For most shipments, FedEx is our standard shipping method with Next Day and Second
                Day priority as options. All orders, including those with Priority Shipping, require
                processing time and may not ship the next business day.
              </div>
              <div className="font-wgt-600">Shipping to other locations:</div>
              <div>
                For most shipments, USPS is our standard shipping method for areas outside of the 48
                contiguous states. For customers in Alaska and Hawaii, you also have the option of
                shipping Next Day and Second Day (if available in your area).
              </div>
            </div>
          ),
        },
        {
          title: 'When will my order(s) arrive?',
          text: (
            <div>
              <p>
                The time it takes to receive your product will depend on production time, where
                we’re shipping the order, and the shipping method you choose. A more precise
                estimate of shipping and delivery dates will be provided when you choose a shipping
                method.
              </p>
              <p>
                In general, customized items like checks take 5-7 business days to print before they
                are shipped.
              </p>
              <p>
                Standard ground shipping takes approximately 10 business days from the time you
                submit your order. Priority Shipping (Next Day or Second Day) takes approximately 7
                business days. Shipping outside of the 48 contiguous states may take longer,
                depending on the geographical area.
              </p>
            </div>
          ),
        },
        {
          title: 'How do you calculate shipping costs?',
          text: (
            <div>
              We base our shipping and handling charges on:
              <div className="help-content-indent">
                Weight of the package
                <br />
                Selected carrier
                <br />
                Zip code of the shipping destination
                <br />
                Accessorial charges (residential/commercial/rural fees)
                <br />
                Fuel surcharges determined by the shipping carrier
              </div>
            </div>
          ),
        },
        {
          title: 'What happens if I choose "Signature Required"?',
          text: 'Choosing "Signature Required" means the shipping carrier will not release the package to you without the signature of someone at your delivery address.',
        },
        {
          title:
            'What happens if I choose "Signature Required" and no one’s available at my delivery address to sign for the package?',
          text: "If no one’s available to sign on the carrier's first attempt, the shipping carrier will return the next day to make another delivery attempt. After three attempts, the package will be returned to Intuit. You may also elect to go to the shipping carrier's facility to sign for and receive the package after the first delivery attempt.",
        },
      ],
    },
    {
      title: 'How do I check my order status or change my order once it has been placed',
      contentText: [
        {
          title: 'How do I check my order status?',
          text: (
            <p>
              To check your order status,{' '}
              <Link to="/signin" classNamee="font-wgt-700">
                Sign in to your account
              </Link>
              . Once signed in, you’ll find your order history, including your product information,
              order number, order status, and order date.
            </p>
          ),
        },
        {
          title: 'How do I review and change my order before placing it?',
          text: (
            <>
              <p>
                Every step of the ordering and customization process allows you to review and make
                changes to your order. You can preview your product during customization; keep in
                mind that the preview on the website will be a low-res version to allow you to
                proofread your information but doesn’t represent the actual printed product.
              </p>
              <p>
                You can preview and edit customized products, as well as remove items in the
                shopping cart and on the order review page.
              </p>
            </>
          ),
        },
        {
          title: "Can I cancel or change my order after I've approved and submitted it?",
          text: (
            <>
              <p>
                Once approved and submitted, changes or cancellations can’t be made online; call us
                at (866) 570-3842, Monday through Friday, 9am-8pm ET.
              </p>
              <p>
                To avoid order errors, we recommend reviewing all text, formatting, design elements,
                and other information carefully before placing your order.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: "What is Intuit's policy for returns or exchanges; do you offer a guarantee?",
      contentText: [
        {
          title: 'How do I return or exchange items I’ve ordered?',
          text: (
            <>
              <p>
                To return or exchange non-personalized items, such as tax products or point of sale
                supplies, refer to the packing slip included with your order. It includes a mailing
                label with instructions on returns and exchanges. If you no longer have the packing
                slip, call us at (866) 570-3842.
              </p>
              <p>
                Personalized items such as checks, deposit slips, envelopes, and other customizable
                products can be returned or exchanged by calling us at (866) 570-3842 if:
                <ul>
                  <li>There has been a printing error,</li>
                  <li>The documents don't work with your printer, or</li>
                  <li>Your financial institution will not accept them.</li>
                </ul>
              </p>
            </>
          ),
        },
        {
          title: 'Do QuickBooks Checks & Supplies come with a money-back guarantee?',
          text: (
            <>
              <p>Yes, all of our checks and supplies come with a 60-day satisfaction guarantee.</p>
              <p>
                We guarantee your checks, forms, business materials and supplies will be printed as
                you specify, will work with your printer and current versions of QuickBooks
                software, and your checks will be accepted by every financial institution in the
                U.S., or we'll reprint your order or refund your money.
              </p>
            </>
          ),
        },
      ],
    },
  ],
};

const HELP_CUSTOMIZATION = {
  Topics: [
    {
      topicTitle: 'Customizing QuickBooks Checks & Supplies',
      topicContent: [
        {
          subTopicLinks: [
            {
              text: 'Can I customize the fonts on my product?',
              link: '',
            },
            {
              text: "Can I add my business' logo?",
              link: '',
            },
            {
              text: 'How do I upload and use my logo?',
              link: '',
            },
            {
              text: "I'm having problems with my logo.",
              link: '',
              scrollIndex: 4,
            },
          ],
        },
      ],
    },
  ],
  Content: [
    {
      title: 'Can I customize the fonts on my product?',
      contentText: [
        {
          title: 'What fonts do you offer for personalized products?',
          text: 'You can choose from Auriol, Calvert, Cascade Script, Helvetica, Hobo, Improv, Kaufmann, Korinna, and Palatino. Helvetica is the default font for all products.',
        },
        {
          title: 'Can I get my information printed in a different color?',
          text: 'Your personalized information can be printed in black, blue green, maroon, red,or violet. For all products but checks, you may choose to use one or two ink colors. If you select two colors, one must be black and your personalized information must all be one color.',
        },
      ],
    },
    {
      title: "Can I add my business' logo?",
      contentText: [
        {
          title: 'Can I add my own logo or other artwork to a pre-designed product template?',
          text: 'Yes. You can add your own logo or other artwork to our Voucher, Standard, Wallet, Manual, and Office and Away checks, Single Window and Large Mailing Envelopes, and Business Forms.',
        },
        {
          title: 'Can I change the color of my logo?',
          text: (
            <>
              <p>
                If you’d like a color logo on your checks, call us at (866) 570-3842, Monday through
                Friday, 9am-8pm ET, to place a custom order.
              </p>
              <p>
                For other customizable products, we have six different color options available:
                black, blue, green, maroon, red, and violet.
              </p>
              <p>
                We offer three different printing options:
                <ul className="help-content-indent">
                  <li>Black only</li>
                  <li>One-color printing (logo and information in same color)</li>
                  <li>
                    Two-color printing (Black and one other color. For example, a violet logo and
                    black text or a black logo and violet text)
                  </li>
                </ul>
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'How do I upload and use my logo?',
      contentText: [
        {
          title: 'How do I upload a logo?',
          text: (
            <div>
              <p>
                During the customization process there will be an option to add a logo if it is
                available for that product. To add your own logo:
                <ol className="help-content-indent">
                  <li>
                    Click <span className="font-wgt-600">Add logo</span>.
                  </li>
                  <li>
                    Choose <span className="font-wgt-600">Add a custom logo</span>. Our check
                    customization tool will then search your account for your saved logos. If you do
                    not have any saved logos, you will be prompted to find the file you want to use.
                  </li>
                  <li>
                    Choose <span className="font-wgt-600">Browse</span> to find your the logo file
                    on your computer.
                  </li>
                  <li>
                    Select the logo file and hit <span className="font-wgt-600">Submit</span>. Your
                    file will then be uploaded and converted so you can continue to place your
                    order.
                  </li>
                </ol>
              </p>
              <p>
                If you are not signed in, you will be asked to sign in before you can upload your
                logo. The logo file name can only contain letters, numbers, the underscore character
                (_), and/or hyphens.
              </p>
            </div>
          ),
        },
        {
          title: 'Can I use my uploaded logo for future orders?',
          text: (
            <>
              <p>
                Yes. Once you have successfully uploaded your logo and placed your order, you'll be
                able to use the logo on other customized products.
              </p>
              <p>
                Logos that are uploaded, but not used to place an order, will be available for 90
                days from the date of upload.
              </p>
            </>
          ),
        },
        {
          title: 'Where can I find my saved logo?',
          text: (
            <span>
              You can access your saved logos by <Link to="/signin">signing into your account</Link>
              . Once you’ve signed in, click{' '}
              <span className="font-wgt-600">Customization options</span>. Any logos you've uploaded
              will be displayed there; you can also add a new logo or delete any logos that have
              been uploaded.
            </span>
          ),
        },
        {
          title: 'Can I use a logo from a previous phone or mail-in order for a new online order?',
          text: (
            <>
              <p>
                Yes. If you placed a phone order with your logo within the past three years, your
                logo is saved on file with us. To use that logo online, you’ll need to create an
                account.
                <ol className="help-content-indent">
                  <li>
                    Hit the <span className="font-wgt-600">Sign in</span> button in the main nav.
                  </li>
                  <li>
                    Hit the <span className="font-wgt-600">Create an account</span> button.
                  </li>
                  <li>
                    On the registration page, enter your email address and create a password. You'll
                    need to use the same email address you used when you placed your original order.
                  </li>
                </ol>
              </p>
              <p>
                Your account should contain your order history where you can access previous orders
                that have your logo.
              </p>
              <p>
                If your logo order included special printing instructions like a two-color logo or
                custom fonts, you’ll need to call us at (866) 570-3842 to place your new order.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'What other customization options do you offer?',
      contentText: [
        {
          title: 'If I don’t have my own logo, do you have logos I can choose from?',
          text: (
            <>
              <p>
                <span>
                  Yes. Our standard logo library has hundreds of home and small business logos.{' '}
                </span>
                <span className="help-catalog-logo">
                  <a
                    href={LOGO_CATALOG}
                    target="popup"
                    className="logo-catalog cust-upload-modal-text"
                    onClick={() => {
                      window.open(
                        LOGO_CATALOG,
                        'popup',
                        'top=0,left=0,height=800,width=1000,toolbar=0,location=0,directories=0,status=0,menubar=0,resizable=1,scrollbars=1',
                      );
                      return false;
                    }}
                    data-wa-link="logocatalog"
                    data-ui-object="link"
                    data-refer-ui-access-point="logo_catalog"
                  >
                    Download our logo catalog here.
                  </a>
                </span>
              </p>
              <p>
                We also offer the option of a monogram logo – an artistic, stylized letter that
                includes a choice of five unique monogram styles. You can choose any letter of the
                alphabet as your monogram logo.
              </p>
            </>
          ),
        },
        {
          title: 'What is a shadow logo?',
          text: 'The regular logo appears to the left of your company information on the check. The shadow logo is a grayed-out image that appears in the check’s middle.',
        },
      ],
    },
    {
      title: "I'm having problems with my logo.",
      contentText: [
        {
          title: "What if my logo doesn't upload?",
          text: (
            <>
              <p>
                During the uploading process, you might get a message that your logo upload was
                unsuccessful. In that case, check to make sure your logo file is less than 5MB and
                is in one of the file formats we accept.
              </p>
              <p>
                Once you’ve made sure your logo meets our specifications, try uploading it again.
              </p>
              <p>
                If your logo still won’t upload, give us a call at (866) 570-3842 or send us an
                email at mktplace_customerservice@intuit.com or fsgoc@intuit.com.
              </p>
            </>
          ),
        },
        {
          title: 'What are the different logo formats you accept?',
          text: 'For best results, use an Adobe Illustrator EPS image file. We also accept JPG, GIF, TIF, WMF, AI, PDF, BMP, and PNG "bitmap" images. If you use a bitmap image, make sure it is bigger than 600x600 pixels. If you use a smaller image, it may not look good when printed.',
        },
        {
          title: 'What logo resolution, size, and color do you accept?',
          text: (
            <>
              <p>We accept a maximum logo file size of 5MB.</p>
              <p>
                The height of the logo image, in relation to the dpi (dots per inch), should be at
                least one inch. Anything smaller than that may lose printing quality. If you use a
                bitmap image, make sure it at least 600 X 600 pixels.
              </p>
              <p>
                Logo color for checks, envelopes (except #10 envelopes), and forms must be black and
                white. Grayscale or color logos may produce unpredictable results when converted to
                one color for printing. If you’re not sure how to convert a grayscale or color logo
                to black and white, check out the help files of your graphic design software or
                contact your graphic artist for assistance.
              </p>
            </>
          ),
        },
        {
          title: 'How do I know if my logo meets your resolution requirements?',
          text: (
            <p>
              Here’s how to make sure your logo’s acceptable:
              <ul className="help-content-indent">
                <li>
                  Note the pixel dimensions of your image. Your graphics design software can help
                  you determine this. For example, your image has pixel dimensions of 900 x 1,200
                  pixels.
                </li>
                <li>
                  Divide the height and width by the resolution required for your product. For
                  example, after dividing the 900 X 1,200 dimension above by 600 the result is 1.5"
                  x 2".
                </li>
                <li>
                  Compare the dimension you determined in Step 2 with 1.5" x 1.5". If that dimension
                  is 1.5" x 1.5" or larger, your image is good for best quality printing.
                </li>
              </ul>
            </p>
          ),
        },
        {
          title: 'What is resolution?',
          text: (
            <>
              <p>
                Bitmap images are made up of dots or pixels of color in a grid. All these tiny dots
                of color come together to form the images you see. Bitmap images are resolution
                dependent. Resolution refers to the number of pixels in an image and are usually
                stated in dpi (dots per inch) or ppi (pixels per inch). The typical computer monitor
                has 72 or 96 pixels per inch, depending on your monitor and screen settings.
              </p>
              <p>
                The higher the dots per inch, the better the resolution and the better the image
                quality. For example, if an image has a resolution of 300dpi, it means that a square
                inch of solid color will be filled with about 90,000 (300X300) dots of ink. We
                recommend a least 300dpi for your logo.
              </p>
            </>
          ),
        },
        {
          title: 'Can I reduce or enlarge the size of the logo I upload?',
          text: 'Our check customization tool will automatically size your image according to the space available on the item you’re purchasing. If your logo appears too small, you may have too much white space around your logo image. In that case, we recommend that you crop your image very closely to the edges of the logo, then upload it again.',
        },
        {
          title: 'What if I have multiple images or text in my logo graphic file?',
          text: 'To make sure we don’t print your order with the wrong logo, we won’t process files with multiple images exist. Use your graphic design software to revise your graphic file so it contains only one high-resolution image of your logo.',
        },
        {
          title: '',
          text: '',
        },
      ],
    },
  ],
};

const HELP_CONTACT = {
  Topics: [
    {
      topicTitle: 'How do I contact QuickBooks Checks & Supplies?',
      subTopicContents: [
        <p>
          Our customer service representatives are ready with product support, help with your
          account, and help with new and existing orders. Give us a call at (866) 570-3842, Monday
          to Friday from 9 am to 8 pm ET.
        </p>,
      ],
    },
  ],
  Content: [
    {
      title: '',
      contentText: [
        {
          title: 'How do I contact Checks & Supplies by email?',
          text: 'We’re here to help – or hear any feedback you might have for us. Send us an email at mktplace_customerservice@intuit.com or fsgoc@intuit.com.',
        },
        {
          title: 'Can I order labor law posters by phone?',
          text: 'Yes. If you’d like to place a labor law poster order by phone, call us at (800) 556-0879, Monday through Friday from 9 am to 8 pm ET.',
        },
        {
          title: 'Can I request a QuickBooks Checks & Supplies catalog?',
          text: 'Yes. If you’d like our catalog, just give call us at (866) 570-3842. We’ll be happy to send you one.',
        },
        {
          title: 'Where do I go for Intuit Software Help and Technical Support?',
          text: (
            <div>
              <ul className="help-content-indent help-content-no-bullets">
                <li>
                  <a href="https://quickbooks.intuit.com/learn-support/en-us/">
                    QuickBooks Software technical support
                  </a>
                </li>
                <li>
                  <a href="https://turbotax.intuit.com/best-tax-software/common-questions/">
                    TurboTax Software technical support
                  </a>
                </li>
              </ul>
              <p>
                You can learn more about and buy Intuit Software and Services, like QuickBooks and
                QuickBooks Payroll here:
              </p>
              <ul className="help-content-indent help-content-no-bullets">
                <li>
                  <a href="https://ttlc.intuit.com/turbotax-support/en-us">QuickBooks Software</a>
                </li>
                <li>
                  <a href="https://quickbooks.intuit.com/app/apps/home/">QuickBooks Services</a>
                </li>
                <li>
                  <a href="https://quickbooks.intuit.com/payroll/">Payroll Services</a>
                </li>
                <li>
                  <a href="https://turbotax.intuit.com/">TurboTax Software</a>
                </li>
              </ul>
            </div>
          ),
        },
      ],
    },
  ],
};

const HELP_ACCOUNT = {
  Topics: [
    {
      topicTitle: 'Managing my account, banking information, and privacy options',
      topicContent: [
        {
          subTopicLinks: [
            {
              text: 'What if I forget my password?',
              link: '',
            },
            {
              text: 'How does Intuit use and protect my banking information?',
              link: '',
            },
            {
              text: 'What is your privacy policy?',
              link: '',
            },
            {
              text: 'How do I manage my marketing preferences?',
              link: '',
              scrollIndex: 2,
            },
            {
              text: 'How do I manage my account information and data rights?',
              link: '',
              scrollIndex: 2,
            },
          ],
        },
      ],
    },
  ],
  Content: [
    {
      title: 'What if I forget my password?',
      contentText: [
        {
          title: '',
          text: (
            <>
              <p>
                Click the <span className="font-wgt-700">Sign in</span> button. In the sign in
                screen, click <span className="font-wgt-700">Can’t access your account</span>. Next,
                enter your email address. We’ll send you an email with your temporary password. When
                you sign in with that temporary password, you’ll be able to create a new one.
              </p>
              <p>
                If you have a phone number associated with your account, you can also use that to
                get a sign in code. If neither of those options work for you, click{' '}
                <span className="font-wgt-700">Try something else</span>.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'How does Intuit use and protect my banking information?',
      contentText: [
        {
          title: 'How do you verify my bank information?',
          text: 'We maintain a database of banks and their information. When you enter your routing or bank account number, we’ll look up the name of the bank in our database. Since banks sometimes merge or change names, we make sure to verify it each time you order.',
        },
        {
          title: 'How do I identify my bank’s routing number?',
          text: 'The routing number is the unique identifier for a particular bank’s location. Routing numbers have nine digits and can be found at the bottom of your check, on the left side. ',
        },
        {
          title: 'How do I edit my bank information?',
          text: (
            <>
              <p>
                If you’re placing a <span className="font-wgt-700">new order</span>:
                <div className="help-content-indent">
                  You can <span className="font-wgt-700">edit</span> your bank information at any
                  point during the personalization process. To edit checks already in your shopping
                  cart but not yet ordered, choose the Edit link next to the checks’ product name in
                  the cart.
                </div>
              </p>
              <p>
                If you’re placing a <span className="font-wgt-700">reorder</span>:
                <div className="help-content-indent">
                  You can edit your bank information until you’ve hit the{' '}
                  <span className="font-wgt-700">Proceed to purchase</span> button. Just hit the
                  shopping cart icon at the top of the page to view a list of items in your cart,
                  then choose the <span className="font-wgt-700">Edit</span> link next to the
                  product you'd like to update.
                </div>
              </p>
            </>
          ),
        },
        {
          title: 'How safe are my bank information and credit card numbers?',
          text: (
            <>
              <p>
                QuickBooks Checks & Supplies uses a highly secure encryption service known as SSL,
                or secure sockets layer. Secure sockets layer encrypts the data sent from your
                browser to our servers to protect your personal information from being stolen or
                misused while traveling over the internet. SSL is the method used by most commercial
                web sites requesting or presenting sensitive financial or personal information.
              </p>
              <p>
                Additionally, we electronically verify your order with the credit card company the
                moment you place it and will send you an "order confirmed" message only if your
                credit card company authorizes it. We also check that the names on credit cards and
                bank accounts match and reject any order that seems suspicious.
              </p>
            </>
          ),
        },
        {
          title: 'How is my credit card information used?',
          text: (
            <>
              <p>
                When you place an order at QuickBooks Checks & Supplies, your credit card
                information is securely passed to your credit card company.
              </p>
              <p>
                Once you’ve entered your credit card information on the Checks & Supplies website,
                it becomes stored on a secure database with restricted access. This information
                isn’t visible to you or to our sales or customer service representatives. For
                security purposes, only the first four and last four digits will appear.
              </p>
            </>
          ),
        },
        {
          title: 'Do I need to re-enter my credit card information with each and every order?',
          text: (
            <>
              <p>
                By default, yes. However, if you find it more convenient, you can make your credit
                card available for future orders from your account. By checking the box{' '}
                <span className="font-wgt-700">Save my credit card info to expedite my orders</span>
                , you will be able to place future orders on the site without re-entering your
                credit card information.
              </p>
              <p>
                Keep in mind that any individual that has access to your account will be able to use
                your stored credit card for purchases on QuickBooks Checks & Supplies. Your credit
                card number will not be visible on the site, but orders can be placed. Make sure to
                protect your username and password and sign off when you finish using a shared
                computer.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'What is your privacy policy?',
      contentText: [
        {
          title: 'How do I manage my marketing preferences?',
          text: (
            <p>
              Visit the{' '}
              <a href="https://www.intuit.com/privacy/">Intuit Privacy and Security site</a> to read
              our privacy policy and manage the marketing settings for your Intuit account.
            </p>
          ),
        },
        {
          title: 'How do I manage my account information and data rights?',
          text: (
            <>
              Use the <Link to="/signin">Sign In</Link> button to sign in to your account. On the
              order history page, click <span className="font-wgt-700">Manage account</span> in the
              account navigation. There you will find a link that will take you to the{' '}
              <span className="font-wgt-700">Intuit Account Manager</span>. From there you can
              review and change your account information as well as manage your data privacy
              options.
            </>
          ),
        },
      ],
    },
  ],
};

const HELP_SHIP_HANDLE_FAQ = {
  Topics: [
    {
      topicTitle: 'Shipping & Handling FAQ',
    },
  ],
  Content: [
    {
      title: 'When will my order arrive?',
      contentText: [
        {
          title: '',
          text: (
            <>
              <p>
                Your order’s arrival time will depend on how much printing time we’ll need to
                prepare your products and the shipping speed you choose:
              </p>
              <div className="help-content-indent">
                <div className="font-wgt-700">Printing time</div>
                <p>
                  Typically, it takes 2 to 3 business days for us to produce customized items like
                  checks, envelopes, deposit slips, and stamps. It can take up to 6 business days
                  for some other customizations – like adding your own logo to a check. Items that
                  don’t require customization will usually ship the next business day after you
                  place your order.
                </p>
                <div className="font-wgt-700 help-content-margin">Shipping speed</div>
                <p>
                  We offer three shipping speeds:
                  <br />
                  <br />
                  Standard shipping – your order will arrive 3 to 4 business days plus printing time
                  <br />
                  Two-Day shipping – your order will arrive 2 business days plus printing time
                  <br />
                  Next Day shipping – your order will arrive the next business day plus printing
                  time
                </p>
              </div>
              <p>
                If your order contains several items we may ship them separately so we can get them
                to you as quickly as possible.
              </p>
              <p>
                As an example, you place an order for Secure Plus Voucher Checks and Self-Seal
                Window Envelopes and select standard shipping:
                <div className="help-content-indent">
                  Your envelopes will arrive 3-4 business days after you place your order — they
                  don’t require printing and should ship the next business day. Because your checks
                  need to be printed, they will arrive 5-7 days after you place your order - they
                  will need 2-3 days to print and another 3-4 days of shipping time.
                </div>
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'What if I need my order faster?',
      contentText: [
        {
          title: '',
          text: 'Give our customer care agents a call at (866) 570-3842 - we’ll do our best to accommodate your needs.',
        },
      ],
    },
    {
      title: 'Which shipping companies do you use?',
      contentText: [
        {
          title: '',
          text: (
            <>
              <p>
                For Standard, Two-Day, and Next Day shipping to the 48 contiguous states, we use
                FedEx.
              </p>
              <p>
                Outside of the 48 contiguous states, we ship using USPS. Customers in Alaska and
                Hawaii also have the option of Two-Day and Next Day Shipping via FedEx.
              </p>
            </>
          ),
        },
      ],
    },
    {
      title: 'Where do you ship?',
      contentText: [
        {
          title: '',
          text: 'We ship to all 50 states, American Samoa, Guam, the Northern Mariana Islands, Puerto Rico, the U.S. Virgin Islands, minor outlying islands and most U.S. military bases.',
        },
      ],
    },
    {
      title: 'Do you ship to PO Boxes?',
      contentText: [
        {
          title: '',
          text: 'No, we are unable to ship to PO Boxes.',
        },
      ],
    },
    {
      title: 'Is my shipment secure?',
      contentText: [
        {
          title: '',
          text: 'We ship your items from a secure facility using reliable carriers. For the highest security possible, choose “Signature Required” as a shipping option. It’s available when ordering our checks and our deposit slips.',
        },
      ],
    },
    {
      title: 'What if I chose “Signature Required,” but no one is there to sign for the package?',
      contentText: [
        {
          title: '',
          text: "If no one is there on the first delivery attempt, the carrier will come back the next day to make another attempt. (If you prefer, you can also go to the carrier's shipping facility to sign for and receive the package there.) After three delivery attempts, the carrier will send the package back to Intuit.",
        },
      ],
    },
    {
      title: 'Can you send me a reminder to order checks so I’m not always ordering last minute?',
      contentText: [
        {
          title: '',
          text: (
            <p>
              Absolutely. To set up a reorder reminder in QuickBooks Desktop:
              <ul className="help-content-indent">
                <li>
                  From the Banking menu, select Order Checks & Envelopes &gt; Set Check Reorder
                  Reminder
                </li>
                <li>
                  In the Set Check Reminder window, select the account you want to set the reminder
                  for and enter the check number that will be used to send the reminder.
                </li>
                <li>Click OK to save your changes.</li>
              </ul>
              Remember to leave yourself enough time to have your new checks printed and shipped to
              you.
            </p>
          ),
        },
      ],
    },
  ],
};

const HelpPageData = {
  HELP: {
    ...HelpCommon,
    HELP_MAIN_PAGE,
    HELP_ORDERING_AND_SHIPPING,
    HELP_CUSTOMIZATION,
    HELP_CONTACT,
    HELP_ACCOUNT,
    HELP_SHIP_HANDLE_FAQ,
  },
};

export default HelpPageData;
