import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import HeaderLink from './HeaderLink';
import HeaderNavigation from './HeaderNavigation';
import '../../../stylesheets/header.scss';
import { getPageConfigInfo } from '../../services/commonDataService';
import { getSessionStorage, setSessionStorage } from '../Util/CommonUtils.jsx';
import { ReduxEvent } from '../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
const HeaderPage = (props) => {
  // const [fetchError, setFetchError] = useState(false);
  const dispatch = useDispatch();
  const mainReducer = useSelector((state) => state.mainReducer);
  const [isPromoClass, setPromoClass] = useState(false);
  const [showPromoBanner, setPromoBanner] = useState('none');
  const [isStickyHeader, setStickyHeader] = useState(false);
  const headerRef = useRef();

  const headerNavInfo = (pageData) => {
    props.logToIMWebLogger('INFO', 'Header Information fetched successfully');
    dispatch({
      type: ReduxEvent.PAGE_DATA,
      data: {
        pageData: {
          cartCount: pageData.CartCount,
          login: pageData.login,
          promotionMessage: pageData.PromotionMessage,
        },
      },
    });
  };

  const headerNavInfoError = (err) => {
    console.log(err);
    props.logToIMWebLogger('Error', 'Header Information fetched failed');
  };

  useEffect(() => {
    if (!!mainReducer.pageData.promotionMessage && mainReducer.pageData.promotionMessage !== '') {
      setPromoBanner('block');
      const ls = getSessionStorage('banner-visited');
      if (ls && ls !== '' && ls.value != null) {
        setPromoClass(true);
      } else {
        setTimeout(() => {
          setSessionStorage('banner-visited', 'true');
          setPromoClass(true);
        }, 2500);
      }
    } else {
      setPromoBanner('none');
      setPromoClass(false);
    }
  }, [mainReducer.pageData.promotionMessage]);

  useEffect(() => {
    getPageConfigInfo(headerNavInfo, headerNavInfoError);
    // Get the header
    // const header = ;

    // Get the offset position of the navbar
    const sticky = headerRef.current.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function fixNavigation() {
      if (window.pageYOffset > sticky) {
        // header.classList.add('sticky-header');
        setStickyHeader(true);
      } else {
        // header.classList.remove('sticky-header');
        setStickyHeader(false);
      }
    }

    window.addEventListener('scroll', fixNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      role="banner"
      id="headerInfo"
      ref={headerRef}
      className={`headerInfo ${isStickyHeader ? ' sticky-header' : ''}`}
    >
      <div className="main-header-section">
        <div
          className={`bh-promo ${isPromoClass ? ' collapsed' : ''}`}
          style={{ display: showPromoBanner }}
        >
          <div className={`offer-bar ${isPromoClass ? ' offer-bar-collapsed' : ''}`}>
            <p className="condition-data">Limited time only*</p>
            <p className="banner-message">{mainReducer.pageData.promotionMessage}</p>
          </div>
          <div className={`beam-logo ${isPromoClass ? ' collapsed-image' : ''}`} />
        </div>
        <HeaderLink {...props} />
        <HeaderNavigation {...props} isStickyHeader={isStickyHeader} />
      </div>
    </div>
  );
};

HeaderPage.propTypes = {
  logToIMWebLogger: PropTypes.func.isRequired,
};

export default HeaderPage;
