import React from 'react';
import dpsPrt120 from '../../images/IM/DepositSlip/dps_prt_120.gif';
import dpsPrt340 from '../../images/IM/DepositSlip/dps_prt_340.gif';
// Deposit Slips
import dpPrint from '../../images/IM/DepositSlip/DepositSlips-Printable.png';
import dpBMD from '../../images/IM/DepositSlip/DepositSlips-BookedManualDeposit.png';
import dpsMan120 from '../../images/IM/DepositSlip/dps_man_120.gif';
import dpsMan340 from '../../images/IM/DepositSlip/dps_man_340.gif';
import dpsBag120 from '../../images/IM/DepositSlip/dps_bag_120.gif';
import dpsBag340 from '../../images/IM/DepositSlip/dps_bag_340.gif';
import bnkDepositBag from '../../images/IM/DepositSlip/DepositSlips-BankDepositBags.jpg';
import dpCrSellEndor from '../../images/IM/DepositSlip/DepositSlips-CrossSell-EndorsementStamp.jpg';
import dpCrSellVC from '../../images/IM/DepositSlip/DepositSlips-CrossSell-VoucherChecks.jpg';
import dpCrSellEv from '../../images/IM/DepositSlip/DepositSlips-CrossSell-Envelopes.jpg';

const DepositSlipsData = {
  DEPOSIT_SLIPS: {
    Department: {
      DeptName: 'Deposit slips',
      DeptDesc:
        'Prepare deposits easily with QuickBooks Deposit Slips for your business. Order the quick-and-easy printable deposit slips or the manual deposit slips you fill out by hand.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['DEP-02', 'DEP-01', 'BAG-01', 'STAMP-19', 'CHECK-16', 'ENV-08'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Printable QuickBooks Deposit Slips',
            ProductDesc:
              'Eliminate the need for double entry by ordering our printable bank deposit slips. Print your business deposit slips directly from QuickBooks with information you’ve already entered.',
            ImageSrc: dpPrint,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/deposit-slips/printable/customization',
            LearnMoreLink: '/deposit-slips/printable',
            LearnMoreSuffixText: null,
            ActualPrice: 68.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'DEP-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Booked Manual Deposit Slips',
            ProductDesc:
              'Save time by entering up to 18 entries on each of our booked deposit slips. Available with two or three carbonless duplicates for easy record keeping.',
            ImageSrc: dpBMD,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/deposit-slips/manual/customization',
            LearnMoreLink: '/deposit-slips/manual',
            LearnMoreSuffixText: null,
            ActualPrice: 43.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'DEP-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Bank Deposit Bags',
            ProductDesc:
              'Disposable and convenient, QuickBooks deposit bags eliminate unnecessary trips to your bank to pick up costly lockbags.',
            ImageSrc: bnkDepositBag,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/deposit-slips/disposable-deposit-bags',
            LearnMoreLink: '/deposit-slips/disposable-deposit-bags',
            LearnMoreSuffixText: null,
            ActualPrice: 12.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'BAG-01',
          },
        ],
      },
    ],
    AlsoRecommended: {
      Header: 'Also recommended',
      Items: [
        {
          Title: 'Endorsement Stamp',
          Desc: [
            'Save time! Our hassle-free pre-inked endorsement stamps can be pesonalized with up to five lines of information.',
          ],
          Image: dpCrSellEndor,
          LearnMoreLink: '/stampsandlabels/endorsement-stamps',
          LearnMoreLinkEnabled: true,
          ButtonText: 'Get started',
          ButtonLink: '/stampsandlabels/endorsement-stamps/customization',
          ButtonLinkEnabled: true,
          PFID: 'STAMP-19',
        },
        {
          Title: 'Secure Premier Voucher Checks',
          Desc: ['Helps secure your business with our strongest defence against counterfeiting.'],
          Image: dpCrSellVC,
          LearnMoreLink: '/spvcpremier',
          LearnMoreLinkEnabled: true,
          ButtonText: 'Get started',
          ButtonLink: '/checks/voucher-checks/secure-premier-voucher-checks/customization',
          ButtonLinkEnabled: false,
          PFID: 'CHECK-16',
        },
        {
          Title: 'Self Seal Voucher Check Envelopes',
          Desc: [
            'QuickBooks standard and voucher checks allign perfectly to keep sensitive information from view.',
          ],
          Image: dpCrSellEv,
          LearnMoreLink: '/envelopes/check-window/self-seal-voucher',
          LearnMoreLinkEnabled: true,
          ButtonText: 'Add to cart',
          PFID: 'ENV-08',
          Sku: '229-00250',
        },
      ],
    },
  },
  DEPOSIT_SLIP_PRINTABLE: {
    Department: {
      DeptName: 'Printable Deposit Slips',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['DEP-02'],
      Image: dpsPrt120,
      ImageLg: dpsPrt340,
      PFID: 'DEP-02',
      Sku: '1021',
      ProductInformation: {
        Desc: (
          <span>
            Print directly from QuickBooks on these deposit slips pre-printed with your information;
            avoid double entry errors and increase record-keeping accuracy. Printable deposit slips
            include a deposit summary stub for easy business record-keeping. Record up to 18 entries
            per slip.
          </span>
        ),
        EachKitContains: null,
        Specifications: [
          'Size: 8.5"w x 11"h',
          'Compatibility: All supported versions of QuickBooks Online, QuickBooks Desktop, and QuickBooks for Macintosh 2007 and above',
        ],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/deposit-slips/printable/customization',
        LinkEnabled: true,
      },
    },
  },
  DEPOSIT_SLIP_BOOKED_MANUAL: {
    Department: {
      DeptName: 'Booked Manual Deposit Slips',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['DEP-01'],
      PFID: 'DEP-01',
      Sku: '4698',
      Image: dpsMan120,
      ImageLg: dpsMan340,
      ProductInformation: {
        Desc: (
          <span>
            Speed up deposits by using slips that have your business name and account number
            pre-printed on them. Make up to 18 entries per slip.
          </span>
        ),
        DescExtras: [
          <span>Available in two or three copy carbonless versions for easy record-keeping.</span>,
        ],
        EachKitContains: null,
        Specifications: null,
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/deposit-slips/manual/customization',
        LinkEnabled: true,
      },
    },
  },
  DEPOSIT_BAGS: {
    Department: {
      DeptName: 'Bank Deposit Bags',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['BAG-01'],
      PFID: 'BAG-01',
      Image: dpsBag120,
      ImageLg: dpsBag340,
      ProductInformation: {
        Desc: (
          <span>
            QuickBooks bank deposit bags eliminate unnecessary trips to your bank to pick up costly
            lockbags and cash bags.
          </span>
        ),
        DescExtras: [
          <span>
            Use these recyclable plastic deposit and money bags to deposit cash, checks, and coins.
            The bags are designed with tamper-evident features for security. A removable, numbered
            receipt is included for easy deposit verification.
          </span>,
        ],
        CheckLockProtection: false,
        EachKitContains: null,
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
};

export default DepositSlipsData;
