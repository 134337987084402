// eslint-disable-next-line require-jsdoc
import React, { useEffect, useState } from 'react';
import Button from '@ids/button';
import Tile from '@ids-beta/tile';
import IndeterminateShort from '@ids/indeterminate-short';
import { Collapse } from '@material-ui/core';
import { getLogoByShopperId } from '../../../../services/customizationDataService';
import { formatStandardImageUrl } from '../../../Util/CustomizationUtil';
import UploadLogoModal from '../../../MyAccount/UploadLogoModal';

// eslint-disable-next-line require-jsdoc
export const UploadedLogo = (props) => {
  const [customLogos, setCustomLogos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);

  const getLogos = () => {
    setLoading(true);
    getLogoByShopperId()
      .then((res) => {
        if (res.length > 0) setCustomLogos(res);
        else setShowUpload(true);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        props.logToIMWebLogger(
          'error',
          `Customization: UploadedLogo.jsx getLogos failed. Error: ${err}`,
        );
      });
  };

  const logoSelectionChanged = (lg) => {
    setSelectedLogo(lg);
  };

  const getChecked = (a) => {
    if (selectedLogo) return selectedLogo.LogoFileNumber === a.LogoFileNumber;
    return false;
  };

  const applyLogo = () => {
    if (selectedLogo) props.onLogoSelect(selectedLogo);
  };

  const postUploadedImage = (logos) => {
    setCustomLogos(logos);
    setShowUpload(false);
  };

  useEffect(() => {
    getLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Collapse in={!showUpload}>
        <div className="margin-top-55">
          <h4 className="margin-bottom-20 logo-header">Select an uploaded logo</h4>
          <p className="cust-upload-modal-text upload-sub-header">
            Select one of the saved logos below or upload a new logo
          </p>
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <IndeterminateShort theme="quickbooks" />
            </div>
          ) : (
            <>
              <div className="margin-bottom-30">
                <div className="row">
                  {customLogos.map((a, key) => {
                    return (
                      <div key={key} className="col-sm-12 col-md-6 col-lg-4">
                        <div style={{ margin: '5px' }}>
                          <Tile
                            className="upload-logo-tile"
                            iconSize="large"
                            title={a.LogoLabel}
                            description={a.CreatedDateTime}
                            automationId={a.LogoFileNumber}
                            icon={<img src={formatStandardImageUrl(a.Url)} alt={`saved-logo-${key}`} />}
                            onChange={() => logoSelectionChanged(a)}
                            checked={getChecked(a)}
                            type="checkbox"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Button
                buttonType="secondary"
                theme="quickbooks"
                className="margin-right-10"
                onClick={() => setShowUpload(true)}
                data-testid="upload-logo"
              >
                Upload your logo
              </Button>
              <Button
                buttonType="primary"
                theme="quickbooks"
                // disabled={loading}
                onClick={applyLogo}
                // data-wa-link="cancelccinfo"
              >
                Apply selected logo
              </Button>
            </>
          )}
        </div>
      </Collapse>
      <Collapse in={showUpload}>
        <UploadLogoModal
          hideClosIcon
          // close={props.onLogoSelect(null)}
          onUploadComplete={postUploadedImage}
          savedLogo={customLogos}
        />
      </Collapse>
    </div>
  );
};
