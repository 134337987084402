// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/walletchecks.scss';
import { formatLogoUrl } from '../../Util/CustomizationUtil';
import { Micr } from '../Common/Micr';

// eslint-disable-next-line require-jsdoc
export const BasicWalletChecksImage = (props) => {
  const {
    image,
    company,
    logo,
    customColor,
    logoColor,
    fromModal,
    checkStartingNumber,
    selectedFontColor,
    selectedFontFamily,
    companyInfoColor,
    micr,
    fractional,
    bankInformation,
    signatureLines,
  } = props;

  const checkStartingNumberInc = (CSN, increamentBy) => {
    const splitArray = CSN.match(/[^\d]+|\d+/g);
    let alphapart = '';
    let numPart = '';
    if (!splitArray) {
      return '';
    }
    /* istanbul ignore next line */
    if (splitArray.length === 0) {
      /* istanbul ignore next line */
      return '';
    }
    if (splitArray.length === 1) {
      if (Number.isNaN(parseInt(splitArray[0]))) {
        return splitArray[0];
      }

      return parseInt(CSN) + increamentBy;
    }
    /* istanbul ignore next line */
    if (splitArray.length === 2) {
      if (Number.isNaN(parseInt(splitArray[0]))) {
        alphapart = splitArray[0];
        numPart = splitArray[1];
      } else {
        numPart = splitArray[0];
        alphapart = splitArray[1];
      }
      const incremented = parseInt(numPart) + increamentBy;
      return alphapart + incremented;
    }
  };

  return (
    <div
      className={
        !fromModal ? 'wallet-checks-image-container' : 'wallet-checks-modal-image-container'
      }
    >
      <img
        style={{ maxWidth: '100%' }}
        alt="..."
        src={customColor.selectedColorPickerImage || image}
        name={customColor.customColorValue || ''}
      />
      <div>
        <div className="logoWithAddress">
          {logo && (
            <img
              className="logo"
              src={formatLogoUrl(logo, logoColor, selectedFontColor)}
              alt="..."
            />
          )}
          <div
            style={{ textAlign: logo && logo.LogoNumber !== '' ? 'left' : 'center' }}
            className={`image-company-info ${
              companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
            } ${selectedFontFamily.toLowerCase()}`}
          >
            <div
              style={{
                fontSize: `${company.CompanyNameAttribute.FontSize}px`,
                fontWeight: 'bold',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.CompanyName}
            </div>
            <div
              style={{
                fontSize: `${company.AddressLine1Attribute.FontSize}px`,
                fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine1}
            </div>
            <div
              style={{
                fontSize: `${company.AddressLine2Attribute.FontSize}px`,
                fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine2}
            </div>
            <div
              style={{
                fontSize: `${company.PhoneAttribute.FontSize}px`,
                fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.Phone}
            </div>
            <div
              style={{
                fontSize: `${company.ExtraLineAttribute.FontSize}px`,
                fontWeight: company.ExtraLineAttribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.ExtraLine}
            </div>
          </div>
        </div>
        <div className="image-checkStartingNumber-text">
          <div>{checkStartingNumber.csn}</div>
        </div>
        {micr && (
          <div className="image-micr">
            <Micr micr={micr} />
          </div>
        )}
        <div
          className={`image-bank-info ${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          <div>
            <strong>{bankInformation.name}</strong>
            <br />
            <strong>{bankInformation.address}</strong>
            <br />
            {`${bankInformation.city}${
              bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
            } ${bankInformation.state} ${bankInformation.zipcode}`}
          </div>
          <div>{fractional}</div>
        </div>
        <div className="sign-lines-upper-text">
          <p style={{ fontSize: '10px' }}>{signatureLines.line2}</p>
        </div>
        {signatureLines.totalLines === 2 && <hr className="sign-upper-line" />}
        <div className="sign-lines-lower-text">
          <p style={{ fontSize: '10px' }}>{signatureLines.line1}</p>
        </div>
      </div>
      <div className="check-middle-section">
        <div className="logoWithAddress">
          {logo && (
            <img
              className="logo align-info-middle"
              src={formatLogoUrl(logo, logoColor, selectedFontColor)}
              alt="..."
            />
          )}
          <div
            style={{ textAlign: logo && logo.LogoNumber !== '' ? 'left' : 'center' }}
            className={`image-company-info ${
              companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
            } ${selectedFontFamily.toLowerCase()} align-info-middle`}
          >
            <div
              style={{
                fontSize: `${company.CompanyNameAttribute.FontSize}px`,
                fontWeight: 'bold',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.CompanyName}
            </div>
            <div
              style={{
                fontSize: `${company.AddressLine1Attribute.FontSize}px`,
                fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine1}
            </div>
            <div
              style={{
                fontSize: `${company.AddressLine2Attribute.FontSize}px`,
                fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine2}
            </div>
            <div
              style={{
                fontSize: `${company.PhoneAttribute.FontSize}px`,
                fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.Phone}
            </div>
            <div
              style={{
                fontSize: `${company.ExtraLineAttribute.FontSize}px`,
                fontWeight: company.ExtraLineAttribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.ExtraLine}
            </div>
          </div>
        </div>
        <div className="image-checkStartingNumber-text align-info-middle">
          <div>{checkStartingNumberInc(checkStartingNumber.csn, 1)}</div>
        </div>
        {micr && (
          <div className="image-micr align-micr-middle">
            <Micr micr={micr} />
          </div>
        )}
        <div
          className={`image-bank-info align-info-middle ${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          <div>
            <strong>{bankInformation.name}</strong>
            <br />
            <strong>{bankInformation.address}</strong>
            <br />
            {`${bankInformation.city}${
              bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
            } ${bankInformation.state} ${bankInformation.zipcode}`}
          </div>
          <div>{fractional}</div>
        </div>
        <div className="sign-lines-upper-text align-sign-middle-uppertext">
          <p style={{ fontSize: '10px' }}>{signatureLines.line2}</p>
        </div>
        {signatureLines.totalLines === 2 && (
          <hr className="sign-upper-line align-sign-middle-line" />
        )}
        <div className="sign-lines-lower-text align-sign-middle-lowertext">
          <p style={{ fontSize: '10px' }}>{signatureLines.line1}</p>
        </div>
      </div>

      <div className="check-bottom-section">
        <div className="logoWithAddress">
          {logo && (
            <img
              className="logo align-info-basic-bottom"
              src={formatLogoUrl(logo, logoColor, selectedFontColor)}
              alt="..."
            />
          )}
          <div
            style={{ textAlign: logo && logo.LogoNumber !== '' ? 'left' : 'center' }}
            className={`image-company-info ${
              companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
            } ${selectedFontFamily.toLowerCase()} align-info-basic-bottom`}
          >
            <div
              style={{
                fontSize: `${company.CompanyNameAttribute.FontSize}px`,
                fontWeight: 'bold',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.CompanyName}
            </div>
            <div
              style={{
                fontSize: `${company.AddressLine1Attribute.FontSize}px`,
                fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine1}
            </div>
            <div
              style={{
                fontSize: `${company.AddressLine2Attribute.FontSize}px`,
                fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine2}
            </div>
            <div
              style={{
                fontSize: `${company.PhoneAttribute.FontSize}px`,
                fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.Phone}
            </div>
            <div
              style={{
                fontSize: `${company.ExtraLineAttribute.FontSize}px`,
                fontWeight: company.ExtraLineAttribute.bold ? 'bold' : 'normal',
              }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.ExtraLine}
            </div>
          </div>
        </div>
        <div className="image-checkStartingNumber-text align-info-basic-bottom">
          <div>{checkStartingNumberInc(checkStartingNumber.csn, 2)}</div>
        </div>
        {micr && (
          <div className="image-micr align-micr-bottom">
            <Micr micr={micr} />
          </div>
        )}
        <div
          className={`image-bank-info align-info-basic-bottom ${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          <div>
            <strong>{bankInformation.name}</strong>
            <br />
            <strong>{bankInformation.address}</strong>
            <br />
            {`${bankInformation.city}${
              bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
            } ${bankInformation.state} ${bankInformation.zipcode}`}
          </div>
          <div>{fractional}</div>
        </div>
        <div className="sign-lines-upper-text align-sign-bottom-uppertext">
          <p style={{ fontSize: '10px' }}>{signatureLines.line2}</p>
        </div>
        {signatureLines.totalLines === 2 && (
          <hr className="sign-upper-line align-sign-bottom-line" />
        )}
        <div className="sign-lines-lower-text align-sign-bottom-lowertext">
          <p style={{ fontSize: '10px' }}>{signatureLines.line1}</p>
        </div>
      </div>
    </div>
  );
};
