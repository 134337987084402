// eslint-disable-next-line require-jsdoc
import React, { useEffect, useState } from 'react';
import Button from '@ids/button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createMuiTheme, InputLabel, MuiThemeProvider, MenuItem } from '@material-ui/core';
import Tile from '@ids-beta/tile';
import IndeterminateShort from '@ids/indeterminate-short';
import {
  getLogoByCategoryId,
  getPredesignCategories,
} from '../../../../services/customizationDataService';
import { formatStandardImageUrl } from '../../../Util/CustomizationUtil';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2ca01c',
    },
  },
});

// eslint-disable-next-line require-jsdoc
export const PreDesignedLogo = (props) => {
  const [selectedCategory, setSelectedCategory] = useState('select');
  const [categories, setCategories] = useState([]);
  const [monograms, setMonograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [selectedMonogram, setSelectedMonogram] = useState(null);

  const getLogos = (catergoryId) => {
    setLoading(true);
    getLogoByCategoryId(catergoryId)
      .then((res) => {
        setMonograms(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        props.logToIMWebLogger(
          'error',
          `Customization: PreDesignedLogo.jsx getLogos failed for categoryid: '${catergoryId}'. Error: ${err}`,
        );
      });
  };

  const handleCategoryChanged = (e) => {
    const { value } = e.target;
    setSelectedCategory(value);
    getLogos(value);
    setSelectedMonogram(null);
  };

  const monogramSelectionChanged = (mono) => {
    setSelectedMonogram(mono);
  };

  const getChecked = (a) => {
    if (selectedMonogram) return selectedMonogram.LogoFileNumber === a.LogoFileNumber;
    return false;
  };

  const applyMonogram = () => {
    props.onLogoSelect(selectedMonogram);
  };

  const getCategories = () => {
    getPredesignCategories()
      .then((res) => {
        setCategories(res);
        setCategoriesLoading(false);
      })
      .catch((err) => {
        setCategoriesLoading(false);
        props.logToIMWebLogger(
          'error',
          `Customization: PreDesignedLogo.jsx getCategories failed. Error: ${err}`,
        );
      });
  };

  const displayLogoCatalog = () => {
    window.open(
      props.CatalogLink,
      'popup',
      'top=0,left=0,height=800,width=1000,toolbar=0,location=0,directories=0,status=0,menubar=0,resizable=1,scrollbars=1',
    );
  };

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="margin-top-55">
      <h4 className="margin-bottom-20 logo-header">Select a pre-designed logo</h4>
      <p className="cust-upload-modal-text">
        There is a one-time set up fee of $9.99 to add a logo.
      </p>
      <p className="cust-upload-modal-text">Choose a category below to see the logos.</p>
      {props.CatalogLink && (
        <div className="logo-catalog-container">
          <a
            href={props.CatalogLink}
            target="popup"
            className="logo-catalog cust-upload-modal-text"
            onClick={() => {
              displayLogoCatalog();
              return false;
            }}
            data-wa-link="logocatalog"
            data-ui-object="link"
            data-refer-ui-access-point="customization_page | download_logo_catalog"
          >
            Download the logo catalog.
          </a>
        </div>
      )}
      <MuiThemeProvider theme={theme} style={{ marginTop: '25px' }}>
        <FormControl variant="outlined" className="margin-bottom-20">
          <InputLabel id="select-aplhabet"> </InputLabel>
          <Select
            labelId="select-aplhabet"
            style={{ fontSize: '19px', width: '256px', height: '40px' }}
            value={selectedCategory}
            onChange={handleCategoryChanged}
            fullWidth
            data-testid="select-category"
          >
            {categoriesLoading ? (
              <MenuItem value="select" disabled style={{ fontSize: '15px' }}>
                Loading..
              </MenuItem>
            ) : (
              <MenuItem value="select" style={{ fontSize: '19px' }}>
                Choose a category
              </MenuItem>
            )}

            {categories.map((a) => {
              return (
                <MenuItem key={a.categoryId} value={a.categoryId} style={{ fontSize: '19px' }}>
                  {a.category_desc}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </MuiThemeProvider>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <IndeterminateShort theme="quickbooks" />
        </div>
      ) : (
        <>
          <div className="margin-bottom-20">
            <div className="row">
              {monograms.map((a, key) => {
                return (
                  <div key={key} className="col-sm-4">
                    <div style={{ margin: '5px' }}>
                      <Tile
                        className="monogram-tile"
                        iconSize="large"
                        automationId={a.LogoFileNumber}
                        icon={<img src={formatStandardImageUrl(a.Url)} alt="..." />}
                        onChange={() => monogramSelectionChanged(a)}
                        checked={getChecked(a)}
                        type="checkbox"
                        title={a.LogoFileNumber}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {selectedMonogram && (
            <Button
              buttonType="primary"
              theme="quickbooks"
              // disabled={loading}
              onClick={applyMonogram}
              // data-wa-link="cancelccinfo"
            >
              Apply selected logo
            </Button>
          )}
        </>
      )}
    </div>
  );
};
