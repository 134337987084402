// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/voucherchecks.scss';
import { formatLogoUrl, formatShadowLogoUrl } from '../../Util/CustomizationUtil';
import { Micr } from '../Common/Micr';

// eslint-disable-next-line require-jsdoc
export const BasicVoucherChecksImage = (props) => {
  const {
    image,
    company,
    logo,
    customColor,
    logoColor,
    shadowLogo,
    shadowLogoColor,
    fromModal,
    summaryText,
    checkStartingNumber,
    selectedFontColor,
    selectedFontFamily,
    companyInfoColor,
    micr,
    fractional,
    bankInformation,
    signatureLines,
  } = props;

  return (
    <div
      className={
        !fromModal ? 'voucher-checks-image-container' : 'voucher-checks-modal-image-container'
      }
    >
      <img
        style={{ maxWidth: '100%' }}
        alt="..."
        src={customColor.selectedColorPickerImage || image}
        name={customColor.customColorValue || ''}
      />
      <div>
        <div className="logoWithAddress">
          {logo && (
            <img
              className="logo"
              src={formatLogoUrl(logo, logoColor, selectedFontColor)}
              alt="..."
            />
          )}
          <div
            style={{ textAlign: logo && logo.LogoNumber !== '' ? 'left' : 'center' }}
            className={`image-company-info ${
              companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
            } ${selectedFontFamily.toLowerCase()}`}
          >
            <div
              style={{ fontWeight: 'bold' }}
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.CompanyName}
            </div>
            <div
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine1}
            </div>
            <div
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.AddressLine2}
            </div>
            <div
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.Phone}
            </div>
            <div
              className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
            >
              {company.ExtraLine}
            </div>
          </div>
        </div>
        <div className="image-checkStartingNumber-text">
          <div>{checkStartingNumber.csn}</div>
        </div>
        {shadowLogo && (
          <div className="shadow-logo-container">
            <img
              className=" shadow-logo shadow-logo-basic-voucher"
              src={formatShadowLogoUrl(shadowLogo, shadowLogoColor, selectedFontColor)}
              alt="..."
            />
          </div>
        )}
        {micr && (
          <div className="image-micr">
            <Micr micr={micr} />
          </div>
        )}
        <div
          className={`image-bank-info ${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          <div>
            <span style={{ fontWeight: 'bold' }}>{bankInformation.name}</span>
            <br />
            <span style={{ fontWeight: 'bold' }}>{bankInformation.address}</span>
            <br />
            {`${bankInformation.city}${
              bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
            } ${bankInformation.state} ${bankInformation.zipcode}`}
            <br />
            <div>{fractional}</div>
          </div>
        </div>
        <div className="sign-lines-upper-text">
          <p style={{ fontSize: '12px' }}>{signatureLines.line2}</p>
        </div>
        {signatureLines.totalLines === 2 && <hr className="sign-upper-line" />}
        <div className="sign-lines-lower-text">
          <p style={{ fontSize: '12px' }}>{signatureLines.line1}</p>
        </div>
      </div>
      <div className="image-summary-text">
        <div>{summaryText.text}</div>
      </div>
      <div className="image-checkStartingNumber-text image-csn">
        <div>{checkStartingNumber.csn}</div>
      </div>
      {shadowLogo && (
        <div className="shadow-logo-container">
          <img
            className="shadow-logo shadow-logo-basic-voucher basic-voucher-shadow shadow-logo-two"
            src={formatShadowLogoUrl(shadowLogo, shadowLogoColor, selectedFontColor)}
            alt="..."
          />
        </div>
      )}
      <div className="lower-image-summary-text">
        <div>{summaryText.text}</div>
      </div>
      <div className="image-checkStartingNumber-text lower-csn">
        <div>{checkStartingNumber.csn}</div>
      </div>
    </div>
  );
};
