// eslint-disable-next-line import/prefer-default-export
export function getEnvironmentConfig() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const envConfig = {
    production: {
      hostname: 'https://intuitmarket.intuit.com/',
      cdn: 'https://intuitmarket.intuitcdn.net',
      ius: 'https://accounts.intuit.com',
    },
    staging: {
      hostname: 'https://qdcstage.intuitmarket.intuit.com/',
      cdn: 'https://awsstaging-intuitmarket.intuitcdn.net',
      ius: 'https://accounts-e2e.intuit.com',
    },
    staging4: {
      hostname: 'https://qdcstage4.intuitmarket.intuit.com/',
      cdn: 'https://staging-intuitmarket.intuitcdn.net', // This remains staging for stage4
      ius: 'https://accounts-e2e.intuit.com',
    },
    development: {
      hostname: 'https://dev.sd.intuit.com/',
      cdn: 'https://dev-intuitmarket.intuitcdn.net',
      ius: 'https://accounts-e2e.intuit.com',
    },
    local: {
      hostname: 'http://localhost:8002/',
      cdn: 'https://awsstaging-intuitmarket.intuitcdn.net',
      ius: 'https://accounts-e2e.intuit.com',
    },
  };

  return envConfig[environment];
}
