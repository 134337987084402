import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getClientConfig,
  getLoggedInDetails,
  loggingOnServerex,
} from '../services/commonDataService';
import { ReduxEvent, SagaEvent } from '../../assets/pagedata/constant';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getClientConfigAndLogin(action) {
  try {
    const getConfigRes = yield call(getClientConfig, 'POST', action.data.payload);
    const logInDetail = JSON.parse(yield call(getLoggedInDetails));

    let configs = {};
    const systemDate = getConfigRes.SystemDate || '';

    for (let i = 0; i < action.data.payload.length; i++) {
      const configKey = action.data.payload[i];

      configs = {
        ...configs,
        [configKey.replace('.', '')]: getConfigRes[configKey],
      };
    }

    yield put({
      type: ReduxEvent.INITIALIZE_INITIAL_PAGE_SUCCESS,
      data: { clientConfigs: configs, logInDetail, loading: false, systemDate },
    });
  } catch (e) {
    loggingOnServerex(
      'error',
      `mainSaga.jsx: getClientConfigAndLogin --> failed. Reason: ${e}`,
    ).then(() => {});
  }
}

function* mainSaga() {
  yield takeLatest(SagaEvent.INITIALIZE_INITIAL_PAGE_LOAD, getClientConfigAndLogin);
}

export default mainSaga;
