import { call, put, takeLatest, select } from 'redux-saga/effects';
import { loggingOnServerex } from '../../services/commonDataService';
import { HostedUISignIn, ReduxEvent, SagaEvent } from '../../../assets/pagedata/constant';
import {
  cloneImprintDataForValuePacks,
  updateDepositSlipsMicrForValuePacks,
} from '../../components/Util/CustomizationUtil';
import {
  getNewImprintId,
  getImprintScaffolding,
  getShopperAndBankInfo,
  getImprintDataByImprintId,
  getBankInfoAndMicr,
  getImprintDataByImprintIdShopperId,
  getImprintDataByImprintIdShopperIdpfIdSku,
  postFds,
} from '../../services/customizationDataService';
import {
  convertJSONValuesToUpperCase,
  formatCompanyInformation,
  checkLogoURL,
} from '../../components/Util/CommonUtils';
import {
  addLogoOptions,
  addLogoOptionsLookup,
} from '../../components/Customization/Configuration/CustomizationConfiguration';
import {
  copyImprintCustomizationWorkflows,
  getColorFromHex,
  getSecondaryColor,
  getSelectedFontColorValue,
} from '../../components/Util/CustomizationUtil';
import { getCompanyImprintReducerModal } from '../../components/Customization/helpers/Modalhelper';
import { setDocumentCookie } from '../../components/Util/CommonUtils';

const customizationReducer = (state) => state.customizationReducer;
const mainReducer = (state) => state.mainReducer;

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* getCustomizationImprintDetails(action) {
  try {
    let imprintId = '';
    let imprintDetails = {};
    const shopperAndBankInfo = {
      CompanyInfo: '',
      Banks: [],
      SelectedBank: null,
    };
    const micr = '';
    const micrMask = '';
    let reducerData = {};
    let loadIntitalState = true;

    const dataIslandAttributes = yield call(getImprintScaffolding, action.inputs.pfId);

    // check if imprint id in query parameter
    // if yes then check if the imprint is valid imprint
    // if not valid imprint, load with new imprint
    if (action.inputs.imprintId) {
      let imprintGetRes;
      imprintId = action.inputs.imprintId;

      // requests coming from retrieveprevious order, we can only fetch the imprint details with the shopperid
      if (
        action.inputs.source &&
        copyImprintCustomizationWorkflows.includes(action.inputs.source.toLowerCase())
      ) {
        if (action.inputs.sku) {
          imprintGetRes = yield call(
            getImprintDataByImprintIdShopperIdpfIdSku,
            imprintId,
            action.inputs.pfId,
            action.inputs.sku,
            action.inputs.shopperid,
          );
        } else {
          imprintGetRes = yield call(
            getImprintDataByImprintIdShopperId,
            imprintId,
            action.inputs.shopperid,
          );
        }
      } else {
        imprintGetRes = yield call(getImprintDataByImprintId, imprintId);
        if (action.inputs.source && action.inputs.source.toUpperCase() === 'VALUE-PACKS') {
          const custReducer = yield select(customizationReducer);
          imprintGetRes.ImprintData = cloneImprintDataForValuePacks(
            imprintGetRes.ImprintData.Id,
            custReducer,
            imprintGetRes.ImprintData,
          );
        }
      }

      if (imprintGetRes) {
        imprintDetails = imprintGetRes.ImprintData;
        loadIntitalState = false;

        // we copy the imprint details of older imprint to new imprint
        // for requests coming from reorder scenarios (order history, retrivepreviousorder, etc.)
        if (
          action.inputs.source &&
          copyImprintCustomizationWorkflows.includes(action.inputs.source.toLowerCase())
        ) {
          const imprint = yield call(getNewImprintId, action.inputs.pfId);
          imprintId = imprint.ImprintId;
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      } else {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    }

    if (!loadIntitalState) {
      let bankInfo = null;
      const selectedBank = null;
      setDocumentCookie('imprintid', imprintId);
      if (action.inputs.loggedIn) {
        try {
          bankInfo = yield call(getShopperAndBankInfo, '', imprintId, action.inputs.pfId);
        } catch (error) {
          // TODO: Currently this fails. Once fixed it should not be an error.
        }
      }

      let logo = null;
      let logoOption = null;
      // for main logo
      if (imprintDetails.LogoImprint.MainLogo !== 'logo_template') {
        const logoUrl = checkLogoURL(imprintDetails.LogoImprint.MainLogoAttributes.logo_url);
        logo = {
          LogoFileNumber: imprintDetails.LogoImprint.MainLogo,
          Url: logoUrl,
        };

        logoOption =
          addLogoOptions[
            addLogoOptionsLookup[imprintDetails.LogoImprint.MainLogoAttributes.logo_type]
          ];
      }
      let shadowLogo = null;
      let shadowLogoOption = null;
      // for shadow logo
      if (
        imprintDetails.LogoImprint.ShadowLogo &&
        imprintDetails.LogoImprint.ShadowLogo !== 'logo_template'
      ) {
        shadowLogo = {
          LogoFileNumber: imprintDetails.LogoImprint.ShadowLogo,
          Url: imprintDetails.LogoImprint.ShadowLogoAttributes.logo_url,
        };

        shadowLogoOption =
          addLogoOptions[
            addLogoOptionsLookup[imprintDetails.LogoImprint.ShadowLogoAttributes.logo_type]
          ];
      }

      let yourName = '';

      if (action.inputs.pfId === 'STAMP-06' && !imprintDetails.ExtraImprintLines['Your Name']) {
        // in case of fax-cover-head-stamp the extraimprintline comes with person name
        const keys = Object.keys(imprintDetails.ExtraImprintLines);
        yourName = keys[0];
      }

      let banksFromRoutingNumber = [];
      let bankNameFromService;
      let comboDataSet = [];
      const fdsRespn = {
        VerificationResponse: 0,
        AccountResponseCode: 0,
        TransId: 0,
        ActionType: null,
      };
      const mainreducer = yield select(mainReducer);
      const fraudDetectCallEnabled = !!(
        mainreducer &&
        mainreducer.clientConfigs &&
        mainreducer.clientConfigs.GiactFraudDetectCallEnabled.toLowerCase() === 'true'
      );
      if (imprintDetails.BankImprint.RoutingNumber && imprintDetails.BankImprint.AccountNumber) {
        if (action.inputs.checksReorder && fraudDetectCallEnabled) {
          const fds_Respn = yield call(
            postFds,
            imprintDetails.BankImprint.RoutingNumber,
            imprintDetails.BankImprint.AccountNumber,
          );
          fdsRespn.VerificationResponse = fds_Respn.VerificationResponse
            ? fds_Respn.VerificationResponse
            : 0;
          fdsRespn.AccountResponseCode = fds_Respn.AccountResponseCode
            ? fds_Respn.AccountResponseCode
            : 0;
          fdsRespn.TransId = fds_Respn.TransId ? fds_Respn.TransId : 0;
          fdsRespn.ActionType = fds_Respn.ActionType ? fds_Respn.ActionType : null;
        }
        const getBankInfoAndMicrResp = yield call(
          getBankInfoAndMicr,
          imprintId,
          action.inputs.pfId,
          imprintDetails.BankImprint.RoutingNumber,
          imprintDetails.BankImprint.AccountNumber,
        );
        banksFromRoutingNumber = getBankInfoAndMicrResp.fsg_bank_spec;
        if (getBankInfoAndMicrResp.fsg_bank_spec.length > 0) {
          const itemData = getBankInfoAndMicrResp.fsg_bank_spec.map((p) => {
            return { value: `${p.bank_name}`, label: `${p.bank_name}` };
          });
          comboDataSet = itemData;
        }
        if (getBankInfoAndMicrResp.fsg_bank_spec.length === 1) {
          bankNameFromService = getBankInfoAndMicrResp.fsg_bank_spec[0].bank_name;
        }
        // update micr details for deposit slips in value packs - UBERPSG-13159
        if (
          action.inputs.source &&
          action.inputs.source.toUpperCase() === 'VALUE-PACKS' &&
          imprintDetails.Id.indexOf('DEP') === 0
        ) {
          let selectedBankData = {
            micr: '',
            micr_mask: '',
            fractional: '',
          };
          if (getBankInfoAndMicrResp.fsg_bank_spec.length > 0) {
            selectedBankData = getBankInfoAndMicrResp.fsg_bank_spec.find((bank) => {
              return bank.bank_name === imprintDetails.BankImprint.BankName;
            });
            selectedBankData = selectedBankData || getBankInfoAndMicrResp.fsg_bank_spec[0];
          }
          imprintDetails = updateDepositSlipsMicrForValuePacks(imprintDetails, selectedBankData);
        }
      }

      const checkStartingNumber = {
        csn: imprintDetails.CheckStartingNumber,
        reverseNumbering: imprintDetails.BankImprint.ReverseNumbering,
      };

      let customColor = {};
      if (action.inputs.ColorMapping) {
        const colorData = action.inputs.ColorMapping;
        const matchedData = colorData.find((x) => x.Val === imprintDetails.CustomColor);
        if (matchedData) {
          customColor = {
            customColorValue: imprintDetails.CustomColor,
            selectedColorPickerImage: matchedData.ImageSource,
          };
        } else {
          customColor = {
            customColorValue: action.inputs.ColorMapping[0].Val,
            selectedColorPickerImage: action.inputs.ColorMapping[0].ImageSource,
          };
        }
      }

      reducerData = {
        imprintId,
        imprintDetails,
        dataIslandAttributes,
        sheets: imprintDetails.DataIslandAttributes.Sheets,
        selectedSku: imprintDetails.DataIslandAttributes.Sku,
        printerType: imprintDetails.DataIslandAttributes.PrinterType,
        company: getCompanyImprintReducerModal('edit', imprintDetails),
        companyInfo: `${imprintDetails.CompanyImprint.CompanyName}\n${imprintDetails.CompanyImprint.AddressLine1}\n${imprintDetails.CompanyImprint.AddressLine2}\n${imprintDetails.CompanyImprint.Phone}\n${imprintDetails.CompanyImprint.ExtraLine}`,
        banks: bankInfo ? bankInfo.PaymentDetails.BankDetails : [],
        selectedBank,
        summaryText: {
          text:
            imprintDetails.VoucherLine === imprintDetails.VoucherLineAttributes.default_value
              ? ''
              : imprintDetails.VoucherLine,
          bold: imprintDetails.VoucherLineAttributes.font_weight === 'bold',
          font_color: imprintDetails.VoucherLineAttributes.font_color,
        },
        routingNumber: imprintDetails.BankImprint.RoutingNumber,
        accountNumber: imprintDetails.BankImprint.AccountNumber,
        verifiedBank: !!bankNameFromService,
        showBankInfoSection:
          imprintDetails.BankImprint.BankName !==
          imprintDetails.BankImprint.BankNameAttributes.default_value,
        bankInformation: {
          name:
            imprintDetails.BankImprint.BankName !==
            imprintDetails.BankImprint.BankNameAttributes.default_value
              ? imprintDetails.BankImprint.BankName
              : '',
          address:
            imprintDetails.BankImprint.AddressLine1 ===
            imprintDetails.BankImprint.AddressLine1Attributes.default_value
              ? ''
              : imprintDetails.BankImprint.AddressLine1,
          city:
            imprintDetails.BankImprint.CityAttributes.default_value ===
            imprintDetails.BankImprint.City
              ? ''
              : imprintDetails.BankImprint.City,
          state:
            imprintDetails.BankImprint.StateAttributes.default_value ===
            imprintDetails.BankImprint.State
              ? ''
              : imprintDetails.BankImprint.State,
          zipcode:
            imprintDetails.BankImprint.ZipAttributes.default_value ===
            imprintDetails.BankImprint.Zip
              ? ''
              : imprintDetails.BankImprint.Zip,
          fractional:
            imprintDetails.BankImprint.FractionalAttributes.default_value ===
            imprintDetails.BankImprint.Fractional
              ? ''
              : imprintDetails.BankImprint.Fractional,
        },

        fractional:
          imprintDetails.BankImprint.FractionalAttributes.default_value ===
          imprintDetails.BankImprint.Fractional
            ? ''
            : imprintDetails.BankImprint.Fractional,
        micr: imprintDetails.BankImprint.Micr,
        micrMask: imprintDetails.BankImprint.MicrAttributes.micr_mask,
        approveInitials: imprintDetails.Initials,
        validationError: false,
        bankAccount: imprintDetails.BankImprint.BankAccount,
        logo,
        logoOption,
        yourName,
        selectedFontFamily: imprintDetails.FontImprint.Font,
        selectedFontColor: getSelectedFontColorValue(imprintDetails),
        secondaryColor: getSecondaryColor(imprintDetails),
        shadowLogo,
        shadowLogoOption,
        matchingEnv: {
          envRequired: action.inputs.source ? 'Y' : 'N',
          envelope_sku: imprintDetails.DataIslandAttributes.envelope_sku,
        },
        logoColor:
          imprintDetails.ColorCount > 0
            ? getColorFromHex(imprintDetails.LogoImprint.MainLogoAttributes.logo_color)
            : '',
        shadowLogoColor:
          imprintDetails.ColorCount > 0
            ? getColorFromHex(imprintDetails.LogoImprint.ShadowLogoAttributes.logo_color)
            : '',
        companyInfoColor:
          imprintDetails.ColorCount > 0
            ? getColorFromHex(imprintDetails.FontImprint.FontAttributes.font_color)
            : '',
        customColor,
        banksFromRoutingNumber,
        checkStartingNumber,
        accountOpenDate: {
          month: imprintDetails.OpeningDate ? imprintDetails.OpeningDateAttributes.month : '00',
          year: imprintDetails.OpeningDate ? imprintDetails.OpeningDateAttributes.year : 'Year',
        },
        signatureLines: {
          totalLines: imprintDetails.SignatureImprint.SignatureLines,
          line1:
            imprintDetails.SignatureImprint.LowerTextSignatureLine1 ===
            imprintDetails.SignatureImprint.LowerTextSignatureLine1Attributes.default_value
              ? ''
              : imprintDetails.SignatureImprint.LowerTextSignatureLine1,
          line2:
            imprintDetails.SignatureImprint.UpperTextSignatureLine1 ===
            imprintDetails.SignatureImprint.UpperTextSignatureLine1Attributes.default_value
              ? ''
              : imprintDetails.SignatureImprint.UpperTextSignatureLine1,
        },
        comboDataSet,
        selectedBankName: !!bankNameFromService,
      };
      if (action.inputs.checksReorder) {
        reducerData.fdsRespn = fdsRespn;
      }
    } else {
      const imprint = yield call(getNewImprintId, action.inputs.pfId);
      imprintId = imprint.ImprintId;
      setDocumentCookie('imprintid', imprintId);
      imprintDetails = dataIslandAttributes;
      reducerData = {};
      let companyInfoObject = getCompanyImprintReducerModal(
        'create',
        null,
        null,
        action.inputs.defaultCompanyFontConfig,
      );

      let shopperInfoAndBankInfo = null;
      if (action.inputs.loggedIn) {
        try {
          shopperInfoAndBankInfo = yield call(
            getShopperAndBankInfo,
            '',
            imprintId,
            action.inputs.pfId,
          );
        } catch (error) {
          // TODO: Currently this fails. Once fixed it should not be an error.
        }
      }

      if (shopperInfoAndBankInfo) {
        if (
          !!shopperInfoAndBankInfo.PaymentDetails &&
          !!shopperInfoAndBankInfo.PaymentDetails.FsgShopperInformation
        ) {
          shopperAndBankInfo.CompanyInfo = formatCompanyInformation(
            shopperInfoAndBankInfo.PaymentDetails.FsgShopperInformation,
          );
          companyInfoObject = getCompanyImprintReducerModal(
            'create',
            imprintDetails,
            shopperInfoAndBankInfo,
            action.inputs.defaultCompanyFontConfig,
          );
        }

        shopperAndBankInfo.Banks = shopperInfoAndBankInfo.PaymentDetails.BankDetails;
        shopperAndBankInfo.SelectedBank = shopperInfoAndBankInfo.PaymentDetails.SelectedBankDetail;
      }

      reducerData = {
        selectedSku: action.inputs.sku ? action.inputs.sku : '',
        sheets: action.inputs.sheets ? action.inputs.sheets : '1',
        imprintId,
        imprintDetails,
        dataIslandAttributes,
        company: action.inputs.companyInfoInCaps
          ? convertJSONValuesToUpperCase(companyInfoObject)
          : companyInfoObject,
        companyInfo: shopperAndBankInfo.CompanyInfo,
        banks: shopperAndBankInfo.Banks,
        micr,
        micrMask,
      };
    }
    if (localStorage.getItem(HostedUISignIn.customizationReducer)) {
      const localData = localStorage.getItem(HostedUISignIn.customizationReducer);
      const localReducerdata = JSON.parse(localData);
      localReducerdata.imprintId = imprintId;
      if (localReducerdata.banksFromRoutingNumber.length === 0) {
        try {
          yield call(
            getBankInfoAndMicr
            ( imprintId,
              action.inputs.pfId,
              localReducerdata.routingNumber,
              localReducerdata.accountNumber,
              localReducerdata.checkStartingNumber.csn
            )
          )
        } catch (error) {
          loggingOnServerex(
            'error',
            `operation= New bank getBankInfoAndMicr failed for ${action.inputs.pfId} RoutingNumber= ${localReducerdata.routingNumber}) imprintId= ${imprintId} Error= ${error}`
          );
        }
      };
      reducerData = localReducerdata;
      localStorage.removeItem(HostedUISignIn.customizationReducer);
    }
    yield put({
      type: ReduxEvent.GET_CUST_IMPRINT_DETAILS_SUCCESS,
      data: reducerData,
    });
  } catch (e) {
    loggingOnServerex(
      'error',
      `custImprintSaga.jsx: getCustomizationImprintDetails --> failed. Reason: ${e}`,
    ).then(() => {});

    yield put({
      type: ReduxEvent.CUSTOMIZATION_SAGA_ERROR,
      data: {
        errorMessage: 'We weren’t able to load your customization.',
      },
    });
  }
}

function* custImprintSaga() {
  yield takeLatest(SagaEvent.GET_CUST_IMPRINT_DETAILS, getCustomizationImprintDetails);
}

export default custImprintSaga;
