import { ReduxEvent } from '../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
const initialState = {
  loading: true,
};

const productListReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ReduxEvent.GET_PRODUCT_DATA_SUCESS:
      return { ...state, loading: data.loading };

    default:
      return state;
  }
};

export default productListReducer;
