import React from 'react';
import ToastMessage from '@ids-beta/toast-message';
import { loggingOnServerex } from '../../services/commonDataService';

export default function withLogAnalytics(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        toastMsg: '',
        showToast: false,
      };
    }

    /**
     * Displays toast with custom message
     * @param {string} text Toast Message
     */
    onShowToast = (text) => {
      this.setState({
        showToast: true,
        toastMsg: text,
      });
    };

    /**
     * helper method for closeToast
     */
    closeToastFromButton = () => {
      this.closeToast();
    };

    /**
     * Closes the toast
     */
    closeToast = () => {
      this.setState({ showToast: false });
    };

    /**
     * Logs to adobe analytics
     * @param {object} params Contains error or product details or the page details
     * @param {string} type Type of logging needed. 'page' or 'link'
     */
    logToAnalytics = (params, type = 'page') => {
      if (process.env.REACT_APP_ENVIRONMENT === 'local') console.log('logToAnalytics: ', params);

      if (typeof window.wa !== 'undefined') {
        switch (type) {
          case 'link':
            if (typeof window.wa.trackLinkPlus !== 'undefined')
              window.wa.trackLinkPlus(params.object, params.linkData);
            break;

          case 'page':
          default:
            if (typeof window.wa.trackPage !== 'undefined') window.wa.trackPage(params);
            break;
        }
      }
    };

    /**
     * Log rum details
     * @param {string} elementName Name of page, link, image, etc
     */
    markUserTime = (elementName) => {
      if (process.env.REACT_APP_ENVIRONMENT === 'local') console.log('markusertime: ', elementName);

      if (window.markUserTime) {
        window.markUserTime(elementName);
      }
    };

    /**
     * Logs to IM Database. Usage can be seen on Product List Page
     * @param {string} level Log level, example 'info', 'error' , 'warn'
     * @param {string} log log value
     */
    // eslint-disable-next-line class-methods-use-this
    logToIMWebLogger(level, log) {
      if (process.env.REACT_APP_ENVIRONMENT === 'local')
        console.log('logToIMWebLogger: ', level, log);

      loggingOnServerex(level, log)
        .then(() => {
          console.log('Log complete', level, log);
        })
        .catch(() => {
          console.log('Log failed', level, log);
        });
    }

    render() {
      const { showToast, toastMsg } = this.state;
      return (
        <div>
          <WrappedComponent
            logToIMWebLogger={(level, log) => this.logToIMWebLogger(level, log)}
            logToAnalytics={(p, t) => this.logToAnalytics(p, t)}
            markUserTime={(e) => this.markUserTime(e)}
            displayToast={(text) => this.onShowToast(text)}
            {...this.props}
            {...this.state}
          />
          <ToastMessage
            open={showToast}
            dismissible
            duration={4000}
            onClose={this.closeToast}
            onActionClick={this.closeToastFromButton}
            showIcon={false}
            actionLabel="Close"
          >
            {toastMsg}
          </ToastMessage>
        </div>
      );
    }
  };
}
