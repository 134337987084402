// eslint-disable-next-line require-jsdoc
import React, { useEffect } from 'react';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
// import Radio, { RadioGroup } from '@ids/radio';
import { useDispatch, useSelector } from 'react-redux';
import { getVariantDetails } from '../../../services/customizationDataService';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import { SheetsToggle } from '.';
import { createOutOfStockListForVariant } from '../../Util/CommonUtils';

// eslint-disable-next-line require-jsdoc
export const FormatQuantity = (props) => {
  const { NumberOfSheets, PFID, DynamicSheet, DefaultQuantity } = props;
  const [loading, setLoading] = React.useState(true);
  const [failed, setFailed] = React.useState(false);
  const [pricing, setPricing] = React.useState([]);
  const [OutOfStock, setOutOfStock] = React.useState({});
  const [OutOfStockSku, setOutofStockSku] = React.useState({});
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { selectedSku, sheets } = customizationReducer;
  const mainReducer = useSelector((state) => state.mainReducer);
  const ProductOutOfStockisOutOfStockPhase3 =
    mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3 &&
    mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3.toLowerCase() === 'true';

  const dispatch = useDispatch();

  const getDefaultQuantitySku = (prices) => {
    let returnSku = '';
    if (DefaultQuantity) {
      const price = prices.find((x) => x.quantity === DefaultQuantity);
      if (price) {
        returnSku = price.sku;
      }
    }

    if (!returnSku && prices.length > 0) {
      returnSku = prices[0].sku;
    }

    return returnSku;
  };

  const getPricing = (sheetCount) => {
    setLoading(true);
    getVariantDetails(PFID, sheetCount)
      .then((res) => {
        const results = res.fsg_variant;
        setPricing(results);
        if (!results.find((x) => x.sku === selectedSku)) {
          const sku = getDefaultQuantitySku(results);
          dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku } });
        }
        if (ProductOutOfStockisOutOfStockPhase3) {
          const OFSList = createOutOfStockListForVariant(results);
          const outOfStockProduct = OFSList[0].baseSkuList.find(
            (o) => o.baseSku === results[0].sku.slice(0, results[0].sku.indexOf('-')),
          );
          const outOfstockSku = OFSList[0].skuList.find((element) => element.sku === selectedSku);
          setOutofStockSku(outOfstockSku);
          setOutOfStock(outOfStockProduct);
          if (props.handleOutofStock) {
            if (outOfStockProduct) {
              props.handleOutofStock(outOfStockProduct);
            } else {
              props.handleOutofStock(outOfstockSku);
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        const msg = `Customization: DepositSlip: FormatQuantity -> getPricing failed for PFID ${PFID}. Error: ${err}`;
        props.logToIMWebLogger('error', msg);
        setFailed(true);
      });
  };

  const handleSheetChange = (newSheetSize) => {
    dispatch({
      type: ReduxEvent.CUSTOMIZATION_SHEET_SELECTION_CHANGED,
      data: { sheets: newSheetSize },
    });
  };

  const getSheetTypes = (printer) => {
    if (DynamicSheet) {
      return NumberOfSheets.filter((x) => x.PrinterTypes.includes(printer));
    }
    return NumberOfSheets;
  };

  const handleChange = (e) => {
    const sku = e.target.value;
    dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku } });

    if (DynamicSheet) {
      const filteredSheets = getSheetTypes(sku);
      if (!filteredSheets.find((x) => x.Val === sheets)) {
        dispatch({
          type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
          data: { sheets: filteredSheets[0].Val },
        });
      }
    }
  };

  useEffect(() => {
    getPricing(sheets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheets, selectedSku]);

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        Choose your quantity and number of sheets
      </h3>

      {ProductOutOfStockisOutOfStockPhase3 && OutOfStock && OutOfStock.displayOutOfStock ? (
        <div className="ofs-message">
          {OutOfStock.outOfStockMessage && OutOfStock.outOfStockMessage.trim().length > 0
            ? OutOfStock.outOfStockMessage
            : 'Temporarily out of stock.'}
        </div>
      ) : (
        <div>
          <p className="margin-bottom-10 text-label">Quantity</p>
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-100-dropdown margin-bottom-20 dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            placeholder={loading ? 'Loading....' : 'Select Value'}
            helperText={loading ? 'Loading....' : ''}
            onChange={handleChange}
            errorText={failed ? 'Unable to fetch the value.' : null}
            value={selectedSku}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | format_quantity | select_sku_quantity"
            data-ui-object-details="Select SKU Quantity"
          >
            {pricing.map((p) => {
              return (
                <MenuItem key={p.sku} value={p.sku}>{`${p.quantity} for $${(
                  p.list_price_cents / 100
                ).toFixed(2)}`}</MenuItem>
              );
            })}
          </Dropdown>
        </div>
      )}

      <p className="sheet-picker text-label">Number of sheets</p>
      <SheetsToggle
        options={NumberOfSheets}
        value={sheets}
        onChange={handleSheetChange}
        className="margin-bottom-20"
      />
      {ProductOutOfStockisOutOfStockPhase3 && OutOfStockSku && OutOfStockSku.displayOutOfStock && (
        <p className="ofs-message font-size-20px">{OutOfStockSku.outOfStockMessage}</p>
      )}
    </div>
  );
};
