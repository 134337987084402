// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/largemailingenvelopes.scss';
import { formatLogoUrl } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const LargeMailingEnvelopesImage = (props) => {
  const {
    company,
    fromModal,
    selectedFontColor,
    companyInfoColor,
    selectedFontFamily,
    customColor,
    image,
    logo,
    logoColor,
    summaryText,
  } = props;

  return (
    <div
      className={
        !fromModal ? 'large-envelopes-image-container' : 'large-envelopes-modal-image-container'
      }
    >
      <img
        style={{ maxWidth: '100%' }}
        alt="..."
        src={customColor.selectedColorPickerImage || image}
        name={customColor.customColorValue || ''}
      />
      {logo && (
        <img className="logo" src={formatLogoUrl(logo, logoColor, selectedFontColor)} alt="..." />
      )}
      <div
        className={`image-company-info ${
          companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
        } ${selectedFontFamily.toLowerCase()}`}
      >
        <div
          style={{
            fontSize: `${company.CompanyNameAttribute.FontSize}px`,
            fontWeight: company.CompanyNameAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.CompanyName}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine1Attribute.FontSize}px`,
            fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.AddressLine1}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine2Attribute.FontSize}px`,
            fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.AddressLine2}
        </div>
        <div
          style={{
            fontSize: `${company.PhoneAttribute.FontSize}px`,
            fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.Phone}
        </div>
        <div
          style={{
            fontSize: `${company.ExtraLineAttribute.FontSize}px`,
            fontWeight: company.ExtraLineAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.ExtraLine}
        </div>
        <div
          style={{
            fontSize: `${company.ExtraLine2Attribute.FontSize}px`,
            fontWeight: company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.ExtraLine2}
        </div>
      </div>
      <div className="image-summary-text">
        <div
          style={{ fontWeight: summaryText.bold ? 'bold' : 'normal' }}
          className={`${
            companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
          } ${selectedFontFamily.toLowerCase()}`}
        >
          {summaryText.text}
        </div>
      </div>
    </div>
  );
};
