// eslint-disable-next-line require-jsdoc
import React from 'react';
import { Micr } from '../Common/Micr';
import '../../../../stylesheets/customization/depositSlip.scss';

// eslint-disable-next-line require-jsdoc
export const ManualDepositSlipImage = (props) => {
  const { image, company, summaryText, micr, bankInformation, fromModal } = props;

  return (
    <div className={!fromModal ? 'mds-image-container' : 'mds-modal-image-container'}>
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      <div className="image-company-info">
        <div style={{ fontWeight: 'bold' }}>{company.CompanyName}</div>
        <div>{company.AddressLine1}</div>
        <div>{company.AddressLine2}</div>
        <div>{company.Phone}</div>
        <div>{company.ExtraLine}</div>
      </div>
      <div className="image-summary-text">{summaryText.text}</div>
      {micr && (
        <div className="image-micr">
          <Micr micr={micr} />
        </div>
      )}
      <div className="image-bank-info">
        <div>
          {bankInformation.name}
          <br />
          {bankInformation.address}
          <br />
          {`${bankInformation.city ? bankInformation.city : ''}${
            bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
          } ${bankInformation.state ? bankInformation.state : ''} ${
            bankInformation.zipcode ? bankInformation.zipcode : ''
          }`}
        </div>
      </div>
    </div>
  );
};
