import secPen from '../../images/IM/POS/security_pens_sm.jpg';
import secPenLg from '../../images/IM/POS/security_pens_lrg.jpg';
import bfStmt from '../../images/IM/BusinessForms/frm_s_120.gif';
import bfInvoice from '../../images/IM/BusinessForms/frm_svi_120.gif';
import frm_mp_120 from '../../images/IM/BusinessForms/frm_mp_120.gif';
import frm_pol_120 from '../../images/IM/BusinessForms/frm_pol_120.gif';
import frm_pol_340 from '../../images/IM/BusinessForms/frm_pol_340.gif';
import bfFl from '../../images/IM/BusinessForms/chk_ldr_120.gif';
import bfFl340 from '../../images/IM/BusinessForms/chk_ldr_340.gif';
import frm_rs_120 from '../../images/IM/BusinessForms/frm_rs_120.gif';
import frm_brs_120 from '../../images/IM/BusinessForms/frm_brs_120.gif';
import frm_brs_340 from '../../images/IM/BusinessForms/frm_brs_340.gif';
import frm_pdi_120 from '../../images/IM/BusinessForms/frm_pdi_120.gif';
import frm_pfi_120 from '../../images/IM/BusinessForms/frm_pfi_120.gif';

const BusinessFormsPageData = {
  BUSINESS_FORMS: {
    Department: {
      DeptName: 'QuickBooks printable business forms',
      DeptDesc:
        'Use QuickBooks forms to save time and display a consistent, professional image. Our printable forms are produced on a thick stock, high quality paper designed specifically for QuickBooks Desktop compatibility.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LEADER-01'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Statements',
            ProductDesc:
              'Business forms with account record information print directly from QuickBooks Desktop. Keep customers informed regarding financial activities like past-due bills, current balances, and recent credits.',
            ImageSrc: bfStmt,
            ImageLrg: null,
            ButtonText: 'View all',
            ButtonLink: '/supplies/forms/business-statements',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: false,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Invoices',
            ProductDesc:
              'QuickBooks preprinted invoices work perfectly with QuickBooks Desktop and project a professional image. Our billing forms are available in three styles to serve your business needs - Product, Service, or Professional.',
            ImageSrc: bfInvoice,
            ImageLrg: null,
            ButtonText: 'View all',
            ButtonLink: '/supplies/forms/invoices',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: false,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Multi-purpose forms',
            ProductDesc:
              'Use high-quality, pre-printed Intuit forms for issuing financial communications directly from QuickBooks Desktop.',
            ImageSrc: frm_mp_120,
            ImageLrg: null,
            ButtonText: 'View all',
            ButtonLink: '/supplies/forms/multi-purpose-forms',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: false,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: '',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Form leaders',
            ProductDesc:
              'This reusable form helps you accurately print the remaining checks from a sheet of standard or wallet checks.',
            ImageSrc: bfFl,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/forms/form-leaders',
            LearnMoreLink: '/supplies/forms/form-leaders',
            LeranMoreOnly: true,
            LearnMoreSuffixText: null,
            ActualPrice: 40.99,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'LEADER-01',
          },
        ],
      },
    ],
  },
  FORM_LEADER: {
    Department: {
      DeptName: 'Form Leaders',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['LEADER-01'],
      Image: bfFl,
      ImageLg: bfFl340,
      PFID: 'LEADER-01',
      PriceDisplay: 'printer',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Save paper and reduce costs by never wasting checks',
            'Easily print one or two "leftover" checks from a three-per-page sheet',
            'Attach form leaders to individual checks and then print as a full sheet',
            'Ensure sequential numbering',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Compatibility: Laser/inkjet printers'],
        Compatibility: null,
        SpecialNote: ['Good for up to 40 uses', 'Available in sets of 10'],
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  SECURITY_PEN: {
    Department: {
      DeptName: 'QuickBooks Super Ink Security Pen',
      DeptDesc:
        'Protect your business against check washing forgery with our Super Ink™ Security Pen.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['SPEN-01'],
      Image: secPen,
      ImageLg: secPenLg,
      ImageText: 'Recommended by Frank Abagnale, Fraud expert and FBI trainer.',
      PFID: 'SPEN-01',
      PriceDisplay: 'pack',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Check washing - erasing details from checks to allow them to be rewritten - is the second most prevalent crime against consumers and small businesses. Only identity theft ranks higher!',
            'The Uniball® 207™ Super Ink™ Security Pen prevents check washing and alterations to other negotiable documents',
            'Uni Super Ink™ is unwashable ink specially formulated to impregnate into paper making it even more effective against check washing and document alteration',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Medium point, 0.7mm',
          'Acid-free',
          'Black ink',
          'Comfortable textured grip',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  BUSINESS_STATEMENTS: {
    Department: {
      DeptName: 'QuickBooks business statements',
      DeptDesc:
        'Business forms with account record information print directly from QuickBooks Desktop. Keep customers informed regarding financial activities like past-due bills, current balances, and recent credits.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STATE-01', 'STATE-02', 'STATE-03'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Customer billing statements',
            ProductDesc:
              'QuickBooks Billing Statements keep customers updated on their account status. Quickly inform customers of outstanding and past-due bills, recent credits and their current balance with these customizable forms.',
            ProductDescExtra: true,
            ImageSrc: bfStmt,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/supplies/forms/business-statements/customer/customization',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: '663',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STATE-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Remittance statements',
            ProductDesc:
              'Track your accounts receivable more efficiently and receive a faster response to your invoices. Make it easy to pay with the perforated payment coupon at the bottom of the Remittance Statement Form.',
            ProductDescExtra: true,
            ImageSrc: frm_rs_120,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/supplies/forms/business-statements/remittance/customization',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: '106',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STATE-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Blank remittance statements',
            ProductDesc:
              'Streamline payment remittance with convenient blank payment remittance forms. Customers simply tear off the coupon and send with their payment.',
            ImageSrc: frm_brs_120,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/forms/blank-remittance-statements',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: '133',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'STATE-03',
          },
        ],
      },
    ],
  },
  BLANK_REMITTANCE_STATEMENT: {
    Department: {
      DeptName: 'Blank remittance statements',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['STATE-03'],
      Image: frm_brs_120,
      ImageLg: frm_brs_340,
      ImageText: null,
      PFID: 'STATE-03',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Save time and customize statements using the Layout Designer in QuickBooks Desktop',
            'Print data directly from QuickBooks Desktop with this blank format',
            'Get paid faster - detachable payment stubs make it easy for customers to mail their payments',
            'Works with laser and inkjet printers',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 ½"(w) x 11"(l)',
          'Compatibility: Not compatible with QuickBooks Online',
        ],
        Compatibility: null,
        SpecialNote: null,
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
    Warning: false,
  },
  BUSINESS_INVOICES: {
    Department: {
      DeptName: 'QuickBooks printable business invoices',
      DeptDesc:
        'Maintain your professional presence with QuickBooks preprinted invoices. Our blank invoice forms and templates integrate with QuickBooks Desktop. Choose a business invoice from three designs - Product, Service, and Professional.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['INV-01', 'INV-02', 'INV-03'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Product invoices',
            ProductDesc:
              'Wholesalers and retailers find our product invoice forms quick and easy to use. These printable invoices include "Bill to" and "Ship to" fields, as well as headings for quantity, item code, description, unit price, and total amount.',
            ProductDescExtra: true,
            ImageSrc: frm_pdi_120,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/supplies/forms/invoices/product/customization',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'INV-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Service invoices',
            ProductDesc:
              'Billing clients by the hour? Pair QuickBooks Desktop with our blank service invoice template for a powerful invoice generator. Prepared headings itemize quantity, services rendered, items delivered, and hourly service rates.',
            ProductDescExtra: true,
            ImageSrc: bfInvoice,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/supplies/forms/invoices/service/customization',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'INV-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Professional invoices',
            ProductDesc:
              'If you bill clients by the project with a retainer, this professional invoice template provides plenty of space for detailed service descriptions.Enhance your professional image by adding your custom logo to the blank invoice template.',
            ProductDescExtra: true,
            ImageSrc: frm_pfi_120,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/supplies/forms/invoices/professional/customization',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'INV-03',
          },
        ],
      },
    ],
  },
  MULTI_PURPOSE_FORMS: {
    Department: {
      DeptName: 'QuickBooks multi-purpose forms',
      DeptDesc:
        'Use high-quality, pre-printed Intuit forms for issuing financial communications directly from QuickBooks Desktop. Send estimates, credit memos, or purchase orders on professional-looking correspondence. The all-purpose form includes a removable label to simultaneously prepare a report and its shipping label.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['MULTIFORM-01', 'MULTIFORM-02'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Multi-purpose forms',
            ProductDesc:
              'This multi-tasking format can be used for a variety of tasks to give all of your financial communications a more impressive, professional look.',
            ProductDescExtra: true,
            ImageSrc: frm_mp_120,
            ImageLrg: null,
            ButtonText: 'Get started',
            ButtonLink: '/supplies/forms/multi-purpose-forms/multi-purpose-forms/customization',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'MULTIFORM-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'All-purpose form with peel-off label',
            ProductDesc:
              'Pressed for time? Print a form and a mailing label in one easy step! Blank All-Purpose Form with white Peel-off Label makes preparing packing slips and mailing packages quick and easy.',
            ImageSrc: frm_pol_120,
            ImageLrg: null,
            ButtonText: 'Order now',
            ButtonLink: '/supplies/forms/all-purpose-forms',
            LearnMoreLink: null,
            LearnMoreSuffixText: null,
            ActualPrice: true,
            DiscountPrice: 0,
            Sku: null,
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'MULTIFORM-02',
          },
        ],
      },
    ],
  },
  ALL_PURPOSE_FORM: {
    Department: {
      DeptName: 'All-purpose form with peel-off label',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['MULTIFORM-02'],
      Image: frm_pol_120,
      ImageLg: frm_pol_340,
      PFID: 'MULTIFORM-02',
      ProductInformation: {
        Desc: null,
        DescExtras: null,
        DescPoints: {
          Header: null,
          List: [
            'Saves time and streamlines your shipping by printing a specially designed multi-purpose form and mailing label in one easy step',
            ' Makes preparing packing slips and mailing packages quick and easy',
            'Convenient and easy to use – creates a Peel-Off Label from the "Ship To" address on many standard QuickBooks forms, such as the Invoice, Credit Memo, Sales Receipt, Purchase Order and Sales Order templates',
          ],
        },
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: [
          'Sheet Size: 8 ½“(w) x 11“(l)',
          'Compatibility: Not compatible with QuickBooks Online',
          'The Sales Order feature is only available in the QuickBooks Desktop Premier 2003 and QuickBooks Desktop Enterprise 2.0 editions and higher.',
        ],
        Compatibility: null,
        SpecialNote: ['Available in one-part only', 'Works with both laser and inkjet printers'],
        Remarks: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
};

export default BusinessFormsPageData;
