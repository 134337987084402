import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, CircularProgress } from '@material-ui/core';

// eslint-disable-next-line require-jsdoc
const Loader = (props) => {
  const { show, loadingText, size } = props;
  /* istanbul ignore next line */
  const spinnerSize = !size ? 80 : size > 120 ? 120 : size < 60 ? 60 : size;

  return (
    show && (
      <div style={{ textAlign: 'center' }}>
        <Backdrop open={show} style={{ zIndex: '2500', opacity: 1 }}>
          <Box position="relative" display="inline-flex">
            <CircularProgress size={spinnerSize} style={{ color: '#53b700' }} />
            {loadingText && (
              <Box
                top={130}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <p className="loader-p">{loadingText}</p>
              </Box>
            )}
          </Box>
        </Backdrop>
      </div>
    )
  );
};

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
  size: PropTypes.number,
};

export default Loader;
