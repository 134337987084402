import { doPutFetch, doGetFetch } from './utils/fetchUtil';
import { SHOPPER, AUTHENTICATE_SHOPPER } from './config/url';
import { getCookie, setCookie } from '../components/Util/CommonUtils';

export const shopperDataService = {
  /**
   * Updates the shopper details
   */
  updateShopper: (options) => {
    const shopperData = options && options.data ? options.data : {};
    const version = options && options.version ? options.version : 'v1';
    const imprintId = shopperData && shopperData.imprintId ? shopperData.imprintId : '';
    const orderId = shopperData && shopperData.OrderId ? shopperData.OrderId : '';
    const bundle_imprints =
      shopperData && shopperData.bundle_imprintIds ? shopperData.bundle_imprintIds : '';
    // var imprintId =  "";
    let requestUrl = SHOPPER.url.replace('{version}', version);
    let redirecturl = getCookie('redirecturl');

    if (orderId !== '') {
      requestUrl = `${requestUrl}?imprintid=${imprintId}`;
      requestUrl = `${requestUrl}&orderId=${orderId}`;
      requestUrl += '&from=confirmationPage';
    } else if (bundle_imprints) {
      requestUrl = `${requestUrl}?bundlekey=1`;
    } else {
      requestUrl = `${requestUrl}?imprintid=${imprintId}`;
      if (!!redirecturl && redirecturl.indexOf('_fromipdsum') > -1) {
        requestUrl += '&from=summaryPage';
        redirecturl = redirecturl.replace('_fromipdsum', '');
        setCookie('redirecturl', redirecturl);
      }
    }

    if (!shopperData) {
      console.warn('Invalid shopper data to save!!!');
      return null;
    }
    return doPutFetch({ url: requestUrl }, shopperData);
  },
  /**
   * Updates the shopper details from OH
   */
  updateShopperDetails: (options) => {
    const shopperData = options && options.data ? options.data : {};
    let requestUrl = SHOPPER.url.replace('{version}', 'v1');
    requestUrl = `${requestUrl}?ipAddress=clientIp&sessionid=`;
    return doPutFetch({ url: requestUrl }, shopperData);
  },
  /**
   * Authenticates the shopper
   */
  authenticate: (options) => {
    const shopperData = options && options.data ? options.data : {};
    const version = options && options.version ? options.version : 'v1';
    const requestUrl = AUTHENTICATE_SHOPPER.url.replace('{version}', version);

    if (!shopperData) {
      console.warn('Invalid shopper data to save!!!');
      return null;
    }
    return doPutFetch({ url: requestUrl }, shopperData).then((response) => response.json());
  },
  /**
   * Get IUS Shopper Detao;s
   */
  getIUSShopper: (userName) => {
    const requestUrl = AUTHENTICATE_SHOPPER.url.replace('{version}', 'v1');
    return doGetFetch({ url: requestUrl }, { IUSUserName: userName });
  },
  /**
   * Sign Out the user
   */
  logOut: () => {
    let requestUrl = SHOPPER.url.replace('{version}', 'v1');
    requestUrl = `${requestUrl}?logOut=true`;
    return doPutFetch({ url: requestUrl });
  },
  /**
   * Get the shopper details
   */
  getShopper: () => {
    let requestUrl = SHOPPER.url.replace('{version}', 'v1');
    requestUrl = `${requestUrl}/GetShopperInformation`;
    return doGetFetch({ url: requestUrl });
  },
};
