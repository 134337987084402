import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutFSHBanner = (props) => {
  return (
    <div id="fsh-cart-banner-container" className="fsh-checkout-banner-container">
      {!props.secureFsh && (
        <div className="fsh-checkout-banner-rectangle">
          <span className="banner-txt fsh-banner-txt">
            Get{' '}
            <span className="fsh-txt">
              <b>free shipping</b>
            </span>
            <span>*</span>
          </span>
          <span className="banner-txt-lite fsh-banner-txt-lite">
            Now through <span className="promo-date">{props.promoDate}</span>
          </span>
        </div>
      )}
      {props.secureFsh && !props.homePage && (
        <div className="fsh-checkout-banner-rectangle secure-fsh">
          <span className="banner-txt secure-banner-txt">
            Free shipping<span>* </span>
          </span>
          <span className="banner-sub-txt">
            {' '}
            for all <span className="bold-txt">Secure Check orders.</span>
          </span>
          <span className="banner-txt-lite secure-banner-txt-lite">{`Until ${props.promoDate}`}</span>
        </div>
      )}
      {props.secureFsh && props.homePage && (
        <Link
          to="/checks"
          data-wa-link="secure fsh home banner link"
          data-ui-object="link"
          data-refer-ui-access-point="secure_fsh_home_banner_link"
          aria-label="Secure checks free shipping banner"
        >
          <div className="fsh-checkout-banner-rectangle secure-width">
            <span
              className="banner-txt secure-banner-txt"
              data-ui-object="link"
              data-refer-ui-access-point="secure_fsh_home_banner_link"
            >
              Free shipping<span>*</span>
            </span>
            <span
              className="banner-sub-txt"
              data-ui-object="link"
              data-refer-ui-access-point="secure_fsh_home_banner_link"
            >
              for all <span className="bold-txt">Secure Check orders.</span>
            </span>
            <span
              className="banner-txt-lite secure-banner-txt-lite"
              data-ui-object="link"
              data-refer-ui-access-point="secure_fsh_home_banner_link"
            >{`Until ${props.promoDate}`}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

export default CheckoutFSHBanner;
