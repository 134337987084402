// eslint-disable-next-line require-jsdoc
import React, { useState } from 'react';
import TextField from '@ids/text-field';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import Checkbox from '@ids/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import InlineValidationMessage from '@ids/inline-validation-message';
import { ReduxEvent, ErrorMessages } from '../../../../assets/pagedata/constant';
import { validateCheckStartNumber } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const CheckStartingNumber = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { checkStartingNumber, validationError } = customizationReducer;
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState('');
  const {
    PFID,
    Title,
    TooltipInfo,
    TooltipInfoReverseNumbering,
    ReverseNumbering,
    Type,
    MaxLength,
    MinLength,
  } = props;

  const handleChange = (key, value) => {
    if (key === 'text' && value.length > MaxLength) {
      console.log('size exceed');
    } else {
      dispatch({
        type: ReduxEvent.CHECK_STARTING_NUMBER,
        data: {
          checkStartingNumber: {
            ...checkStartingNumber,
            [key]: value,
          },
        },
      });
    }
  };

  const valiDateCheckNumber = () => {
    // let validCSN = false;
    const validError = validateCheckStartNumber(
      checkStartingNumber.csn,
      MinLength,
      MaxLength,
      PFID,
    );
    if (validError) {
      setErrorText(ErrorMessages[validError]);
      return false;
    }
    return true;
  };

  const handleBlur = () => {
    const validCheckNumber = valiDateCheckNumber();
    if (validCheckNumber === true) {
      setErrorText('');
      dispatch({
        type: ReduxEvent.CHECK_STARTING_NUMBER,
        data: {
          checkStartingNumber: {
            ...checkStartingNumber,
            validationError: true,
          },
        },
      });
    }
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        {Title}{' '}
        {TooltipInfo && (
          <Tooltip message={<div>{TooltipInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <TextField
        theme="quickbooks"
        label=""
        width="100%"
        aria-label="check starting number"
        maxLength={MaxLength}
        onChange={(e) => handleChange('csn', e.target.value)}
        onBlur={() => handleBlur()}
        value={checkStartingNumber.csn}
        errorText={errorText}
        type={Type}
        autoComplete="off"
      />
      {validationError &&
        validateCheckStartNumber(checkStartingNumber.csn, MinLength, MaxLength, PFID) && (
          <InlineValidationMessage
            type="error"
            message={!errorText ? 'Enter your starting check number.' : ''}
          />
        )}
      {ReverseNumbering && (
        <div className="width-reverse-tag">
          <Checkbox
            theme="quickbooks"
            aria-label="select row"
            onChange={(e) => handleChange('reverseNumbering', e.target.checked ? 'Y' : 'N')}
            checked={checkStartingNumber.reverseNumbering === 'Y'}
          />
          Optional: Use reverse numbering
          {TooltipInfoReverseNumbering && (
            <Tooltip message={<div>{TooltipInfoReverseNumbering}</div>}>
              <span className="circle-info-span padding-left-5">
                <CircleInfoIcon size="x-small" />
              </span>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
