// eslint-disable-next-line require-jsdoc
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCookie, removeCookie } from '../Util/CommonUtils';
import CheckoutFSHBanner from './CheckoutFSHBanner';

// eslint-disable-next-line require-jsdoc
export const FreeShippingPromo = (props) => {
  const mainReducer = useSelector((state) => state.mainReducer);
  // For below mentioned routes fsh footer discalimer has to implemented in their respective components
  const execeptionalRoutes = [
    '/cart',
    '/interruptivexsell',
    '/shipping',
    '/billing',
    '/summary',
    '/confirmation',
    '/in-product-landing-page',
    '/file-upload-disclaimer',
  ];

  const isFreeShipPromoActive = () => {
    let isFSHPromo = false;
    // Check for the server cookie, it will always be unreadable at client side, if its not undefined then treat it as a hack attempt and dont allow FSH
    if (getCookie('freeShipPromo') === undefined) {
      if (getCookie('fshPromo') === 'B') {
        isFSHPromo = true;
      }
    } else {
      removeCookie('freeShipPromo');
      removeCookie('fshPromo');
    }
    if (!!getCookie('automationtest') && /1/.test(getCookie('automationtest'))) {
      isFSHPromo = false;
    }
    if (mainReducer.clientConfigs) {
      const configStatus =
        mainReducer.clientConfigs.FreeShippingisPromoActive.toLowerCase() === 'true';
      isFSHPromo = isFSHPromo || configStatus;
    }
    return isFSHPromo;
  };

  const isSecureFreeShipPromoActive = () => {
    let isSecureFSHPromo = false;
    // Check for the server cookie, it will always be unreadable at client side, if its not undefined then treat it as a hack attempt and dont allow FSH
    if (getCookie('secureFreeShipPromo') === undefined) {
      if (getCookie('secureFshPromo') === 'B') {
        isSecureFSHPromo = true;
      }
    } else {
      removeCookie('secureFreeShipPromo');
      removeCookie('secureFshPromo');
    }
    if (!!getCookie('automationtest') && /1/.test(getCookie('automationtest'))) {
      isSecureFSHPromo = false;
    }
    if (mainReducer.clientConfigs) {
      const configStatus =
        mainReducer.clientConfigs.FreeShippingisSecureFshPromoActive.toLowerCase() === 'true';
      isSecureFSHPromo = isSecureFSHPromo || configStatus;
    }
    return isSecureFSHPromo;
  };

  const getFSHPromoDate = () => {
    let promoDate = '';
    if (mainReducer.clientConfigs) {
      promoDate = isFreeShipPromoActive()
        ? mainReducer.clientConfigs.FreeShippingpromoDate
        : isSecureFreeShipPromoActive()
        ? mainReducer.clientConfigs.FreeShippingsecureFshPromoDate
        : '';
    }
    return promoDate;
  };

  const isExempted_Route = () => {
    let exempted = false;
    const route = window.location.pathname;
    exempted = !!execeptionalRoutes.find((path) => {
      return route === path;
    });
    return exempted;
  };
  const isExemptedRoute = isExempted_Route();
  let innerHtml = <div />;

  useEffect(() => {
    if (typeof props.isPromoActive === 'function') {
      props.isPromoActive(isFreeShipPromoActive() || isSecureFreeShipPromoActive());
    }
  });

  if (isFreeShipPromoActive()) {
    innerHtml = (
      <div className="container-center-lg">
        <div className="">
          <p className="free-ship-terms">
            *Free shipping offer only applies to standard shipping within the 48 contiguous U.S.
            states and does not include optional signature required fee.
          </p>
        </div>
      </div>
    );
  } else if (isSecureFreeShipPromoActive()) {
    innerHtml = (
      <div className="container-center-lg">
        <div className="">
          <p className="free-ship-terms">
            *Free shipping offer only applies to orders that include secure checks and use standard
            shipping within the 48 contiguous states; offer does not include optional signature
            required fee.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isExemptedRoute && !props.chekoutBanner && <>{innerHtml}</>}
      {isExemptedRoute && props.onRequest && !props.chekoutBanner && <>{innerHtml}</>}
      {(isFreeShipPromoActive() || isSecureFreeShipPromoActive()) && props.chekoutBanner && (
        <CheckoutFSHBanner
          {...props}
          promoDate={getFSHPromoDate()}
          secureFsh={isSecureFreeShipPromoActive()}
        />
      )}
    </>
  );
};
