import { ReduxEvent } from '../../../assets/pagedata/constant';

const initialState = {
  accountData: {
    email: '',
    userID: '',
    shippingInformation: [],
    billingInformation: [],
    paymentDetails: {},
  },
  displayReachBanner: false,
};

const myAccountReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ReduxEvent.SHOPPER_ACCOUNT_SUCCESS:
      return { ...state, accountData: data.accountData };
    case ReduxEvent.ORDER_LIST_SUCCESS:
      return { ...state, displayReachBanner: data.displayReachBanner };
    default:
      return state;
  }
};

export default myAccountReducer;
