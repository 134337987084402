// eslint-disable-next-line require-jsdoc
import React from 'react';
import Loader from '../Common/Loader';

// eslint-disable-next-line require-jsdoc
export const UnAuthenticatedRoute = (props) => {
  const { unAuthenticatedRoute } = props;

  window.open(unAuthenticatedRoute, '_self');

  return <Loader show size={80} loadingText="Loading.." />;
};
