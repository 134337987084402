// eslint-disable-next-line require-jsdoc
import React from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line require-jsdoc
export const ImageComponent = (props) => {
  const { additionalProps } = props;
  const { ImageInformation } = additionalProps;
  const { Image, Component } = ImageInformation;

  const customizationReducer = useSelector((state) => state.customizationReducer);

  return (
    <div className="image-component-section">
      <Component {...props} {...customizationReducer} image={Image} />
    </div>
  );
};
