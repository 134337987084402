import React from 'react';
import Button from '@ids/button';
import { Link } from 'react-router-dom';
import '../../../stylesheets/ie11banner.scss';

const Ie11Banner = () => {
  return (
    <div className="ie11-banner-container">
      <p className="ie11-banner-text font-wgt-600">
        Customers using IE 11 cannot sign into their account.
      </p>
      <Link
        to="/help/migrate-from-ie11"
        data-wa-link="IE 11 Migrate Page"
        aria-label="IE 11 Migrate Page"
        className="ie11-banner-link"
      >
        <Button
          theme="quickbooks"
          buttonType="primary"
          id="btnIe11Migration"
          className="ie11-banner-btn"
          data-wa-link=""
          data-wa-event="Learn more"
          data-ui-object="button"
          data-refer-ui-access-point="ie_11_migration_learn_more"
        >
          Learn more
        </Button>
      </Link>
    </div>
  );
};
export default Ie11Banner;
