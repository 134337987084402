// eslint-disable-next-line require-jsdoc
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import { addLogoOptions } from '../Configuration/CustomizationConfiguration';
import { CommonAddLogoComponent } from './CommonAddLogoComponent';

// eslint-disable-next-line require-jsdoc
export const AddMonogramOrLogo = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { logo } = customizationReducer;

  const dispatch = useDispatch();
  const saveLogo = (params) => {
    if (params) {
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_LOGO_CHANGED,
        data: { logo: params.logo, logoOption: addLogoOptions[params.logoOption] },
      });
    }
  };

  const removeLogo = () => {
    dispatch({
      type: ReduxEvent.CUSTOMIZATION_LOGO_CHANGED,
      data: { logo: null, logoOption: null },
    });
  };

  return (
    <div>
      <CommonAddLogoComponent {...props} saveLogo={saveLogo} removeLogo={removeLogo} logo={logo} />
    </div>
  );
};
