import CHECK_ICON_BLUE from '../images/IM/Customization/checks/common/ico-custom-color-blue.jpg';
import CHECK_ICON_GREEN from '../images/IM/Customization/checks/common/ico-custom-color-green.jpg';
import CHECK_ICON_MAROON from '../images/IM/Customization/checks/common/ico-custom-color-red.jpg';
import CHECK_ICON_PURPLE from '../images/IM/Customization/checks/common/ico-custom-color-purple.jpg';
import CHECK_ICON_YELLOW from '../images/IM/Customization/checks/common/ico-custom-color-yellow.jpg';

import CHECK_ICON_BLUE_BASIC from '../images/IM/Customization/checks/common/ico-custom-basic-blue.jpg';
import CHECK_ICON_GREEN_BASIC from '../images/IM/Customization/checks/common/ico-custom-basic-green.jpg';
import CHECK_ICON_MAROON_BASIC from '../images/IM/Customization/checks/common/ico-custom-basic-maroon.jpg';
import CHECK_ICON_PURPLE_BASIC from '../images/IM/Customization/checks/common/ico-custom-prestige-purple.jpg';
import CHECK_ICON_YELLOW_BASIC from '../images/IM/Customization/checks/common/ico-custom-basic-yellow.jpg';
import CHECK_ICON_PRESTIGE_BLUE_BASIC from '../images/IM/Customization/checks/common/ico-custom-prestige-blue.jpg';
import CHECK_ICON_PRESTIGE_GREEN_BASIC from '../images/IM/Customization/checks/common/ico-custom-prestige-green.jpg';
import CHECK_ICON_PRESTIGE_MAROON_BASIC from '../images/IM/Customization/checks/common/ico-custom-prestige-maroon.jpg';
import CHECK_ICON_ANTIQUE_BASIC from '../images/IM/Customization/checks/common/ico-custom-basic-antique.jpg';

import VOUCHER_SECURE_PLUS_BLUE_LARGE from '../images/IM/Customization/checks/voucherchecks/secureplus/VPlus_Blue_Preview.jpg';
import VOUCHER_SECURE_PLUS_GREEN_LARGE from '../images/IM/Customization/checks/voucherchecks/secureplus/VPlus_Green_Preview.jpg';
import VOUCHER_SECURE_PLUS_MAROON_LARGE from '../images/IM/Customization/checks/voucherchecks/secureplus/VPlus_Maroon_Preview.jpg';
import VOUCHER_SECURE_PLUS_PURPLE_LARGE from '../images/IM/Customization/checks/voucherchecks/secureplus/VPlus_Purple_Preview.jpg';
import VOUCHER_SECURE_PLUS_YELLOW_LARGE from '../images/IM/Customization/checks/voucherchecks/secureplus/VPlus_Yellow_Preview.jpg';

import VOUCHER_SECURE_PREMIER_BLUE_LARGE from '../images/IM/Customization/checks/voucherchecks/securepremier/VoucherPremier_Blue_Preview.jpg';
import VOUCHER_SECURE_PREMIER_GREEN_LARGE from '../images/IM/Customization/checks/voucherchecks/securepremier/VoucherPremier_Green_Preview.jpg';
import VOUCHER_SECURE_PREMIER_MAROON_LARGE from '../images/IM/Customization/checks/voucherchecks/securepremier/VoucherPremier_Maroon_Preview.jpg';
import VOUCHER_SECURE_PREMIER_PURPLE_LARGE from '../images/IM/Customization/checks/voucherchecks/securepremier/VoucherPremier_Purple_Preview.jpg';
import VOUCHER_SECURE_PREMIER_YELLOW_LARGE from '../images/IM/Customization/checks/voucherchecks/securepremier/VoucherPremier_Yellow_Preview.jpg';

import VOUCHER_BASIC_BLUE_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_Blue_Preview.jpg';
import VOUCHER_BASIC_GREEN_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_Green_Preview.jpg';
import VOUCHER_BASIC_MAROON_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_Maroon_Preview.jpg';
import VOUCHER_BASIC_PURPLE_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_Purple_Preview.jpg';
import VOUCHER_BASIC_YELLOW_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_Yellow_Preview.jpg';
import VOUCHER_BASIC_PRESTIGE_BLUE_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_PrestigeBlue_Preview.jpg';
import VOUCHER_BASIC_PRESTIGE_GREEN_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_PrestigeGreen_Preview.jpg';
import VOUCHER_BASIC_PRESTIGE_MAROON_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/VoucherBasic_PrestigeMaroon_Preview.jpg';
import VOUCHER_BASIC_ANTIQUE_LARGE from '../images/IM/Customization/checks/voucherchecks/basic/antique-antique_Preview.jpg';

import STANDARD_SECURE_PLUS_BLUE_LARGE from '../images/IM/Customization/checks/standardchecks/secureplus/prism_blue.jpg';
import STANDARD_SECURE_PLUS_GREEN_LARGE from '../images/IM/Customization/checks/standardchecks/secureplus/prism_green.jpg';
import STANDARD_SECURE_PLUS_MAROON_LARGE from '../images/IM/Customization/checks/standardchecks/secureplus/prism_maroon.jpg';
import STANDARD_SECURE_PLUS_PURPLE_LARGE from '../images/IM/Customization/checks/standardchecks/secureplus/prism_purple.jpg';
import STANDARD_SECURE_PLUS_YELLOW_LARGE from '../images/IM/Customization/checks/standardchecks/secureplus/prism_yellow.jpg';

import STANDARD_SECURE_PREMIER_BLUE_LARGE from '../images/IM/Customization/checks/standardchecks/securepremier/prism_blue.jpg';
import STANDARD_SECURE_PREMIER_GREEN_LARGE from '../images/IM/Customization/checks/standardchecks/securepremier/prism_green.jpg';
import STANDARD_SECURE_PREMIER_MAROON_LARGE from '../images/IM/Customization/checks/standardchecks/securepremier/prism_maroon.jpg';
import STANDARD_SECURE_PREMIER_PURPLE_LARGE from '../images/IM/Customization/checks/standardchecks/securepremier/prism_purple.jpg';
import STANDARD_SECURE_PREMIER_YELLOW_LARGE from '../images/IM/Customization/checks/standardchecks/securepremier/prism_yellow.jpg';

import STANDARD_BASIC_BLUE_LARGE from '../images/IM/Customization/checks/standardchecks/basic/cblue.jpg';
import STANDARD_BASIC_GREEN_LARGE from '../images/IM/Customization/checks/standardchecks/basic/cgreen.jpg';
import STANDARD_BASIC_MAROON_LARGE from '../images/IM/Customization/checks/standardchecks/basic/cmaroon.jpg';
import STANDARD_BASIC_PURPLE_LARGE from '../images/IM/Customization/checks/standardchecks/basic/cpurple.jpg';
import STANDARD_BASIC_YELLOW_LARGE from '../images/IM/Customization/checks/standardchecks/basic/cyellow.jpg';
import STANDARD_BASIC_PRESTIGE_BLUE_LARGE from '../images/IM/Customization/checks/standardchecks/basic/pblue.jpg';
import STANDARD_BASIC_PRESTIGE_GREEN_LARGE from '../images/IM/Customization/checks/standardchecks/basic/pgreen.jpg';
import STANDARD_BASIC_PRESTIGE_MAROON_LARGE from '../images/IM/Customization/checks/standardchecks/basic/pmaroon.jpg';
import STANDARD_BASIC_ANTIQUE_LARGE from '../images/IM/Customization/checks/standardchecks/basic/antique.jpg';

import WALLET_SECURE_PLUS_BLUE_LARGE from '../images/IM/Customization/checks/walletchecks/secureplus/SecurePlusWalletPreview_blue_1Sign.jpg';
import WALLET_SECURE_PLUS_GREEN_LARGE from '../images/IM/Customization/checks/walletchecks/secureplus/SecurePlusWalletPreview_green_1Sign.jpg';
import WALLET_SECURE_PLUS_MAROON_LARGE from '../images/IM/Customization/checks/walletchecks/secureplus/SecurePlusWalletPreview_maroon_1Sign.jpg';

import WALLET_BASIC_BLUE_LARGE from '../images/IM/Customization/checks/walletchecks/basic/cblue.jpg';
import WALLET_BASIC_GREEN_LARGE from '../images/IM/Customization/checks/walletchecks/basic/cgreen.jpg';
import WALLET_BASIC_PRESTIGE_BLUE_LARGE from '../images/IM/Customization/checks/walletchecks/basic/pblue.jpg';
import WALLET_BASIC_PRESTIGE_GREEN_LARGE from '../images/IM/Customization/checks/walletchecks/basic/pgreen.jpg';
import WALLET_BASIC_PRESTIGE_ANTIQUE_LARGE from '../images/IM/Customization/checks/walletchecks/basic/antique.jpg';

import MANUAL_CHECK_BLUE_LARGE from '../images/IM/Customization/checks/manualchecks/safety_blue.jpg';
import MANUAL_CHECK_GREEN_LARGE from '../images/IM/Customization/checks/manualchecks/safety_green.jpg';
import MANUAL_CHECK_YELLOW_LARGE from '../images/IM/Customization/checks/manualchecks/safety_yellow.jpg';
import MANUAL_CHECK_PINK_LARGE from '../images/IM/Customization/checks/manualchecks/safety_pink.jpg';
import MANUAL_CHECK_GRAY_LARGE from '../images/IM/Customization/checks/manualchecks/safety_gray.jpg';

import CHECK_ICON_MANUAL_BLUE from '../images/IM/Customization/checks/common/ico-custom-manual-business-blue.jpg';
import CHECK_ICON_MANUAL_GREEN from '../images/IM/Customization/checks/common/ico-custom-manual-business-green.jpg';
import CHECK_ICON_MANUAL_YELLOW from '../images/IM/Customization/checks/common/ico-custom-manual-business-yellow.jpg';
import CHECK_ICON_MANUAL_PINK from '../images/IM/Customization/checks/common/ico-custom-manual-business-pink.jpg';
import CHECK_ICON_MANUAL_GRAY from '../images/IM/Customization/checks/common/ico-custom-manual-business-gray.jpg';

import PERSONAL_SECURE_PLUS_BLUE_LARGE from '../images/IM/Customization/checks/personalchecks/personal-secureplus-blue.jpg';
import PERSONAL_SECURE_PLUS_GREEN_LARGE from '../images/IM/Customization/checks/personalchecks/personal-secureplus-green.jpg';
// import PERSONAL_SECURE_PLUS_MAROON_LARGE from '../images/IM/Customization/checks/personalchecks/personal-secureplus-maroon.jpg';

import DEPOSIT_FORM_ANTIQUE_LARGE from '../images/IM/Customization/checks/depositforms/antique.gif';
import DEPOSIT_FORM_BLUE_LARGE from '../images/IM/Customization/checks/depositforms/cblue.gif';
import DEPOSIT_FORM_GREEN_LARGE from '../images/IM/Customization/checks/depositforms/cgreen.gif';
import DEPOSIT_FORM_ANTIQUE from '../images/IM/Customization/checks/depositforms/chk_option_antique.gif';
import DEPOSIT_FORM_BLUE_P from '../images/IM/Customization/checks/depositforms/chk_option_blue_s.gif';
import DEPOSIT_FORM_BLUE from '../images/IM/Customization/checks/depositforms/chk_option_blue.gif';
import DEPOSIT_FORM_GREEN_P from '../images/IM/Customization/checks/depositforms/chk_option_green_s.gif';
import DEPOSIT_FORM_GREEN from '../images/IM/Customization/checks/depositforms/chk_option_green.gif';
import DEPOSIT_FORM_MAROON_P from '../images/IM/Customization/checks/depositforms/chk_option_maroon_s.gif';
import DEPOSIT_FORM_MAROON from '../images/IM/Customization/checks/depositforms/chk_option_maroon.gif';
import DEPOSIT_FORM_PURPLE from '../images/IM/Customization/checks/depositforms/chk_option_purple.gif';
import DEPOSIT_FORM_YEL from '../images/IM/Customization/checks/depositforms/chk_option_yellow.gif';
import DEPOSIT_FORM_YEL_LARGE from '../images/IM/Customization/checks/depositforms/cyellow.gif';
import DEPOSIT_FORM_MAROON_LARGE from '../images/IM/Customization/checks/depositforms/cmaroon.gif';
import DEPOSIT_FORM_PURPLE_LARGE from '../images/IM/Customization/checks/depositforms/cpurple.gif';
import DEPOSIT_FORM_BLUE_P_LARGE from '../images/IM/Customization/checks/depositforms/pblue.gif';
import DEPOSIT_FORM_GREEN_P_LARGE from '../images/IM/Customization/checks/depositforms/pgreen.gif';
import DEPOSIT_FORM_MAROON_P_LARGE from '../images/IM/Customization/checks/depositforms/pmaroon.gif';

import ENV_OPT_WHITE from '../images/IM/Customization/env_opt_white.gif';
import LME_01_WHITE from '../images/IM/Customization/env_large_white.png';
import ENV_OPT_GOLD from '../images/IM/Customization/env_opt_gold.gif';
import LME_01_YEL from '../images/IM/Customization/env_large_yel.png';

export const BVCcolorMap = [
  {
    Key: 'Classic Blue',
    Val: 'classic-blue',
    Imageurl: CHECK_ICON_BLUE_BASIC,
    ImageSource: VOUCHER_BASIC_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Classic Green',
    Val: 'classic-green',
    Imageurl: CHECK_ICON_GREEN_BASIC,
    ImageSource: VOUCHER_BASIC_GREEN_LARGE,
  },
  {
    Key: 'Classic Maroon',
    Val: 'classic-maroon',
    Imageurl: CHECK_ICON_MAROON_BASIC,
    ImageSource: VOUCHER_BASIC_MAROON_LARGE,
  },
  {
    Key: 'Classic Purple',
    Val: 'classic-purple',
    Imageurl: CHECK_ICON_PURPLE_BASIC,
    ImageSource: VOUCHER_BASIC_PURPLE_LARGE,
  },
  {
    Key: 'Classic Yellow',
    Val: 'classic-yellow',
    Imageurl: CHECK_ICON_YELLOW_BASIC,
    ImageSource: VOUCHER_BASIC_YELLOW_LARGE,
  },
  {
    Key: 'Prestige Blue',
    Val: 'prestige-blue',
    Imageurl: CHECK_ICON_PRESTIGE_BLUE_BASIC,
    ImageSource: VOUCHER_BASIC_PRESTIGE_BLUE_LARGE,
  },
  {
    Key: 'Prestige Green',
    Val: 'prestige-green',
    Imageurl: CHECK_ICON_PRESTIGE_GREEN_BASIC,
    ImageSource: VOUCHER_BASIC_PRESTIGE_GREEN_LARGE,
  },
  {
    Key: 'Prestige Maroon',
    Val: 'prestige-maroon',
    Imageurl: CHECK_ICON_PRESTIGE_MAROON_BASIC,
    ImageSource: VOUCHER_BASIC_PRESTIGE_MAROON_LARGE,
  },
  {
    Key: 'Antique',
    Val: 'antique-antique',
    Imageurl: CHECK_ICON_ANTIQUE_BASIC,
    ImageSource: VOUCHER_BASIC_ANTIQUE_LARGE,
  },
];

export const SPPCcolorMap = [
  {
    Key: 'Prismatic Blue',
    Val: 'prismatic-blue',
    Imageurl: CHECK_ICON_BLUE,
    ImageSource: PERSONAL_SECURE_PLUS_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Prismatic Green',
    Val: 'prismatic-green',
    Imageurl: CHECK_ICON_GREEN,
    ImageSource: PERSONAL_SECURE_PLUS_GREEN_LARGE,
  },
  /* {
    Key: 'Prismatic Maroon',
    Val: 'prismatic-maroon',
    Imageurl: CHECK_ICON_MAROON,
    ImageSource: PERSONAL_SECURE_PLUS_MAROON_LARGE,
  }, */
];

export const MCheckcolorMap = [
  {
    Key: 'Safety Blue',
    Val: 'safety-blue',
    Imageurl: CHECK_ICON_MANUAL_BLUE,
    ImageSource: MANUAL_CHECK_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Safety Green',
    Val: 'safety-green',
    Imageurl: CHECK_ICON_MANUAL_GREEN,
    ImageSource: MANUAL_CHECK_GREEN_LARGE,
  },
  {
    Key: 'Safety Yellow',
    Val: 'safety-yellow',
    Imageurl: CHECK_ICON_MANUAL_YELLOW,
    ImageSource: MANUAL_CHECK_YELLOW_LARGE,
  },
  {
    Key: 'Safety Pink',
    Val: 'safety-pink',
    Imageurl: CHECK_ICON_MANUAL_PINK,
    ImageSource: MANUAL_CHECK_PINK_LARGE,
  },
  {
    Key: 'Safety Gray',
    Val: 'safety-gray',
    Imageurl: CHECK_ICON_MANUAL_GRAY,
    ImageSource: MANUAL_CHECK_GRAY_LARGE,
  },
];

export const BWCcolorMap = [
  {
    Key: 'Classic Blue',
    Val: 'classic-blue',
    Imageurl: CHECK_ICON_BLUE_BASIC,
    ImageSource: WALLET_BASIC_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Classic Green',
    Val: 'classic-green',
    Imageurl: CHECK_ICON_GREEN_BASIC,
    ImageSource: WALLET_BASIC_GREEN_LARGE,
  },
  {
    Key: 'Prestige Blue',
    Val: 'prestige-blue',
    Imageurl: CHECK_ICON_PRESTIGE_BLUE_BASIC,
    ImageSource: WALLET_BASIC_PRESTIGE_BLUE_LARGE,
  },
  {
    Key: 'Prestige Green',
    Val: 'prestige-green',
    Imageurl: CHECK_ICON_PRESTIGE_GREEN_BASIC,
    ImageSource: WALLET_BASIC_PRESTIGE_GREEN_LARGE,
  },
  {
    Key: 'Antique',
    Val: 'antique-antique',
    Imageurl: CHECK_ICON_ANTIQUE_BASIC,
    ImageSource: WALLET_BASIC_PRESTIGE_ANTIQUE_LARGE,
  },
];

export const SPWCcolorMap = [
  {
    Key: 'Prismatic Blue',
    Val: 'prismatic-blue',
    Imageurl: CHECK_ICON_BLUE,
    ImageSource: WALLET_SECURE_PLUS_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Prismatic Green',
    Val: 'prismatic-green',
    Imageurl: CHECK_ICON_GREEN,
    ImageSource: WALLET_SECURE_PLUS_GREEN_LARGE,
  },
  {
    Key: 'Prismatic Maroon',
    Val: 'prismatic-maroon',
    Imageurl: CHECK_ICON_MAROON,
    ImageSource: WALLET_SECURE_PLUS_MAROON_LARGE,
  },
];

export const BSCcolorMap = [
  {
    Key: 'Classic Blue',
    Val: 'classic-blue',
    Imageurl: CHECK_ICON_BLUE_BASIC,
    ImageSource: STANDARD_BASIC_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Classic Green',
    Val: 'classic-green',
    Imageurl: CHECK_ICON_GREEN_BASIC,
    ImageSource: STANDARD_BASIC_GREEN_LARGE,
  },
  {
    Key: 'Classic Maroon',
    Val: 'classic-maroon',
    Imageurl: CHECK_ICON_MAROON_BASIC,
    ImageSource: STANDARD_BASIC_MAROON_LARGE,
  },
  {
    Key: 'Classic Purple',
    Val: 'classic-purple',
    Imageurl: CHECK_ICON_PURPLE_BASIC,
    ImageSource: STANDARD_BASIC_PURPLE_LARGE,
  },
  {
    Key: 'Classic Yellow',
    Val: 'classic-yellow',
    Imageurl: CHECK_ICON_YELLOW_BASIC,
    ImageSource: STANDARD_BASIC_YELLOW_LARGE,
  },
  {
    Key: 'Prestige Blue',
    Val: 'prestige-blue',
    Imageurl: CHECK_ICON_PRESTIGE_BLUE_BASIC,
    ImageSource: STANDARD_BASIC_PRESTIGE_BLUE_LARGE,
  },
  {
    Key: 'Prestige Green',
    Val: 'prestige-green',
    Imageurl: CHECK_ICON_PRESTIGE_GREEN_BASIC,
    ImageSource: STANDARD_BASIC_PRESTIGE_GREEN_LARGE,
  },
  {
    Key: 'Prestige Maroon',
    Val: 'prestige-maroon',
    Imageurl: CHECK_ICON_PRESTIGE_MAROON_BASIC,
    ImageSource: STANDARD_BASIC_PRESTIGE_MAROON_LARGE,
  },
  {
    Key: 'Antique',
    Val: 'antique-antique',
    Imageurl: CHECK_ICON_ANTIQUE_BASIC,
    ImageSource: STANDARD_BASIC_ANTIQUE_LARGE,
  },
];

export const SPSCPremiercolorMap = [
  {
    Key: 'Prismatic Blue',
    Val: 'prismatic-blue',
    Imageurl: CHECK_ICON_BLUE,
    ImageSource: STANDARD_SECURE_PREMIER_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Prismatic Green',
    Val: 'prismatic-green',
    Imageurl: CHECK_ICON_GREEN,
    ImageSource: STANDARD_SECURE_PREMIER_GREEN_LARGE,
  },
  {
    Key: 'Prismatic Maroon',
    Val: 'prismatic-maroon',
    Imageurl: CHECK_ICON_MAROON,
    ImageSource: STANDARD_SECURE_PREMIER_MAROON_LARGE,
  },
  {
    Key: 'Prismatic Purple',
    Val: 'prismatic-purple',
    Imageurl: CHECK_ICON_PURPLE,
    ImageSource: STANDARD_SECURE_PREMIER_PURPLE_LARGE,
  },
  {
    Key: 'Prismatic Yellow',
    Val: 'prismatic-yellow',
    Imageurl: CHECK_ICON_YELLOW,
    ImageSource: STANDARD_SECURE_PREMIER_YELLOW_LARGE,
  },
];

export const SPSCcolorMap = [
  {
    Key: 'Prismatic Blue',
    Val: 'prismatic-blue',
    Imageurl: CHECK_ICON_BLUE,
    ImageSource: STANDARD_SECURE_PLUS_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Prismatic Green',
    Val: 'prismatic-green',
    Imageurl: CHECK_ICON_GREEN,
    ImageSource: STANDARD_SECURE_PLUS_GREEN_LARGE,
  },
  {
    Key: 'Prismatic Maroon',
    Val: 'prismatic-maroon',
    Imageurl: CHECK_ICON_MAROON,
    ImageSource: STANDARD_SECURE_PLUS_MAROON_LARGE,
  },
  {
    Key: 'Prismatic Purple',
    Val: 'prismatic-purple',
    Imageurl: CHECK_ICON_PURPLE,
    ImageSource: STANDARD_SECURE_PLUS_PURPLE_LARGE,
  },
  {
    Key: 'Prismatic Yellow',
    Val: 'prismatic-yellow',
    Imageurl: CHECK_ICON_YELLOW,
    ImageSource: STANDARD_SECURE_PLUS_YELLOW_LARGE,
  },
];

export const SPVCPremiercolorMap = [
  {
    Key: 'Prismatic Blue',
    Val: 'prismatic-blue',
    Imageurl: CHECK_ICON_BLUE,
    ImageSource: VOUCHER_SECURE_PREMIER_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Prismatic Green',
    Val: 'prismatic-green',
    Imageurl: CHECK_ICON_GREEN,
    ImageSource: VOUCHER_SECURE_PREMIER_GREEN_LARGE,
  },
  {
    Key: 'Prismatic Maroon',
    Val: 'prismatic-maroon',
    Imageurl: CHECK_ICON_MAROON,
    ImageSource: VOUCHER_SECURE_PREMIER_MAROON_LARGE,
  },
  {
    Key: 'Prismatic Purple',
    Val: 'prismatic-purple',
    Imageurl: CHECK_ICON_PURPLE,
    ImageSource: VOUCHER_SECURE_PREMIER_PURPLE_LARGE,
  },
  {
    Key: 'Prismatic Yellow',
    Val: 'prismatic-yellow',
    Imageurl: CHECK_ICON_YELLOW,
    ImageSource: VOUCHER_SECURE_PREMIER_YELLOW_LARGE,
  },
];

export const SPVCcolorMap = [
  {
    Key: 'Prismatic Blue',
    Val: 'prismatic-blue',
    Imageurl: CHECK_ICON_BLUE,
    ImageSource: VOUCHER_SECURE_PLUS_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'Prismatic Green',
    Val: 'prismatic-green',
    Imageurl: CHECK_ICON_GREEN,
    ImageSource: VOUCHER_SECURE_PLUS_GREEN_LARGE,
  },
  {
    Key: 'Prismatic Maroon',
    Val: 'prismatic-maroon',
    Imageurl: CHECK_ICON_MAROON,
    ImageSource: VOUCHER_SECURE_PLUS_MAROON_LARGE,
  },
  {
    Key: 'Prismatic Purple',
    Val: 'prismatic-purple',
    Imageurl: CHECK_ICON_PURPLE,
    ImageSource: VOUCHER_SECURE_PLUS_PURPLE_LARGE,
  },
  {
    Key: 'Prismatic Yellow',
    Val: 'prismatic-yellow',
    Imageurl: CHECK_ICON_YELLOW,
    ImageSource: VOUCHER_SECURE_PLUS_YELLOW_LARGE,
  },
];

export const DEPcolorMap = [
  {
    Key: 'classic blue',
    Val: 'classic-blue',
    Imageurl: DEPOSIT_FORM_BLUE,
    ImageSource: DEPOSIT_FORM_BLUE_LARGE,
    Default: true,
  },
  {
    Key: 'classic green',
    Val: 'classic-green',
    Imageurl: DEPOSIT_FORM_GREEN,
    ImageSource: DEPOSIT_FORM_GREEN_LARGE,
  },
  {
    Key: 'classic maroon',
    Val: 'classic-maroon',
    Imageurl: DEPOSIT_FORM_MAROON,
    ImageSource: DEPOSIT_FORM_MAROON_LARGE,
  },
  {
    Key: 'classic purple',
    Val: 'classic-purple',
    Imageurl: DEPOSIT_FORM_PURPLE,
    ImageSource: DEPOSIT_FORM_PURPLE_LARGE,
  },
  {
    Key: 'classic yellow',
    Val: 'classic-yellow',
    Imageurl: DEPOSIT_FORM_YEL,
    ImageSource: DEPOSIT_FORM_YEL_LARGE,
  },
  {
    Key: 'prestige blue',
    Val: 'prestige-blue',
    Imageurl: DEPOSIT_FORM_BLUE_P,
    ImageSource: DEPOSIT_FORM_BLUE_P_LARGE,
  },
  {
    Key: 'prestige green',
    Val: 'prestige-green',
    Imageurl: DEPOSIT_FORM_GREEN_P,
    ImageSource: DEPOSIT_FORM_GREEN_P_LARGE,
  },
  {
    Key: 'prestige maroon',
    Val: 'prestige-maroon',
    Imageurl: DEPOSIT_FORM_MAROON_P,
    ImageSource: DEPOSIT_FORM_MAROON_P_LARGE,
  },
  {
    Key: 'antique',
    Val: 'antique-antique',
    Imageurl: DEPOSIT_FORM_ANTIQUE,
    ImageSource: DEPOSIT_FORM_ANTIQUE_LARGE,
  },
];

export const LargeMailingcolorMap = [
  {
    Key: 'White',
    Val: 'White',
    Imageurl: ENV_OPT_WHITE,
    ImageSource: LME_01_WHITE,
    Default: true,
    PredefinedSku: '115WH',
    defaultSkuVal: '115WH-00100',
  },
  {
    Key: 'Yellow',
    Val: 'Golden_Brown',
    Imageurl: ENV_OPT_GOLD,
    ImageSource: LME_01_YEL,
    PredefinedSku: '115GB',
    defaultSkuVal: '115GB-00100',
  },
];
