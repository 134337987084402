// eslint-disable-next-line require-jsdoc
import React from 'react';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent, Month } from '../../../../assets/pagedata/constant';
import { getPastYears } from '../../Util/CommonUtils';

// eslint-disable-next-line require-jsdoc
export const AccountOpenDate = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { accountOpenDate } = customizationReducer;
  const dispatch = useDispatch();
  const { Title, TooltipInfo } = props;
  const years = getPastYears(50);

  const handleChange = (key, value) => {
    let dateVal = value;
    if (key === 'year' && value !== 'Year') {
      dateVal = value.slice(-2);
    }
    dispatch({
      type: ReduxEvent.ACCOUNT_OPEN_DATE,
      data: {
        accountOpenDate: {
          ...accountOpenDate,
          [key]: dateVal,
        },
      },
    });
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        {Title}{' '}
        {TooltipInfo && (
          <Tooltip message={<div>{TooltipInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <Dropdown
        data-wa-link="quantity"
        theme="quickbooks"
        buttonType="secondaryGhost"
        className="account-open-dropdown margin-account dropdown-background-color-white"
        onChange={(e) => handleChange('month', e.target.value)}
        value={accountOpenDate.month}
        aria-label="quantity"
        data-ui-object="dropdown"
        data-refer-ui-access-point="customization_page | account_open_month"
        data-ui-object-details="Account Open Month"
      >
        {Month.map((el) => {
          return (
            <MenuItem key={el.key} value={el.value}>
              {el.key}
            </MenuItem>
          );
        })}
      </Dropdown>
      <Dropdown
        data-wa-link="quantity"
        theme="quickbooks"
        aria-label="quantity"
        buttonType="secondaryGhost"
        className="account-open-dropdown dropdown-background-color-white"
        onChange={(e) => handleChange('year', e.target.value)}
        value={accountOpenDate.year}
        data-ui-object="dropdown"
        data-refer-ui-access-point="customization_page | account_open_year"
        data-ui-object-details="Account Open Year"
      >
        {years.map((year) => {
          return <MenuItem value={year.slice(-2)}>{year}</MenuItem>;
        })}
      </Dropdown>
    </div>
  );
};
