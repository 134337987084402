// eslint-disable-next-line require-jsdoc
import React from 'react';
import TextField from '@ids/text-field';
// import { CircleInfoIcon } from '@ids-ts/icon';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
// import { ReduxEvent } from '../../../../assets/pagedata/constant';
// import { CustomTextField } from '../../Common/CustomTextField';

// eslint-disable-next-line require-jsdoc
export const CompanyInfo = (props) => {
  // const [componayInfo, setCompanyInfo] = useState('');
  const { allCaps } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { company, bankInformation, bankAccount } = customizationReducer;
  const dispatch = useDispatch();

  const handleTextChanged = (text, val) => {
    if (val.length <= 25)
      dispatch({
        type: ReduxEvent.CUST_BANK_INFO_CHANGED,
        data: { [text]: allCaps ? val.toUpperCase() : val },
      });
  };

  const onCompanyInfoChanged = (e) => {
    const { value } = e.target;
    if (value.length <= 25)
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
        data: {
          company: {
            ...company,
            CompanyName: allCaps ? value.toUpperCase() : value,
          },
        },
      });
  };

  const onBankAccountChanged = (e) => {
    const { value } = e.target;
    if (value.length <= 25)
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
        data: { bankAccount: allCaps ? value.toUpperCase() : value },
      });
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">Enter your company information</h3>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Bank name"
            placeholder="Bank name"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={bankInformation.name}
            onChange={(e) => handleTextChanged('name', e.target.value)}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Company name"
            placeholder="Company Name"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={company.CompanyName}
            onChange={onCompanyInfoChanged}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Bank account"
            placeholder="Bank Account"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={bankAccount}
            onChange={onBankAccountChanged}
            autoComplete="off"
          />
        </div>
      </div>
    </div>
  );
};
