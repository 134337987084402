import { doGetFetch, doPutFetch, doPostForm, doPostFetch } from './utils/fetchUtil';
import {
  IMPRINTS_ENDPOINT,
  DATA_ISLANDS_ENDPOINT,
  MISC_VARIANT_ENDPOINT,
  GET_BANK_SHOPPER_INFO_ENDPOINT,
  GET_BANK_INFO_ENDPOINT,
  LOGO_ENDPOINT,
  FDS_ENDPOINT,
  LAUNCH_NEW_BUNDLE_ENDPOINT,
  LAUNCH_EDIT_BUNDLE_ENDPOINT,
  ADD_BUNDLE_TO_CART,
} from './config/customizationUrl';

export function getNewImprintId(pfId) {
  const params = {
    ProductID: pfId,
  };

  return doGetFetch(IMPRINTS_ENDPOINT, params);
}

export function getImprintScaffolding(pfId) {
  const params = {
    id: pfId,
  };

  return doGetFetch(DATA_ISLANDS_ENDPOINT, params);
}

export function getVariantDetails(productId, sheet, printerType, BundleKey) {
  const params = {
    productId,
  };

  // default value needs to be 1
  if (sheet) params.sheet = sheet;
  else params.sheet = 1;

  if (printerType) params.printerType = printerType;

  if (BundleKey) params.BundleKey = BundleKey;

  return doGetFetch(MISC_VARIANT_ENDPOINT, params);
}

export function getShopperAndBankInfo(shopperId, imprintId, productId) {
  const params = {
    shopperId: shopperId ?? '',
    imprintId: imprintId ?? '',
    productId: productId ?? '',
  };

  return doGetFetch(GET_BANK_SHOPPER_INFO_ENDPOINT, params);
}

export function getBankInfoAndMicr(imprintID, productId, routingNumber, accNumber, CSN) {
  const params = {
    ImprintID: imprintID,
    ProductID: productId,
    RoutingNumber: routingNumber,
    AccountNumber: accNumber,
    startNumber: CSN,
  };

  return doGetFetch(GET_BANK_INFO_ENDPOINT, params);
}

export function saveImprint(imprintId, imprintData) {
  const params = {
    ImprintId: imprintId,
    ImprintData: imprintData,
  };

  return doPutFetch({ url: `${IMPRINTS_ENDPOINT.url}/${imprintId}` }, params);
}

export function getImprintDataByImprintId(imprintId) {
  return doGetFetch({ url: `${IMPRINTS_ENDPOINT.url}/${imprintId}` });
}

export function getImprintDataByImprintIdShopperId(imprintId, shopperId) {
  const params = {
    imprintID: imprintId,
    shopperId,
  };
  return doGetFetch(IMPRINTS_ENDPOINT, params);
}

export function getLogoByAlphabet(alphabet) {
  const params = {
    alphabet: alphabet.toLowerCase(),
  };
  return doGetFetch(LOGO_ENDPOINT, params);
}

export function getPredesignCategories() {
  const params = {
    categories: 1,
  };
  return doGetFetch(LOGO_ENDPOINT, params);
}

export function getLogoByCategoryId(id) {
  const params = {
    categoryId: id,
  };
  return doGetFetch(LOGO_ENDPOINT, params);
}

export function getLogoByShopperId(shopperId) {
  const params = {
    shopperId: shopperId || '',
  };
  return doGetFetch(LOGO_ENDPOINT, params);
}

export function postFds(theRoutingNumber, theAccountNumber) {
  const formData = new URLSearchParams();
  formData.append('Check[RoutingNumber]', theRoutingNumber);
  formData.append('Check[AccountNumber]', theAccountNumber);

  return doPostForm(FDS_ENDPOINT, formData);
}

export function getImprintDataByImprintIdShopperIdpfIdSku(imprint, pf_id, sku, shopperId) {
  const requestUrl = `${IMPRINTS_ENDPOINT.url}?id=${imprint}&pf_id=${pf_id}&sku=${sku}&shopperid=${shopperId}`;
  const params = {
    imprint,
  };
  return doPostFetch({ url: requestUrl }, params);
}

export function launchNewBundle(pfid, sku) {
  const params = {
    bundle_pfid: pfid,
    bundle_sku: sku,
  };
  return doGetFetch(LAUNCH_NEW_BUNDLE_ENDPOINT, params);
}

export function launchEditBundle(bundleKey) {
  const params = {
    bundleKey,
  };
  return doGetFetch(LAUNCH_EDIT_BUNDLE_ENDPOINT, params);
}
export function addBundelToCart(bundleKey) {
  const requestUrl = `${ADD_BUNDLE_TO_CART.url}?bundleKey=${bundleKey}`;
  return doPostFetch({ url: requestUrl });
}
