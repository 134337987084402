import { doGetFetch, doDeleteData, doPutFetch, doPostFormFetch } from './utils/fetchUtil';
import { GET_SAVED_LOGO, GET_SAVED_WORK, GET_IMAGE } from './config/url';

export function getSavedLogo() {
  let requestUrl = GET_SAVED_LOGO.url.replace('{version}', 'v1');
  requestUrl = `${requestUrl}?shopperid=`;
  return doGetFetch({ url: requestUrl });
}

export function deleteSavedLogo(logoGuid) {
  let requestUrl = GET_SAVED_LOGO.url.replace('{version}', 'v1');
  requestUrl = `${requestUrl}?logoguid=${logoGuid}&status=0`;
  return doDeleteData({ url: requestUrl });
}

export function getSavedWork() {
  let requestUrl = GET_SAVED_WORK.url;
  requestUrl = `${requestUrl}?shopperid=null`;
  return doGetFetch({ url: requestUrl });
}

export function deleteSavedWork(bundleKey) {
  let requestUrl = GET_SAVED_WORK.url;
  requestUrl = `${requestUrl}?sDeleteImprintID=${bundleKey}`;
  return doDeleteData({ url: requestUrl });
}

export function getUploadImage() {
  const requestUrl = GET_IMAGE.url.replace('{version}', 'v1');
  return doGetFetch({ url: requestUrl });
}

export function postUploadImage(file, capchaResponse) {
  let requestUrl = GET_IMAGE.url.replace('{version}', 'v1');
  requestUrl = `${requestUrl}?configId=500`;
  const formData = new FormData();
  formData.append('capchaResponse', capchaResponse);
  formData.append('file', file);
  return doPostFormFetch({ url: requestUrl }, formData);
}

export function putUploadLogo(logoGuid, logoName) {
  let requestUrl = GET_SAVED_LOGO.url.replace('{version}', 'v1');
  requestUrl = `${requestUrl}?shopperId=&imageguid=${logoGuid}&logoLabel=${logoName}`;
  return doPutFetch({ url: requestUrl });
}
