// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/stamps.scss';
import { formatStandardImageUrl } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const MonogramStampImage = (props) => {
  const { company, fromModal, image, logo } = props;

  return (
    <div
      className={
        !fromModal ? 'monogram-stamp-image-container' : 'monogram-stamp-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      <div className="image-company-info">
        <div className="monogram-wrapper">
          {logo ? (
            <img src={formatStandardImageUrl(logo.Url)} alt="..." />
          ) : (
            <div style={{ marginLeft: '70px' }} />
          )}
        </div>
        <p className={logo ? '' : 'spacing-left'}>
          {company.CompanyName}
          <br />
          {company.AddressLine1}
          <br />
          {company.AddressLine2}
          <br />
          {company.Phone}
        </p>
      </div>
    </div>
  );
};
