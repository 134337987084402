export const getHexByColor = (colorName) => {
  switch (colorName) {
    case 'Blue':
      return '#003399';

    case 'Green':
      return '#006633';

    case 'Maroon':
      return '#990000';

    case 'Red':
      return '#ff0000';

    case 'Violet':
      return '#660099';

    default:
      return '#000000';
  }
};

export const getColorFromHex = (colorHex) => {
  switch (colorHex) {
    case '#003399':
      return 'Blue';

    case '#006633':
      return 'Green';

    case '#990000':
      return 'Maroon';

    case '#ff0000':
      return 'Red';

    case '#660099':
      return 'Violet';

    default:
      return 'Black';
  }
};

export const productSpecId = (pfId) => {
  switch (pfId) {
    case 'CHECK-02':
    case 'CHECK-02-ult':
      return '178';
    case 'CHECK-11':
    case 'CHECK-11-ult':
      return '179';
    case 'CHECK-16':
    case 'CHECK-16-ult':
      return '180';
    case 'CHECK-01':
      return '140';
    case 'CHECK-12':
      return '138';
    case 'CHECK-17':
      return '172';
    case 'CHECK-09':
      return '139';
    case 'CHECK-14':
      return '141';
    case 'CHECK-15':
      return '136';
    default:
      return '';
  }
};

const isValidAlphaNumeric = (value) => {
  const regEx = new RegExp('^[a-zA-Z]{0,2}[0-9]{2,6}$', 'i');
  const result = regEx.test(value);

  if (result) {
    let number = '';
    const data =
      value &&
      value
        .toString()
        .split('')
        .map((el, key) => {
          if (!Number.isNaN(Number(el))) {
            number += el;
          }
          return key;
        });
    console.log(data);
    if (parseInt(number) >= 101 && parseInt(number) <= 999999) {
      return true;
    }
  }
  return false;
};

const is_AlphaNumeric = (value) => {
  if (new RegExp(/^([0-9]+)$/).test(value)) {
    return false;
  }
  if (new RegExp(/^([a-zA-Z0-9]+)$/).test(value)) {
    return true;
  }
};
/*
Function to validate imprint details and clean unncessary attributes 
 */
const cleanImprintDetails = (imprintData) => {
  if (imprintData) {
    // deleteing unnecessary bank_state attribute
    delete imprintData.BankImprint.MicrAttributes.bank_state;
    // deleteing unnecessary MainLogoAttributes attributes
    delete imprintData.LogoImprint.MainLogoAttributes.required_yn;
    delete imprintData.LogoImprint.MainLogoAttributes.required_logo_type;
    // deleteing unnecessary ShadowLogoAttributes attributes
    delete imprintData.LogoImprint.ShadowLogoAttributes.required_yn;
    delete imprintData.LogoImprint.ShadowLogoAttributes.required_logo_type;
    // deleteing unnecessary ColorCountAttributes attribute custom_color_yn, pms_no
    delete imprintData.ColorCountAttributes.custom_color_yn;
    delete imprintData.Color1Attributes.pms_no;
    // deleteing unnecessary CheckStartingNumberAttributes attributes
    delete imprintData.CheckStartingNumberAttributes.alpha_prefix;
    delete imprintData.CheckStartingNumberAttributes.digits;
  }
  return imprintData;
};
/**
 * Sets the basic data island properties like SKU, sheets and bundle
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const UpdateDataIslandImprint = (imprintData, custReducer, pfId) => {
  imprintData.DataIslandAttributes.Sheets = custReducer.sheets;
  imprintData.DataIslandAttributes.Sku = custReducer.selectedSku;
  imprintData.DataIslandAttributes.PrinterType = custReducer.printerType;
  if (pfId) {
    imprintData.DataIslandAttributes.product_spec_id = productSpecId(pfId);
    imprintData.DataIslandAttributes.SigLines = custReducer.signatureLines.totalLines;
    imprintData.DataIslandAttributes.envelope_sku = custReducer.matchingEnv.envelope_sku;
  }
  return imprintData;
};

/**
 * Sets the basic company imprint
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyImprint = (imprintData, custReducer) => {
  imprintData.CompanyImprint.CompanyName = custReducer.company.CompanyName;
  imprintData.CompanyImprint.AddressLine1 = custReducer.company.AddressLine1;
  imprintData.CompanyImprint.AddressLine2 = custReducer.company.AddressLine2;
  imprintData.CompanyImprint.Phone = custReducer.company.Phone;
  // ExtraLine is not available for deposit slips
  if (imprintData.Id === 'DEP-01' || imprintData.Id === 'DEP-02') {
    imprintData.CompanyImprint.ExtraLine = '';
  } else {
    imprintData.CompanyImprint.ExtraLine = custReducer.company.ExtraLine;
  }
  return imprintData;
};

/**
 * Sets the basic company imprint for Fax header cover stamp
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCustomCompanyAttributes = (imprintData, custReducer) => {
  imprintData.CompanyImprint.CompanyName = custReducer.company.CompanyName;
  imprintData.CompanyImprint.Phone = custReducer.company.Phone;
  imprintData.CompanyImprint.ExtraLine = custReducer.company.ExtraLine;
  imprintData.CompanyImprint.CompanyNameAttributes.font_size = '11';
  imprintData.CompanyImprint.CompanyNameAttributes.text_align = 'justify';
  imprintData.CompanyImprint.ExtraLineAttributes.font_size = '11';
  imprintData.CompanyImprint.ExtraLineAttributes.text_align = 'justify';
  imprintData.CompanyImprint.PhoneAttributes.font_size = '11';
  imprintData.CompanyImprint.PhoneAttributes.text_align = 'justify';
  return imprintData;
};

/**
 * Sets the basic bank imprint
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateBankImprint = (imprintData, custReducer) => {
  // bank imprint
  imprintData.BankImprint.RoutingNumber = custReducer.routingNumber;
  imprintData.BankImprint.AccountNumber = custReducer.accountNumber;
  imprintData.BankImprint.BankName = custReducer.bankInformation.name;
  imprintData.BankImprint.BankAccount = custReducer.accountNumber;
  imprintData.BankImprint.Micr = custReducer.micr;
  imprintData.BankImprint.MicrAttributes.micr_mask = custReducer.micrMask;
  imprintData.BankImprint.AddressLine1 = custReducer.bankInformation.address;
  imprintData.BankImprint.AddressLine2 = `${custReducer.bankInformation.city}, ${custReducer.bankInformation.state} ${custReducer.bankInformation.zipcode}`;
  imprintData.BankImprint.City = custReducer.bankInformation.city;
  imprintData.BankImprint.Fractional =
    custReducer.banksFromRoutingNumber.length !== 0
      ? custReducer.banksFromRoutingNumber[0].fractional
      : null;
  imprintData.BankImprint.State = custReducer.bankInformation.state;
  imprintData.BankImprint.Zip = custReducer.bankInformation.zipcode;
  return imprintData;
};

/**
 * Sets the Bank imprint attributes
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateBankInfoAttribute = (imprintData, custReducer) => {
  const fontColor = getHexByColor(custReducer.selectedFontColor);
  if (imprintData.BankImprint.AddressLine1Attributes) {
    imprintData.BankImprint.AddressLine1Attributes = {
      ...imprintData.BankImprint.AddressLine1Attributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.AddressLine2Attributes) {
    imprintData.BankImprint.AddressLine2Attributes = {
      ...imprintData.BankImprint.AddressLine2Attributes,
      font_color: fontColor,
    };
    imprintData.BankImprint.AddressLine2Attributes.default_value = '';
  }

  if (imprintData.BankImprint.AddressLine3Attributes) {
    imprintData.BankImprint.AddressLine3Attributes = {
      ...imprintData.BankImprint.AddressLine3Attributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.BankAccountAttributes) {
    imprintData.BankImprint.BankAccountAttributes = {
      ...imprintData.BankImprint.BankAccountAttributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.BankNameAttributes) {
    imprintData.BankImprint.BankNameAttributes = {
      ...imprintData.BankImprint.BankNameAttributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.CityAttributes) {
    imprintData.BankImprint.CityAttributes = {
      ...imprintData.BankImprint.CityAttributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.FractionalAttributes) {
    imprintData.BankImprint.FractionalAttributes = {
      ...imprintData.BankImprint.FractionalAttributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.StateAttributes) {
    imprintData.BankImprint.StateAttributes = {
      ...imprintData.BankImprint.StateAttributes,
      font_color: fontColor,
    };
  }

  if (imprintData.BankImprint.ZipAttributes) {
    imprintData.BankImprint.ZipAttributes = {
      ...imprintData.BankImprint.ZipAttributes,
      font_color: fontColor,
    };
  }

  // if (imprintData.CompanyImprint.ExtraLine2Attributes) {
  //   imprintData.CompanyImprint.ExtraLine2Attributes = {
  //     ...imprintData.CompanyImprint.ExtraLine2Attributes,
  //     font_size: custReducer.company.ExtraLine2Attribute.FontSize,
  //     font_weight: custReducer.company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
  //   };
  // }

  return imprintData;
};

/**
 * Sets the voucher line text with summary text
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateVoucherLine = (imprintData, custReducer) => {
  if (imprintData.VoucherLineAttributes) {
    imprintData.VoucherLine = custReducer.summaryText.text
      ? custReducer.summaryText.text
      : imprintData.VoucherLine === imprintData.VoucherLineAttributes.default_value
      ? ''
      : imprintData.VoucherLine;
    imprintData.VoucherLineAttributes = {
      ...imprintData.VoucherLineAttributes,
      font_weight: custReducer.summaryText.bold ? 'bold' : 'normal',
    };
  }

  if (imprintData.VoucherLineAttributes) {
    imprintData.VoucherLineAttributes = {
      ...imprintData.VoucherLineAttributes,
      font_color: custReducer.summaryText.font_color,
    };
  }

  return imprintData;
};

/**
 * Specific method for endorsement stamp
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const endorsementStampUpdate = (imprintData, custReducer) => {
  imprintData.BankImprint.BankName = custReducer.bankInformation.name;
  imprintData.CompanyImprint.CompanyName = custReducer.company.CompanyName;
  imprintData.BankImprint.BankAccount = custReducer.bankAccount;
  return imprintData;
};

/**
 * Update Custom color attributes
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const customColorAttributes = (imprintData, custReducer) => {
  imprintData.CustomColor = custReducer.customColor.customColorValue;
  imprintData.CustomColorAttributes.style_url = custReducer.customColor.selectedColorPickerImage;
  return imprintData;
};

/**
 * Sets the logo attributes on imprint
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateLogoDetails = (imprintData, custReducer) => {
  if (custReducer.logo) {
    imprintData.LogoImprint.MainLogo = custReducer.logo.LogoFileNumber;
    imprintData.LogoImprint.MainLogoAttributes.logo_type = custReducer.logoOption.LogoType;
    imprintData.LogoImprint.MainLogoAttributes.logo_url = custReducer.logo.Url;
  } else {
    imprintData.LogoImprint.MainLogo = custReducer.dataIslandAttributes.LogoImprint.MainLogo;
    imprintData.LogoImprint.MainLogoAttributes =
      custReducer.dataIslandAttributes.LogoImprint.MainLogoAttributes;
  }
  return imprintData;
};

/**
 * Sets the shadow logo attributes on imprint
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateShadowLogoDetails = (imprintData, custReducer) => {
  if (custReducer.shadowLogo) {
    imprintData.LogoImprint.ShadowLogo = custReducer.shadowLogo.LogoFileNumber;
    imprintData.LogoImprint.ShadowLogoAttributes.logo_type = custReducer.shadowLogoOption.LogoType;
    imprintData.LogoImprint.ShadowLogoAttributes.logo_url = custReducer.shadowLogo.Url;
  } else {
    imprintData.LogoImprint.ShadowLogo = custReducer.dataIslandAttributes.LogoImprint.ShadowLogo;
    imprintData.LogoImprint.ShadowLogoAttributes =
      custReducer.dataIslandAttributes.LogoImprint.ShadowLogoAttributes;
  }
  return imprintData;
};

/**
 * Sets the font imprint of the dataisland
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateFontImprint = (imprintData, custReducer) => {
  if (custReducer.selectedFontFamily) {
    const fontFamily = custReducer.selectedFontFamily;
    imprintData.FontImprint.Font = fontFamily;
    imprintData.FontImprint.FontAttributes.font_family = fontFamily;
  }

  if (custReducer.selectedFontColor === 'DoubleColor') {
    imprintData.FontImprint.FontAttributes.font_color = getHexByColor(custReducer.companyInfoColor);
  } else {
    imprintData.FontImprint.FontAttributes.font_color = getHexByColor(
      custReducer.selectedFontColor,
    );
  }

  return imprintData;
};

/**
 * Sets the font family attributes of the dataisland
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyAttributesWithFontFamily = (imprintData, custReducer) => {
  if (custReducer.selectedFontFamily) {
    const fontFamily = custReducer.selectedFontFamily;
    imprintData.CompanyImprint.CompanyNameAttributes.font_family = fontFamily;
    imprintData.CompanyImprint.AddressLine1Attributes.font_family = fontFamily;
    imprintData.CompanyImprint.AddressLine2Attributes.font_family = fontFamily;
    if (imprintData.CompanyImprint.AddressLine3Attributes) {
      imprintData.CompanyImprint.AddressLine3Attributes.font_family = fontFamily;
    }
    imprintData.CompanyImprint.StateAttributes.font_family = fontFamily;
    imprintData.CompanyImprint.ZipAttributes.font_family = fontFamily;
    imprintData.CompanyImprint.PhoneAttributes.font_family = fontFamily;
    imprintData.CompanyImprint.ExtraLineAttributes.font_family = fontFamily;
  }

  return imprintData;
};

/**
 * Sets the font color attributes of the dataisland
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyAttributesWithFontColor = (imprintData, custReducer) => {
  let fontColor;
  if (custReducer.selectedFontColor === 'DoubleColor') {
    fontColor = getHexByColor(custReducer.companyInfoColor);
  } else {
    fontColor = getHexByColor(custReducer.selectedFontColor);
  }

  imprintData.CompanyImprint.CompanyNameAttributes.font_color = fontColor;
  imprintData.CompanyImprint.AddressLine1Attributes.font_color = fontColor;
  imprintData.CompanyImprint.AddressLine2Attributes.font_color = fontColor;
  if (imprintData.CompanyImprint.AddressLine3Attributes) {
    imprintData.CompanyImprint.AddressLine3Attributes.font_color = fontColor;
  }
  imprintData.CompanyImprint.StateAttributes.font_color = fontColor;
  imprintData.CompanyImprint.ZipAttributes.font_color = fontColor;
  imprintData.CompanyImprint.PhoneAttributes.font_color = fontColor;

  // Not all products have extralineattribute present in the dataisland
  if (imprintData.CompanyImprint.ExtraLineAttributes)
    imprintData.CompanyImprint.ExtraLineAttributes.font_color = fontColor;

  return imprintData;
};

/**
 * Sets your_name extra imprint line attribute
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateYourName = (imprintData, custReducer) => {
  imprintData.ExtraImprintLines = custReducer.dataIslandAttributes.ExtraImprintLines;

  if (custReducer.yourName) {
    imprintData.ExtraImprintLines = {
      [custReducer.yourName]: {
        default_text: 'Your Name',
        text_align: 'justify',
        font_weight: 'normal',
        font_size: '11',
        font_family: 'helvetica',
        font_color: '#000000',
        default_value: 'Your Name',
        dirty: '1',
      },
    };
  }

  return imprintData;
};

/**
 * Sets the voucher line attribute with extra line 2 value
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyExtraLine2ToVoucherLine = (imprintData, custReducer) => {
  imprintData.VoucherLine = custReducer.company.ExtraLine2;

  if (imprintData.VoucherLineAttributes) {
    imprintData.VoucherLineAttributes = {
      ...imprintData.VoucherLineAttributes,
      font_weight: custReducer.company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
    };
  }

  if (imprintData.VoucherLineAttributes) {
    imprintData.VoucherLineAttributes = {
      ...imprintData.VoucherLineAttributes,
      font_color: getHexByColor(custReducer.selectedFontColor),
    };
  }
  return imprintData;
};

/**
 * Sets the Imprint line attribute with extra line 2 value
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyExtraLine2 = (imprintData, custReducer) => {
  imprintData.ExtraImprintLines = custReducer.dataIslandAttributes.ExtraImprintLines;

  if (custReducer.company.ExtraLine2) {
    imprintData.ExtraImprintLines = {
      [custReducer.company.ExtraLine2]: {
        default_text: custReducer.company.ExtraLine2,
        text_align: 'justify',
        font_weight: custReducer.company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
        font_size: custReducer.company.ExtraLine2Attribute.FontSize,
        font_family: 'helvetica',
        font_color: '#000000',
        default_value: 'Extra Line (Optional)',
        dirty: '1',
      },
    };
  }
  return imprintData;
};

/**
 * Sets the Imprint line attribute with extra line 2 value only
 * removing other extra line value (line 6 to 12)
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyExtraLine2WithoutOtherExtraLines = (imprintData, custReducer) => {
  if (custReducer.company.ExtraLine2) {
    imprintData.ExtraImprintLines = {
      [custReducer.company.ExtraLine2]: {
        default_text: custReducer.company.ExtraLine2,
        text_align: 'justify',
        font_weight: custReducer.company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
        font_size: custReducer.company.ExtraLine2Attribute.FontSize,
        font_family: 'helvetica',
        font_color: '#000000',
        default_value: 'Extra Line (Optional)',
        dirty: '1',
      },
    };
  } else {
    imprintData.ExtraImprintLines = {
      'Extra Line (Optional)': {
        default_text: 'Extra Line (Optional)',
        text_align: 'center',
        font_weight: 'normal',
        font_size: '8',
        font_family: 'helvetica',
        font_color: '#000000',
        default_value: 'Extra Line (Optional)',
      },
    };
  }
  return imprintData;
};
/**
 * When the product supports different color to different attributes like, company info, logo and shadow logo.
 * This functions sets the color attributes on the dataisland.
 * Example, Invoices.
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateColorImprintAttributes = (imprintData, custReducer) => {
  if (custReducer.selectedFontColor === 'DoubleColor') {
    imprintData.ColorCount = 2;
    imprintData.ColorCountAttributes.dirty = '1';
    imprintData.Color1 = '#000000'; // for dual colors first color is always black
    imprintData.Color1Attributes.dirty = '1';
    imprintData.Color2 = getHexByColor(custReducer.secondaryColor);
    imprintData.Color2Attributes.dirty = '1';
  } else if (custReducer.selectedFontColor !== 'Black') {
    imprintData.ColorCount = 1;
    imprintData.ColorCountAttributes.dirty = '1';
    imprintData.Color1 = getHexByColor(custReducer.selectedFontColor);
    imprintData.Color1Attributes.dirty = '1';
    imprintData.Color2 = '';
    imprintData.Color2Attributes.dirty = '1';
  } else {
    imprintData.ColorCount = 0;
    imprintData.ColorCountAttributes.dirty = '1';
    imprintData.Color1 = '';
    imprintData.Color1Attributes.dirty = '1';
    imprintData.Color2 = '';
    imprintData.Color2Attributes.dirty = '1';
  }
  return imprintData;
};

/**
 * When the product supports different color to different attributes like, company info, logo and shadow logo.
 * This functions sets the logo, shadow logo attributes on the dataisland.
 * Example, Invoices.
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateLogoColor = (imprintData, custReducer) => {
  if (custReducer.logo) {
    if (custReducer.selectedFontColor === 'DoubleColor') {
      imprintData.LogoImprint.MainLogoAttributes.logo_color = getHexByColor(custReducer.logoColor);
    } else {
      imprintData.LogoImprint.MainLogoAttributes.logo_color = getHexByColor(
        custReducer.selectedFontColor,
      );
    }
  }

  if (custReducer.shadowLogo) {
    if (custReducer.selectedFontColor === 'DoubleColor') {
      imprintData.LogoImprint.ShadowLogoAttributes.logo_color = getHexByColor(
        custReducer.shadowLogoColor,
      );
    } else {
      imprintData.LogoImprint.ShadowLogoAttributes.logo_color = getHexByColor(
        custReducer.selectedFontColor,
      );
    }
  }

  return imprintData;
};

/**
 * Sets the company imprint attribute details
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyInfoAttributeDetails = (imprintData, custReducer) => {
  if (imprintData.CompanyImprint.CompanyNameAttributes) {
    imprintData.CompanyImprint.CompanyNameAttributes = {
      ...imprintData.CompanyImprint.CompanyNameAttributes,
      font_size: custReducer.company.CompanyNameAttribute.FontSize,
      font_weight: custReducer.company.CompanyNameAttribute.bold ? 'bold' : 'normal',
    };
  }

  if (imprintData.CompanyImprint.AddressLine1Attributes) {
    imprintData.CompanyImprint.AddressLine1Attributes = {
      ...imprintData.CompanyImprint.AddressLine1Attributes,
      font_size: custReducer.company.AddressLine1Attribute.FontSize,
      font_weight: custReducer.company.AddressLine1Attribute.bold ? 'bold' : 'normal',
    };
  }

  if (imprintData.CompanyImprint.AddressLine2Attributes) {
    imprintData.CompanyImprint.AddressLine2Attributes = {
      ...imprintData.CompanyImprint.AddressLine2Attributes,
      font_size: custReducer.company.AddressLine2Attribute.FontSize,
      font_weight: custReducer.company.AddressLine2Attribute.bold ? 'bold' : 'normal',
    };
  }

  if (imprintData.CompanyImprint.PhoneAttributes) {
    imprintData.CompanyImprint.PhoneAttributes = {
      ...imprintData.CompanyImprint.PhoneAttributes,
      font_size: custReducer.company.PhoneAttribute.FontSize,
      font_weight: custReducer.company.PhoneAttribute.bold ? 'bold' : 'normal',
    };
  }

  if (imprintData.CompanyImprint.ExtraLineAttributes) {
    imprintData.CompanyImprint.ExtraLineAttributes = {
      ...imprintData.CompanyImprint.ExtraLineAttributes,
      font_size: custReducer.company.ExtraLineAttribute.FontSize,
      font_weight: custReducer.company.ExtraLineAttribute.bold ? 'bold' : 'normal',
    };
  }

  // if (imprintData.CompanyImprint.ExtraLine2Attributes) {
  //   imprintData.CompanyImprint.ExtraLine2Attributes = {
  //     ...imprintData.CompanyImprint.ExtraLine2Attributes,
  //     font_size: custReducer.company.ExtraLine2Attribute.FontSize,
  //     font_weight: custReducer.company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
  //   };
  // }

  return imprintData;
};

/**
 * Sets the company imprint attribute details for text align
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateCompanyInfoAttributeAlignment = (imprintData, custReducer) => {
  if (imprintData.CompanyImprint.CompanyNameAttributes) {
    imprintData.CompanyImprint.CompanyNameAttributes = {
      ...imprintData.CompanyImprint.CompanyNameAttributes,
      text_align: custReducer.logo && custReducer.logo.LogoFileNumber ? 'left' : 'center',
    };
  }

  if (imprintData.CompanyImprint.AddressLine1Attributes) {
    imprintData.CompanyImprint.AddressLine1Attributes = {
      ...imprintData.CompanyImprint.AddressLine1Attributes,
      text_align: custReducer.logo && custReducer.logo.LogoFileNumber ? 'left' : 'center',
    };
  }

  if (imprintData.CompanyImprint.AddressLine2Attributes) {
    imprintData.CompanyImprint.AddressLine2Attributes = {
      ...imprintData.CompanyImprint.AddressLine2Attributes,
      text_align: custReducer.logo && custReducer.logo.LogoFileNumber ? 'left' : 'center',
    };
  }

  if (imprintData.CompanyImprint.PhoneAttributes) {
    imprintData.CompanyImprint.PhoneAttributes = {
      ...imprintData.CompanyImprint.PhoneAttributes,
      text_align: custReducer.logo && custReducer.logo.LogoFileNumber ? 'left' : 'center',
    };
  }

  if (imprintData.CompanyImprint.ExtraLineAttributes) {
    imprintData.CompanyImprint.ExtraLineAttributes = {
      ...imprintData.CompanyImprint.ExtraLineAttributes,
      text_align: custReducer.logo && custReducer.logo.LogoFileNumber ? 'left' : 'center',
    };
  }

  // if (imprintData.CompanyImprint.ExtraLine2Attributes) {
  //   imprintData.CompanyImprint.ExtraLine2Attributes = {
  //     ...imprintData.CompanyImprint.ExtraLine2Attributes,
  //     font_size: custReducer.company.ExtraLine2Attribute.FontSize,
  //     font_weight: custReducer.company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
  //   };
  // }

  return imprintData;
};

/**
 * Update Check starting Number
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const checkStartingNumber = (imprintData, custReducer) => {
  imprintData.CheckStartingNumber = custReducer.checkStartingNumber.csn;
  imprintData.CheckStartingNumberAttributes.default_text = custReducer.checkStartingNumber.csn;
  const isAlphaNumeric = is_AlphaNumeric(custReducer.checkStartingNumber.csn);
  if (isAlphaNumeric) {
    imprintData.CheckStartingNumberAttributes.alpha_numbering = 'Y';
  } else {
    imprintData.CheckStartingNumberAttributes.alpha_numbering = 'N';
  }
  if (custReducer.checkStartingNumber.reverseNumbering === 'Y') {
    imprintData.BankImprint.ReverseNumbering = 'Y';
  }
  return imprintData;
};

/**
 * Update matching envelopes
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const hasMatchingEnvelopes = (imprintData, custReducer) => {
  imprintData.HasMatchingEnvelop = custReducer.matchingEnv.envRequired === 'Y';
  imprintData.HasMatchingEnvelopAttributes = {
    default_value: 'Y',
  };
  return imprintData;
};

/**
 * Update fdsresp
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateFdsResp = (imprintData, custReducer) => {
  imprintData.fdsRespn = custReducer.fdsRespn;
  return imprintData;
};

/**
 * Update matching envelopes
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const updateSignatureLInes = (imprintData, custReducer) => {
  imprintData.SignatureImprint.LowerTextSignatureLine1 = custReducer.signatureLines.line1;
  imprintData.SignatureImprint.UpperTextSignatureLine1 = custReducer.signatureLines.line2;
  imprintData.SignatureImprint.SignatureLines = custReducer.signatureLines.totalLines;

  return imprintData;
};
/**
 * Update Account opened data only for personal Check
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const accountOpenData = (imprintData, custReducer) => {
  if (
    custReducer.accountOpenDate &&
    custReducer.accountOpenDate.month !== '00' &&
    custReducer.accountOpenDate.year !== 'Year'
  ) {
    imprintData.OpeningDate = `${custReducer.accountOpenDate.month}/${custReducer.accountOpenDate.year}`;
    imprintData.OpeningDateAttributes.month = custReducer.accountOpenDate.month;
    imprintData.OpeningDateAttributes.year = custReducer.accountOpenDate.year;
  } else {
    imprintData.OpeningDate = '';
  }
  return imprintData;
};

/**
 * Updates the basic data island properties like SKU, sheets and bundle
 * @param {object} imprintData dataisland copy to send to server on save
 * @param {object} custReducer reducer to map the values to imprint
 */
const upgradeDataIslandImprint = (imprintData, custReducer, pfId) => {
  imprintData.DataIslandAttributes.Sheets = custReducer.sheets;
  const skuQuantity = custReducer.selectedSku && custReducer.selectedSku.split('-')[1];
  const upgradeSku =
    imprintData.DataIslandAttributes.Sku && imprintData.DataIslandAttributes.Sku.split('-')[0];
  imprintData.DataIslandAttributes.Sku = `${upgradeSku}-${skuQuantity}`;
  imprintData.DataIslandAttributes.PrinterType = custReducer.printerType;
  if (pfId) {
    imprintData.DataIslandAttributes.product_spec_id = productSpecId(pfId);
    imprintData.DataIslandAttributes.SigLines = custReducer.signatureLines.totalLines;
    imprintData.DataIslandAttributes.envelope_sku = custReducer.matchingEnv.envelope_sku;
  }
  return imprintData;
};

const upgradeCustomColorAttributes = (imprintData, custReducer, colorMapping) => {
  let upgradeColor =
    custReducer.customColor.customColorValue &&
    custReducer.customColor.customColorValue
      .replace('classic', 'prismatic')
      .replace('prestige', 'prismatic');
  let upgradeImgSrc;
  if (colorMapping) {
    const colorData = colorMapping;
    let matchedData = colorData.find((x) => x.Val === upgradeColor);
    if (matchedData) {
      upgradeColor = matchedData.Val;
      upgradeImgSrc = matchedData.ImageSource;
    } else {
      matchedData = colorData.find((x) => x.Default === true);
      upgradeColor = matchedData.Val;
      upgradeImgSrc = matchedData.ImageSource;
    }
  }
  imprintData.CustomColor = upgradeColor;
  imprintData.CustomColorAttributes.style_url = upgradeImgSrc;
  return imprintData;
};

export const cloneImprintDataForValuePacks = (pfid, custReducer, imprintData) => {
  let imprintCopy = JSON.parse(JSON.stringify(imprintData));
  switch (pfid) {
    case 'DEP-02':
    case 'DEP-01':
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      break;

    case 'STAMP-19':
      imprintCopy = endorsementStampUpdate(imprintCopy, custReducer);
      break;

    default:
      break;
  }
  // Call imprint clean up - to remove unnecessary attributes
  imprintCopy = cleanImprintDetails(imprintCopy);
  return imprintCopy;
};

// This function updates the micr and fractional attributes for deposit slips in value packs -  UBERPSG-13159
export const updateDepositSlipsMicrForValuePacks = (imprintData, bankData) => {
  // bank imprint
  imprintData.BankImprint.Micr = bankData.micr;
  imprintData.BankImprint.MicrAttributes.micr_mask = bankData.micr_mask;
  imprintData.BankImprint.Fractional = bankData.fractional ? bankData.fractional : null;
  imprintData = cleanImprintDetails(imprintData);
  return imprintData;
};

export const cloneImprintDataForUpgradeChecks = (pfid, custReducer, imprintData, colorData) => {
  let imprintCopy = JSON.parse(JSON.stringify(imprintData));
  switch (pfid) {
    case 'CHECK-11-ult':
      // upgrade
      imprintCopy = upgradeDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      // Upgrade
      imprintCopy = upgradeCustomColorAttributes(imprintCopy, custReducer, colorData);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-16-ult':
      // upgrade
      imprintCopy = upgradeDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      // upgrade
      imprintCopy = upgradeCustomColorAttributes(imprintCopy, custReducer, colorData);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-12':
      // upgrade
      imprintCopy = upgradeDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      // upgrade
      imprintCopy = upgradeCustomColorAttributes(imprintCopy, custReducer, colorData);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-17':
      // upgrade
      imprintCopy = upgradeDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      // upgrade
      imprintCopy = upgradeCustomColorAttributes(imprintCopy, custReducer, colorData);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-14':
      // upgrade
      imprintCopy = upgradeDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      // upgrade
      imprintCopy = upgradeCustomColorAttributes(imprintCopy, custReducer, colorData);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    default:
      break;
  }
  // Call imprint clean up - to remove unnecessary attributes
  imprintCopy = cleanImprintDetails(imprintCopy);
  return imprintCopy;
};

export const generateImprintDataFromDataIslandData = (pfid, custReducer, imprintData) => {
  let imprintCopy;
  if (imprintData) {
    imprintCopy = JSON.parse(JSON.stringify(imprintData));
  } else {
    imprintCopy = JSON.parse(JSON.stringify(custReducer.imprintDetails));
  }
  switch (pfid) {
    case 'DEP-02':
    case 'DEP-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      break;

    case 'STAMP-19':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = endorsementStampUpdate(imprintCopy, custReducer);
      break;

    case 'STAMP-03':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      break;

    case 'STAMP-04':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      break;

    case 'STAMP-06':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCustomCompanyAttributes(imprintCopy, custReducer);
      imprintCopy = updateYourName(imprintCopy, custReducer);
      break;

    case 'STATE-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      break;

    case 'STATE-02':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyExtraLine2ToVoucherLine(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      break;

    case 'INV-01':
    case 'INV-02':
    case 'INV-03':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      break;

    case 'ENV-07':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      break;

    case 'ENV-05':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      break;

    case 'LME-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = updateCompanyExtraLine2(imprintCopy, custReducer);

      break;

    case 'MULTIFORM-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyExtraLine2WithoutOtherExtraLines(imprintCopy, custReducer);
      break;

    case 'DEP-ADV-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeDetails(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = updateCompanyExtraLine2(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);

      break;

    case 'CHECK-11-ult':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-02-ult':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-16-ult':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateVoucherLine(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-17':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-12':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-09':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-14':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = hasMatchingEnvelopes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'MCHECK-01':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateBankInfoAttribute(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-04':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateFontImprint(imprintCopy, custReducer);
      imprintCopy = updateLogoColor(imprintCopy, custReducer);
      imprintCopy = updateShadowLogoDetails(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontFamily(imprintCopy, custReducer);
      imprintCopy = updateCompanyAttributesWithFontColor(imprintCopy, custReducer);
      imprintCopy = updateColorImprintAttributes(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateCompanyInfoAttributeAlignment(imprintCopy, custReducer);
      imprintCopy = updateBankInfoAttribute(imprintCopy, custReducer);
      imprintCopy = updateSignatureLInes(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    case 'CHECK-15':
      imprintCopy = UpdateDataIslandImprint(imprintCopy, custReducer, pfid);
      imprintCopy = updateCompanyImprint(imprintCopy, custReducer);
      imprintCopy = updateBankImprint(imprintCopy, custReducer);
      imprintCopy = customColorAttributes(imprintCopy, custReducer);
      imprintCopy = checkStartingNumber(imprintCopy, custReducer);
      imprintCopy = updateBankInfoAttribute(imprintCopy, custReducer);
      imprintCopy = accountOpenData(imprintCopy, custReducer);
      imprintCopy = updateFdsResp(imprintCopy, custReducer);

      break;

    default:
      break;
  }
  // Call imprint clean up - to remove unnecessary attributes
  imprintCopy = cleanImprintDetails(imprintCopy);
  return imprintCopy;
};

export const formatStandardImageUrl = (url) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return `https://qdcstage4.intuitmarket.intuit.com${url}`;
  }
  if (url && url.indexOf(window.location.origin) > -1) {
    return `${url}`;
  }
  return `${window.location.origin}${url}`;
};

export const getSelectedFontColorValue = (imprintDetails) => {
  if (imprintDetails.ColorCount === 2) {
    return 'DoubleColor';
  }

  return getColorFromHex(imprintDetails.FontImprint.FontAttributes.font_color);
};

export const getSecondaryColor = (imprintDetails) => {
  if (imprintDetails.ColorCount === 2) {
    return getColorFromHex(imprintDetails.Color2);
  }

  return '';
};

export const formatLogoUrl = (logo, logoColor, selectedFontColor) => {
  const url = new URL(formatStandardImageUrl(logo.Url));
  url.searchParams.set('color', getHexByColor(logoColor || selectedFontColor).replace('#', ''));

  return url.toString();
};

export const formatShadowLogoUrl = (shadowLogo, shadowLogoColor, selectedFontColor) => {
  let shadowLogoUrl = shadowLogo.Url;
  shadowLogoUrl = shadowLogoUrl
    ? shadowLogoUrl.slice(0, shadowLogoUrl.indexOf('&opacity=15'))
    : shadowLogoUrl;
  const url = new URL(formatStandardImageUrl(shadowLogoUrl));
  url.searchParams.set('width', '360');
  url.searchParams.set('height', '216');
  url.searchParams.set(
    'color',
    getHexByColor(shadowLogoColor || selectedFontColor).replace('#', ''),
  );

  return url.toString();
};

export const reactCustomizationUrl = {
  'DEP-02': '/deposit-slips/printable/customization',
  'DEP-01': '/deposit-slips/manual/customization',
  'STAMP-19': '/stampsandlabels/endorsement-stamps/customization',
  'STAMP-03': '/stampsandlabels/return-address-stamps/customization',
  'STAMP-04': '/stampsandlabels/monogram-stamps/customization',
  'STAMP-06': '/stampsandlabels/fax-cover-stamps/customization',
  'STATE-01': '/supplies/forms/business-statements/customer/customization',
  'STATE-02': '/supplies/forms/business-statements/remittance/customization',
  'INV-01': '/supplies/forms/invoices/product/customization',
  'INV-02': '/supplies/forms/invoices/service/customization',
  'INV-03': '/supplies/forms/invoices/professional/customization',
  'ENV-07': '/envelopes/custom-printed-envelopes/business-reply-envelopes/customization',
  'ENV-05': '/envelopes/custom-printed-envelopes/single-window-form-envelopes/customization',
  'LME-01': '/envelopes/custom-printed-envelopes/large-mailing-envelopes/customization',
  'MULTIFORM-01': '/supplies/forms/multi-purpose-forms/multi-purpose-forms/customization',
  'DEP-ADV-01': '/checks/direct-deposit-forms/customization',
  'CHECK-11': '/checks/voucher-checks/secure-plus-voucher-checks/customization',
  'CHECK-16': '/checks/voucher-checks/secure-premier-voucher-checks/customization',
  'CHECK-02': '/checks/voucher-checks/basic-voucher-checks/customization',
  'CHECK-12': '/checks/standard-checks/secure-plus-standard-business-checks/customization',
  'CHECK-17': '/checks/standard-checks/secure-premier-standard-business-checks/customization',
  'CHECK-01': '/checks/standard-checks/basic-standard-business-checks/customization',
  'CHECK-14': '/checks/wallet-checks/secure-plus-wallet-checks/customization',
  'CHECK-09': '/checks/wallet-checks/basic-wallet-checks/customization',
  'MCHECK-01': '/checks/manual-checks/customization',
  'CHECK-04': '/checks/office-away-checks/customization',
  'CHECK-15': '/checks/personal-checks/customization',
  'BUNDLE-01': '/checks/voucher-check-value-pack/customization',
  'BUNDLE-02': '/checks/basic-check-value-pack/customization',
  'BUNDLE-03': '/checks/standard-value-pack/customization',
  'BUNDLE-06': '/checks/secure-plus-check-value-pack/customization',
  'BUNDLE-07': '/checks/secure-premier-check-value-pack/customization',
};

export const copyImprintCustomizationWorkflows = ['orderhistory', 'previousorder'];

export const validateCheckStartNumber = (csn, MinLength, MaxLength, PFID) => {
  let errorMsg = '';
  const isNumeric = /^[0-9]+$/.test(csn);
  const isAlphaNumeric = isValidAlphaNumeric(csn);
  if (PFID === 'CHECK-15') {
    if (!isNumeric || csn.length < MinLength || csn.length > MaxLength || parseInt(csn) <= 100) {
      errorMsg = 'CSN_Missing_PersonalCheck';
    }
    return errorMsg;
  }

  if (PFID === 'CHECK-14' || PFID === 'CHECK-09') {
    if (!isNumeric || csn.length < MinLength || csn.length > MaxLength || parseInt(csn) <= 100) {
      errorMsg = 'CSN_3_6_Digits';
    }
    return errorMsg;
  }

  if (isNumeric) {
    if (csn && csn.length >= MinLength && csn.length <= MaxLength - 2 && parseInt(csn) > 100) {
      errorMsg = '';
      // validCSN = true;
    } else {
      errorMsg = 'CSN_Missing';
    }
  } else if (isAlphaNumeric) {
    errorMsg = '';
  } else {
    errorMsg = 'CSN_AlphaNumeric';
  }
  return errorMsg;
};

export const composeMicrInvalidBank = (tmpStartNo, acctNumber, routingNumber) => {
  let micr = '';
  tmpStartNo = tmpStartNo
    .toString()
    .replace(/[^0-9]/g, '')
    .split('');
  if (acctNumber) {
    const lenStartNo = tmpStartNo.length; //  tmpStartNo is array till here, convert to string after this
    tmpStartNo = tmpStartNo.toString().replace(/,/g, '');
    switch (lenStartNo) {
      case 0:
        tmpStartNo = '000000';
        break;
      case 3:
        tmpStartNo = `000${tmpStartNo}`;
        break;
      case 4:
        tmpStartNo = `00${tmpStartNo}`;
        break;
      case 5:
        tmpStartNo = `0${tmpStartNo}`;
        break;
      default:
        break;
    }
    micr = `${tmpStartNo}SSA${routingNumber}AS${acctNumber}`;
  }
  return micr;
};

export const getBundlePfidFromSku = (sku) => {
  let pf_id;
  let splitSku;
  if (sku) {
    splitSku = sku.split('-');
    splitSku = splitSku ? splitSku[0] : '';
  }
  switch (splitSku) {
    case 'B1':
      pf_id = 'BUNDLE-01';
      break;
    case 'B2':
      pf_id = 'BUNDLE-02';
      break;
    case 'B3':
      pf_id = 'BUNDLE-03';
      break;
    case 'B6':
      pf_id = 'BUNDLE-06';
      break;
    case 'B7':
      pf_id = 'BUNDLE-07';
      break;
    default:
      break;
  }
  return pf_id;
};
