// eslint-disable-next-line require-jsdoc
import React, { useEffect } from 'react';
// import Radio, { RadioGroup } from '@ids/radio';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@ids-beta/tooltip';
import { CircleInfoIcon } from '@ids-ts/icon';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
// eslint-disable-next-line require-jsdoc
export const ColorToggle = (props) => {
  const { ColorToggler, Title, TooltipInfo, PFID } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { customColor, selectedSku } = customizationReducer;
  const colorDetail = [];
  colorDetail.push(ColorToggler[0]);

  const colorData =
    PFID === 'CHECK-02-ult' && selectedSku && selectedSku.split('-')[1] === '00050'
      ? colorDetail
      : ColorToggler;
  const dispatch = useDispatch();

  const handleColorChange = (_, colrVal) => {
    const defaultValue = colorData.find((x) => x.Val === colrVal);
    // const imageData = value;
    // dispatch({ type: ReduxEvent.CUSTOM_IMAGE_SELECTED, data: { imageData } });
    dispatch({
      type: ReduxEvent.CUSTOM_IMAGE_SELECTED,
      data: {
        imageData: defaultValue.ImageSource,
        customColorValue: defaultValue.Val,
      },
    });
  };

  useEffect(() => {
    const defaultValue = colorData.find((x) => x.Default);
    const imageData =
      colorData.length !== 1 && customColor.selectedColorPickerImage !== ''
        ? customColor.selectedColorPickerImage
        : defaultValue.ImageSource;
    const imageName =
      colorData.length !== 1 && customColor.customColorValue !== ''
        ? customColor.customColorValue
        : defaultValue.Val;

    dispatch({
      type: ReduxEvent.CUSTOM_IMAGE_SELECTED,
      data: {
        imageData,
        customColorValue: imageName,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        {Title}{' '}
        {TooltipInfo && (
          <Tooltip message={<div>{TooltipInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <ToggleButtonGroup
        value={customColor.customColorValue}
        exclusive
        onChange={handleColorChange}
        aria-label="color picker"
        className="cust-color-picker"
      >
        {colorData.map((el, key) => {
          return (
            <ToggleButton
              key={key}
              value={el.Val}
              aria-label="centered"
              data-wa-link={el.Key}
              className="color-toggler"
              data-ui-object="segmented_button"
              data-refer-ui-access-point="customization_page | color_toggle"
              data-ui-object-details="Custom Color Picker"
            >
              <Tooltip
                role="tooltip"
                automationId="tooltip_custom"
                message={
                  <div>
                    <span>{el.Key}</span>
                  </div>
                }
              >
                <img src={el.Imageurl} alt="Select product color" />
              </Tooltip>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};
