// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/stamps.scss';

// eslint-disable-next-line require-jsdoc
export const ReturnAddressStampImage = (props) => {
  const { company, fromModal, image } = props;

  return (
    <div
      className={
        !fromModal ? 'endorsement-stamp-image-container' : 'endorsement-stamp-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      <div className="image-company-info">
        <div>{company.CompanyName}</div>
        <div>{company.AddressLine1}</div>
        <div>{company.AddressLine2}</div>
        <div>{company.Phone}</div>
      </div>
    </div>
  );
};
