import React from 'react';
import allEnvChkEnv from '../../images/IM/Envelopes/AllEnvelopes-CheckEnvelopes.png';
import allEnvFrmEnv from '../../images/IM/Envelopes/AllEnvelopes-FormEnvelopes.png';
import allEnvCusEnv from '../../images/IM/Envelopes/AllEnvelopes-CustomEnvelopes.png';
import allEnvRtrnFilEnv from '../../images/IM/Envelopes/6x9_ReturnFiling_lg.png';
import allEnv115FilEnv from '../../images/IM/Envelopes/9-5x11-5_ReturnFiling_sm.jpg';
import selfSeal from '../../images/IM/Envelopes/LU_image_page_selfseal.png';
import stdChk from '../../images/IM/Envelopes/LU_image_page_standard.png';
import wallChk from '../../images/IM/Envelopes/LU_image_page_walletcheck.png';
import wallChkPlus from '../../images/IM/Envelopes/LU_image_page_walletcheckplus.png';
import chkIllustration from '../../images/IM/Envelopes/CH_illustration_page_envelopes.png';
import frmSelfSeal from '../../images/IM/Envelopes/LU_image_page_selfsealforms.png';
// import frmSelfSeal from '../images/IM/Envelopes/LU_image_page_selfse6x9_ReturnFiling_sm6x9_ReturnFiling_smalforms.png';
import frmEnv from '../../images/IM/Envelopes/LU_image_page_forms.png';
import sinWinFrmEnv from '../../images/IM/Envelopes/LU_image_page_singlewindowform.png';
import cusEnvBusReply from '../../images/IM/Envelopes/LU_image_page_busreply.png';
import sinWinLarge from '../../images/IM/Envelopes/LU_image_page_largemailing.png';
// tax organizer
import ssStandVoucher120 from '../../images/IM/Envelopes/env_ss_dw_stand_vouch_120.gif';
import ssStandVoucher340 from '../../images/IM/Envelopes/env_ss_dw_stand_vouch_340.gif';
import dwStandVouChk120 from '../../images/IM/Envelopes/env_dw_stand_vouch_120.gif';
import dwStandVouChk340 from '../../images/IM/Envelopes/env_dw_stand_vouch_340.gif';
import dwWallet120 from '../../images/IM/Envelopes/env_dw_wallet_120.gif';
import dwWallet340 from '../../images/IM/Envelopes/env_dw_wallet_340.gif';
import dwWalletP120 from '../../images/IM/Envelopes/env_dw_walletplus_120.gif';
import dwWalletP340 from '../../images/IM/Envelopes/env_dw_walletplus_340.gif';
import frmEnv120 from '../../images/IM/Envelopes/env_dw_forms_120.gif';
import frmEnv340 from '../../images/IM/Envelopes/env_dw_forms_340.gif';
import swForms120 from '../../images/IM/Envelopes/env_sw_forms_120.gif';
import swForms340 from '../../images/IM/Envelopes/env_sw_forms_340.gif';
import bizRep120 from '../../images/IM/Envelopes/env_biz_reply_120.gif';
import bizRep340 from '../../images/IM/Envelopes/env_biz_reply_340.gif';
import envLrg120 from '../../images/IM/Envelopes/env_lg_120.gif';
import envLrg340 from '../../images/IM/Envelopes/env_lg_340.gif';
import ssDwForm120 from '../../images/IM/Envelopes/env_ss_dw_forms_120.gif';
import ssDwForm340 from '../../images/IM/Envelopes/env_ss_dw_forms_340.gif';

const EnvelopesPageData = {
  ENVELOPES: {
    Department: {
      DeptName: 'QuickBooks Business Envelopes',
      DeptDesc:
        'Business envelopes available in various sizes for invoices, forms, and correspondence. Designed for optimal compatibility with QuickBooks products, these envelopes provide a professional business appearance. Get blank or customized envelopes with your company’s logo and information. Envelopes come with industry-recognized security features so you can mail with confidence.',
      DeptId: '',
      FetchPricing: false,
      RequestBody: null,
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Checks Envelopes',
            ProductDesc:
              'QuickBooks business envelopes are available in a variety of sizes and styles. Made specifically for QuickBooks checks and forms, these self-seal business envelopes with windows hide sensitive information and include built-in security tabs to identify tampering.',
            ImageSrc: allEnvChkEnv,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/envelopes/check-window',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/envelopes/check-window',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Forms Envelopes',
            ProductDesc:
              'Deliver your forms in a professional, secure envelope. Give your business a polished appearance with envelopes designed for QuickBooks products.',
            ImageSrc: allEnvFrmEnv,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/envelopes/form-envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/envelopes/form-envelopes',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'QuickBooks Custom Printed Envelopes',
            ProductDesc:
              "QuickBooks custom-printed business envelopes come in a variety of sizes and types. Choose the style and size, then add your business’ logo, name, and address to reinforce your company's brand identity and professional image",
            ImageSrc: allEnvCusEnv,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/envelopes/custom-printed-envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/envelopes/custom-printed-envelopes',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Tax Filing Envelopes',
            ProductDesc:
              "Save time by using tax filing envelopes designed to work perfectly with slip sheets printed from your software. Ensure that your clients' returns are mailed to the correct filing center, without printing multiple address labels.",
            ImageSrc: allEnvRtrnFilEnv,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/tax-products/tax-filing-envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/tax-products/tax-filing-envelopes',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Tax Organizer Envelopes',
            ProductDesc:
              'We offer a variety of envelopes that are slip-sheet compatible or pre-printed.',
            ImageSrc: allEnv115FilEnv,
            ImageLrg: null,
            ButtonText: 'View all',
            LearnMoreLink: '/tax-products/organizer-envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/tax-products/organizer-envelopes',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: null,
          },
        ],
      },
    ],
  },
  CHECKS_ENVELOPES: {
    Department: {
      DeptName: 'QuickBooks checks envelopes',
      DeptDesc:
        'QuickBooks business window envelopes are available in a variety of sizes and styles. Made specifically for QuickBooks checks and forms, these self-seal business envelopes with windows hide sensitive information and include built-in security tabs to identify tampering.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['ENV-08', 'ENV-01', 'ENV-02', 'ENV-03'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Self-seal Window Envelopes for QuickBooks Checks',
            ProductDesc:
              'Save time and look professional with double window check envelopes featuring self-sealing adhesive. Built-in security tabs aid in identifying instances of envelope tampering. The dimensions allow QuickBooks Standard and Voucher checks to align perfectly inside the envelope.',
            ImageSrc: selfSeal,
            ImageLrg: null,
            ButtonText: 'Add to cart',
            LearnMoreLink: '/envelopes/check-window/self-seal-voucher',
            LearnMoreSuffixText: 'Self-seal Window Envelopes',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-08',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Standard Voucher Check Envelopes',
            ProductDesc:
              'Perfectly align Standard and Voucher checks with the check envelope double windows. Avoid the hassle of manually addressing envelopes.',
            ImageSrc: stdChk,
            ImageLrg: null,
            ButtonText: 'Add to cart',
            LearnMoreLink: '/envelopes/check-window/voucher-checks',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-01',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Wallet Check Envelopes',
            ProductDesc:
              'Checks are held in place with a tab to ensure precise alignment. Eliminate the need to hand-write the return address with these window envelopes for QuickBooks checks.',
            ImageSrc: wallChk,
            ImageLrg: null,
            ButtonText: 'Add to cart',
            LearnMoreLink: '/wce-details',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: '738',
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-02',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Wallet Plus Check Envelopes',
            ProductDesc:
              'Save resources with wallet plus envelopes for checks. A standard size envelope with a unique pocket for wallet checks allows you to send other documents in the same envelope.',
            ImageSrc: wallChkPlus,
            ImageLrg: null,
            ButtonText: 'Add to cart',
            LearnMoreLink: '/wpce',
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: '538',
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-03',
          },
        ],
      },
    ],
    FooterSection: {
      Image: chkIllustration,
      Header: [
        'Business size envelope dimensions:',
        'Why QuickBooks check envelopes are different.',
      ],
      Paragraphs: [
        'Standard sized business envelopes don’t fit standard sized checks. Envelopes can be too large, potentially allowing sliding inside the envelope that can compromise security or partially obstruct information.',
        'QuickBooks check envelopes are designed to improve security and work specifically with QuickBooks checks. Slightly smaller than standard #10 business envelopes, our check envelopes are 8 3⁄4” (w) x 3 9/16” (h) in order to fit more snugly and securely within the envelope, eliminating potential movement.',
      ],
    },
  },
  SELF_SEALED_VOUCHER_CHECK_ENVELOPES: {
    Department: {
      DeptName: 'Self Seal Voucher Check Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-08'],
      Image: ssStandVoucher120,
      ImageLg: ssStandVoucher340,
      PFID: 'ENV-08',
      ProductInformation: {
        Desc: (
          <span>
            QuickBooks Standard and Voucher Checks align perfectly with the envelope windows,
            keeping sensitive information from view. Dual adhesive flaps and built-in security tabs
            reveal evidence of tampering.
          </span>
        ),
        DescExtras: [
          <span>
            Self-sealing adhesive is convenient and saves time. Box for easy storage included.
          </span>,
        ],
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 8.75"w x 3.5625"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  STANDARD_AND_COUCHER_CHECK_ENVELOPES: {
    Department: {
      DeptName: 'Standard and Voucher Check Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-01'],
      Image: dwStandVouChk120,
      ImageLg: dwStandVouChk340,
      PFID: 'ENV-01',
      ProductInformation: {
        Desc: (
          <span>
            The dual windows on our Standard Voucher Check Envelopes align perfectly with both
            QuickBooks Standard and Voucher Checks, keeping sensitive information confidential.
          </span>
        ),
        DescExtras: [
          <span>
            Avoid manually addressing envelopes to save time and frustration and project a
            professional image.
          </span>,
        ],
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 8.75"w x 3.5625"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  WALLET_CHECK_ENVELOPES: {
    Department: {
      DeptName: 'Wallet Check Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-02'],
      Image: dwWallet120,
      ImageLg: dwWallet340,
      PFID: 'ENV-02',
      ProductInformation: {
        Desc: (
          <span>
            The built-in tab holds the check in place for perfect alignment and helps keep private
            information covered. Eliminate hand-addressing and pay bills faster.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 6.2188"w x 3.5"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  WALLET_PLUS_CHECK_ENVELOPES: {
    Department: {
      DeptName: 'Wallet Plus Check Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-03'],
      Image: dwWalletP120,
      ImageLg: dwWalletP340,
      PFID: 'ENV-03',
      ProductInformation: {
        Desc: (
          <span>
            Useful for sending other documents in the same envelope. The special pocket holds the
            check in place for perfect alignment and keeps sensitive information hidden. Save time
            and stop hand-addressing.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 8.875"w x 3.875"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  FORM_ENVELOPES: {
    Department: {
      DeptName: 'QuickBooks forms envelopes',
      DeptDesc:
        'QuickBooks business envelopes are available in a variety of sizes, dimensions, and styles. Made specifically for QuickBooks checks and forms while also working with many standard sizes, these self-seal business envelopes with windows hide sensitive information and include built-in security tabs to identify tampering.',
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['ENV-09', 'ENV-04', 'ENV-05'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Self-seal Forms Envelopes',
            ProductDesc:
              'Convenient self-sealing envelopes help you save time and look professional while keeping your sensitive information confidential. Built-in security tabs perfectly align QuickBooks forms and aid in identifying tampering.',
            ImageSrc: frmSelfSeal,
            ImageLrg: null,
            ButtonText: 'Add to cart',
            LearnMoreLink: '/envelopes/form-envelopes/self-seal-form-envelopes',
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-09',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Forms Envelopes',
            ProductDesc:
              'These are a perfect fit for our pre-printed forms or forms printed on plain paper from QuickBooks. No hand-addressing with these either! Handy windows let your logo and company information show through.',
            ImageSrc: frmEnv,
            ImageLrg: null,
            ButtonText: 'Add to cart',
            LearnMoreLink: '/envelopes/form-envelopes/forms-envelopes',
            LearnMoreSuffixText: null,
            ActualPrice: 0,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '',
            Quantity: null,
            SpecialProcessId: 0,
            HasPricing: true,
            PFID: 'ENV-04',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Single Window Form Envelopes',
            ProductDesc:
              'Designed to align perfectly with QuickBooks pre-printed forms and plain paper forms. These custom window envelopes have a single window showing the recipient’s address and your company’s logo and return address printed in the upper left corner.',
            ImageSrc: sinWinFrmEnv,
            ImageLrg: null,
            ButtonText: 'Get started',
            LearnMoreLink: '/envelopes/form-envelopes/single-window-form-envelopes',
            LearnMoreSuffixText: null,
            ActualPrice: 7,
            DiscountPrice: 0,
            Sku: null,
            Quantity: 10,
            HasPricing: false,
            ButtonLink:
              '/envelopes/custom-printed-envelopes/single-window-form-envelopes/customization',
            SpecialProcessId: 0,
            PFID: 'ENV-05',
          },
        ],
      },
    ],
  },
  SELF_SEAL_FORMS_ENVELOPES: {
    Department: {
      DeptName: 'Self-Seal Forms Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-09'],
      Image: ssDwForm120,
      ImageLg: ssDwForm340,
      PFID: 'ENV-09',
      ProductInformation: {
        Desc: (
          <span>
            Envelope windows align perfectly with all QuickBooks forms to maintain the
            confidentiality of sensitive information, whether pre-printed or printed on plain paper.
            Convenient, self-sealing from envelopes save time. Easy to use with postage machines.
          </span>
        ),
        DescExtras: [
          <span>
            Skip hand-addressing altogether - let your logo, company and mailing addresses show
            through the envelope windows.
          </span>,
        ],
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 9.0625"w x 4.125"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  FORMS_ENVELOPES_PRODUCT: {
    Department: {
      DeptName: 'Forms Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-04'],
      Image: frmEnv120,
      ImageLg: frmEnv340,
      PFID: 'ENV-04',
      ProductInformation: {
        Desc: (
          <span>
            Save time with envelopes that are a perfect fit for our pre-printed forms or forms
            printed on plain paper from QuickBooks. Dual windows designed to keep sensitive
            information confidential.
          </span>
        ),
        DescExtras: [
          <span>
            Skip hand-addressing altogether - let your logo, company and mailing addresses show
            through the envelope windows.
          </span>,
        ],
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 9.0625"w x 4.125"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'ADDTOCART',
      },
    },
  },
  SINGLE_WINDOW_FORM_ENVELOPES: {
    Department: {
      DeptName: 'Single-Window Form Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-05'],
      Image: swForms120,
      ImageLg: swForms340,
      PFID: 'ENV-05',
      ProductInformation: {
        Desc: (
          <span>
            Look professional with single window envelopes pre-printed with your company's return
            address and logo. The recipient's mailing address on the QuickBooks form shows through
            the clear window - no need to hand-address. Designed to maintain confidentiality.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 9.0625"w x 4.125"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/envelopes/custom-printed-envelopes/single-window-form-envelopes/customization',
        LinkEnabled: true,
      },
    },
  },
  CUSTOM_ENVELOPES: {
    Department: {
      DeptName: 'QuickBooks custom printed envelopes',
      DeptDesc:
        "QuickBooks custom printed business envelopes come in a variety of sizes and types. Choose the style and size, then add a logo to the name and address to reinforce your company's brand identity and professional image.",
      DeptId: '',
      FetchPricing: true,
      RequestBody: ['ENV-07', 'ENV-05', 'LME-01'],
    },
    ProductSections: [
      {
        SectionHeader: '',
        SectionDesc: '',
        Products: [
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Business Reply Envelopes',
            ProductDesc:
              "Include this pre-printed return envelope with your invoices. You'll look professional and give your customers a convenient way to send their payment quickly.",
            ImageSrc: cusEnvBusReply,
            ImageLrg: null,
            ButtonText: 'Get started',
            LearnMoreLink: '/envelopes/custom-printed-envelopes/business-reply',
            LearnMoreSuffixText: null,
            ActualPrice: 7,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink:
              '/envelopes/custom-printed-envelopes/business-reply-envelopes/customization',
            Quantity: 10,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'ENV-07',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Single Window Form Envelopes',
            ProductDesc:
              'Designed to align perfectly with QuickBooks pre-printed forms and plain paper forms. These custom window envelopes have a single window showing the recipient’s address and your company’s logo and return address printed in the upper left corner.',
            ImageSrc: sinWinFrmEnv,
            ImageLrg: null,
            ButtonText: 'Get started',
            LearnMoreLink: '/envelopes/form-envelopes/single-window-form-envelopes',
            LearnMoreSuffixText: null,
            ActualPrice: 7,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink:
              '/envelopes/custom-printed-envelopes/single-window-form-envelopes/customization',
            Quantity: 10,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'ENV-05',
          },
          {
            ProdDeptId: '',
            ProductId: '',
            ProductName: 'Large Mailing Envelopes with Logo',
            ProductDesc:
              'Large custom printed mailing envelopes are ideal for important documents that should not be folded.',
            ImageSrc: sinWinLarge,
            ImageLrg: null,
            ButtonText: 'Get started',
            LearnMoreLink: '/envelopes/custom-printed-envelopes/large-mailing-envelopes',
            LearnMoreSuffixText: null,
            ActualPrice: 7,
            DiscountPrice: 0,
            Sku: null,
            ButtonLink: '/envelopes/custom-printed-envelopes/large-mailing-envelopes/customization',
            Quantity: 10,
            SpecialProcessId: 0,
            HasPricing: false,
            PFID: 'LME-01',
          },
        ],
      },
    ],
  },
  BUSINESS_REPLY_ENVELOPES: {
    Department: {
      DeptName: 'Business Reply Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['ENV-07'],
      Image: bizRep120,
      ImageLg: bizRep340,
      PFID: 'ENV-07',
      ProductInformation: {
        Desc: (
          <span>
            Enclose a convenient, pre-printed business return envelope with your invoices and get
            paid faster. Our business reply envelopes are specifically designed to work with our
            Remittance Statements.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 8.875"w x 3.875"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/envelopes/custom-printed-envelopes/business-reply-envelopes/customization',
        LinkEnabled: true,
      },
    },
  },
  LARGE_MAILING_ENVELOPES: {
    Department: {
      DeptName: 'Large Mailing Envelopes',
      DeptDesc: null,
      DeptId: '',
      FetchPricing: true, // TODO: Deposit bag needs the drop down values to be populated for add to cart
      RequestBody: ['LME-01'],
      Image: envLrg120,
      ImageLg: envLrg340,
      PFID: 'LME-01',
      Sku: '115GB',
      ProductInformation: {
        Desc: (
          <span>
            Perfect for mailing important documents you don't want to fold. Reinforce your company's
            brand with large envelopes customized with your logo, address, and marketing message.
          </span>
        ),
        DescExtras: null,
        CheckLockProtection: false,
        EachKitContains: null,
        Specifications: ['Size: 9.5"w x 12.5"h'],
        Compatibility: null,
        SpecialNote: null,
      },
      PricingInformation: {
        Type: 'GETSTARTED',
        Link: '/envelopes/custom-printed-envelopes/large-mailing-envelopes/customization',
        LinkEnabled: true,
      },
    },
  },
};

export default EnvelopesPageData;
