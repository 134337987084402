// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/personalchecks.scss';
import { Micr } from '../Common/Micr';

// eslint-disable-next-line require-jsdoc
export const PersonalChecksImage = (props) => {
  const {
    image,
    company,
    customColor,
    fromModal,
    checkStartingNumber,
    selectedFontColor,
    selectedFontFamily,
    companyInfoColor,
    micr,
    fractional,
    bankInformation,
    accountOpenDate,
  } = props;

  return (
    <div
      className={
        !fromModal ? 'personal-checks-image-container' : 'personal-checks-modal-image-container'
      }
    >
      <img
        style={{ maxWidth: '100%' }}
        alt="..."
        src={customColor.selectedColorPickerImage || image}
        name={customColor.customColorValue || ''}
      />
      <div
        style={{ textAlign: 'left' }}
        className={`image-company-info ${
          companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
        } ${selectedFontFamily.toLowerCase()}`}
      >
        <div
          style={{
            fontWeight: 'bold',
          }}
          className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
        >
          {company.CompanyName}
        </div>
        <div className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}>
          {company.AddressLine1}
        </div>
        <div className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}>
          {company.AddressLine2}
        </div>
        <div className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}>
          {company.Phone}
        </div>
        <div className={`${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}>
          {company.ExtraLine}
        </div>
      </div>

      {accountOpenDate.month !== '00' && accountOpenDate.year !== 'year' && (
        <div className="account-open-date">
          {accountOpenDate.month}/{accountOpenDate.year}
        </div>
      )}
      <div className="image-checkStartingNumber-text">
        <div style={{ fontSize: '13px' }}>{checkStartingNumber.csn}</div>
        <div style={{ fontSize: '11px' }}>{fractional}</div>
      </div>
      {micr && (
        <div className="image-micr">
          <Micr micr={micr} />
        </div>
      )}
      <div
        className={`image-bank-info ${selectedFontColor.toLowerCase()} ${selectedFontFamily.toLowerCase()}`}
      >
        <div>
          <strong>{bankInformation.name}</strong>
          <br />
          <strong>{bankInformation.address}</strong>
          <br />
          {`${bankInformation.city}${
            bankInformation.city && (bankInformation.state || bankInformation.zipcode) ? ',' : ''
          } ${bankInformation.state} ${bankInformation.zipcode}`}
        </div>
      </div>
    </div>
  );
};
