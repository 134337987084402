// eslint-disable-next-line require-jsdoc
import React from 'react';
import TextField from '@ids/text-field';
// import { CircleInfoIcon } from '@ids-ts/icon';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
// import { ReduxEvent } from '../../../../assets/pagedata/constant';
// import { CustomTextField } from '../../Common/CustomTextField';

// eslint-disable-next-line require-jsdoc
export const FaxHeaderCompanyInfo = (props) => {
  // const [componayInfo, setCompanyInfo] = useState('');
  const { allCaps } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { company, yourName } = customizationReducer;
  const dispatch = useDispatch();

  const onCompanyInfoChanged = (key, e) => {
    const { value } = e.target;
    if (value.length <= 25)
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
        data: {
          company: {
            ...company,
            [key]: allCaps ? value.toUpperCase() : value,
          },
        },
      });
  };

  const onNameChange = (e) => {
    const { value } = e.target;
    if (value.length <= 25)
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
        data: { yourName: allCaps ? value.toUpperCase() : value },
      });
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">Enter your company information</h3>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Name"
            placeholder="Your Name"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={yourName}
            onChange={onNameChange}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Company"
            placeholder="Company Name"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={company.CompanyName}
            onChange={(e) => onCompanyInfoChanged('CompanyName', e)}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Telephone"
            placeholder="Your Phone"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={company.Phone}
            onChange={(e) => onCompanyInfoChanged('Phone', e)}
          />
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Fax"
            placeholder="Your Fax"
            className="ids-text-field"
            width="100%"
            maxLength="25"
            value={company.ExtraLine}
            onChange={(e) => onCompanyInfoChanged('ExtraLine', e)}
          />
        </div>
      </div>
    </div>
  );
};
