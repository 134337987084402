import React, { useState, useRef, useEffect } from 'react';
import '../../../stylesheets/header.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NAVIGATION } from '../../../assets/pagedata/imheader';
import { shopperDataService } from '../../services/shopperDataService';
import { ReduxEvent } from '../../../assets/pagedata/constant';
import { convertToSnakeCase, removeSessionStorage } from '../Util/CommonUtils';
// eslint-disable-next-line require-jsdoc
const HeaderNavigation = (props) => {
  const mainReducer = useSelector((state) => state.mainReducer);
  const dispatch = useDispatch();
  const [navStatus, setNavStatus] = useState(false);
  const [showDropdown, setDispDropDown] = useState({});
  const [showAccount, setShowAccount] = useState(false);
  const [tabDropdown, setTabDropdown] = useState(true);
  const myTopNav = useRef();
  const history = useHistory();

  const handleCloseAccountDropDown = () => {
    setShowAccount(false);
  };

  const signOut = (e) => {
    e.preventDefault();
    handleCloseAccountDropDown();
    shopperDataService
      .logOut()
      .then(() => {
        props.logToIMWebLogger('Info', 'HeaderNavigation: Logout successful');
        dispatch({
          type: ReduxEvent.SIGN_OUT_SUCCESS,
          data: {
            cartCount: 0,
            login: false,
          },
        });
        removeSessionStorage('ReorderedProduct');
        window.open('/signout', '_self');
      })
      .catch((err) => {
        props.logToIMWebLogger('Error', `HeaderNavigation: Logout Failed. Error: ${err}`);
      });
  };

  const handleClickOutside = (e) => {
    if (myTopNav.current.contains(e.target)) {
      return;
    }
    setNavStatus(false);
  };

  const handleCloseDropDown = () => {
    const obj = {};
    setDispDropDown(obj);
  };
  const navRoute = (e) => {
    handleCloseDropDown();
    if (window.screen.width > '1025') {
      history.push(e.target.value);
    }
  };

  const handleDropdownShow = (e, i) => {
    const obj = {};
    obj[i] = true;
    setDispDropDown(obj);
    if (showAccount) {
      handleCloseAccountDropDown();
    }
  };

  useEffect(() => {
    history.listen(() => {
      setNavStatus(false);
    });
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleCloseDropDown();
        handleCloseAccountDropDown();
        setTabDropdown(false);
      }
      if (event.keyCode === 9) {
        setTabDropdown(true);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const navHandler = () => {
    if (myTopNav.current.style.display === '' || myTopNav.current.style.display === 'none') {
      setNavStatus(true);
    } else {
      setNavStatus(false);
    }
  };

  const handleAccounDropdownShow = () => {
    setShowAccount(true);
    handleCloseDropDown();
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setTabDropdown(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });

  return (
    <div className="container header-container-navigation">
      <div id="navigation" className={`im-info ${props.isStickyHeader ? ' border-invisible' : ''}`}>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-lg-12">
            <div className="im-name-head">
              <button type="submit" className="dropbtn" onClick={navHandler}>
                QuickBooks Checks & Supplies
                <i className="icon-dropdown" />
              </button>
            </div>
            <div
              className={`topnav ${navStatus ? ' display-status' : ''}`}
              id="myTopnav"
              ref={myTopNav}
            >
              {NAVIGATION.map((el, index) => {
                return (
                  <div key={index} className="dropdown">
                    <button
                      type="submit"
                      className="dropbtn"
                      tabIndex="0"
                      data-wa-link={el.datalink}
                      data-ui-object="button"
                      data-refer-ui-access-point={`${convertToSnakeCase(el.datalink)}`}
                      value={el.link}
                      onClick={navRoute}
                      onFocus={(e) => handleDropdownShow(e, index)}
                      onMouseOut={() => {
                        handleCloseDropDown();
                      }}
                    >
                      {el.title}
                      <i
                        className={`icon-dropdown ${
                          showDropdown[index] && tabDropdown ? 'display-up-arrow' : ''
                        }`}
                      />
                    </button>
                    <div
                      className={`dropdown-content ${
                        showDropdown[index] && tabDropdown ? 'display-dropdown' : ''
                      }`}
                    >
                      {el.navigation.map((linkData, i) => {
                        return (
                          <Link
                            key={i}
                            to={linkData.link}
                            data-wa-link={linkData.datalink}
                            data-ui-object="link"
                            data-refer-ui-access-point={`${convertToSnakeCase(linkData.datalink)}`}
                            className="navInfo"
                            onClick={() => {
                              handleCloseDropDown();
                            }}
                            onBlur={() => {
                              if (linkData.lastElement) {
                                handleCloseDropDown();
                              }
                            }}
                          >
                            {linkData.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {mainReducer.pageData.login && (
                <div className="dropdown">
                  <button
                    type="submit"
                    className="dropbtn"
                    tabIndex="0"
                    onFocus={handleAccounDropdownShow}
                    onMouseOut={() => {
                      handleCloseAccountDropDown();
                    }}
                  >
                    My Account
                    <i
                      className={`icon-dropdown ${
                        showAccount && tabDropdown ? 'display-up-arrow' : ''
                      }`}
                    />
                  </button>
                  <div
                    className={`dropdown-content ${
                      showAccount && tabDropdown
                        ? 'display-dropdown-account'
                        : 'ide-dropdown-account'
                    }`}
                  >
                    <Link
                      to="/orderhistory"
                      onClick={() => {
                        handleCloseAccountDropDown();
                      }}
                    >
                      Order History
                    </Link>
                    <Link
                      to="/savedworklogo"
                      onClick={() => {
                        handleCloseAccountDropDown();
                      }}
                    >
                      Customization Options
                    </Link>
                    <Link
                      to="/account"
                      onClick={() => {
                        handleCloseAccountDropDown();
                      }}
                    >
                      Manage account
                    </Link>
                    <Link
                      to="/shipbill"
                      onClick={() => {
                        handleCloseAccountDropDown();
                      }}
                    >
                      Shipping & Billing
                    </Link>
                    <Link
                      href="/"
                      onClick={signOut}
                      onBlur={() => {
                        handleCloseAccountDropDown();
                      }}
                    >
                      Sign Out
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div style={{ float: 'right' }} className="display-data">
              <p style={{ textAlign: 'right', fontSize: '14px', fontWeight: '600' }}>
                (866) 570-3842
              </p>
              <p style={{ textAlign: 'right', fontSize: '10px', color: '#6B6C72' }}>
                Mon - Fri, 9am - 8pm ET
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderNavigation.propTypes = {
  logToIMWebLogger: PropTypes.func.isRequired,
  isStickyHeader: PropTypes.bool.isRequired,
  logToAnalytics: PropTypes.func.isRequired,
};

export default HeaderNavigation;
