// eslint-disable-next-line require-jsdoc
import React, { useState } from 'react';
import Button from '@ids/button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createMuiTheme, InputLabel, MuiThemeProvider, MenuItem } from '@material-ui/core';
import Tile from '@ids-beta/tile';
import IndeterminateShort from '@ids/indeterminate-short';
import { getLogoByAlphabet } from '../../../../services/customizationDataService';
import { upperCaseAlphabets } from '../../../../../assets/pagedata/constant';
import { formatStandardImageUrl } from '../../../Util/CustomizationUtil';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2ca01c',
    },
  },
});

// eslint-disable-next-line require-jsdoc
export const Monogram = (props) => {
  const [alphabet, setAlphabet] = useState('select');
  const [monograms, setMonograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMonogram, setSelectedMonogram] = useState(null);

  const getLogos = (alpha) => {
    setLoading(true);
    getLogoByAlphabet(alpha)
      .then((res) => {
        setMonograms(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        props.logToIMWebLogger(
          'error',
          `Customization: Monogram.jsx getLogos failed for '${alpha}'. Error: ${err}`,
        );
      });
  };

  const handleAlphabetChanged = (e) => {
    const { value } = e.target;
    setAlphabet(value);
    getLogos(value);
    setSelectedMonogram(null);
  };

  const monogramSelectionChanged = (mono) => {
    setSelectedMonogram(mono);
  };

  const getChecked = (a) => {
    if (selectedMonogram) return selectedMonogram.LogoFileNumber === a.LogoFileNumber;
    return false;
  };

  const applyMonogram = () => {
    props.onLogoSelect(selectedMonogram);
  };

  return (
    <div className="margin-top-55">
      <h4 className="margin-bottom-20 logo-header">Add a monogram</h4>
      <p className="cust-upload-modal-text">
        There is a one-time set up fee of $9.99 to add a logo.
      </p>
      <p className="margin-bottom-40 cust-upload-modal-text">
        Select a letter below to see the different designs.
      </p>
      <MuiThemeProvider theme={theme} style={{ marginTop: '25px' }}>
        <FormControl variant="outlined" className="margin-bottom-20">
          <InputLabel id="select-aplhabet"> </InputLabel>
          <Select
            labelId="select-aplhabet"
            style={{ fontSize: '19px', width: '256px', height: '40px' }}
            value={alphabet}
            onChange={handleAlphabetChanged}
            label="Age"
          >
            <MenuItem value="select" style={{ fontSize: '19px' }}>
              Select a letter
            </MenuItem>
            {upperCaseAlphabets.map((a) => {
              return (
                <MenuItem key={a} value={a} style={{ fontSize: '19px' }}>
                  {a}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </MuiThemeProvider>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <IndeterminateShort theme="quickbooks" />
        </div>
      ) : (
        <>
          <div className="margin-bottom-20">
            <div className="row">
              {monograms.map((a, key) => {
                return (
                  <div key={key} className="col-sm-4">
                    <div style={{ margin: '5px' }}>
                      <Tile
                        className="monogram-tile"
                        iconSize="large"
                        automationId={a.LogoFileNumber}
                        icon={<img src={formatStandardImageUrl(a.Url)} alt="..." />}
                        onChange={() => monogramSelectionChanged(a)}
                        checked={getChecked(a)}
                        type="checkbox"
                        title={a.LogoFileNumber}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {selectedMonogram && (
            <Button
              buttonType="primary"
              theme="quickbooks"
              // disabled={loading}
              onClick={applyMonogram}
              // data-wa-link="cancelccinfo"
            >
              Apply monogram
            </Button>
          )}
        </>
      )}
    </div>
  );
};
