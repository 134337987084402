import { call, put, takeLatest } from 'redux-saga/effects';
import { addItemToCart, getPageData, loggingOnServerex } from '../../services/commonDataService';
import { ReduxEvent, SagaEvent } from '../../../assets/pagedata/constant';
import { saveImprint, addBundelToCart } from '../../services/customizationDataService';
import { removeSessionStorage } from '../../components/Util/CommonUtils.jsx';
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* saveCustomization(action) {
  try {
    yield put({
      type: ReduxEvent.CUSTOMIZATION_TOGGLE_LOADING,
      data: { loading: true },
    });

    yield call(saveImprint, action.inputs.imprintId, action.inputs.imprintData);
    if (action.inputs.source && action.inputs.source.toLowerCase() === 'bundle') {
      yield call(addBundelToCart, action.inputs.bundleKey);
    } else {
      yield call(
        addItemToCart,
        action.inputs.imprintData.DataIslandAttributes.Sku,
        action.inputs.imprintData.DataIslandAttributes.ProductID,
        action.inputs.imprintId,
        action.inputs.imprintData.DataIslandAttributes.Quantity,
        action.inputs.imprintData.fdsRespn,
        action.inputs.imprintData.HasMatchingEnvelop,
      );
    }

    const pageInfo = yield call(getPageData);
    const parsedPageInfo = JSON.parse(pageInfo);
    yield put({
      type: ReduxEvent.PAGE_DATA,
      data: {
        pageData: {
          cartCount: parsedPageInfo.CartCount,
          login: parsedPageInfo.login,
          promotionMessage: parsedPageInfo.PromotionMessage,
        },
      },
    });

    // TODO: Need to remove this when completely moved to react
    // window.open('/cart', '_self');
    removeSessionStorage('Bundle_data');
    action.inputs.history.push('/cart');
  } catch (e) {
    yield put({
      type: ReduxEvent.CUSTOMIZATION_SAGA_ERROR,
      data: {
        errorMessage: 'We weren’t able to save your customization.',
      },
    });

    loggingOnServerex(
      'error',
      `saveCustSaga.jsx: saveCustSaga --> failed. Reason: ${e}`,
    ).then(() => {});
  }
}

function* saveCustSaga() {
  yield takeLatest(SagaEvent.CUSTOMIZATION_SAVE, saveCustomization);
}

export default saveCustSaga;
