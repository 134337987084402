// eslint-disable-next-line require-jsdoc
import React from 'react';
import TextField from '@ids/text-field';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import Checkbox from '@ids/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
export const Summary = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { summaryText } = customizationReducer;
  const dispatch = useDispatch();
  const { Title, Size, TooltipInfo, BoldFeature } = props;

  const handleChange = (key, value) => {
    if (key === 'text' && value.length > Size) {
      console.log('size exceed');
    } else {
      dispatch({
        type: ReduxEvent.SUMMARY_TEXT_CHANGED,
        data: {
          summaryText: {
            ...summaryText,
            [key]: value,
          },
        },
      });
    }
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        {Title}{' '}
        {TooltipInfo && (
          <Tooltip message={<div>{TooltipInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <TextField
        theme="quickbooks"
        label=""
        aria-label="summary"
        width="100%"
        maxLength={Size}
        onChange={(e) => handleChange('text', e.target.value)}
        value={summaryText.text}
        className={BoldFeature ? 'summarytext' : ''}
      />
      {BoldFeature && (
        <div className="width-font-weight">
          <Checkbox
            theme="quickbooks"
            aria-label="select row"
            onChange={(e) => handleChange('bold', e.target.checked)}
            checked={summaryText.bold}
          />
        </div>
      )}
    </div>
  );
};
