import React from 'react';
import { Modal, ModalHeader, ModalContent } from '@ids/modal-dialog';
import { Link } from 'react-router-dom';
import { browsersList, convertToSnakeCase, openInNewWindow } from '../Util/CommonUtils';
import '../../../stylesheets/ieMigrationModal.scss';

const IeMigrationModal = (props) => {
  const { openIeMigrationModal, ieMigrationModalClose } = props;
  return (
    <Modal
      dismissible
      size="large"
      backdropZIndex={20000}
      className="ie-migration-modal"
      open={openIeMigrationModal}
      onClose={() => {
        ieMigrationModalClose();
      }}
    >
      <ModalHeader align="left" className="ie-migration-modal-header font-wgt-700">
        Move to a New Browser to Continue Using Your Account
      </ModalHeader>
      <ModalContent>
        <div className="ie-migration-modal-container">
          <p className="ie-migration-sub-modal-container margin-bottom-35">
            The QuickBooks Checks & Supplies website no longer supports Internet Explorer 11.{' '}
            <span className="font-wgt-600">
              Customers using IE 11 cannot sign into their account
            </span>{' '}
            and access their order history or any other saved information.
          </p>
          <p className="ie-migration-sub-modal-container">
            To continue using your account, switch to a new browser now by clicking one of the links
            below:
          </p>
          <ul className="ie-migration-modal-link-ul">
            {browsersList.map((el, key) => {
              return (
                <Link
                  to="#"
                  key={key}
                  onClick={() => openInNewWindow(el.browserLink.link)}
                  className="ie-migration-browser-link font-wgt-600"
                  data-wa-link="open browser link"
                  data-ui-object="link"
                  data-ui-access-point="modal"
                  data-refer-ui-access-point={`open_browser_link | ${convertToSnakeCase(
                    el.browserLink.content,
                  )}`}
                >
                  {el.browserLink.content}
                </Link>
              );
            })}
          </ul>
        </div>
      </ModalContent>
    </Modal>
  );
};
export default IeMigrationModal;
