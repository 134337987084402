import { string } from 'prop-types';
import Cookies from 'universal-cookie';
import {
  MetaTagRobotsFollowRoutes,
  MetaTagRobotsNoFollowRoutes,
  SEOMetaDetails,
  unavailableProducts,
  MetaTagRobotsNoIndexRoutes,
  getOutofstockProducts,
  getStockChangeText,
  getOutofstockProductList,
} from '../../../assets/pagedata/constant.jsx';
import { getEnvironmentConfig } from '../../services/config/restClientEnvConfig.js';
import store from '../../redux/reducers/store';
import { discontinuedSkus } from '../../../assets/pagedata/accountConstant.jsx';
import { loggingOnServerex } from '../../services/commonDataService.js';

const cookies = new Cookies();

const TimeOut = {
  GUEST: 'g',
  IM: 'im',
};

const GetRobotsMetatag = (pathname) => {
  const { hostname } = getEnvironmentConfig();

  if (hostname !== 'https://intuitmarket.intuit.com/') {
    return 'noindex, nofollow';
  }

  if (MetaTagRobotsFollowRoutes.find((x) => x === pathname.toLowerCase())) {
    return 'noindex, follow';
  }
  if (MetaTagRobotsNoFollowRoutes.find((x) => x === pathname.toLowerCase())) {
    return 'noindex, nofollow';
  }
  if (MetaTagRobotsNoIndexRoutes.find((x) => x === pathname.toLowerCase())) {
    return 'noindex';
  }
  return null;
};

const hostname = 'intuitmarket.intuit.com';

export const CreateMeta = (location) => {
  let path = '';
  path = `${window.location.protocol}//${hostname}${window.location.pathname}`;
  const data = SEOMetaDetails[location.pathname.toLowerCase()]
    ? SEOMetaDetails[location.pathname.toLowerCase()]
    : SEOMetaDetails.default;
  const iscanonical = data.path ? `${window.location.protocol}//${hostname}${data.path}` : path;

  if (!data.TitleTag) {
    data.TitleTag = SEOMetaDetails.default.TitleTag;
  }

  if (!data.MetaDescription) {
    data.MetaDescription = SEOMetaDetails.default.MetaDescription;
  }

  return {
    title: data.TitleTag,
    canonical: iscanonical,
    meta: {
      charset: 'utf-8',
      description: data.MetaDescription,
      robots: GetRobotsMetatag(location.pathname),
      name: {
        keywords: data.keywords,
      },
    },
    script: data.ProductSchema ? data.ProductSchema : ' ',
  };
};

export const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key) => {
  return {
    value: window.sessionStorage.getItem(key),
  };
};

export const removeSessionStorage = (key) => {
  window.sessionStorage.removeItem(key);
};

/**
 * Takes tag and the attribute details and returns a html node object
 * @param {string} tag name of the html tag
 * @param {object} attributes json object with attributes
 * @param {object} params json object with element
 */
export const createHTMLNode = (tag, attributes = null, params = null) => {
  const el = document.createElement(tag);

  if (attributes) {
    const attKeys = Object.keys(attributes);
    for (let i = 0; i < attKeys.length; i += 1) {
      const att = attKeys[i];
      el.setAttribute(att, attributes[att]);
    }
  }

  if (params) {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      el[key] = params[key];
    }
  }

  return el;
};

/**
 * Stores / Updates cookie value
 * @param {string} key cookie key
 * @param {any} value cookie value
 */
export const setCookie = (key, value) => {
  cookies.set(key, value);
};

/**
 * Gets the cookie value
 * @param {string} key cookie key
 */
export const getCookie = (key) => {
  return cookies.get(key);
};

/**
 * Stores / Updates document cookie value
 * @param {string} key cookie key
 * @param {any} value cookie value
 */
export const setDocumentCookie = (name, value) => {
  document.cookie = `${name}=${value}; path=/`;
};

/**
 * Delete the cookie
 * @param {string} key cookie key
 */
export const removeCookie = (key) => {
  setDocumentCookie(key, '', -1);
};

/**
 * Sets a cookie that is an object
 * @param {string} name name of the cookie
 * @param {object} value value of the cookies
 * @param {number} days expiry days
 */
export const setObjectCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${JSON.stringify(value)}${expires}; path=/`;
};

/**
 * Mask the string
 * @param {*} input String
 * @param {*} leftUnMaskLength left pointer
 * @param {*} rightUnMaskLength right pointer
 */
export const maskString = (input, leftUnMaskLength, rightUnMaskLength) => {
  if (input !== 'undefined' && input !== null) {
    const sum = leftUnMaskLength + rightUnMaskLength;
    if (sum >= input.length) {
      leftUnMaskLength = rightUnMaskLength = input.length / (leftUnMaskLength + rightUnMaskLength);
    }
    const masked =
      input.substring(0, leftUnMaskLength) +
      input.slice(leftUnMaskLength, input.length - rightUnMaskLength).replace(/./gi, '*') +
      input.substring(input.length - rightUnMaskLength);
    return masked;
  }

  return '';
};

/**
 * Handles session time out
 */
export const handleSessionTimeOut = () => {
  const timedOut = getCookie('IMSessionTimedOut');
  if (timedOut) {
    if (timedOut.toLowerCase() === TimeOut.GUEST) {
      setCookie('IMSessionTimedOut', 0);
      window.open('/', '_self');
    }

    if (timedOut.toLowerCase() === TimeOut.IM) {
      setCookie('IMSessionTimedOut', 0);
      setCookie('IMSessionTimedOutModal', true);
      window.open('/signin', '_self');
    }
  }
};

/**
 * Sorts By Qunatity Asc
 * @param {object} price1 Pricing of object1
 * @param {object} price2 Pricing of object 2
 */
export const sortByQuantityAsc = (price1, price2) => {
  if (parseInt(price1.Quantity) < parseInt(price2.Quantity)) {
    return -1;
  }
  if (parseInt(price1.Quantity) > parseInt(price2.Quantity)) {
    return 1;
  }
  return 0;
};

export const sortByPriceAsc = (price1, price2) => {
  if (price1.DiscPrice && price2.DiscPrice) {
    const DiscPrice1 = price1.DiscPrice.substring(1, price1.DiscPrice.length);
    const DiscPrice2 = price2.DiscPrice.substring(1, price2.DiscPrice.length);
    if (parseFloat(DiscPrice1) < parseFloat(DiscPrice2)) {
      return -1;
    }
    if (parseFloat(DiscPrice1) > parseFloat(DiscPrice2)) {
      return 1;
    }
    return 0;
  }
  const ListPrice1 = price1.ListPrice.substring(1, price1.ListPrice.length);
  const ListPrice2 = price2.ListPrice.substring(1, price2.ListPrice.length);
  if (parseFloat(ListPrice1) < parseFloat(ListPrice2)) {
    return -1;
  }
  if (parseFloat(ListPrice1) > parseFloat(ListPrice2)) {
    return 1;
  }
  return 0;
};

// Tax season dates from 1 October through 31 January
export const checkForTaxSeason = (sysDate) => {
  let todayDt = sysDate || new Date();
  let mm = todayDt.getMonth() + 1;
  let dynamicStartYear;
  const yyyy = todayDt.getFullYear();
  if (mm === 1) {
    dynamicStartYear = yyyy - 1;
  } else {
    dynamicStartYear = yyyy;
  }
  const dynamicEndYear = dynamicStartYear + 1;
  const startDate = Date.parse(`10/01/${dynamicStartYear}`);
  const endDate = Date.parse(`01/31/${dynamicEndYear}`);
  function formatDate() {
    let dd = todayDt.getDate();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    // eslint-disable-next-line no-return-assign
    return (todayDt = `${mm}/${dd}/${yyyy}`);
  }
  const today = Date.parse(formatDate());
  return !!(today <= endDate && today >= startDate);
};

export const isNullOrEmpty = (obj) => {
  let result = obj == null || typeof obj === 'undefined';
  if (typeof obj !== 'object' && typeof obj !== 'function') result = result || obj === '';
  return result;
};

const Card = {
  VISA: {
    ID: 'VISA',
    Name: 'VISA',
    validCvv: /^[0-9]{3}$/,
    regEx: /^visa|vi/i,
    message: 'The security code must be three digits.',
  },
  MC: {
    ID: 'MC',
    Name: 'MasterCard',
    validCvv: /^[0-9]{3}$/,
    regEx: /mc|master/i,
    message: 'The security code must be three digits.',
  },
  DISC: {
    ID: 'DISC',
    Name: 'Discover',
    validCvv: /^[0-9]{3}$/,
    regEx: /dc|discover|di/i,
    message: 'The security code must be three digits.',
  },
  AMEX: {
    ID: 'AMEX',
    Name: 'American Express',
    validCvv: /^[0-9]{4}$/,
    regEx: /amex|american|express|ax/i,
    message: 'The security code must be four digits.',
  },
};

export const getCCTypeFromCCNum = (ccNumber, isTokenized) => {
  //	Token number format : 99TN-NNNN-NNNN-#### : T = card type
  if (!isNullOrEmpty(ccNumber))
    if (isTokenized) {
      switch (ccNumber[2]) {
        case '1':
          return Card.AMEX.Name;
        case '2':
          return Card.DISC.Name;
        case '3':
          return Card.MC.Name;
        case '4':
          return Card.VISA.Name;
        default:
      }
    } else {
      switch (ccNumber[0]) {
        case '3':
          return Card.AMEX.Name;
        case '6':
          return Card.DISC.Name;
        case '5':
          return Card.MC.Name;
        case '4':
          return Card.VISA.Name;
        default:
      }
    }
  return string.Empty;
};

export const validCVVForCCType = ({ cardType, cvv }) => {
  switch (true) {
    case Card.VISA.regEx.test(cardType):
      return {
        isValid: Card.VISA.validCvv.test(cvv),
        validationMsg: Card.VISA.message,
      };
    case Card.MC.regEx.test(cardType):
      return {
        isValid: Card.MC.validCvv.test(cvv),
        validationMsg: Card.MC.message,
      };
    case Card.DISC.regEx.test(cardType):
      return {
        isValid: Card.DISC.validCvv.test(cvv),
        validationMsg: Card.DISC.message,
      };
    case Card.AMEX.regEx.test(cardType):
      return {
        isValid: Card.AMEX.validCvv.test(cvv),
        validationMsg: Card.AMEX.message,
      };
    default:
      return {
        isValid: false,
        validationMsg: 'Unable to verify the security code.',
      };
  }
};

export const getMaskedNumber = (number) => {
  if (typeof number === 'number') number = number.toString();
  if (!number) return '';
  let maskedNumber = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'; //  a very long mask
  maskedNumber += number.substring(number.length - 4, number.length);
  return maskedNumber.substring(maskedNumber.length - number.length, maskedNumber.length);
};

export const formatDate = (date, format) => {
  switch (format) {
    case 'M d, yyyy':
      return new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    case 'mm/dd/yyyy':
      return new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    default:
      return new Date(date).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
  }
};

export const getCurrentFisicalYear = () => {
  const today = new Date();
  const orderMonth = today.getMonth() + 1;
  let fisicalYear = today.getFullYear();
  if (orderMonth < 5) {
    fisicalYear -= 1;
  }
  return fisicalYear;
};

export const stripAlphaChars = (src) => {
  return src.replace(/[^0-9]/g, '');
};

export const stripSpecialChars = (src) => {
  return src.replace(/[^0-9a-z]/gi, '');
};

export const stripWhitespace = (src) => {
  return src.replace(/\s+/g, '');
};

export const isValidPhoneNumber = (phone) => {
  // eslint-disable-next-line no-useless-escape
  const phoneFormat = /^(\+[0-9]{1}[ ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (phone.length < 10 || !phone.match(phoneFormat)) {
    return false;
  }

  return true;
};

export const isValidEmailAddress = (email) => {
  // eslint-disable-next-line no-useless-escape
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!email.match(mailformat)) {
    return false;
  }

  return true;
};

export const maskEmailAddress = (email) => {
  let maskedEmail = '';
  const emailParts = email.split('@');
  maskedEmail += emailParts[0][0];
  for (let i = 1; i < emailParts[0].length; i++) {
    maskedEmail += '*';
  }

  maskedEmail += '@';

  const secondaryEmailParts = emailParts[1].split('.');

  for (let j = 0; j < secondaryEmailParts[0].length; j++) {
    if (j !== secondaryEmailParts[0].length - 1) {
      maskedEmail += '*';
    } else {
      maskedEmail += secondaryEmailParts[0][j];
    }
  }

  maskedEmail += '.';
  maskedEmail += secondaryEmailParts[1];

  return maskedEmail;
};

export const isTaxFormUnavailable = () => {
  const state = store.getState();
  const fromInclusiveDate = new Date(
    `${
      state.mainReducer.clientConfigs.ProductUnavailableTaxFormInclusiveFromDate
    } ${new Date().getFullYear()}`,
  );
  const toExclusiveDate = new Date(
    `${
      state.mainReducer.clientConfigs.ProductUnavailableTaxFormExclusiveToDate
    } ${new Date().getFullYear()}`,
  );
  const systemDate = state.mainReducer.systemDate;
  return systemDate >= fromInclusiveDate && systemDate < toExclusiveDate;
};

const getOrdinal = (number) => {
  let ordinal = '';
  if (number > 10 && number < 20) {
    ordinal = 'th';
  } else {
    switch (number % 10) {
      case 1:
        ordinal = 'st';
        break;
      case 2:
        ordinal = 'nd';
        break;
      case 3:
        ordinal = 'rd';
        break;
      default:
        ordinal = 'th';
        break;
    }
  }
  return ordinal;
};

export const taxFormsNextAvlDate = (
  format = { month: 'long', day: 'numeric' },
  showOrdinal = true,
) => {
  const state = store.getState();
  if (!state.mainReducer.clientConfigs) {
    return;
  }
  const toExclusiveDate = new Date(
    `${
      state.mainReducer.clientConfigs.ProductUnavailableTaxFormExclusiveToDate
    } ${new Date().getFullYear()}`,
  );
  let dateString = toExclusiveDate.toLocaleDateString('en-us', format);
  if (showOrdinal) {
    dateString += getOrdinal(toExclusiveDate.getDate());
  }
  return dateString;
};

const FISCAL_MONTH = 5; // move to constant file
export const isCurrentFiscal = (orderMonth, orderYear) => {
  const customIMDate = new Date();
  orderYear = orderYear || customIMDate.getFullYear();
  orderMonth = orderMonth != null ? orderMonth : customIMDate.getMonth();
  const fiscalYr =
    customIMDate.getMonth() < FISCAL_MONTH
      ? customIMDate.getFullYear() - 1
      : customIMDate.getFullYear();

  const yrDiff = orderYear - fiscalYr;
  if (yrDiff < 0) return false; /* Past years orders */
  if (yrDiff === 0 && orderMonth < FISCAL_MONTH)
    return false; /* Same year orders, months below FISCAL_Month should be SHOP, above REORDER */
  return true;
};

export const passOptionsToCustomization = (options) => {
  document.cookie = `custOptions=${JSON.stringify(options)}`;
  // setCookie("custOptions", JSON.stringify(options), 1 / 48);
};

export const formatCompanyInformation = (companyInfo) => {
  return `${companyInfo.BusinessName}\n${companyInfo.AddressLine1} ${companyInfo.AddressLine2}\n${companyInfo.City}, ${companyInfo.State} ${companyInfo.Zip}\n${companyInfo.Phone}\n`;
};

export const getCompanyObjectFromString = (addressString) => {
  const arr = addressString.split('\n');
  const returnObj = {
    companyName: arr.length >= 1 ? arr[0] : '',
    addressLine1: arr.length >= 2 ? arr[1] : '',
    addressLine2: arr.length >= 3 ? arr[2] : '',
    phoneNumber: arr.length >= 4 ? arr[3] : '',
    extraLine: arr.length >= 5 ? arr[4] : '',
  };

  return returnObj;
};

export const convertJSONValuesToUpperCase = (data) => {
  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (key.indexOf('Attribute') > -1) {
      // eslint-disable-next-line no-continue
      continue;
    }
    data[key] = data[key].toUpperCase();
  }

  return data;
};

export const getYears = (yearsFromNow) => {
  if (yearsFromNow === '' || Number.isNaN(yearsFromNow)) {
    yearsFromNow = 30;
  }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year <= currentYear + yearsFromNow; year++) {
    years.push(year);
  }
  return years;
};

export const getMonths = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
};

export const getBrowser = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'MSIE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge|Edg)\/(\d+)/);
    if (tem !== null) {
      const browsername = tem[1] === 'OPR' ? 'Opera' : 'Edge';
      return { name: browsername, version: tem[2] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  tem = ua.match(/version\/(\d+)/i);
  if (tem !== null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const isIE11Browser = () => {
  const browserdetails = getBrowser();
  let isIE_11Browser = false;
  if (browserdetails.name === 'MSIE' && browserdetails.version === '11') {
    isIE_11Browser = true;
  }
  return isIE_11Browser;
};

export const checkLogoURL = (logoUrl) => {
  if (logoUrl) {
    const intrimLogo = logoUrl.split('&');
    if (intrimLogo[intrimLogo.length - 1]) {
      const heightValue = intrimLogo[intrimLogo.length - 1].split('=');
      if (!heightValue[1]) {
        logoUrl += '100';
      }
    }
  }
  return logoUrl;
};

export const getPastYears = (yearsInPast) => {
  if (yearsInPast === '' || Number.isNaN(yearsInPast)) {
    yearsInPast = 30;
  }
  const currentYear = new Date().getFullYear();
  const years = ['Year'];
  for (let year = currentYear; year > currentYear - yearsInPast; year--) {
    years.push(year.toString());
  }
  return years;
};

export const getUniqueBankValue = (obj) => {
  // eslint-disable-next-line array-callback-return
  return obj.filter(function (a) {
    const key = `${a.AccountNumber}|${a.RoutingNumber}|${a.BankName.toLowerCase()}`;
    // eslint-disable-next-line react/no-this-in-sfc
    if (!this[key]) {
      // eslint-disable-next-line react/no-this-in-sfc
      this[key] = true;
      return true;
    }
  }, Object.create(null));
};

export const getAccountRecoveryUrl = () => {
  const state = store.getState();
  const accountRecovery = state.mainReducer.clientConfigs.IUSIUSAccountRecoveryEndPoint;
  const track = getCookie('track');
  const originalTrack = getCookie('originaltrack');
  const url = `${accountRecovery}&redirect_url=https%3a%2f%2f${window.location.hostname}%2fcommon%2fidmanager%2fLoginAfterPwdRecovery.aspx%3fiusacctrec%3D1%26originaltrack%3d${originalTrack}%26track%3d${track}`;

  return url;
};

export const isProductUnavailable = ({ pfid, sku }) => {
  const unavailable =
    unavailableProducts.pfid.includes(pfid) ||
    unavailableProducts.sku.includes(sku) ||
    unavailableProducts.baseSku.includes(sku.split('-')[0]);
  return unavailable;
};

export const getUniqueId = () => {
  return Math.floor(Math.random() * Date.now());
};

export const getRandomGuid = () => {
  return Math.floor(Math.random() * 100000000000000);
};

export const isOutOfStockProduct = ({ pfid, sku }) => {
  const state = store.getState();
  const outofstockProducts = getOutofstockProducts(state);
  const outofstock =
    (pfid && pfid.length && outofstockProducts.pfid.includes(pfid)) ||
    (sku &&
      sku.length &&
      (outofstockProducts.sku.includes(sku) ||
        outofstockProducts.baseSku.includes(sku.split('-')[0])));
  return outofstock;
};

export const isTheProductOutOfStockProduct = ({ pfid, sku }, listOfOutOfStockProducts) => {
  const outofstockProducts = getOutofstockProductList(listOfOutOfStockProducts);
  const state = store.getState();
  const ProductOutOfStockisOutOfStockPhase3 =
    state.mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3 &&
    state.mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3.toLowerCase() === 'true';
  if (ProductOutOfStockisOutOfStockPhase3 && Array.isArray(pfid)) {
    let outofstock =
      pfid[0] && pfid[0].length && outofstockProducts.pfid.some((obj) => obj.pfid === pfid[0]);
    for (let i = 1; i < pfid.length && outofstock; i++) {
      outofstock =
        outofstock &&
        pfid[i] &&
        pfid[i].length &&
        outofstockProducts.pfid.some((obj) => obj.pfid === pfid[i]);
    }
    return outofstock
      ? pfid[0] && pfid[0].length && outofstockProducts.pfid.filter((obj) => obj.pfid === pfid[0])
      : outofstock;
  }

  const outofstock =
    (pfid && pfid.length && outofstockProducts.pfid.filter((obj) => obj.pfid === pfid)) ||
    (sku && sku.length && outofstockProducts.sku.filter((obj) => obj.sku === sku));
  return outofstock;
};

export const getProductOutOfStockMsg = () => {
  const state = store.getState();
  return state.mainReducer.clientConfigs.ProductOutOfStockOutOfStockMsg;
};

export const isStockChangeTextAvil = ({ pfid }) => {
  const state = store.getState();
  const isStockChangeFolderProducts = getStockChangeText(state);
  const isStockChangeFolderPfid =
    pfid && pfid.length && isStockChangeFolderProducts.pfid.includes(pfid);
  return isStockChangeFolderPfid;
};

export const createOutOfStockList = (data) => {
  const skuArr = [];
  const pfidArr = [];
  data.forEach((eachProdEle) => {
    const outOfStockList = eachProdEle.filter((eachProduct) => eachProduct.OutOfStock);

    if (eachProdEle.length === outOfStockList.length) {
      pfidArr.push({
        pfid: eachProdEle[0]?.PfID,
        outOfStockMessage: eachProdEle[0]?.OutOfStockMessage,
      });
    } else {
      eachProdEle.forEach((eachProduct) => {
        if (eachProduct.OutOfStock) {
          skuArr.push({
            sku: eachProduct.Sku,
            outOfStockMessage: eachProduct.OutOfStockMessage,
          });
        }
      });
    }
  });
  return [
    {
      pfidList: pfidArr,
      skuList: skuArr,
    },
  ];
};

export const createOutOfStockListForVariant = (data) => {
  const baseSkuArr = [];
  const skuArr = [];
  const outOfStockList = data.filter((eachProduct) => eachProduct.OutOfStock);

  if (data.length === outOfStockList.length) {
    baseSkuArr.push({
      baseSku: outOfStockList[0].sku.split('-')[0],
      displayOutOfStock: outOfStockList[0].OutOfStock,
      outOfStockMessage: outOfStockList[0]?.OutOfStockMessage,
    });
  } else {
    outOfStockList.forEach((eachProduct) => {
      if (eachProduct.OutOfStock) {
        skuArr.push({
          sku: eachProduct.sku,
          outOfStockMessage: eachProduct.OutOfStockMessage,
          displayOutOfStock: eachProduct.OutOfStock,
        });
      }
    });
  }
  return [
    {
      baseSkuList: baseSkuArr,
      skuList: skuArr,
    },
  ];
};
export const createOutOfStockListForBasesku = (data) => {
  const skuArr = [];
  const pfidArr = [];
  const baseSkuArr = [];
  let prevBaseSku = 0;
  data.forEach((eachProdEle) => {
    const outOfStockList = eachProdEle.filter((eachProduct) => eachProduct.OutOfStock);
    if (eachProdEle.length === outOfStockList.length) {
      pfidArr.push({
        pfid: eachProdEle[0]?.PfID,
        outOfStockMessage: eachProdEle[0]?.OutOfStockMessage,
      });
    } else {
      eachProdEle.forEach((eachProduct) => {
        const baseSku = eachProduct.Sku.split('-')[0];
        if (baseSku === prevBaseSku) {
          const baseSkuLength = eachProdEle.filter((o) => o.Sku.includes(baseSku)).length;
          const baseSkuOFSListLength = eachProdEle.filter(
            (o) => o.OutOfStock === true && o.Sku.includes(baseSku),
          ).length;
          if (
            baseSkuLength === baseSkuOFSListLength &&
            baseSkuArr.find((element) => element.baseSku === baseSku) === undefined
          ) {
            baseSkuArr.push({
              baseSku,
              outOfStockMessage: eachProduct.OutOfStockMessage,
            });
          }
        }
        if (eachProduct.OutOfStock) {
          skuArr.push({
            sku: eachProduct.Sku,
            outOfStockMessage: eachProduct.OutOfStockMessage,
          });
        }
        prevBaseSku = baseSku;
      });
    }
  });
  return [
    {
      pfidList: pfidArr,
      baseSkuList: baseSkuArr,
      skuList: skuArr,
    },
  ];
};
export const sortColorOnStock = (data, SuportedColorArray, attribute) => {
  const colorArr = [];
  const sortedArr = [];
  let accentChangeFlag = false;
  SuportedColorArray.forEach((color) => {
    let filteredData = [];
    if (attribute) {
      filteredData = data.filter(
        (ele) =>
          ele.Color === attribute && color && ele.Accent === color && ele.OutOfStock === true,
      );
    } else {
      filteredData = data.filter((ele) => ele.Color === color && ele.OutOfStock === true);
    }
    if (filteredData.length > 0) {
      accentChangeFlag = true;
    }
    colorArr.push(`${filteredData.length}-${color}`);
  });
  if (accentChangeFlag) {
    colorArr.sort(function (a, b) {
      return a.substring(0, a.indexOf('-')) - b.substring(0, b.indexOf('-'));
    });
    colorArr.forEach((ele) => {
      sortedArr.push(ele.substring(ele.indexOf('-') + 1));
    });
    return sortedArr;
  }

  return SuportedColorArray;
};

export const isProductOutOfStock = ({ pfid, sku }, outOfStockSku) => {
  if (!!outOfStockSku && outOfStockSku.length >= 1) {
    const ofs = isTheProductOutOfStockProduct(
      {
        pfid,
        sku,
      },
      outOfStockSku,
    );
    if (ofs && ofs.length >= 1) {
      return {
        displayOutOfStock: true,
        outOfStockMessage: ofs[0]?.outOfStockMessage,
      };
    }
    return {
      displayOutOfStock: false,
    };
  }
};

export const handleOutOfStockProductOhAndRr = (pfid, sku, isOfsLineItem, ofsLineItemMsg) => {
  const state = store.getState();
  const ProductOutOfStockisOutOfStock =
    state.mainReducer.clientConfigs.ProductOutOfStockisOutOfStock &&
    state.mainReducer.clientConfigs.ProductOutOfStockisOutOfStock.toLowerCase() === 'true';
  if (
    ProductOutOfStockisOutOfStock &&
    isOfsLineItem &&
    ofsLineItemMsg &&
    ofsLineItemMsg.length >= 1
  ) {
    return {
      displayOutOfStock: isOfsLineItem,
      outOfStockMessage: ofsLineItemMsg,
    };
  }
  const isOutOfStock = isOutOfStockProduct({
    pfid,
    sku,
  });
  return {
    displayOutOfStock: isOutOfStock,
    outOfStockMessage: getProductOutOfStockMsg(),
  };
};

export const browsersList = [
  {
    browserLink: {
      content: 'Microsoft Edge',
      link: 'https://www.microsoft.com/en-us/edge',
    },
  },
  {
    browserLink: {
      content: 'Google Chrome',
      link: 'https://www.google.com/chrome/index.html',
    },
  },
  {
    browserLink: {
      content: 'Mozilla Firefox',
      link: 'https://www.mozilla.org/en-US/firefox/new/',
    },
  },
  {
    browserLink: {
      content: 'Opera',
      link: 'https://www.opera.com/',
    },
  },
  {
    browserLink: {
      content: 'Safari',
      link: 'https://support.apple.com/downloads/safari',
    },
  },
];

export const openInNewWindow = (url) => {
  window.open(url, 'newwindow', 'width=1200, height=900');
};

export const getImageSrc = (img) => {
  const { cdn } = getEnvironmentConfig();
  if (img.indexOf('base64') < 0 && !getSessionStorage('CDNFallback')?.value) {
    return `${cdn}${img}`;
  }
  return img;
};

export const validateMonthAndYear = (expMonth, expYear) => {
  const month = Number(parseFloat(expMonth));
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const year = expYear;
  const isNumeric = /^(1[0-2]|0[1-9]|[1-9])$/;
  // -- Month can only be numeric 01-12 or 1-12
  if (
    month === 0 ||
    !isNumeric.test(month) ||
    month > 12 ||
    year < currentYear ||
    (year === currentYear && month < currentMonth)
  ) {
    return false;
  }
  return true;
};

export const currencyFormatter = (currency) => {
  if (currency && currency !== '') {
    const arraySplit = currency.split('');
    arraySplit.splice(0, 1);
    const final = `$${parseFloat(arraySplit.join('')).toFixed(2)}`;
    return final;
  }
  return '';
};

export const toPascalCase = (str) => {
  // First, split the sentence by any non-alphanumeric characters and spaces
  const words = str.split(/[^a-zA-Z0-9]+/);

  // Next, capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Finally, join the words together with no spaces and return the result
  return capitalizedWords.join(' ');
};

export const backToCategory = (str) => {
  const category = str.toUpperCase();
  let categorylink = '/';
  switch (category) {
    case 'CHECKS':
      categorylink = '/resources/checks';
      break;
    case 'TAX FORMS':
      categorylink = '/resources/tax-forms';
      break;
    case 'CHECK FRAUD PREVENTION':
      categorylink = '/resources/fraud-prevention';
      break;
    case 'BUSINESS FRAUD PREVENTION':
      categorylink = '/resources/business-fraud-prevention';
      break;
    default:
      break;
  }
  return categorylink;
};

export const getBaseSku = (sku) => {
  return sku.split('-')[0];
};

export const checkDiscontinuedBaseSku = (sku, miscProp) => {
  const retrievedBaseSku = getBaseSku(sku);
  let discontinued = false;

  discontinuedSkus.forEach((ele) => {
    if (ele.baseSku === retrievedBaseSku && ele.additionalProp === null) {
      discontinued = true;
      return discontinued;
    }
    if (
      ele.baseSku === retrievedBaseSku &&
      ele.additionalProp !== null &&
      ele.additionalProp === miscProp
    ) {
      discontinued = true;
      return discontinued;
    }
  });
  return discontinued;
};

export const checkDiscontinuedColorWithBaseSKU = (sku) => {
  let discontinued = false;
  discontinuedSkus.forEach((ele) => {
    if (ele.baseSku === sku.split('-')[0] && ele.additionalProp) {
      discontinued = true;
    }
  });
  return discontinued;
};
// convert text to lowercase and seperate by underscore
export const convertToSnakeCase = (text) => {
  // add regex to remove special characters
  if (text === undefined) return '';
  const regex = /[-/!@#$%^&*(),.?"' ';:{}|<>]/g;
  const rtxt = text.replace('/', '');
  return rtxt.toLowerCase().split(regex).join('_');
};

export const trackClickEvent = function (event) {
  try {
    let pathname = window.location.pathname;
    if (getCookie('PrevURL')) {
      pathname = getCookie('PrevURL');
    }
    setCookie('PrevURL', String(window.location.pathname));
    const screen = pathname === '/' ? 'homepage' : pathname.substr(1);
    const dataSet = event.target.dataset;
    if (dataSet && dataSet.referUiAccessPoint && dataSet.uiObject) {
      window.intuit.tracking.ecs.webAnalytics.track({
        scope_area: 'storefront_and_marketplace',
        screen,
        object: dataSet.object ? dataSet.object : 'content',
        action: dataSet.action ? dataSet.action : 'engaged',
        'data-wa-link': dataSet.waLink,
        region: 'US',
        ui_action: dataSet.uiAction ? dataSet.uiAction : 'clicked',
        ui_object: dataSet.uiObject,
        ui_object_detail: event.target.innerText
          ? event.target.innerText
          : dataSet.uiObjectDetails
          ? dataSet.uiObjectDetails
          : dataSet.referUiAccessPoint,
        ui_access_point: dataSet.uiAccessPoint ? dataSet.uiAccessPoint : 'center',
        refer_ui_access_point: dataSet.referUiAccessPoint,
      });
    }
  } catch (e) {
    loggingOnServerex(
      'Error',
      `Flow=Eventbus Opperation=trackClickEvent Status=error Message=${e}`,
    );
  }
};

export const getNextYearDate = (month, day) => {
  let currentDate = new Date();
  let  currentYear = new Date().getFullYear();
  // if current month is october or later, then the target year is next year
  let targetYear = currentDate.getMonth() >= 9 ? currentYear + 1 : currentYear;
  let targetDate  = new Date(`${month} ${day}, ${targetYear}`);
  return targetDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}
