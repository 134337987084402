import { call, put, takeLatest, select } from 'redux-saga/effects';
import { loggingOnServerex } from '../../services/commonDataService';
import { ReduxEvent, SagaEvent } from '../../../assets/pagedata/constant';
import { cloneImprintDataForUpgradeChecks } from '../../components/Util/CustomizationUtil';
import { getImprintScaffolding } from '../../services/customizationDataService';

const customizationReducer = (state) => state.customizationReducer;

function* upgradeChecks(action) {
  try {
    yield put({
      type: ReduxEvent.CUSTOMIZATION_TOGGLE_LOADING,
      data: { loading: true },
    });
    const custReducer = yield select(customizationReducer);
    const dataIslandAttributes = yield call(getImprintScaffolding, action.inputs.pf_id);
    const imprintData = cloneImprintDataForUpgradeChecks(
      action.inputs.pf_id,
      custReducer,
      dataIslandAttributes,
      action.inputs.upgradeColorMapping,
    );
    yield put({
      type: SagaEvent.CUSTOMIZATION_SAVE,
      inputs: { imprintId: custReducer.imprintId, imprintData, history: action.inputs.history },
    });
  } catch (e) {
    loggingOnServerex(
      'error',
      `upgradeChecksSaga.jsx: upgradeChecks --> failed. Reason: ${e}`,
    ).then(() => {});

    yield put({
      type: ReduxEvent.CUSTOMIZATION_SAGA_ERROR,
      data: {
        errorMessage: 'We weren’t able to load your customization.',
      },
    });
  }
}

function* upgradeChecksSaga() {
  yield takeLatest(SagaEvent.UPGRADE_CHECKS, upgradeChecks);
}

export default upgradeChecksSaga;
