// eslint-disable-next-line require-jsdoc
import React, { useState, useEffect } from 'react';
import TextField from '@ids/text-field';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import Checkbox from '@ids/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
export const SignatureLines = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { signatureLines } = customizationReducer;
  const dispatch = useDispatch();
  const { Size, TooltipInfo, TooltipAnotherSignatureInfo } = props;
  const [isChecked, setCheck] = useState(false);

  const handleChange = (key, value) => {
    let numLines = 1;
    if (key === 'doubleline') {
      setCheck(value);
      if (value) {
        numLines = 2;
        dispatch({
          type: ReduxEvent.SIGNATURE_LINES_SELECTED,
          data: {
            signatureLines: {
              ...signatureLines,
              totalLines: numLines,
            },
          },
        });
      } else {
        numLines = 1;
        dispatch({
          type: ReduxEvent.SIGNATURE_LINES_SELECTED,
          data: {
            signatureLines: {
              ...signatureLines,
              totalLines: numLines,
              line2: '',
            },
          },
        });
      }
    } else {
      dispatch({
        type: ReduxEvent.SIGNATURE_LINES_SELECTED,
        data: {
          signatureLines: {
            ...signatureLines,
            [key]: value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (signatureLines.totalLines === 2) {
      setCheck(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        Optional: Add text above signature line 1
        {TooltipInfo && (
          <Tooltip message={<div>{TooltipInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <TextField
        theme="quickbooks"
        label=""
        width="100%"
        maxLength={Size}
        onChange={(e) => handleChange('line1', e.target.value)}
        value={signatureLines.line1}
        className="no"
      />

      <div>
        <p
          className="margin-bottom-10 margin-top-15"
          style={{ fontSize: '16px', color: '#393a3D;', fontWeight: '500' }}
        >
          <Checkbox
            theme="quickbooks"
            aria-label="select row"
            onChange={(e) => handleChange('doubleline', e.target.checked)}
            checked={isChecked}
            className="here"
          />
          <span style={{ verticalAlign: 'top' }}>
            Optional: Add another signature line{' '}
            <Tooltip message={TooltipAnotherSignatureInfo}>
              <span className="circle-info-span">
                <CircleInfoIcon size="medium" />
              </span>
            </Tooltip>
          </span>
        </p>
        {isChecked && (
          <div>
            <p
              className="margin-bottom-10"
              style={{ fontSize: '16px', color: '#393a3D;', fontWeight: '500' }}
            >
              Add text above signature line 2
            </p>
            <TextField
              theme="quickbooks"
              label=""
              width="100%"
              maxLength={Size}
              onChange={(e) => handleChange('line2', e.target.value)}
              value={signatureLines.line2}
              className="no"
            />
          </div>
        )}
      </div>
    </div>
  );
};
