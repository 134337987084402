import { doGetFetch, doPostFetch, doPutFetch } from './utils/fetchUtil';
import {
  ADD_ITEM_TO_CART,
  GET_CLIENT_CONFIGS,
  GET_PAGE_INFO,
  GET_PAGE_INFO_PARAM,
  PUT_WEBLOG_INFO,
} from './config/url';

// eslint-disable-next-line import/prefer-default-export
export function getClientConfig(type, params) {
  switch (type) {
    case 'POST':
      return doPostFetch(GET_CLIENT_CONFIGS, params, null);

    case 'GET':
    default:
      return doGetFetch(GET_CLIENT_CONFIGS, params, null);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function getPageData() {
  const refURLParam = new URLSearchParams(window.location.search);
  const id = refURLParam.get('ref_');
  const pageUrl = id === '-1' ? GET_PAGE_INFO_PARAM : GET_PAGE_INFO;
  return doGetFetch(pageUrl);
}

export function getPageConfigInfo(successCallBack, errorCallBack) {
  const refURLParam = new URLSearchParams(window.location.search);
  const id = refURLParam.get('ref_');
  const PageUrl = id === '-1' ? GET_PAGE_INFO_PARAM : GET_PAGE_INFO;
  doGetFetch(PageUrl)
    .then((json) => {
      console.log('Clicked:', 'get promo and other data');
      let data;
      if (typeof json === 'string') {
        data = JSON.parse(json);
      } else {
        data = json;
      }
      // loggingOnServerex("info","successfullly fetched Data");
      // loggingOnServerex("info","successfullly fetched Data")
      //     .then((json) => {
      //         console.log("error logged suceessfully");
      //     })
      //     .catch(() => {
      //         console.log("error logged issue");
      //     });
      if (successCallBack) {
        successCallBack(data);
      }
    })
    .catch((e) => {
      // setFetchError(true)
      if (errorCallBack) {
        errorCallBack(`commonDataService -> getPageConfigInfo failed. Error: ${e}`);
      }
      console.log('Header Issue get page data:', 'error');
    });
}

export function getLoggedInDetails() {
  const refURLParam = new URLSearchParams(window.location.search);
  const id = refURLParam.get('ref_');
  const PageUrl = id === '-1' ? GET_PAGE_INFO_PARAM : GET_PAGE_INFO;
  return doGetFetch(PageUrl);
}

export function loggingOnServerex(level, logData) {
  const msg = `React: ${logData}`;
  return doPutFetch(PUT_WEBLOG_INFO, { level, Message: msg });
}

export function addItemToCart(sku, pfId, imprintId, quantity, fdsRespn, matchingProduct) {
  const params = {
    BaseSku: sku,
    ProductTypeID: pfId,
    ImprintId: imprintId || '',
    BaseQuantity: quantity || 1,
    FraudDetectResult: fdsRespn,
    HasMatchingProduct: matchingProduct,
  };

  return doPostFetch(ADD_ITEM_TO_CART, params);
}
