import { ReduxEvent } from '../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
const initialState = {
  loading: true,
  clientConfigs: null,
  isHeaderUpdate: true,
  pageData: {
    cartCount: 0,
    login: false,
    promotionMessage: '',
  },
  isTaxSeason: false,
  systemDate: null,
};

const mainReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ReduxEvent.DATA_LOAD_SUCCESS:
      return { ...state, loading: data.loading };

    case ReduxEvent.GET_CLIENT_CONFIG_SUCCESS:
      return { ...state, clientConfigs: data.clientConfigs, loading: data.loading };

    case ReduxEvent.INITIALIZE_INITIAL_PAGE_SUCCESS:
      return {
        ...state,
        clientConfigs: data.clientConfigs,
        loading: data.loading,
        pageData: {
          ...state.pageData,
          login: data.logInDetail.login,
        },
        systemDate: new Date(data.systemDate),
      };

    case ReduxEvent.HEADER_UPDATE:
      return { ...state, isHeaderUpdate: data.isHeaderUpdate };

    case ReduxEvent.PAGE_DATA:
      return { ...state, pageData: data.pageData };

    case ReduxEvent.SIGN_OUT_SUCCESS:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          cartCount: data.cartCount,
          login: data.login,
        },
      };

    case ReduxEvent.TAX_SEASON:
      return { ...state, isTaxSeason: data.isTaxSeason };

    default:
      return state;
  }
};

export default mainReducer;
