import { ReduxEvent } from '../../../assets/pagedata/constant';

const initialState = {
  loading: true,
  micrUpdated: false,
  sagaError: false,
  sagaErrorMessage: 'Some error occured',
  imprintId: '',
  imprintDetails: {}, // updated data from dataisland properties
  dataIslandAttributes: {}, // dataisland properties
  printerType: 'Laser_InkJet',
  sheets: '1',
  selectedSku: '',
  company: {
    CompanyName: '',
    CompanyNameAttribute: {
      FontSize: '10',
      bold: true,
    },
    AddressLine1: '',
    AddressLine1Attribute: {
      FontSize: '10',
      bold: false,
    },
    AddressLine2: '',
    AddressLine2Attribute: {
      FontSize: '10',
      bold: false,
    },
    Phone: '',
    PhoneAttribute: {
      FontSize: '10',
      bold: false,
    },
    ExtraLine: '', // Works differently for different products.. 1. Cover stamp, acts as fax number
    ExtraLineAttribute: {
      FontSize: '10',
      bold: false,
    },
    ExtraLine2: '',
    ExtraLine2Attribute: {
      FontSize: '10',
      bold: false,
    },
  },
  companyInfo: '', // concatenanted version of company
  banks: [],
  selectedBank: null,
  summaryText: {
    text: '',
    bold: true,
    font_color: '#000000',
  },
  routingNumber: '',
  accountNumber: '',
  banksFromRoutingNumber: [],
  verifiedBank: false,
  showBankInfoSection: false,
  bankInformation: {
    name: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    fractional: '',
  },
  micr: '',
  micrMask: '',
  approveInitials: '',
  validationError: false, // set to true when the validation fails
  bankAccount: '', // specific to endorsement stamp
  logo: null, // stores details of the selected logo
  logoOption: null, // stores the kind of logo selected (upload, pre-designe, monogram)
  logoColor: '', // this is needed for the invoices which has dual color concept
  yourName: '', // Stores your name, specific to fax header cover stamp
  selectedFontFamily: 'Helvetica',
  selectedFontColor: 'Black',
  selectedFontSize: '',
  secondaryColor: '', // This is used for all products that have INK COLOR option of Black + 1 Color
  shadowLogo: null, // stores details of the selected logo
  shadowLogoOption: null, // stores the kind of logo selected (upload, pre-designe, monogram)
  shadowLogoColor: '', // this is needed for the invoices which has dual color concept
  companyInfoColor: '', // this is needed for the invoices which has dual color concept
  customColor: {
    customColorValue: '',
    selectedColorPickerImage: '',
  },
  skuCategory: '',
  checkStartingNumber: {
    csn: '',
    reverseNumbering: 'N',
  },
  signatureLines: {
    totalLines: 1,
    line1: '',
    line2: '',
  },
  accountOpenDate: {
    month: '00',
    year: 'Year',
  },
  matchingEnv: {
    envRequired: 'Y',
    envelope_sku: '',
  },
  fdsRespn: {
    VerificationResponse: 0,
    AccountResponseCode: 0,
    TransId: 0,
    ActionType: null,
  },
  selectedBankName: '',
  comboDataSet: [],
  isAccountRoutingChanged: false,
};

const customizationReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ReduxEvent.GET_CUST_IMPRINT_DETAILS_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };

    case ReduxEvent.CUSTOMIZATION_SHEET_SELECTION_CHANGED:
      return {
        ...state,
        sheets: data.sheets,
      };

    case ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED:
      return {
        ...state,
        selectedSku: data.sku,
        validationError: false,
      };

    case ReduxEvent.COMPANY_INFO_TEXT_CHANGED:
      return {
        ...state,
        company: data.company,
        validationError: false,
      };

    case ReduxEvent.SUMMARY_TEXT_CHANGED:
      return {
        ...state,
        summaryText: data.summaryText,
      };

    case ReduxEvent.FONT_COLOR_CHANGED:
      return {
        ...state,
        selectedFontColor: data.fontColorData,
      };

    case ReduxEvent.FONT_FAMILY_CHANGED:
      return {
        ...state,
        selectedFontFamily: data.fontFamilyData,
      };

    case ReduxEvent.FONT_SIZE_CHANGED:
      return {
        ...state,
        selectedFontSize: data.fontSizeData,
      };

    case ReduxEvent.CUSTOM_IMAGE_SELECTED:
      return {
        ...state,
        customColor: {
          selectedColorPickerImage: data.imageData,
          customColorValue: data.customColorValue,
        },
      };

    case ReduxEvent.BANK_SELECTION_CHANGE:
      return {
        ...state,
        selectedBank: data.selectedBank,
        verifiedBank: !!data.selectedBank,
        showBankInfoSection: !!data.selectedBank,
        routingNumber: data.selectedBank ? data.selectedBank.RoutingNumber : '',
        accountNumber: data.selectedBank ? data.selectedBank.AccountNumber : '',
        bankInformation: {
          ...state.bankInformation,
        },
      };

    case ReduxEvent.RESET_SELECTED_BANK:
      return {
        ...state,
        selectedBank: null,
        selectedBankName: null,
      };

    case ReduxEvent.ROUTING_NUMBER_CHANGE:
      return {
        ...state,
        routingNumber: data.routingNumber,
      };

    case ReduxEvent.ACCOUNT_NUMBER_CHANGE:
      return {
        ...state,
        accountNumber: data.accountNumber,
      };

    case ReduxEvent.CUST_BANK_INFO_CHANGED:
      return {
        ...state,
        bankInformation: {
          ...state.bankInformation,
          ...data,
        },
        validationError: false,
      };

    case ReduxEvent.CUSTOMIZATION_MICR_CHANGED:
      return {
        ...state,
        micr: data.micr,
        micrMask: data.micrMask,
        showBankInfoSection: data.showBankInfoSection,
        verifiedBank: data.bankName !== '',
        banksFromRoutingNumber: data.banksFromRoutingNumber,
        fractional: data.fractional,
        selectedBankName: data.selectedBankName,
        bankInformation: {
          ...state.bankInformation,
          name: data.bankName,
          address: data.address,
          city: data.city,
          state: data.state,
          zipcode: data.zipcode,
          fractional: data.fractional,
        },
      };

    case ReduxEvent.CUSTOMIZATION_MICR_CHANGED_CSN:
      return {
        ...state,
        micrUpdated: !state.micrUpdated,
        micr: data.micr,
        micrMask: data.micrMask,
        fractional: data.fractional,
        banksFromRoutingNumber: data.banksFromRoutingNumber,
        bankInformation: {
          ...state.bankInformation,
          fractional: data.fractional,
        },
      };

    case ReduxEvent.CUSTOMIZATION_INTIALS_CHANGED:
      return {
        ...state,
        approveInitials: data.initials,
        validationError: false,
      };

    case ReduxEvent.CUSTOMIZATION_VALIDATION_ERROR_TOGGLE:
      return {
        ...state,
        validationError: data.error,
      };

    case ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE:
      return {
        ...state,
        ...data,
      };

    case ReduxEvent.CUSTOMIZATION_RESET_STATE:
      return initialState;

    case ReduxEvent.CUSTOMIZATION_TOGGLE_LOADING:
      return {
        ...state,
        loading: data.loading,
      };

    case ReduxEvent.CUSTOMIZATION_LOGO_CHANGED:
      return {
        ...state,
        logo: data.logo,
        logoOption: data.logoOption,
      };

    case ReduxEvent.CUSTOMIZATION_PRINTER_TYPE_CHANGED:
      return {
        ...state,
        printerType: data.printerType,
      };

    case ReduxEvent.CUSTOMIZATION_SHADOW_LOGO_CHANGED:
      return {
        ...state,
        shadowLogo: data.shadowLogo,
        shadowLogoOption: data.shadowLogoOption,
      };

    case ReduxEvent.CUSTOMIZATION_SAGA_ERROR:
      return {
        ...state,
        sagaError: true,
        loading: false,
        sagaErrorMessage: data.errorMessage,
      };
    case ReduxEvent.CUSTOMIZATION_SKU_CATEGORY_SELCTION:
      return {
        ...state,
        skuCategory: data.skuCategory,
      };

    case ReduxEvent.CHECK_STARTING_NUMBER:
      return {
        ...state,
        checkStartingNumber: data.checkStartingNumber,
        reverseNumbering: data.reverseNumbering,
        validationError: false,
      };

    case ReduxEvent.ENV_REQUIRED:
      return {
        ...state,
        matchingEnv: data.matchingEnv,
      };

    case ReduxEvent.SIGNATURE_LINES_SELECTED:
      return {
        ...state,
        signatureLines: data.signatureLines,
      };

    case ReduxEvent.ACCOUNT_OPEN_DATE:
      return {
        ...state,
        accountOpenDate: data.accountOpenDate,
      };

    case ReduxEvent.FRAUD_DETECT_RESULT:
      return {
        ...state,
        fdsRespn: data.fdsRespn,
      };

    case ReduxEvent.COMBO_BOX_LIST:
      return {
        ...state,
        comboDataSet: data.comboDataSet,
      };

    case ReduxEvent.IS_ACCOUN_ROUTING_CHANGED:
      return {
        ...state,
        ...data,
      };

    default:
      return state;
  }
};

export default customizationReducer;
