// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/businessenvelopes.scss';

// eslint-disable-next-line require-jsdoc
export const BusinessReplyEnvelopesImage = (props) => {
  const { company, fromModal, selectedFontFamily, image } = props;

  return (
    <div
      className={
        !fromModal
          ? 'business-envelopes-image-container'
          : 'business-envelopes-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      <div className="image-company-info">
        <div
          style={{
            fontSize: `${company.CompanyNameAttribute.FontSize}px`,
            fontWeight: company.CompanyNameAttribute.bold ? 'bold' : 'normal',
          }}
          className={`${selectedFontFamily.toLowerCase()}`}
        >
          {company.CompanyName}
        </div>
        <div
          style={{ fontSize: `${company.AddressLine1Attribute.FontSize}px` }}
          className={`${selectedFontFamily.toLowerCase()}`}
        >
          {company.AddressLine1}
        </div>
        <div
          style={{ fontSize: `${company.AddressLine2Attribute.FontSize}px` }}
          className={`${selectedFontFamily.toLowerCase()}`}
        >
          {company.AddressLine2}
        </div>
        <div
          style={{ fontSize: `${company.PhoneAttribute.FontSize}px` }}
          className={`${selectedFontFamily.toLowerCase()}`}
        >
          {company.Phone}
        </div>
      </div>
    </div>
  );
};
