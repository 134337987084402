// eslint-disable-next-line require-jsdoc
import React, { useEffect, useRef } from 'react';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
import IndeterminateShort from '@ids/indeterminate-short';
import { useDispatch, useSelector } from 'react-redux';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import Checkbox from '@ids/checkbox';
import { getVariantDetails } from '../../../services/customizationDataService';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import { createOutOfStockListForVariant } from '../../Util/CommonUtils';

// eslint-disable-next-line require-jsdoc
export const Quantity = (props) => {
  const { PFID, showEnvConfig, TooltipInfoEnvRequired, DefaultQuantity, TitleEnV, ColorToggler } =
    props;
  const [loading, setLoading] = React.useState(true);
  const [envloading, setEnvloading] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [pricing, setPricing] = React.useState([]);
  const [envPricing, setEnvPricing] = React.useState([]);
  const [OutOfStockSku, setOutofStockSku] = React.useState([]);
  const [OutOfStock, setOutOfStock] = React.useState({});
  const [checkSkuOFS, setCheckSkuOFS] = React.useState({});
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { selectedSku, matchingEnv, sheets, printerType } = customizationReducer;
  const mainReducer = useSelector((state) => state.mainReducer);
  const ProductOutOfStockisOutOfStockPhase3 =
    mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3 &&
    mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3.toLowerCase() === 'true';
  // this flag we will use to identify wether the component is mounted or not.
  const didMount = useRef(false);

  const dispatch = useDispatch();

  const getDefaultQuantitySku = (prices) => {
    let returnSku = '';
    if (DefaultQuantity) {
      const price = prices.find((x) => x.quantity === DefaultQuantity);
      if (price) {
        returnSku = price.sku;
      }
    }

    if (!returnSku && prices.length > 0) {
      returnSku = prices[0].sku;
    }

    return returnSku;
  };

  const dispatchEnvData = (envCheck, sku) => {
    dispatch({
      type: ReduxEvent.ENV_REQUIRED,
      data: {
        matchingEnv: {
          ...matchingEnv,
          envRequired: envCheck ? 'Y' : 'N',
          envelope_sku: sku,
        },
      },
    });
  };

  const matchingEnvelopesSku = (envCheck, skuVal) => {
    let envId = '';
    if (PFID === 'CHECK-14' || PFID === 'CHECK-09') {
      envId = 'ENV-02';
    } else {
      envId = 'ENV-08';
    }
    if (envCheck) {
      // Make this service call only if we do not have data. Otherwise do not keep making this call.
      if (envPricing.length === 0) {
        getVariantDetails(envId)
          .then((res) => {
            const resultEnvSku = res.fsg_variant;
            setEnvPricing(resultEnvSku);
            const skuQuantity = skuVal ? skuVal.split('-')[1] : selectedSku.split('-')[1];
            let envelope_sku = resultEnvSku.find((x) => x.sku.split('-')[1] === skuQuantity);
            if (!envelope_sku) {
              envelope_sku = getDefaultQuantitySku(resultEnvSku);
            }
            dispatchEnvData(envCheck, envelope_sku.sku);
            setEnvloading(false);
          })
          .catch((err) => {
            const msg = `Customization: Quantity -> getPricing failed for PFID Envelopes. Error: ${err}`;
            props.logToIMWebLogger('error', msg);
            setEnvloading(false);
            // setFailed(true);
          });
      } else {
        setEnvloading(false);
        const skuQuantity = skuVal ? skuVal.split('-')[1] : selectedSku.split('-')[1];
        let envelope_sku = envPricing.find((x) => x.sku.split('-')[1] === skuQuantity);
        if (!envelope_sku) {
          envelope_sku = getDefaultQuantitySku(envPricing);
        }
        dispatchEnvData(envCheck, envelope_sku.sku);
      }
    } else {
      setEnvloading(false);
      dispatchEnvData(envCheck, '');
    }
  };

  const setSelectedSku = (results, newSku) => {
    if (ProductOutOfStockisOutOfStockPhase3) {
      const isOutOfstockSku = OutOfStockSku.find((element) => element.sku === newSku);
      setCheckSkuOFS(isOutOfstockSku);
      if (props.handleOutofStock && !OutOfStock) {
        props.handleOutofStock(isOutOfstockSku);
      }
    }
    if (!results.find((x) => x.sku === newSku)) {
      const sku = getDefaultQuantitySku(results);
      newSku = sku;
      dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku } });
    }
  };

  const updateSelectedSku = (results, newSku) => {
    let sku_val = newSku;
    const qty = newSku && newSku.split('-')[1];
    const matchingVariant = results.find((x) => x.sku && x.sku.split('-')[1] === qty);
    if (matchingVariant) {
      sku_val = matchingVariant.sku;
    } else {
      sku_val = getDefaultQuantitySku(results);
    }
    dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku: sku_val } });
  };

  const getPricing = () => {
    setLoading(true);
    let printer_type = printerType;
    // Temporary fix for JIRA - UBERPSG-13246 - changing the printer type to dot matrix only to get the price details
    if (
      PFID &&
      PFID.toUpperCase() === 'CHECK-01' &&
      printerType &&
      printerType.toUpperCase() === 'LASER_INKJET' &&
      sheets === '2'
    ) {
      printer_type = 'Dot_Matrix';
    }
    getVariantDetails(PFID, sheets, printer_type)
      .then((res) => {
        let resultData = '';
        if (PFID === 'CHECK-15') {
          resultData = res.fsg_PersonalChecksvariant;
        } else {
          resultData = res.fsg_variant;
        }
        const results = resultData;
        if (ProductOutOfStockisOutOfStockPhase3) {
          const OFSList = createOutOfStockListForVariant(results);
          const BaseSkuFormat =
            results[0].sku.indexOf('-') === -1 ? results[0].sku : results[0].sku.indexOf('-');
          const outOfStockProduct = OFSList[0].baseSkuList.find(
            (o) => o.baseSku === results[0].sku.slice(0, BaseSkuFormat),
          );
          const outOfstockSku = OFSList[0].skuList;
          setOutofStockSku(outOfstockSku);
          setOutOfStock(outOfStockProduct);
          if (props.handleOutofStock) {
            props.handleOutofStock(outOfStockProduct);
          }
        }
        setPricing(results);
        updateSelectedSku(results, selectedSku);
        setLoading(false);
      })
      .catch((err) => {
        const msg = `Customization: Quantity -> getPricing failed for PFID ${PFID}. Error: ${err}`;
        props.logToIMWebLogger('error', msg);
        setFailed(true);
      });
  };

  const handleChange = (e) => {
    const sku = e.target.value;
    dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku } });

    const isBasicVoucher = !!(PFID === 'CHECK-02' && sku && sku.split('-')[1] === '00050');
    let colorData;
    let defaultValue;
    const colorDetail = [];
    if (isBasicVoucher) {
      colorDetail.push(ColorToggler[0]);
      colorData = colorDetail;
      defaultValue = colorData && colorData.find((x) => x.Val === 'classic-blue');
      dispatch({
        type: ReduxEvent.CUSTOM_IMAGE_SELECTED,
        data: {
          imageData: defaultValue.ImageSource,
          customColorValue: defaultValue.Val,
        },
      });
    } else {
      colorData = ColorToggler;
    }
    if (showEnvConfig && matchingEnv.envRequired === 'Y') {
      matchingEnvelopesSku(true, sku);
    }
  };

  const handleEnvChange = (e) => {
    const envCheck = e;
    setEnvloading(true);
    matchingEnvelopesSku(envCheck);
  };

  // The setSelectedSku will be called everytime except om the initial load.
  // We do not want to do anything with selectedsku on initial load.
  useEffect(() => {
    if (didMount.current) {
      setSelectedSku(pricing, selectedSku);
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSku]);
  // Update envelope sku only after imprint call is complete and reducer is updated
  useEffect(() => {
    if (!customizationReducer.loading) {
      if (showEnvConfig && matchingEnv.envRequired === 'Y') {
        matchingEnvelopesSku(true);
      }
      getPricing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizationReducer.loading]);

  return (
    <div className="customization-quantity-container">
      <h3 className="margin-bottom-20 component-header">Choose your quantity</h3>
      {ProductOutOfStockisOutOfStockPhase3 && OutOfStock && OutOfStock.displayOutOfStock ? (
        <div className="ofs-message">
          {OutOfStock.outOfStockMessage && OutOfStock.outOfStockMessage.trim().length > 0
            ? OutOfStock.outOfStockMessage
            : 'Temporarily out of stock.'}
        </div>
      ) : (
        <div>
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-100-dropdown dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            placeholder={loading ? 'Loading....' : 'Select Value'}
            onChange={handleChange}
            errorText={failed ? 'Unable to fetch the value.' : null}
            value={selectedSku}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | quantity | select_sku_quantity"
            data-ui-object-details="Select SKU Quantity"
          >
            {pricing.map((p) => {
              return (
                <MenuItem key={p.sku} value={p.sku}>{`${p.quantity} for $${(
                  p.list_price_cents / 100
                ).toFixed(2)}`}</MenuItem>
              );
            })}
          </Dropdown>

          {showEnvConfig && (
            <div className="width-reverse-tag">
              <p>Get the envelopes that help keep your checks secure:</p>
              <Checkbox
                theme="quickbooks"
                aria-label="select row"
                onChange={(e) => handleEnvChange(!!e.target.checked)}
                checked={matchingEnv.envRequired === 'Y'}
                disabled={envloading}
              />
              <span className="add-security-env">{TitleEnV}</span>
              {TooltipInfoEnvRequired && (
                <Tooltip message={<div>{TooltipInfoEnvRequired}</div>}>
                  <span className="circle-info-span padding-left-5">
                    <CircleInfoIcon size="x-small" />
                  </span>
                </Tooltip>
              )}
            </div>
          )}
          {ProductOutOfStockisOutOfStockPhase3 && checkSkuOFS && checkSkuOFS.displayOutOfStock && (
            <p className="ofs-message font-size-20px">{checkSkuOFS.outOfStockMessage}</p>
          )}
        </div>
      )}

      {envloading && (
        <p style={{ fontSize: '16px', fontStyle: 'italic', marginTop: '10px', color: '##393A' }}>
          <IndeterminateShort theme="quickbooks" size={20} />
          <span> Please wait...</span>
        </p>
      )}
    </div>
  );
};
