// eslint-disable-next-line require-jsdoc
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
import IndeterminateShort from '@ids/indeterminate-short';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import { CustomTextField } from '../../Common/CustomTextField';
import { BankInformation } from '../Common/BankInformation';
import { getBankInfoAndMicr, postFds } from '../../../services/customizationDataService';
import { getUniqueBankValue } from '../../Util/CommonUtils';
import { composeMicrInvalidBank } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const RoutingAndBankInformation = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const {
    banks,
    selectedBank,
    routingNumber,
    accountNumber,
    imprintId,
    validationError,
    checkStartingNumber,
  } = customizationReducer;

  const mainReducer = useSelector((state) => state.mainReducer);
  const { clientConfigs } = mainReducer;
  const fraudDetectCallEnabled = !!(
    clientConfigs && clientConfigs.GiactFraudDetectCallEnabled.toLowerCase() === 'true'
  );

  const { pageData } = mainReducer;
  const login = mainReducer.pageData.login;

  const { additionalProps, TooltipRoutingInfo, TooltipAccountInfo } = props;
  const { PFID, IsCheckProduct } = additionalProps;
  const dispatch = useDispatch();

  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirtyFields, setDirty] = useState([]);

  const dispatchEmptyMicr = () => {
    dispatch({
      type: ReduxEvent.CUSTOMIZATION_MICR_CHANGED,
      data: {
        micr: '',
        micrMask: '',
        city: '',
        state: '',
        zipcode: '',
        showBankInfoSection: false,
      },
    });
  };

  const dispatchResetFds = () => {
    const FraudDetectResult = {
      VerificationResponse: 0,
      AccountResponseCode: 0,
      TransId: 0,
      ActionType: null,
    };
    dispatch({
      type: ReduxEvent.FRAUD_DETECT_RESULT,
      data: { fdsRespn: FraudDetectResult },
    });
  };
  const handleKeydown = (e) => {
    if (['ArrowUp', 'ArrowDown'].indexOf(e.code) > -1) {
      e.preventDefault();
    }
  };

  const handleWheelScroll = (e) => {
    e.target.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  };

  const callPostFDSService = (routing, account) => {
    dispatchResetFds();
    postFds(routing, account).then((fdsRespn) => {
      const FraudDetectResult = {
        VerificationResponse: 0,
        AccountResponseCode: 0,
        TransId: 0,
        ActionType: null,
      };
      FraudDetectResult.VerificationResponse = fdsRespn.VerificationResponse
        ? fdsRespn.VerificationResponse
        : 0;
      FraudDetectResult.AccountResponseCode = fdsRespn.AccountResponseCode
        ? fdsRespn.AccountResponseCode
        : 0;
      FraudDetectResult.TransId = fdsRespn.TransId ? fdsRespn.TransId : 0;
      FraudDetectResult.ActionType = fdsRespn.ActionType ? fdsRespn.ActionType : null;

      dispatch({
        type: ReduxEvent.FRAUD_DETECT_RESULT,
        data: { fdsRespn: FraudDetectResult },
      });
    });
  };

  const handleMutipleBanksDropdown = (data) => {
    const itemData = data.map((p) => {
      return { value: `${p.bank_name}`, label: `${p.bank_name}` };
    });
    dispatch({ type: ReduxEvent.COMBO_BOX_LIST, data: { comboDataSet: itemData } });
  };

  const callGetMicrService = (routing, account, bank) => {
    setLoading(true);
    getBankInfoAndMicr(imprintId, PFID, routing, account)
      .then((res) => {
        let micrFromService = '';
        let bankNameFromService = '';
        let micrMaskFromService = '';
        let fractionalFromService = '';

        if (res.fsg_bank_spec.length > 0) {
          micrFromService = res.fsg_bank_spec[0].micr;
          micrMaskFromService = res.fsg_bank_spec[0].micr_mask;
          fractionalFromService = res.fsg_bank_spec[0].fractional;

          handleMutipleBanksDropdown(res.fsg_bank_spec);
          if (res.fsg_bank_spec.length > 1 && bank) {
            const defaultBank = res.fsg_bank_spec.filter((bankInfo) => {
              return bankInfo.bank_name === bank;
            });
            if (defaultBank && defaultBank.length > 0) {
              bankNameFromService = defaultBank[0].bank_name;
            }
          }
        }

        if (res.fsg_bank_spec.length === 0 && !!checkStartingNumber && !!checkStartingNumber.csn) {
          micrFromService = composeMicrInvalidBank(checkStartingNumber.csn, account, routing);
        }
        if (res.fsg_bank_spec.length === 1) {
          if (bank) {
            if (res.fsg_bank_spec[0].bank_name === bank) {
              bankNameFromService = res.fsg_bank_spec[0].bank_name;
            }
          } else {
            bankNameFromService = res.fsg_bank_spec[0].bank_name;
          }
        }

        dispatch({
          type: ReduxEvent.CUSTOMIZATION_MICR_CHANGED,
          data: {
            verifiedBank: !!bankNameFromService,
            micr: micrFromService,
            micrMask: micrMaskFromService,
            bankName: bankNameFromService,
            selectedBankName: bankNameFromService,
            fractional: fractionalFromService,
            banksFromRoutingNumber: res.fsg_bank_spec,
            showBankInfoSection: true,
            address: '',
            city: '',
            state: '',
            zipcode: '',
          },
        });
        dispatch({
          type: ReduxEvent.IS_ACCOUN_ROUTING_CHANGED,
          data: { isAccountRoutingChanged: false },
        });
        setLoading(false);
        setDirty([]);
      })
      .catch((err) => {
        props.logToIMWebLogger(
          'error',
          `Cutomization -> RoutingAndBankInformation: getBankInfoAndMicr failed  for ${PFID} (routing number : ${routing}). Error: ${err}`,
        );
        dispatchEmptyMicr();
        setLoading(false);
        setDirty([]);
      });
  };

  const setFieldDirty = (name) => {
    if (!dirtyFields.includes(name)) setDirty(dirtyFields.concat(name));
  };

  const bankSelectionChange = (e) => {
    setValidating(false);
    const { value } = e.target;
    const index = value.split('_');
    const bank = banks.find(
      (x) =>
        x.RoutingNumber === index[0] && x.AccountNumber === index[1] && x.BankName === index[2],
    );
    dispatch({ type: ReduxEvent.BANK_SELECTION_CHANGE, data: { selectedBank: bank } });

    if (bank) {
      callGetMicrService(bank.RoutingNumber, bank.AccountNumber, bank.BankName);
      if (IsCheckProduct && fraudDetectCallEnabled) {
        callPostFDSService(bank.RoutingNumber, bank.AccountNumber);
      }
    } else {
      dispatchEmptyMicr();
    }
  };

  const handleResetBankSelection = () => {
    dispatch({ type: ReduxEvent.RESET_SELECTED_BANK });
  };

  const routingNumberChange = (e) => {
    handleResetBankSelection();
    setFieldDirty('routing');
    setValidating(false);
    const { value } = e.target;

    if (value.length <= 9)
      dispatch({ type: ReduxEvent.ROUTING_NUMBER_CHANGE, data: { routingNumber: value } });
    dispatch({
      type: ReduxEvent.IS_ACCOUN_ROUTING_CHANGED,
      data: { isAccountRoutingChanged: true },
    });
  };

  const accountNumberChange = (e) => {
    handleResetBankSelection();
    setFieldDirty('account');
    setValidating(false);
    const { value } = e.target;
    if (value.length <= 20)
      dispatch({ type: ReduxEvent.ACCOUNT_NUMBER_CHANGE, data: { accountNumber: value } });
    dispatch({
      type: ReduxEvent.IS_ACCOUN_ROUTING_CHANGED,
      data: { isAccountRoutingChanged: true },
    });
  };

  const validateAccountInfo = () => {
    setValidating(true);

    if (routingNumber.length < 9) return;

    if (accountNumber.length < 3) return;

    if (dirtyFields.length > 0) callGetMicrService(routingNumber, accountNumber);

    if (IsCheckProduct && routingNumber.length >= 9 && fraudDetectCallEnabled)
      callPostFDSService(routingNumber, accountNumber);
  };

  const getErrorText = (text) => {
    switch (text) {
      case 'routing':
        if (routingNumber.length > 0 && routingNumber.length < 9 && validating) {
          return 'Your routing number should be 9 digits.';
        }
        if (validationError && routingNumber.length < 9)
          return 'Your routing number should be 9 digits.';

        return '';

      case 'account':
        if (accountNumber.length > 0 && accountNumber.length < 3 && validating) {
          return 'Enter your account number; account numbers are usually 7-10 digits.';
        }
        if (validationError && accountNumber.length < 3)
          return 'Enter your account number; account numbers are usually 7-10 digits.';

        return '';

      default:
        return '';
    }
  };

  useEffect(() => {
    // if (selectedBank) callGetMicrService(selectedBank.RoutingNumber, selectedBank.AccountNumber);
    // commented above line as we have stored the values in reducer and will be reading the same

    if (IsCheckProduct && routingNumber.length >= 9 && fraudDetectCallEnabled)
      callPostFDSService(routingNumber, accountNumber);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="margin-bottom-40">
      <h3 className="margin-bottom-20 component-header">
        {login
          ? 'Choose saved bank information or enter a new routing and bank account number.'
          : 'Enter your routing and bank account numbers'}
      </h3>
      {pageData.login && (
        <>
          <Dropdown
            data-wa-link="choose an account"
            data-ui-object="dropdown"
            data-ui-object-details="Choose saved bank information or enter a new routing and bank account number."
            data-refer-ui-access-point="choose_an_account"
            label=""
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="margin-bottom-20 width-100-dropdown dropdown-background-color-white-label"
            width="inherit"
            aria-label="quantity"
            onChange={bankSelectionChange}
            value={
              selectedBank
                ? `${selectedBank.RoutingNumber}_${selectedBank.AccountNumber}_${selectedBank.BankName}`
                : 'none'
            }
            disabled={loading}
          >
            <MenuItem value="none">Choose saved bank information...</MenuItem>
            {getUniqueBankValue(banks).map((p, key) => {
              return (
                <MenuItem
                  key={key}
                  value={`${p.RoutingNumber}_${p.AccountNumber}_${p.BankName}`}
                >{`${p.BankName} - ${p.AccountNumber}`}</MenuItem>
              );
            })}
          </Dropdown>
        </>
      )}

      <CustomTextField
        labelText="Routing number"
        showInfo
        tooltip={TooltipRoutingInfo}
        textFieldProps={{
          id: 'custom-routing-number',
          placeholder: 'Routing number',
          width: '100%',
          maxLength: 9,
          className: 'margin-bottom-20 custom-text-field',
          type: 'number',
          value: routingNumber,
          disabled: loading,
          onChange: routingNumberChange,
          onBlur: validateAccountInfo,
          onKeyDown: handleKeydown,
          onMouseEnter: handleWheelScroll,
          errorText: getErrorText('routing'),
          autoComplete: 'off',
        }}
      />

      <CustomTextField
        labelText="Account number"
        showInfo
        tooltip={TooltipAccountInfo}
        textFieldProps={{
          id: 'custom-acc-number',
          placeholder: 'Account number',
          width: '100%',
          maxLength: 20,
          className: 'custom-text-field',
          type: 'number',
          value: accountNumber,
          onChange: accountNumberChange,
          onBlur: validateAccountInfo,
          onKeyDown: handleKeydown,
          onMouseEnter: handleWheelScroll,
          disabled: loading,
          errorText: getErrorText('account'),
          autoComplete: 'off',
        }}
      />

      {loading && (
        <p style={{ fontSize: '16px', fontStyle: 'italic', marginTop: '10px', color: '##393A' }}>
          <IndeterminateShort theme="quickbooks" size={24} />
          <span> Validating...</span>
        </p>
      )}

      <BankInformation handleResetBankSelection={handleResetBankSelection} />
    </div>
  );
};
