import { doPostFetch } from './utils/fetchUtil';
import { POST_SHOPPER_INFORMATION } from './config/url';

export const postShopperInfo = (shopperInfo, urlParams) => {
  const uri = `${POST_SHOPPER_INFORMATION.url}?`;
  const params = Object.entries(urlParams)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
  return doPostFetch({ url: uri + params }, shopperInfo);
};
