// eslint-disable-next-line require-jsdoc
import React, { useEffect } from 'react';
// import Radio, { RadioGroup } from '@ids/radio';
import { useDispatch, useSelector } from 'react-redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@ids-beta/tooltip';
import { CircleInfoIcon } from '@ids-ts/icon';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import { getVariantDetails } from '../../../services/customizationDataService';
import { convertToSnakeCase, createOutOfStockListForVariant } from '../../Util/CommonUtils';

// eslint-disable-next-line require-jsdoc
export const SkuQuantitySelection = (props) => {
  const { ColorToggler, Title, TooltipInfo, PFID, DefaultQuantity } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { customColor } = customizationReducer;
  const [loading, setLoading] = React.useState(true);
  const [failed, setFailed] = React.useState(false);
  const [pricing, setPricing] = React.useState([]);
  const [OutOfStockSku, setOutofStockSku] = React.useState({});
  const [OutOfStock, setOutOfStock] = React.useState({});
  const { selectedSku } = customizationReducer;
  const mainReducer = useSelector((state) => state.mainReducer);
  const ProductOutOfStockisOutOfStockPhase3 =
    mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3 &&
    mainReducer.clientConfigs.ProductOutOfStockisOutOfStockPhase3.toLowerCase() === 'true';

  const dispatch = useDispatch();

  const getDefaultQuantitySku = (prices) => {
    let returnSku = '';
    if (DefaultQuantity) {
      const price = prices.find((x) => x.quantity === DefaultQuantity);
      if (price) {
        returnSku = price.sku;
      }
    }

    if (!returnSku && prices.length > 0) {
      returnSku = prices[0].sku;
    }

    return returnSku;
  };

  const getPricing = (skuValue) => {
    setLoading(true);
    getVariantDetails(PFID)
      .then((res) => {
        const results = res.fsg_variant.filter(function (e) {
          return e.sku.includes(skuValue) === true;
        });
        if (ProductOutOfStockisOutOfStockPhase3) {
          const OFSList = createOutOfStockListForVariant(results);
          const outOfStockProduct = OFSList[0].baseSkuList.find(
            (o) => o.baseSku === results[0].sku.slice(0, results[0].sku.indexOf('-')),
          );
          const outOfstockSku = OFSList[0].skuList.find((element) => element.sku === selectedSku);
          setOutofStockSku(outOfstockSku);
          setOutOfStock(outOfStockProduct);
          if (props.handleOutofStock) {
            if (outOfStockProduct) {
              props.handleOutofStock(outOfStockProduct);
            } else {
              props.handleOutofStock(outOfstockSku);
            }
          }
        }
        setPricing(results);
        if (!results.find((x) => x.sku === selectedSku)) {
          const sku = getDefaultQuantitySku(results);
          dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku } });
        }
        setLoading(false);
      })
      .catch((err) => {
        const msg = `Customization: Quantity -> getPricing failed for PFID ${PFID}. Error: ${err}`;
        props.logToIMWebLogger('error', msg);
        setFailed(true);
      });
  };

  const handleColorChange = (_, colrVal) => {
    const defaultValue = ColorToggler.find((x) => x.Val === colrVal);
    // const imageData = value;
    // dispatch({ type: ReduxEvent.CUSTOM_IMAGE_SELECTED, data: { imageData } });
    dispatch({
      type: ReduxEvent.CUSTOM_IMAGE_SELECTED,
      data: {
        imageData: defaultValue.ImageSource,
        customColorValue: defaultValue.Val,
      },
    });
    getPricing(defaultValue.PredefinedSku);
  };

  const handleChange = (e) => {
    const sku = e.target.value;
    dispatch({ type: ReduxEvent.CUSTOMIZATION_SKU_SELECTION_CHANGED, data: { sku } });
  };

  useEffect(() => {
    const defaultValue = ColorToggler.find((x) => x.Default);
    const imageData =
      customColor.selectedColorPickerImage !== ''
        ? customColor.selectedColorPickerImage
        : defaultValue.ImageSource;
    const imageName =
      customColor.customColorValue !== '' ? customColor.customColorValue : defaultValue.Val;

    dispatch({
      type: ReduxEvent.CUSTOM_IMAGE_SELECTED,
      data: {
        imageData,
        customColorValue: imageName,
      },
    });

    if (selectedSku) {
      const definedSku = selectedSku.split('-');
      getPricing(definedSku[0]);
    } else {
      getPricing(defaultValue.PredefinedSku);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSku]);

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        {Title}{' '}
        {TooltipInfo && (
          <Tooltip message={<div>{TooltipInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <ToggleButtonGroup
        value={customColor.customColorValue}
        exclusive
        onChange={handleColorChange}
        aria-label="color picker"
        className="cust-color-picker"
      >
        {ColorToggler.map((el, key) => {
          return (
            <ToggleButton
              key={key}
              value={el.Val}
              aria-label="centered"
              data-wa-link={el.Key}
              className="color-toggler"
              data-ui-object="segmented_button"
              data-refer-ui-access-point={`customization_page | customiozation_envelopes | ${convertToSnakeCase(
                el.Key,
              )}`}
              data-ui-object-details={Title}
            >
              <Tooltip
                role="tooltip"
                automationId="tooltip_custom"
                message={
                  <div>
                    <span>{el.Val}</span>
                  </div>
                }
              >
                <img src={el.Imageurl} alt="Select product color" />
              </Tooltip>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <hr className="section-divider" />

      <h3 className="margin-bottom-20 component-header">Choose your quantity</h3>
      {ProductOutOfStockisOutOfStockPhase3 && OutOfStock && OutOfStock.displayOutOfStock ? (
        <div className="ofs-message">
          {OutOfStock.outOfStockMessage && OutOfStock.outOfStockMessage.trim().length > 0
            ? OutOfStock.outOfStockMessage
            : 'Temporarily out of stock.'}
        </div>
      ) : (
        <div>
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-100-dropdown dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            placeholder={loading ? 'Loading....' : 'Select Value'}
            helperText={loading ? 'Loading....' : ''}
            onChange={handleChange}
            errorText={failed ? 'Unable to fetch the value.' : null}
            value={selectedSku}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | customiozation_envelopes | select_sku"
            data-ui-object-details="Choose your quantity"
          >
            {pricing.map((p) => {
              return (
                <MenuItem key={p.sku} value={p.sku}>{`${p.quantity} for $${(
                  p.list_price_cents / 100
                ).toFixed(2)}`}</MenuItem>
              );
            })}
          </Dropdown>
          {ProductOutOfStockisOutOfStockPhase3 &&
            OutOfStockSku &&
            OutOfStockSku.displayOutOfStock && (
              <p className="ofs-message font-size-20px">{OutOfStockSku.outOfStockMessage}</p>
            )}
        </div>
      )}
    </div>
  );
};
