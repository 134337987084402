// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/stamps.scss';

// eslint-disable-next-line require-jsdoc
export const EndorsementStampImage = (props) => {
  const { bankInformation, company, bankAccount, fromModal, image } = props;

  return (
    <div
      className={
        !fromModal ? 'endorsement-stamp-image-container' : 'endorsement-stamp-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      <div className="image-company-info">
        <div>PAY TO THE ORDER OF</div>
        {bankInformation.name ? (
          <div>
            <b>{bankInformation.name}</b>
          </div>
        ) : (
          <br />
        )}
        <div style={{ marginTop: '5px' }}>FOR DEPOSIT ONLY</div>
        <div>{company.CompanyName}</div>
        <div>{bankAccount}</div>
      </div>
    </div>
  );
};
