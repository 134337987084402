import fetch from 'node-fetch';
// import { getEnvironmentConfig } from '../config/restClientEnvConfig';
import AbortController from 'abort-controller';
import { handleSessionTimeOut } from '../../components/Util/CommonUtils';

export function getUrlConfig() {
  /* const { hostname } = getEnvironmentConfig();
  return {
    baseUrl: hostname,
  }; */
  /* Commented the above code to address the CORS issue, 
  domain was hardcoded to intuitmarket.intuit.com, 
  where as we have www domain as well. So removed the domain path in api calls. */
  return {
    baseUrl: '/',
  };
}

export function getKeyValuePairs(params, separator) {
  return `${Object.entries(params).reduce(
    (tempUrl, [key, value]) => `${tempUrl}${key}=${value}${separator}`,
    '',
  )}`;
}
// Get data with Params
export function doGetFetch(
  urlConfig,
  params = {},
  separator = '&',
  headerKey,
  contentType = 'application/json',
) {
  const { url } = urlConfig;
  const keyValueStr = getKeyValuePairs(params, separator).replace(/[&,;]$/, '');
  const { baseUrl } = getUrlConfig();
  const finalUrl = `${baseUrl}${url}${keyValueStr === '' ? '' : `?${keyValueStr}`}`;

  let headers = {
    'Content-Type': contentType,
  };

  if (headerKey) {
    headers = {
      ...headers,
      SecureKey: headerKey,
    };
  }

  return fetch(finalUrl, {
    method: 'get',
    headers,
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

// Post Call with secure Key as request header
export function doPostParamsFetch(
  urlConfig,
  params = {},
  headerKey,
  contentType = 'application/json',
) {
  const { url } = urlConfig;
  const { baseUrl } = getUrlConfig();
  const finalUrl = `${baseUrl}${url}`;
  let headers = {
    'Content-Type': contentType,
  };
  if (headerKey) {
    headers = {
      ...headers,
      SecureKey: headerKey,
    };
  }
  return fetch(finalUrl, {
    method: 'post',
    body: JSON.stringify(params),
    headers,
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export function doGetFetchWithHeader(urlConfig, params = {}, separator = ';', headerKey) {
  const { url } = urlConfig;
  const keyValueStr = getKeyValuePairs(params, separator).replace(/[&,;]$/, '');
  const { baseUrl } = getUrlConfig();
  const finalUrl = `${baseUrl}${url}${keyValueStr === '' ? '' : `?${keyValueStr}`}`;

  let headers = {
    'Content-Type': 'application/json',
  };

  const headerMap = {};

  if (headerKey) {
    headers = {
      ...headers,
      SecureKey: headerKey,
    };
  }

  return fetch(finalUrl, {
    method: 'get',
    headers,
  })
    .then((response) => {
      if (!response.ok) {
        handleSessionTimeOut(response);
        throw Error(response.statusText);
      }

      response.headers.forEach((value, key) => {
        headerMap[key] = [value];
      });

      return response.json();
    })
    .then((json) => {
      return {
        data: json,
        headers: headerMap,
      };
    });
}

export function doPostFetch(urlConfig, params = {}) {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;
  return fetch(finalUrl, {
    method: 'post',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }

    if (response.status === 204 || response.status === 202) {
      return response;
    }
    return response.json();
  });
}

export function doPostFetchToken(urlConfig, params = {}) {
  return fetch(urlConfig, {
    method: 'post',
    body: JSON.stringify(params.walletRequest),
    headers: {
      Accept: '*/*',
      Authorization: params.autherization,
      'Content-Type': 'application/json; charset=utf-8',
    },
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw response;
      // throw Error(response);
    }

    if (response.status === 204 || response.status === 202) {
      return response;
    }
    return response.json();
  });
}

export function doPutFetch(urlConfig, params = {}) {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;

  return fetch(finalUrl, {
    method: 'put',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });
}

// to process PUT response
export function doPutFetchResponse(urlConfig, params = '') {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;

  return fetch(finalUrl, {
    method: 'put',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      handleSessionTimeOut(response);
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    })
    .then((res) => {
      return res;
    });
}

// Get data without Params
export function doGetFetchData(urlConfig, headerKey) {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;

  return fetch(finalUrl, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      SecureKey: headerKey,
    },
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

export function doDeleteData(urlConfig, params = {}) {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;

  return fetch(finalUrl, {
    method: 'delete',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });
}

export function doPostFormFetch(urlConfig, params = {}) {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;
  return fetch(finalUrl, {
    method: 'post',
    body: params,
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    if (response.statusText === 'No Content') {
      return response;
    }
    return response.json();
  });
}

export function doPostForm(urlConfig, formData) {
  const { baseUrl } = getUrlConfig();
  const { url } = urlConfig;
  const finalUrl = `${baseUrl}${url}`;
  return fetch(finalUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formData.toString(),
    json: true,
  }).then((response) => {
    handleSessionTimeOut(response);
    if (!response.ok) {
      throw Error(response.statusText);
    }
    if (response.statusText === 'No Content') {
      return response;
    }
    return response.json();
  });
}

export function doPostQl(TOKEN, uri, payload, customHeader, walletCallTimeOut) {
  const pay_load = {
    query: TOKEN,
    variables: payload,
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  if (customHeader) {
    customHeader.forEach((custHeader) => {
      headers[custHeader.key] = custHeader.value;
    });
  }

  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, walletCallTimeOut);

  return fetch(uri, {
    credentials: 'include',
    method: 'post',
    headers,
    body: JSON.stringify(pay_load),
    dataType: 'json',
    signal: controller.signal,
  })
    .then((promise) => {
      if (!promise.ok) {
        if (promise.name === 'AbortError') {
          throw 'timeout';
        } else {
          throw promise;
        }
      } else {
        return promise;
      }
    })
    .finally(() => {
      clearTimeout(timeout);
    });
}

export function doWalletPost(url, params = {}, customHeader, walletCallTimeOut) {
  const finalUrl = url;
  const headers = {
    'Content-Type': 'application/json',
  };
  if (customHeader) {
    customHeader.forEach((custHeader) => {
      headers[custHeader.key] = custHeader.value;
    });
  }

  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, walletCallTimeOut);

  return fetch(finalUrl, {
    credentials: 'include',
    method: 'post',
    headers,
    body: JSON.stringify(params),
    dataType: 'json',
    signal: controller.signal,
  })
    .then((promise) => {
      if (!promise.ok) {
        if (promise.name === 'AbortError') {
          throw 'timeout';
        } else {
          throw promise;
        }
      } else {
        return promise;
      }
    })
    .finally(() => {
      clearTimeout(timeout);
    });
}
