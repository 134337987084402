// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/statements.scss';
import { formatStandardImageUrl, getHexByColor } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const ProductInvoiceImage = (props) => {
  const {
    image,
    company,
    logo,
    logoColor,
    shadowLogo,
    shadowLogoColor,
    fromModal,
    selectedFontColor,
    selectedFontFamily,
    companyInfoColor,
  } = props;

  const getLogoUrl = () => {
    const url = new URL(formatStandardImageUrl(logo.Url));
    url.searchParams.set('color', getHexByColor(logoColor || selectedFontColor).replace('#', ''));

    return url.toString();
  };

  const getShadowLogoUrl = () => {
    let shadowLogoUrl = shadowLogo.Url;
    shadowLogoUrl = shadowLogoUrl
      ? shadowLogoUrl.slice(0, shadowLogoUrl.indexOf('&opacity=15'))
      : shadowLogoUrl;
    const url = new URL(formatStandardImageUrl(shadowLogoUrl));
    url.searchParams.set('width', '360');
    url.searchParams.set('height', '216');
    url.searchParams.set(
      'color',
      getHexByColor(shadowLogoColor || selectedFontColor).replace('#', ''),
    );

    return url.toString();
  };

  return (
    <div
      className={
        !fromModal ? 'product-invoice-image-container' : 'product-invoice-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      {logo && <img className="logo" src={getLogoUrl()} alt="..." />}
      {shadowLogo && <img className="shadow-logo" src={getShadowLogoUrl()} alt="..." />}
      <div
        className={`image-company-info ${
          companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
        } ${selectedFontFamily.toLowerCase()}`}
      >
        <div
          style={{
            fontSize: `${company.CompanyNameAttribute.FontSize}px`,
            fontWeight: company.CompanyNameAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.CompanyName}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine1Attribute.FontSize}px`,
            fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.AddressLine1}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine2Attribute.FontSize}px`,
            fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.AddressLine2}
        </div>
        <div
          style={{
            fontSize: `${company.PhoneAttribute.FontSize}px`,
            fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.Phone}
        </div>
        <div
          style={{
            fontSize: `${company.ExtraLineAttribute.FontSize}px`,
            fontWeight: company.ExtraLineAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.ExtraLine}
        </div>
        <div
          style={{
            fontSize: `${company.ExtraLine2Attribute.FontSize}px`,
            fontWeight: company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.ExtraLine2}
        </div>
      </div>
    </div>
  );
};
