import React from 'react';

const DiscountPageData = {
  DISCOUNT: {
    heading: 'Save big on QuickBooks Checks, Forms & Supplies!',
    subHeading: (
      <>
        Visit{' '}
        <strong>
          <a href="//intuitmarket.com/extra20online">intuitmarket.com/extra20online</a>
        </strong>{' '}
        or enter extra20online at checkout to take advantage of this discount code.
      </>
    ),
    productDiscounts: [
      {
        helperTitle: (
          <>
            Over 74% of companies that experienced payment fraud were victims of check fraud.
            <sup>1</sup>
          </>
        ),
        title: 'Help keep your business safe from fraud with QuickBooks Secure Checks.',
        description: (
          <>
            Secure Premier Checks offer <strong>29 powerful security features</strong> including:
            <ul>
              <li>
                Security hologram makes your check{' '}
                <strong>virtually impossible to counterfeit</strong>
              </li>
              <li>
                Exclusive security coating <strong>creates a visible stain</strong> if information
                on the check is tampered with
              </li>
              <li>
                QuickBooks proprietary paper stock makes the checks{' '}
                <strong>extremely difficult to duplicate</strong>
              </li>
            </ul>
          </>
        ),
        link: '/spvcpremier?track=56081&src=extra20online',
        linkText: 'Get 20% off now',
      },
      {
        title: 'Enjoy the ease of our Pre-Printed W-2 Kits',
        description: (
          <ul>
            <li>
              Use for reporting employee wages and salaries to Federal, State and local agencies,
              with a copy for your employee.
            </li>
            <li>
              Easily print data directly from QuickBooks Desktop onto the correct section of each
              form.
            </li>
            <li>
              Specifically designed for small businesses – available in quantities as low as 10 so
              you don't buy forms you won't need.
            </li>
          </ul>
        ),
        link: '/tax-forms/w2-forms/pre-printed-w-2-kits?track=56081&src=extra20online',
        linkText: 'Get 20% off now',
      },
      {
        title: 'Save time with our Printable Deposit Slips',
        description: (
          <ul>
            <li>
              Save time by printing deposit slips directly from QuickBooks with information you’ve
              already entered.
            </li>
            <li>Improve record-keeping accuracy by avoiding double entry errors.</li>
            <li>Manual deposit slips also available.</li>
          </ul>
        ),
        link: '/deposit-slips/printable?track=56081&src=extra20online',
        linkText: 'Get 20% off now',
      },
      {
        title: 'Get a 20% savings on QuickBooks Checks, Forms, & Supplies for yourself.',
        description: (
          <>
            <div>Share this discount code with your friends to help them get started.</div>
            <div>
              Go to{' '}
              <a href="//intuitmarket.com/extra20online">
                <strong>intuitmarket.com/extra20online</strong>
              </a>{' '}
              or use the discount code <strong>extra20online</strong> at checkout
            </div>
          </>
        ),
      },
    ],
    disclaimers: (
      <ul>
        <li>1 Association for Financial Professionals 2019 Payments Fraud and Control Survey</li>
        <li>Promo code cannot be combined with other offers. Offer only available online.</li>
      </ul>
    ),
  },
};

export default DiscountPageData;
