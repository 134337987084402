// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/forms.scss';
import { formatLogoUrl, formatShadowLogoUrl } from '../../Util/CustomizationUtil';

// eslint-disable-next-line require-jsdoc
export const MultiPurposeFormImage = (props) => {
  const {
    image,
    company,
    logo,
    logoColor,
    shadowLogo,
    shadowLogoColor,
    fromModal,
    selectedFontColor,
    selectedFontFamily,
    companyInfoColor,
  } = props;

  return (
    <div
      className={
        !fromModal ? 'product-invoice-image-container' : 'product-invoice-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      {logo && (
        <img className="logo" src={formatLogoUrl(logo, logoColor, selectedFontColor)} alt="..." />
      )}
      {shadowLogo && (
        <img
          className="shadow-logo"
          src={formatShadowLogoUrl(shadowLogo, shadowLogoColor, selectedFontColor)}
          alt="..."
        />
      )}
      <div
        className={`image-company-info ${
          companyInfoColor ? companyInfoColor.toLowerCase() : selectedFontColor.toLowerCase()
        } ${selectedFontFamily.toLowerCase()}`}
      >
        <div
          style={{
            fontSize: `${company.CompanyNameAttribute.FontSize}px`,
            fontWeight: company.CompanyNameAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.CompanyName}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine1Attribute.FontSize}px`,
            fontWeight: company.AddressLine1Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.AddressLine1}
        </div>
        <div
          style={{
            fontSize: `${company.AddressLine2Attribute.FontSize}px`,
            fontWeight: company.AddressLine2Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.AddressLine2}
        </div>
        <div
          style={{
            fontSize: `${company.PhoneAttribute.FontSize}px`,
            fontWeight: company.PhoneAttribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.Phone}
        </div>
        <div
          style={{
            fontSize: `${company.ExtraLine2Attribute.FontSize}px`,
            fontWeight: company.ExtraLine2Attribute.bold ? 'bold' : 'normal',
          }}
        >
          {company.ExtraLine2}
        </div>
      </div>
    </div>
  );
};
