// eslint-disable-next-line require-jsdoc
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';
import { addLogoOptions } from '../Configuration/CustomizationConfiguration';
import { CommonAddLogoComponent } from './CommonAddLogoComponent';

// eslint-disable-next-line require-jsdoc
export const AddShadowMonogramOrLogo = (props) => {
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { shadowLogo } = customizationReducer;

  const dispatch = useDispatch();
  const saveLogo = (params) => {
    if (params) {
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_SHADOW_LOGO_CHANGED,
        data: {
          shadowLogo: params.logo,
          shadowLogoOption: addLogoOptions[params.logoOption],
        },
      });
    }
  };

  const removeLogo = () => {
    dispatch({
      type: ReduxEvent.CUSTOMIZATION_SHADOW_LOGO_CHANGED,
      data: { shadowLogo: null, shadowLogoOption: null },
    });
  };

  return (
    <div>
      <CommonAddLogoComponent
        {...props}
        saveLogo={saveLogo}
        removeLogo={removeLogo}
        logo={shadowLogo}
      />
    </div>
  );
};
