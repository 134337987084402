/**
 * Had to introduce this component to handle the metadata change
 * on change of route
 */

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainComponentPage from './MainComponentPage';

const RootComponent = (props) => {
  return (
    <Router>
      <MainComponentPage {...props} />
    </Router>
  );
};
export default RootComponent;
