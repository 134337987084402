import { ReduxEvent } from '../../../assets/pagedata/constant';
import { RetrieveOrderModule } from '../../../assets/pagedata/constant';
// eslint-disable-next-line require-jsdoc
const initialState = {
  currentStep: RetrieveOrderModule.find,
  orders: [],
  searchById: 0,
  selectedOrder: null,
  securityQuestion: null,
  loading: false,
};

const retrieveOrderReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ReduxEvent.RETRIEVE_ORDER_SUCCESS:
      return {
        ...state,
        currentStep: RetrieveOrderModule.orderList,
        orders: data.orders,
        searchById: data.searchById,
        loading: data.loading !== null ? data.loading : state.loading,
      };

    case ReduxEvent.RETRIEVE_ORDER_MOVE_STEP:
      return {
        ...state,
        currentStep: data.goToStep,
        orders: data.orders ? data.orders : state.orders,
        searchById: data.searchById ? data.searchById : state.searchById,
      };

    case ReduxEvent.RETRIEVE_ORDER_GET_SECURITY_QUESTION_SUCCESS:
      return {
        ...state,
        currentStep: data.gotToStep,
        orders: [],
        selectedOrder: data.selectedOrder,
        securityQuestion: data.securityQuestion,
        loading: data.loading !== null ? data.loading : state.loading,
      };

    case ReduxEvent.RETRIEVE_ORDER_TOGGLE_LOADING:
      return {
        ...state,
        loading: data.loading,
      };

    case ReduxEvent.RETRIEVE_ORDER_REORDER_SUCCESS:
      return {
        ...state,
        currentStep: data.gotToStep,
        selectedOrder: data.selectedOrder,
        loading: data.loading !== null ? data.loading : state.loading,
      };

    case ReduxEvent.RETRIEVE_ORDER_RESET_INTIAL:
      return {
        currentStep: RetrieveOrderModule.find,
        orders: [],
        searchById: 0,
        selectedOrder: null,
        securityQuestion: null,
        loading: false,
      };

    default:
      return state;
  }
};

export default retrieveOrderReducer;
