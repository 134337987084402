// eslint-disable-next-line require-jsdoc
import React from 'react';
import TextField from '@ids/text-field';
// import { CircleInfoIcon } from '@ids-ts/icon';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent, validStatesInUS } from '../../../../assets/pagedata/constant';
import { ComboBox } from './ComboBox';
// import { ReduxEvent } from '../../../../assets/pagedata/constant';
// import { CustomTextField } from '../../Common/CustomTextField';

// eslint-disable-next-line require-jsdoc
export const BankInformation = (props) => {
  // const [componayInfo, setCompanyInfo] = useState('');
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const {
    verifiedBank,
    showBankInfoSection,
    bankInformation,
    validationError,
    banksFromRoutingNumber,
    selectedBankName,
  } = customizationReducer;
  const dispatch = useDispatch();

  const handleTextChanged = (text, val) => {
    dispatch({ type: ReduxEvent.CUST_BANK_INFO_CHANGED, data: { [text]: val } });
  };

  const handleBankDropDownChange = (value) => {
    dispatch({ type: ReduxEvent.CUST_BANK_INFO_CHANGED, data: { name: value } });
  };

  if (!showBankInfoSection) {
    return null;
  }

  return (
    <div>
      <hr className="section-divider" />

      <h3 className="margin-bottom-20 component-header">
        {`${verifiedBank ? 'Check' : 'Add'} your bank's information`}
      </h3>

      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          {banksFromRoutingNumber && banksFromRoutingNumber.length > 1 ? (
            <>
              <p className="margin-bottom-10 text-label" style={{ fontSize: '16px' }}>
                Bank name
              </p>
              <ComboBox
                handleDropDownChange={handleBankDropDownChange}
                defaultSelectedValue={selectedBankName}
                defaultTypedValue={bankInformation.name}
                handleResetSelection={props.handleResetBankSelection}
                validationError={validationError}
                errMsg="Please enter bank name"
              />
            </>
          ) : (
            <TextField
              theme="quickbooks"
              type="text"
              label="Bank name"
              className="ids-text-field"
              width="100%"
              value={bankInformation.name}
              onChange={(e) => {
                handleTextChanged('name', e.target.value);
                props.handleResetBankSelection();
              }}
              errorText={validationError & !bankInformation.name ? 'Please enter bank name' : ''}
            />
          )}
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="Address"
            className="ids-text-field"
            width="100%"
            value={bankInformation.address}
            onChange={(e) => handleTextChanged('address', e.target.value)}
          />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 margin-bottom-20">
          <TextField
            theme="quickbooks"
            type="text"
            label="City"
            className="ids-text-field"
            width="100%"
            value={bankInformation.city}
            onChange={(e) => handleTextChanged('city', e.target.value)}
          />
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 margin-bottom-20">
          <div className="padding-left-10">
            <TextField
              theme="quickbooks"
              type="text"
              label="State"
              className="ids-text-field"
              width="100%"
              value={bankInformation.state}
              onChange={(e) => {
                if (e.target.value.length <= 2)
                  handleTextChanged('state', e.target.value.toUpperCase());
              }}
              errorText={
                validationError &&
                bankInformation.state.length > 0 &&
                !validStatesInUS.includes(bankInformation.state)
                  ? 'Please verify'
                  : ''
              }
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom-20">
          <div className="padding-left-10">
            <TextField
              theme="quickbooks"
              type="number"
              label="Zip code"
              className="ids-text-field"
              width="100%"
              value={bankInformation.zipcode}
              onChange={(e) => {
                if (e.target.value.length <= 5) handleTextChanged('zipcode', e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
