import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/*
 * Responsive grid supports mobile or xs mode at 480px.
 * But according to specs it needs to be 375px.
 *Include the custom built css for mobile everytime the responsivegrid is included.
 */
import '@ids-alpha/responsive-grid';
import '../../../stylesheets/responsiveGridStyles.scss';
import '../../../stylesheets/notFound.scss';
import HostedSignIn from '../Header/HostedSignIn';

// eslint-disable-next-line require-jsdoc
const NotFoundPage = (props) => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const closeSignInModal = () => {
    setShowSignInModal(false);
  };

  const openSignInModal = (flag) => {
    setShowSignInModal(flag);
  };

  const onSignInClicked = (e) => {
    e.preventDefault();
    openSignInModal(true);
  };

  return (
    <div>
      <div className="container-center-lg">
        <div id="notFoundToken343314k4j21k4j" style={{ display: 'none' }} />
        <div className="margin-main-section">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-12">
              <h1 className="pnf-header">Sorry, that page isn’t available</h1>
              <div className="pnf-sub-section">
                <h2 className="pnf-sub-header">Buy it again.</h2>
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={onSignInClicked}
                  to="/"
                  aria-label="Go to Sign In page"
                  data-wa-link="Sign In"
                  data-ui-object="link"
                  data-refer-ui-access-point="sign_in | not_found_page"
                  className="pnf-link pnf-signin"
                >
                  <p className="pnf-text-link">Sign in to reorder</p>
                </Link>
                <Link to="/orderstatus/lookup" className="pnf-link">
                  <p className="pnf-text-link">Find a previous order</p>
                </Link>
              </div>
            </div>
          </div>

          <div className="pnf-sub-section">
            <h2 className="pnf-sub-header">Shop for checks and supplies.</h2>
            <Link to="/checks" className="pnf-link">
              <p className="pnf-text-link">QuickBooks checks</p>
            </Link>
            <Link to="/secure-products" className="pnf-link">
              <p className="pnf-text-link">Security products</p>
            </Link>
            <Link to="/tax-forms" className="pnf-link">
              <p className="pnf-text-link">Tax forms</p>
            </Link>
            <Link to="/envelopes" className="pnf-link">
              <p className="pnf-text-link">Envelopes</p>
            </Link>
          </div>

          <div>
            <h2 className="pnf-sub-header">We’re here to help.</h2>
            <Link to="/help" className="pnf-link">
              <p className="pnf-text-link">QuickBooks Checks & Supplies help</p>
            </Link>
            <p className="pnf-contact">Call us at (866) 570-3842, Mon. - Fri., 9am-8pm ET.</p>
          </div>
        </div>
      </div>
      {showSignInModal && (
        <HostedSignIn
          {...props}
          showModal={showSignInModal}
          closeModal={closeSignInModal}
          location="orderhistory"
        />
      )}
    </div>
  );
};

export default NotFoundPage;
