// eslint-disable-next-line require-jsdoc
import React from 'react';
import TextField from '@ids/text-field';
import { CircleInfoIcon } from '@ids-ts/icon';
import Tooltip from '@ids-beta/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxEvent } from '../../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
export const CompanyInformation = (props) => {
  // const [componayInfo, setCompanyInfo] = useState('');
  const { allCaps, hideExtraline, maxLength, showNameInSummary, Title, TooltipCompanyInfo } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const { company, summaryText } = customizationReducer;
  const dispatch = useDispatch();

  const textLength = maxLength || 25;

  const handleTextChanged = (key, value) => {
    if (value.length <= textLength)
      dispatch({
        type: ReduxEvent.COMPANY_INFO_TEXT_CHANGED,
        data: {
          company: {
            ...company,
            [key]: allCaps ? value.toUpperCase() : value,
          },
        },
      });
  };

  const handleCompanyTextChanged = (key, value) => {
    if (value && showNameInSummary && !summaryText.text) {
      dispatch({
        type: ReduxEvent.SUMMARY_TEXT_CHANGED,
        data: {
          summaryText: {
            ...summaryText,
            text: value,
          },
        },
      });
    }
  };

  return (
    <div>
      <h3 className="margin-bottom-20 component-header">
        {Title || ' Enter your company information'}{' '}
        {TooltipCompanyInfo && (
          <Tooltip message={<div>{TooltipCompanyInfo}</div>}>
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
        {!TooltipCompanyInfo && (
          <Tooltip
            message={
              <div>
                <span>
                  Company Name <br />
                  Address <br />
                  City, State and Zip
                  <br />
                  Phone Number
                  {!hideExtraline && (
                    <>
                      {' '}
                      <br />
                      Extra Line
                    </>
                  )}
                </span>
                {allCaps && <div style={{ marginTop: '10px' }}>*Text is in all caps</div>}
              </div>
            }
          >
            <span className="circle-info-span">
              <CircleInfoIcon size="medium" />
            </span>
          </Tooltip>
        )}
      </h3>
      <div className="company-information-text-wrapper">
        <TextField
          theme="quickbooks"
          type="text"
          placeholder="Company Name"
          className="ids-text-field text-field-hide-border"
          width="100%"
          value={company.CompanyName}
          onChange={(e) => handleTextChanged('CompanyName', e.target.value)}
          onBlur={(e) => handleCompanyTextChanged('CompanyName', e.target.value)}
        />
        <TextField
          theme="quickbooks"
          type="text"
          placeholder="Address"
          className="ids-text-field text-field-hide-border"
          width="100%"
          value={company.AddressLine1}
          onChange={(e) => handleTextChanged('AddressLine1', e.target.value)}
        />
        <TextField
          theme="quickbooks"
          type="text"
          placeholder="City, State and Zip"
          className="ids-text-field text-field-hide-border"
          width="100%"
          value={company.AddressLine2}
          onChange={(e) => handleTextChanged('AddressLine2', e.target.value)}
        />
        <TextField
          theme="quickbooks"
          type="text"
          placeholder="Phone"
          className="ids-text-field text-field-hide-border"
          width="100%"
          value={company.Phone}
          onChange={(e) => handleTextChanged('Phone', e.target.value)}
        />
        {!hideExtraline && (
          <TextField
            theme="quickbooks"
            type="text"
            placeholder="Extra Line"
            className="ids-text-field text-field-hide-border"
            width="100%"
            value={company.ExtraLine}
            onChange={(e) => handleTextChanged('ExtraLine', e.target.value)}
          />
        )}
      </div>
    </div>
  );
};
