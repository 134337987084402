export const IMPRINTS_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/imprints',
};

export const DATA_ISLANDS_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/dataislands',
};

export const MISC_VARIANT_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v3/miscdata/variant',
};

export const GET_BANK_SHOPPER_INFO_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/shopperaccount/getshopperinfowithbankdetails',
};

export const GET_BANK_INFO_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v3/miscdata/bankinfo',
};

export const LOGO_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/logos',
};

export const FDS_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/Fds',
};

export const LAUNCH_NEW_BUNDLE_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/productImprint/Launchnewbundle',
};

export const LAUNCH_EDIT_BUNDLE_ENDPOINT = {
  url: 'imrestfulservices/im.simpleservices/api/v1/productImprint/HandleEditBundle',
};

export const ADD_BUNDLE_TO_CART = {
  url: 'imrestfulservices/im.simpleservices/api/v1/productImprint/AddBundleProducts',
};
