// eslint-disable-next-line require-jsdoc
import React from 'react';
import '../../../../stylesheets/customization/stamps.scss';

// eslint-disable-next-line require-jsdoc
export const FaxHeaderCoverStampImage = (props) => {
  const { company, fromModal, image, yourName } = props;

  return (
    <div
      className={
        !fromModal ? 'fax-header-stamp-image-container' : 'fax-header-stamp-modal-image-container'
      }
    >
      <img style={{ maxWidth: '100%' }} alt="..." src={image} />
      <div className="image-company-info">
        {yourName ? <div>{yourName}</div> : <div className="blank-div" />}
        {company.CompanyName ? <div>{company.CompanyName}</div> : <div className="blank-div" />}
        {company.Phone ? <div>{company.Phone}</div> : <div className="blank-div" />}
        {company.ExtraLine ? <div>{company.ExtraLine}</div> : <div className="blank-div" />}
      </div>
    </div>
  );
};
