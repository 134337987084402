// eslint-disable-next-line require-jsdoc
import React, { useEffect } from 'react';
import Dropdown, { MenuItem } from '@ids-ts/dropdown';
// import Radio, { RadioGroup } from '@ids/radio';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@ids-beta/tooltip';
import { CircleInfoIcon } from '@ids-ts/icon';
import { fontColor, fontFamily, ReduxEvent } from '../../../../assets/pagedata/constant';

// eslint-disable-next-line require-jsdoc
export const CustomAttributesComponent = (props) => {
  const { showFontFamily, showInkColor, showSingleColor, TooltipFontInfo, TooltipInkInfo } = props;
  const customizationReducer = useSelector((state) => state.customizationReducer);
  const {
    selectedFontColor,
    selectedFontFamily,
    logo,
    logoColor,
    shadowLogo,
    shadowLogoColor,
    companyInfoColor,
    secondaryColor,
    validationError,
  } = customizationReducer;

  const dispatch = useDispatch();

  const handleChangeFontFamily = (e) => {
    const fontFamilyData = e.target.value;
    dispatch({ type: ReduxEvent.FONT_FAMILY_CHANGED, data: { fontFamilyData } });
  };

  const handleChangeColor = (e) => {
    const fontColorData = e.target.value;
    dispatch({ type: ReduxEvent.FONT_COLOR_CHANGED, data: { fontColorData } });
    dispatch({
      type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
      data: {
        companyInfoColor: '',
        logoColor: '',
        shadowLogoColor: '',
        secondaryColor: '',
        validationError: false,
      },
    });
  };

  const handleChangeSecondaryColor = (key, e) => {
    const { value } = e.target;
    let areColorsSame = false;
    switch (key) {
      case 'companyInfoColor':
        if (shadowLogo && logo) {
          areColorsSame = logoColor === value && shadowLogoColor === value;
        } else if (logoColor) {
          areColorsSame = logoColor === value;
        } else if (shadowLogoColor) {
          areColorsSame = shadowLogoColor === value;
        }
        break;

      case 'logoColor':
        if (shadowLogo) {
          areColorsSame = companyInfoColor === value && shadowLogoColor === value;
        } else {
          areColorsSame = companyInfoColor === value;
        }
        break;

      case 'shadowLogoColor':
        if (logo) {
          areColorsSame = companyInfoColor === value && logoColor === value;
        } else {
          areColorsSame = companyInfoColor === value;
        }
        break;

      default:
        break;
    }

    if (areColorsSame) {
      dispatch({ type: ReduxEvent.FONT_COLOR_CHANGED, data: { fontColorData: value } });
      dispatch({
        type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
        data: { companyInfoColor: '', logoColor: '', shadowLogoColor: '', secondaryColor: '' },
      });
    } else {
      dispatch({ type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE, data: { [key]: value } });
    }
  };

  const getFontColors = () => {
    if ((logo || shadowLogo) && !showSingleColor) {
      return fontColor;
    }
    return fontColor.filter((x) => x.color !== 'DoubleColor');
  };

  const updateSecondaryColor = (e) => {
    const { value } = e.target;
    dispatch({
      type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE,
      data: { companyInfoColor: '', logoColor: '', shadowLogoColor: '', secondaryColor: value },
    });
  };

  useEffect(() => {
    if (!logo) {
      dispatch({ type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE, data: { logoColor: '' } });
    }

    if (!shadowLogo) {
      dispatch({ type: ReduxEvent.CUSTOMIZATION_UPDATE_ANY_STATE, data: { shadowLogoColor: '' } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo, shadowLogo]);

  return (
    <div>
      <p className="component-header margin-bottom-20">
        Optional: Change {showFontFamily ? 'font' : ''} {showFontFamily && showInkColor ? 'or' : ''}{' '}
        {showInkColor ? 'font color' : ''}{' '}
      </p>
      {showFontFamily && (
        <div>
          <p
            className="margin-bottom-10"
            style={{ fontSize: '16px', color: '#393a3D;', fontWeight: '500' }}
          >
            Select a different font{' '}
            <Tooltip
              message={
                TooltipFontInfo || (
                  <div>
                    <span>Select a different font </span>
                  </div>
                )
              }
            >
              <span className="circle-info-span">
                <CircleInfoIcon size="medium" />
              </span>
            </Tooltip>
          </p>
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className={`width-100-dropdown dropdown-background-color-white ${selectedFontFamily.toLowerCase()}`}
            width="inherit"
            aria-label="quantity"
            onChange={handleChangeFontFamily}
            value={selectedFontFamily}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | select_font_family"
            data-ui-object-details="Select Font Family"
          >
            {fontFamily.map((a) => {
              return (
                <MenuItem key={a.key} value={a.key} className={a.key}>
                  {a.value}
                </MenuItem>
              );
            })}
          </Dropdown>
        </div>
      )}
      {showInkColor && (
        <div>
          <p
            className="margin-bottom-10 margin-top-20"
            style={{ fontSize: '16px', color: '#393a3D;', fontWeight: '500' }}
          >
            Add a different font or ink color{' '}
            <Tooltip
              message={
                TooltipInkInfo || (
                  <div>
                    <span>Add a different font or ink color</span>
                  </div>
                )
              }
            >
              <span className="circle-info-span">
                <CircleInfoIcon size="medium" />
              </span>
            </Tooltip>
          </p>
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-100-dropdown dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            onChange={handleChangeColor}
            value={selectedFontColor}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | select_font_color"
            data-ui-object-details="Select Font Color"
          >
            {getFontColors().map((a) => {
              return (
                <MenuItem key={a.color} value={a.color}>
                  {`${a.title} (${a.price})`}
                </MenuItem>
              );
            })}
          </Dropdown>
        </div>
      )}
      {(logo || shadowLogo) && selectedFontColor === 'DoubleColor' && (
        <div>
          <p
            className="margin-top-20 margin-bottom-20"
            style={{ fontSize: '16px', color: '#393a3D;', fontWeight: '500' }}
          >
            Select a secondary color and a color for each item{' '}
            <Tooltip
              message={
                <div>
                  <span>Select a secondary color and a color for each item</span>
                </div>
              }
            >
              <span className="circle-info-span">
                <CircleInfoIcon size="medium" />
              </span>
            </Tooltip>
          </p>

          <p className="margin-bottom-10" style={{ fontSize: '16px' }}>
            Select the secondary color
          </p>
          <Dropdown
            data-wa-link="quantity"
            theme="quickbooks"
            buttonType="secondaryGhost"
            className="width-100-dropdown margin-bottom-20 dropdown-background-color-white"
            width="inherit"
            aria-label="quantity"
            onChange={updateSecondaryColor}
            value={secondaryColor}
            placeholder="Select Color"
            errorText={validationError && !secondaryColor ? 'Please select a secondary color.' : ''}
            data-ui-object="dropdown"
            data-refer-ui-access-point="customization_page | select_secondary_color"
            data-ui-object-details="Select Secondary Color"
          >
            {fontColor
              .filter((x) => x.color !== 'DoubleColor' && x.color !== 'Black')
              .map((a) => {
                return (
                  <MenuItem key={a.color} value={a.color}>
                    {a.title}
                  </MenuItem>
                );
              })}
          </Dropdown>

          <div className="">
            <p className="margin-bottom-10" style={{ fontSize: '16px' }}>
              Select company information font color
            </p>
            <Dropdown
              data-wa-link="quantity"
              theme="quickbooks"
              buttonType="secondaryGhost"
              className="width-100-dropdown dropdown-background-color-white"
              width="inherit"
              aria-label="quantity"
              onChange={(e) => handleChangeSecondaryColor('companyInfoColor', e)}
              value={companyInfoColor}
              placeholder={secondaryColor ? 'Select Color' : 'Select Secondary Color'}
              errorText={
                validationError && !companyInfoColor
                  ? 'Please select a company information font color.'
                  : ''
              }
              data-ui-object="dropdown"
              data-refer-ui-access-point="customization_page | select_color"
              data-ui-object-details="Select Color"
            >
              {secondaryColor ? (
                fontColor
                  .filter((x) => x.color === 'Black' || x.color === secondaryColor)
                  .map((a) => {
                    return (
                      <MenuItem key={a.color} value={a.color}>
                        {a.title}
                      </MenuItem>
                    );
                  })
              ) : (
                <MenuItem disabled value="0">
                  Select Secondary Color
                </MenuItem>
              )}
            </Dropdown>

            {logo && (
              <>
                <p className="margin-bottom-10 margin-top-20" style={{ fontSize: '16px' }}>
                  Select logo ink color
                </p>
                <Dropdown
                  data-wa-link="quantity"
                  theme="quickbooks"
                  buttonType="secondaryGhost"
                  className="width-100-dropdown dropdown-background-color-white"
                  width="inherit"
                  aria-label="quantity"
                  onChange={(e) => handleChangeSecondaryColor('logoColor', e)}
                  value={logoColor}
                  placeholder={secondaryColor ? 'Select Color' : 'Select Secondary Color'}
                  errorText={validationError && !logoColor ? 'Please select a logo ink color' : ''}
                  data-ui-object="dropdown"
                  data-refer-ui-access-point="customization_page | select_logo_color"
                  data-ui-object-details="Select Logo Color"
                >
                  {secondaryColor ? (
                    fontColor
                      .filter((x) => x.color === 'Black' || x.color === secondaryColor)
                      .map((a) => {
                        return (
                          <MenuItem key={a.color} value={a.color}>
                            {a.title}
                          </MenuItem>
                        );
                      })
                  ) : (
                    <MenuItem disabled value="0">
                      Select Secondary Color
                    </MenuItem>
                  )}
                </Dropdown>
              </>
            )}

            {shadowLogo && shadowLogo.LogoFileNumber !== '' && (
              <>
                <p className="margin-bottom-10 margin-top-20" style={{ fontSize: '16px' }}>
                  Select shadow logo ink color
                </p>
                <Dropdown
                  data-wa-link="quantity"
                  theme="quickbooks"
                  buttonType="secondaryGhost"
                  className="width-100-dropdown dropdown-background-color-white"
                  width="inherit"
                  aria-label="quantity"
                  onChange={(e) => handleChangeSecondaryColor('shadowLogoColor', e)}
                  value={shadowLogoColor}
                  placeholder={secondaryColor ? 'Select Color' : 'Select Secondary Color'}
                  errorText={
                    validationError && !shadowLogoColor
                      ? 'Please assign a shadow logo ink color'
                      : ''
                  }
                  data-ui-object="dropdown"
                  data-refer-ui-access-point="customization_page | select_shadow_logo_color"
                  data-ui-object-details="Select Shadow Logo Color"
                >
                  {secondaryColor ? (
                    fontColor
                      .filter((x) => x.color === 'Black' || x.color === secondaryColor)
                      .map((a) => {
                        return (
                          <MenuItem key={a.color} value={a.color}>
                            {a.title}
                          </MenuItem>
                        );
                      })
                  ) : (
                    <MenuItem disabled value="0">
                      Select Secondary Color
                    </MenuItem>
                  )}
                </Dropdown>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
